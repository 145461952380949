import firebase from 'firebase/app'
// import Vue from 'vue'
import 'firebase/firebase-messaging'
import { firebaseConfig, vapidKey } from '@src/services/.env.js'
export default async function firebaseInitialize() {
  firebase.initializeApp(firebaseConfig)
  const messaging = firebase.messaging()
  messaging
    .getToken(vapidKey)
    .then((deviceToken) => {
      if (deviceToken) {
        console.log('Device Token', deviceToken)
        localStorage.setItem('fcmToken', deviceToken)
      } else {
        console.log('No registration token available. Request permission to generate one.')
      }
    })
    .catch((err) => {
      console.log('token error')
      console.log('An error occurred while retrieving token. ', err)
    })

  messaging.onMessage((payload) => {
    console.log('payload', payload)
    const notification = new Notification(payload.data.title, {
      body: payload.data.body,
      icon: require(`@src/assets/images/logo-green.png`),
    })
    notification.onclick = (event) => {
      event.preventDefault() // prevent the browser from focusing the Notification's tab
      window.open(window.location.host)
    }
  })
}
