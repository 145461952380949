import { TENNAT_API_URL } from '@services/.env'
import { BaseService } from './base.service'
import { by, del } from './custom.service'

export class SubjectService extends BaseService {
  static createSubject({ subject, id = null }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}subjects`, subject, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not mart attendance'
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static assignTeacherToSubject(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}subjects/assign_teacher`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }
  static getSubjectStats(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}/subjects/subject_stats`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }
  static deleteSubject(id) {
    return new Promise((resolve, reject) => {
      return del(`${TENNAT_API_URL}subjects/deactivate`, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error))
        })
    })
  }

  static getSubjects(query) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}subjects/filter`, query)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Subjects list'
          reject(this.errorWrapper(error, message))
        })
    })
  }
}
