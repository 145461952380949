import { accessLevel } from '@utils/generalUtil'
import { FEATURES_ENVIRONMENT } from '@services/.env'
import store from '@src/state/store'
const PermissionDirective = {
  mounted(el, binding, vnode) {
    const value = binding.value
    if (!value) return
    let currentCampusScope = store.state.layout.currentCampusScope
    let currentSectionScope = store.state.layout.currentSectionScope
    let currentClassScope = store.state.layout.currentClassScope
    let activeRole = store.state.layout.activeRole
    el.initialDisplay = window.getComputedStyle(el).display
    checkForAuthentication(value, currentCampusScope, currentSectionScope, currentClassScope)

    /* CAMPUS CHECK */
    const unwatchCampus = store.watch(
      () => store.state.layout.currentCampusScope,
      (campus) => {
        checkForAuthentication(value, campus, '')
      },
    )

    /* Section CHECK */
    const unwatchSection = store.watch(
      () => store.state.layout.currentSectionScope,
      (section) => {
        checkForAuthentication(value, currentCampusScope, section)
      },
    )

    /* Section CHECK */
    const unwatchClass = store.watch(
      () => store.state.layout.currentClassScope,
      (selectedClass) => {
        checkForAuthentication(value, currentCampusScope, currentSectionScope, selectedClass)
      },
    )

    el.unwatch = [unwatchCampus, unwatchSection, unwatchClass]

    function hideElement(el) {
      el.style.display = 'none'
    }

    function showElement(el) {
      el.style.display = el.initialDisplay ? el.initialDisplay : 'inherit'
    }

    function checkForAuthentication(item, campus, section, selectedClass) {
      let authenticate = accessLevel(item, campus, section, selectedClass)
      if (authenticate && value.roles && value.roles.length) {
        authenticate = value.roles.includes(activeRole)
      }
      if (authenticate && FEATURES_ENVIRONMENT && value.env && value.env.length) {
        authenticate = value.env.includes(FEATURES_ENVIRONMENT)
      }
      if (authenticate) {
        showElement(el)
      } else {
        hideElement(el)
      }
    }
  },
  /* UNWATCH THE WATCHERS */
  beforeUnmount(el) {
    if (el && el.unwatch) {
      el.unwatch.forEach((unwatch) => {
        unwatch()
      })
    }
  },
}

export default PermissionDirective
