// import { UsersService } from '@src/services/users.service'
import i18n from '@src/i18n'
import StaffService from './services'
import { fullName } from '@src/utils/settings/tenant-user.util.js'

const t = i18n?.global?.t

export default {
  createStaff({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return StaffService.createStaff(payload)
        .then((response) => {
          commit(
            'toast/NEW',
            { message: t(`toast.Successfully Created`), type: 'success' },
            { root: true },
          )
          resolve(response.data)
        })
        .catch((error) => {
          commit('layout/IS_LOADING', false, { root: true })
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  updateStaff({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return StaffService.updateStaff(payload)
        .then((response) => {
          commit('toast/NEW', { message: 'Updated Successfully', type: 'success' }, { root: true })
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  getUsers({ commit }, payload) {
    return new Promise((resolve, reject) => {
      StaffService.getAllUsers(payload)
        .then((response) => {
          let userList = response.data.records
          userList.forEach((user) => {
            user.full_name = `${user.first_name} ${user.last_name}`
          })
          commit('SET_USER_LIST', userList)
          resolve(response, response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getUserDetails({ commit }, { id, payload }) {
    return new Promise((resolve, reject) => {
      StaffService.getUserById(id, payload)
        .then((response) => {
          let user = response.data
          user.full_name = `${user.first_name} ${user.last_name}`
          resolve(response, response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getPickupPersonDetails({ commit }, payload) {
    return new Promise((resolve, reject) => {
      StaffService.getPickupPersonById(payload)
        .then((response) => {
          let user = response.data
          user.full_name = `${user.first_name} ${user.last_name}`
          resolve(response, response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getGuardianDetails({ commit }, payload) {
    return new Promise((resolve, reject) => {
      StaffService.getGuardianById(payload)
        .then((response) => {
          let user = response.data
          user.full_name = `${user.first_name} ${user.last_name}`
          resolve(response, response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getSectionStudentDetails({ commit }, payload) {
    return new Promise((resolve, reject) => {
      StaffService.getSectionStudentById(payload)
        .then((response) => {
          let user = response.data
          user.full_name = `${user.first_name} ${user.last_name}`
          resolve(response, response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getSectionTeacherDetails({ commit }, payload) {
    return new Promise((resolve, reject) => {
      StaffService.getSectionTeacherById(payload)
        .then((response) => {
          let user = response.data
          user.full_name = `${user.first_name} ${user.last_name}`
          resolve(response, response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getCampusAdminDetails({ commit }, { id, payload }) {
    return new Promise((resolve, reject) => {
      StaffService.getCampusAdminById(id, payload)
        .then((response) => {
          let user = response.data
          user.full_name = `${user.first_name} ${user.last_name}`
          resolve(response, response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getOnlyUserofDetails({ commit }, { id, payload }) {
    return new Promise((resolve, reject) => {
      StaffService.getOnlyUserofDetailsById(id, payload)
        .then((response) => {
          let user = response.data
          fullName(user)
          resolve(response, response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getStaffDetails({ commit }, { id, payload }) {
    return new Promise((resolve, reject) => {
      StaffService.getStaffById(id, payload)
        .then((response) => {
          let user = response.data
          user.full_name = `${user.first_name} ${user.last_name}`
          resolve(response, response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getGuardianInformationDetails({ commit }, { id, payload }) {
    return new Promise((resolve, reject) => {
      StaffService.getGuardianInformationById(id, payload)
        .then((response) => {
          let user = response.data
          fullName(user)
          resolve(response, response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setUserRole({ commit }, payload) {
    return new Promise((resolve, reject) => {
      StaffService.setUserRole(payload)
        .then((response) => {
          commit(
            'toast/NEW',
            { message: t(`toast.Successfully Added Role`), type: 'success' },
            { root: true },
          )
          resolve(response, response.data)
        })
        .catch((error) => {
          commit('layout/IS_LOADING', false, { root: true })
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  // Get all user

  getAllStaff({ commit }, payload) {
    return new Promise((resolve, reject) => {
      StaffService.getAllStaff(payload)
        .then((response) => {
          resolve(response, response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getStaffWithFilters({ commit }, payload) {
    return new Promise((resolve, reject) => {
      StaffService.getStaffWithFilters(payload)
        .then((response) => {
          response.data.records.forEach((user) => {
            fullName(user)
          })
          resolve(response, response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getStaffStats({ commit }, payload = '') {
    return new Promise((resolve, reject) => {
      return StaffService.getStaffStats(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  loadStaffAttendance({ commit }, payload) {
    return new Promise((resolve, reject) => {
      StaffService.loadStaffAttendance(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  markStaffAttendance({ commit }, payload = {}) {
    return new Promise((resolve, reject) => {
      StaffService.markStaffAttendance(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  fetchStaffAndTeacherList({ commit }, payload = {}) {
    return new Promise((resolve, reject) => {
      StaffService.fetchStaffAndTeacherList(payload)
        .then((response) => {
          response?.data?.records.forEach((staff) => fullName(staff))
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
}
