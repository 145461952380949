<template>
  <div>
    <UiSingleSelect
      v-model="currentClass"
      title="CLASS"
      label="title"
      :select-object-without-reduce-key="true"
      :search-through-api="true"
      :options="usersClassList"
      class="flex-1"
      :disabled="!currentCampusScope"
      drop-down-container="w-full md:top-11  text-xxs sm:text-xs md:text-xs lg:text-sm top-8"
      select-box-classes="lg:h-11 md:h-9 sm:h-11 h-8"
      container-classes="lg:min-w-[210px] md:min-w-[158px] min-w-[140px]"
      title-classes="lg:text-sm md:text-xxs text-[9px]"
      placeholder="SELECT_CLASS"
      input-value-classes="text-xxs md:text-xs lg:text-sm"
      @search="filterClassList"
      @deSelect="switchClass(currentClass)"
      @change="switchClass(currentClass)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import UiSingleSelect from '@components/UiElements/UiSingleSelect.vue'
import { buildWhereQuery } from '@utils/filters/index'

export default {
  components: {
    UiSingleSelect,
  },
  data() {
    return {
      classesNotAllowerdFor: ['pickup_person'],
      currentClass: '',
    }
  },
  computed: {
    ...mapState({
      currentCampusScope: (state) => state.layout.currentCampusScope,
      currentClassScope: (state) => state.layout.currentClassScope,
      classRoles: (state) => state.layout.currentSelectedSectionRoles,
      activeRole: (state) => state.layout.activeRole,
      usersClassList: (state) => state.classes.usersClassList,
    }),
  },
  watch: {
    currentCampusScope: {
      handler() {
        this.getUserClasses()
        this.setCurrentSelectedClass('')
        this.currentClass = ''
      },
    },
  },
  created() {
    if (this.currentCampusScope && !this.classesNotAllowerdFor.includes(this.activeRole)) {
      this.getUserClasses()
    }
    if (this.currentClassScope?.id) {
      this.currentClass = this.currentClassScope
    }
  },
  methods: {
    switchClass(selectedClass) {
      this.setCurrentSelectedClass(selectedClass)
      this.setcurrentSelectedSection('')
      this.getUserClasses()
    },
    filterClassList(query = '') {
      const payload = {
        $where: { ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query) },
      }
      this.getUserClasses(payload)
    },
    ...mapActions('layout', ['setcurrentSelectedSection', 'setCurrentSelectedClass']),
    ...mapActions('classes', ['getUserClasses']),
  },
}
</script>
