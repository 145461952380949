export default {
  methods: {
    displayError(message) {
      this.errorHandler()
      if (this.isErrorAvailable) return message
    },
    errorHandler() {
      setTimeout(() => {
        if (
          this.confirmPasswordModelValue !== 'undefined' &&
          this.confirmPasswordModelValue === this.fieldValue
        ) {
          this.isErrorAvailable = false
        }
        this.isErrorAvailable =
          this.$refs?.errorContainer?.firstElementChild?.classList[0] === 'error-msg'
      }, 0)
    },
  },
}
