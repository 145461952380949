<template>
  <div>
    <div
      class="absolute top-9 tooltip tooltip-left flex w-auto px-2.5 py-3.5 bg-white items-center rounded-md font-roboto tooltip-shadow z-60"
      :class="[customClasses]"
    >
      <div class="flex flex-col gap-1 flip-tooltip ltr:order-2 rtl:order-1 items-start">
        <div class="text-xs lg:text-sm font-medium text-text-color capitalize">
          {{ title }}
        </div>
        <div :class="[textExtraSmall ? 'text-xxs' : 'text-xxs lg:text-xs']">
          <div class="text-primary-grey-light">
            {{ description }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import icon from '@components/icons/icon.vue'

export default {
  components: {
    // icon,
  },
  props: {
    title: { type: [String, Number], default: 'title' },
    description: { type: String, default: 'des' },
    customClasses: { type: String, default: '' },
    textExtraSmall: { type: Boolean, default: false },
  },
}
</script>

<style lang="scss" scoped>
.tooltip {
  &::before {
    position: absolute;
    top: -7px;
    width: 13px;
    height: 13px;
    content: '';
    background: var(--bg-white);
    // box-shadow: 1px 1px 15px #00000033;
    border-top: 1px solid var(--text-color-info);
    border-left: 1px solid var(--text-color-info);
    transform: rotate(45deg);
  }
  &-right {
    &::before {
      right: 28px;
      @media (max-width: 450px) {
        right: 95px;
      }
    }
  }
  &-left {
    &::before {
      left: 17px;
    }
  }
}

.tooltip-shadow {
  left: -2px;
  box-shadow: 1px 1px 15px #0003;
  &::before {
    margin-left: 12px;
    border: none;
  }
}
.tooltip-border {
  border: none;
}
.tooltip-width {
  min-width: 132px;
}
.tooltip-padding {
  padding: 2px;
  padding-left: 10px;
}
.tooltip-margin {
  margin-top: 40px;
  margin-left: -10px;
}

body[dir='ltr'] {
  .flip-tooltip-right-bar {
    -ms-filter: fliph; /* IE */
    filter: fliph; /* IE */
    -moz-transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -o-transform: scaleX(1);
    transform: scaleX(1);
  }
}

body[dir='rtl'] {
  .flip-tooltip,
  .flip-tooltip-right-bar {
    -ms-filter: fliph; /* IE */
    filter: fliph; /* IE */
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  .tooltip {
    &::before {
      top: -8px;
    }
  }
}

@media (max-width: 769px) {
  body[dir='ltr'] {
    .tooltip {
      &-right {
        &::before {
          right: 16px;
        }
      }
    }
  }

  body[dir='rtl'] {
    .tooltip {
      &-right {
        &::before {
          top: -7px;
          right: 16px;
        }
      }
    }
  }
}

/* IPAD TO LOWER UPTO 810 PX WIDTH */
@media screen and (max-width: 810px) {
  body[dir='ltr'] {
    .tooltip {
      .leftbartooltip {
        margin-left: 9px !important;
      }
      &-left {
        &::before {
          top: -7px;
          right: 16px;
        }
      }
    }
  }

  body[dir='rtl'] {
    .tooltip {
      .leftbartooltip {
        margin-right: 14px !important;
      }
      &-left {
        &::before {
          left: 15px !important;
        }
      }
    }
  }
}

/* UPTO 600 PX WIDTH */
@media screen and (max-width: 600px) {
  body[dir='ltr'] {
    .tooltip {
      .leftbartooltip {
        margin-left: 80px !important;
      }
    }
  }
  body[dir='rtl'] {
    .tooltip {
      .leftbartooltip {
        margin-right: 82px !important;
      }
      &-left {
        &::before {
          left: 13px !important;
        }
      }
    }
  }
}

/* EXCEPTIONAL CASE */
@media only screen and (min-width: 450px) and (max-width: 600px) {
  .tooltip {
    &::before {
      position: relative !important;
      top: -32px !important;
      left: 5px !important;
    }
  }
}
</style>

<style lang="scss" module>
.toolTip {
  top: 70px;
  right: 195px;
  z-index: 5;
}
</style>
