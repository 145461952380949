<template>
  <div>
    <div>
      <Field name="file">
        <input
          :id="randomID"
          ref="imgUpload"
          type="file"
          class="input-field w-100"
          hidden
          :rules="rules"
          name="file"
          accept="image/png, image/jpeg, image/jpg"
          @input="changeImage"
        />
      </Field>
    </div>

    <label
      :for="randomID"
      class="flex w-full items-center cursor-pointer border px-2 sm:px-5 pt-2 pb-2 rounded-md"
      :class="rightbarImageUpload ? 'border-border-button' : 'border-primary-grey'"
    >
      <span>
        <icon
          class="icon ltr:mr-1 rtl:ml-1"
          :icon="'cameraIcon' ? 'cloudUpload' : 'camera'"
          color="primary-green"
          height="20"
          width="20"
        />
      </span>
      <span
        class="font-roboto font-normal text-xs sm:text-base"
        :class="rightbarImageUpload ? 'text-primary-green' : 'text-menu'"
      >
        {{ label }}
      </span>
    </label>
    <div ref="errorContainer" class="flex justify-end h-5">
      <ErrorMessage v-slot="{ message }" :name="name === '' ? title : name">
        <small class="error-msg text-text-color-danger text-sm italic">
          <p>{{ message }}</p>
        </small>
      </ErrorMessage>
    </div>
  </div>
</template>

<script>
import icon from '@components/icons/icon.vue'
import { Field, configure, ErrorMessage } from 'vee-validate'
import { validationConfiguration } from '@src/vee-validate/index.js'
configure(validationConfiguration)

export default {
  name: 'UploadImage',
  components: {
    icon,
    ErrorMessage,
    Field,
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    title: { type: String, default: '' },
    rightbarImageUpload: {
      type: Boolean,
      default: false,
    },
    cameraIcon: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Array],
      default: 'size:500',
    },
    id: {
      type: String,
      default: 'imageSelector',
    },
    label: {
      type: String,
      default: 'file',
    },
    values: {
      type: String,
      default: 'file',
    },
  },
  emits: ['update:modelValue', 'preview'],
  data() {
    return {
      image: {
        fileName: '',
        url: '',
      },
    }
  },
  computed: {
    randomID() {
      return Math.random() * 10
    },
  },
  methods: {
    changeImage(e) {
      let image = {
        fileName: '',
        url: '',
      }
      const input = this.$refs.imgUpload
      const file = input.files
      this.$emit('update:modelValue', file[0])
      if (file && file[0]) {
        const reader = new FileReader()
        reader.onload = (e) => {
          image.url = e.target.result
          image.fileName = file[0].name
        }
        this.$emit('preview', image)
        reader.readAsDataURL(file[0])
      }
    },
  },
}
</script>
