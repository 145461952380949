export const siteSettingParser = function (name, setting) {
  let obj = {}
  const excludedEditableSettings = ['image', 'favicon', 'title', 'manual_password_reset_allowed']
  const defaultValueObj = {
    default_currency: 'PKR',
    default_language: 'en',
    default_theme: 'green',
    default_timezone: 'Asia/Karachi',
    format_of_date: 'YYYY-MM-DD',
    max_file_size: 500,
    permissible_languages: ['en', 'ur', 'ar'],
    permissible_themes: ['green', 'maroon', 'green dark', 'maroon dark'],
    week_start_day: 'monday',
  }
  if (!setting && !excludedEditableSettings.includes(name)) {
    obj[name] = defaultValueObj[name] || ''
    obj[`is_editable_${name}`] = true
    obj[`is_locked_${name}`] = false
  } else obj = setting

  return obj
}
