<template>
  <!-- <div class="loader"></div> -->
  <div :class="content ? 'loading-spinner-content-container' : 'loading-spinner-container'">
    <div
      class="loader"
      :class="{ 'loader-content': content, 'loader-light-bg': isBackgroundLight }"
    >
      <div><div></div></div>
      <div><div></div></div>
      <div><div></div></div>
      <div><div></div></div>
      <div><div></div></div>
      <div><div></div></div>
      <div><div></div></div>
      <div><div></div></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Boolean,
      default: false,
    },
    isBackgroundLight: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
// .loader {
//   width: 20px;
//   height: 20px;
//   margin: auto;
//   border: 3px solid white;
//   border-top: 3px solid black;
//   border-radius: 50%;
//   animation: spin 0.3s linear infinite;
// }

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner-container {
  width: 20px;
  height: 20px;
  background: none;
}
.loading-spinner-content-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: none;
}
.loader {
  position: relative;
  width: auto;
  height: auto;
  transform: translateZ(0) scale(0.2);
  transform-origin: 0 0; /* see note above */
  backface-visibility: hidden;
}
.loader-content {
  position: relative;
  transform: translateX(-50px) translateZ(0) scale(1.2);
  transform-origin: 0 0; /* see note above */
  backface-visibility: hidden;
}
.loader div {
  box-sizing: content-box;
}
@keyframes loadingAnimation {
  0% {
    opacity: 1;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1.5, 1.5);
  }
  100% {
    opacity: 0;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1, 1);
  }
}
.loader div > div {
  position: absolute;
  width: 12px;
  height: 12px;
  background: var(--primary-white);
  border-radius: 50%;
  animation: loadingAnimation 1s linear infinite;
}
.loader-content div > div {
  background: var(--primary-purple-600);
}
.loader-light-bg div > div {
  background: var(--primary-purple-600);
}
.loader > div:nth-child(1) {
  transform: rotate(0deg);
  transform-origin: 84px 50px;
}
.loader > div:nth-child(2) {
  transform: rotate(45deg);
  transform-origin: 74px 74px;
}
.loader > div:nth-child(3) {
  transform: rotate(90deg);
  transform-origin: 50px 84px;
}
.loader > div:nth-child(4) {
  transform: rotate(135deg);
  transform-origin: 26px 74px;
}
.loader > div:nth-child(5) {
  transform: rotate(180deg);
  transform-origin: 16px 50px;
}
.loader > div:nth-child(7) {
  transform: rotate(270deg);
  transform-origin: 50px 16px;
}
.loader > div:nth-child(8) {
  transform: rotate(315deg);
  transform-origin: 74px 26px;
}
.loader div:nth-child(1) > div {
  top: 44px;
  left: 78px;
  animation-delay: -0.875s;
}
.loader div:nth-child(2) > div {
  top: 68px;
  left: 68px;
  animation-delay: -0.75s;
}
.loader div:nth-child(3) > div {
  top: 78px;
  left: 44px;
  animation-delay: -0.625s;
}
.loader div:nth-child(4) > div {
  top: 68px;
  left: 20px;
  animation-delay: -0.5s;
}
.loader div:nth-child(5) > div {
  top: 44px;
  left: 10px;
  animation-delay: -0.375s;
}
.loader div:nth-child(6) > div {
  top: 20px;
  left: 20px;
  animation-delay: -0.25s;
}
.loader div:nth-child(7) > div {
  top: 10px;
  left: 44px;
  animation-delay: -0.125s;
}

.loader div:nth-child(8) > div {
  top: 20px;
  left: 68px;
  animation-delay: 0s;
}

/* generated by https://loading.io/ */
</style>
