import { Socket } from 'phoenix'
import { API_URL, SOCKET_SCHEME } from '@services/.env'
import { ENV } from '@src/constants/general-constants.js'

export default {
  install(app, options) {
    // Notifier Socket
    app.config.globalProperties.$connectSocket = (url, token) => {
      const socketConfig = {
        params: { token },
      }
      if (process.env.VUE_APP_FEATURES_ENVIRONMENT === ENV.DEV) {
        socketConfig.logger = (kind, msg, data) => {
          console.log(`${kind}: ${msg}`, data)
        }
      }
      const socket = new Socket(`${SOCKET_SCHEME}://${API_URL}${url}`, socketConfig)

      socket.connect()
      socket.channel(url)

      socket.onError(() => {
        socket.disconnect()
      })

      app.config.globalProperties.$socket = socket
    }

    // Tenant socket

    app.config.globalProperties.$connectPickupSocket = function (url, token) {
      const socket = new Socket(`${SOCKET_SCHEME}://${API_URL}${url}`, {
        params: { token },
      })

      socket.connect()
      socket.channel(url)

      socket.onError(() => {
        socket.disconnect()
      })

      app.config.globalProperties.$pickupSocket = socket
    }
  },
}
