import { defineRule } from 'vee-validate'
import isEmpty from 'lodash/isEmpty'
import { isValidPhoneNumber } from 'libphonenumber-js'

const maximumTextLength = 255
function checkTextLimit(value, title) {
  if (value && value.length > maximumTextLength) {
    return `${title} field has exceeded limit`
  }
}
defineRule('required', (value, params, el) => {
  let title = el.field
  if (
    (!value && value !== 0) ||
    (typeof value === 'object' && isEmpty(value)) ||
    (Array.isArray(value) && !value.length)
  ) {
    return `${title.replace(/\s*\(.*?\)\s*/g, '')} field is required`
  }
  return true
})
defineRule('maxFiles', (value, params) => {
  const maxFileLimit = parseInt(params[0], 10)
  if (value.length >= maxFileLimit) {
    return `Attached file limit (${maxFileLimit})`
  }
  return true
})
defineRule('max_value', (value, params, el) => {
  const maxValue = Number(params[0])
  const elValue = Number(value)
  const title = el.field

  if (elValue > maxValue) {
    return `${title} must be ${maxValue} or less`
  }
  return true
})
defineRule('min_value', (value, params, el) => {
  const minValue = Number(params[0])
  const elValue = Number(value)
  const title = el.field
  if (elValue < minValue) {
    return `${title} must be ${minValue} or more`
  }
  return true
})
defineRule('size', (value, params, el) => {
  if (value?.length > 1) {
    for (let i = 0; i < value.length; i++) {
      const fileSize = value[i]?.size / 1024
      const title = el.field
      const maxFileSize = params
      if (value[i] && fileSize > maxFileSize) {
        return `${title} must be up to ${maxFileSize} KB`
      }
    }
  } else {
    const fileSize = value?.size / 1000
    const title = el.field
    const maxFileSize = params
    if (value && fileSize > maxFileSize) {
      return `${title} must be up to ${maxFileSize} KB`
    }
  }
  return true
})
defineRule('email', (value, params, el) => {
  let title = el.field
  if (value && !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
    return `${title} is  not valid email`
  }
  let limitExceeded = checkTextLimit(value, title)
  if (limitExceeded) return limitExceeded
  return true
})
defineRule('validNumber', (value, args) => {
  let valid = isValidPhoneNumber(value, args[0])
  if (value && !valid) {
    return 'Phone Number is not valid'
  } else return true
})

defineRule('alpha_spaces', (value, params, el) => {
  let title = el.field

  if (value && !/(^[a-zA-Z][a-zA-Z0-9_\s-]*$)/.test(value)) {
    return `${title} is  not valid input`
  }
  let limitExceeded = checkTextLimit(value, title)
  if (limitExceeded) return limitExceeded
  return true
})
defineRule('alpha_numeric_spaces', (value, params, el) => {
  let title = el.field

  if (value && !/^[a-zA-Z0-9\s]+$/.test(value)) {
    return `${title} should be alphabets and numeric`
  }
  let limitExceeded = checkTextLimit(value, title)
  if (limitExceeded) return limitExceeded
  return true
})
defineRule('alpha_numeric_dashes', (value, params, el) => {
  let title = el.field

  if (value && !/^[a-zA-Z0-9_\s-]+$/.test(value)) {
    return `${title} is  not valid input`
  }
  let limitExceeded = checkTextLimit(value, title)
  if (limitExceeded) return limitExceeded
  return true
})

defineRule('alpha', (value, params, el) => {
  let title = el.field

  if (value && !/^[A-Za-z\s]+$/.test(value)) {
    return `${title} is  not valid input`
  }
  let limitExceeded = checkTextLimit(value, title)
  if (limitExceeded) return limitExceeded
  return true
})

defineRule('min', (value, params, el) => {
  let title = el.field

  let length = params.length && Number(params[0])

  if (value && value.length < length) {
    return `${title} must be ${length} characters`
  }
  return true
})

defineRule('max', (value, params, el) => {
  let title = el.field

  let length = params.length && Number(params[0])

  if (value && value.length > length) {
    return `${title} must be less than ${length} characters`
  }
  return true
})
defineRule('between', (value, [min, max], el) => {
  let title = el.field
  if (value.length < min || value.length > max) {
    return `${title} length must be between ${min} to ${max} `
  }

  return true
})

defineRule('numeric', (value, params, el) => {
  let title = el.field

  if (value && !/^[0-9]+$/.test(value)) {
    return `${title.replace(/\s*\(.*?\)\s*/g, '')} takes only integers`
  }
  let limitExceeded = checkTextLimit(value, title)
  if (limitExceeded) return limitExceeded
  return true
})
defineRule('double', (value, params, el) => {
  let title = el.field
  if (value && (!/^-?\d+(\.\d+)?$/.test(value) || value.length > maximumTextLength)) {
    return `${title} must be number or floating`
  }
  return true
})
export const validationConfiguration = {
  validateOnChange: true,
  validateOnInput: true,
  validateOnModelUpdate: true,
}
defineRule('alpha_dash', (value, params, el) => {
  let title = el.field

  if (value && !/(^[a-zA-Z][a-zA-Z0-9_-]*$)/.test(value)) {
    return `${title} allow alphanumeric and dashes`
  }
  let limitExceeded = checkTextLimit(value, title)
  if (limitExceeded) return limitExceeded
  return true
})

defineRule('confirmed', (value, [target]) => {
  if (value === target) {
    return true
  }
  return 'Passwords must match'
})
