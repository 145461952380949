import moment from 'moment'
import { isString, isObject, every, map, isArray } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import countries from '@src/constants/countriesArray'
import currenciesList from '@/src/constants/settings/currenciesList.json'
import DATE_TIME from '@src/constants/date-time-constants.js'
import store from '@state/store'
import { siteDomainHandler } from '@src/utils/site-utils.js'

const defaultCurrency = store.getters['layout/defaultCurrency']
export const transformTextToCapitalize = (text) => {
  let capitalizeText = text
    .split('_')
    .map((val) => val.charAt(0).toUpperCase() + val.slice(1))
    .join(' ')
  return capitalizeText
}
export const capitalizeCharacterFromSentense = (sentence = '', characterPosition = 0) =>
  sentence.charAt(characterPosition).toUpperCase() + sentence.slice(characterPosition + 1)

export function printDocument(domId) {
  let dom = document.getElementById(domId).cloneNode(true)
  let printDom = document.getElementById('containerForPrint')
  printDom.innerHTML = dom.innerHTML
  window.print()
  /* TIME OUT ADDED JUST FOR MOBILE DEVICES DELAY RENDER */
  setTimeout(() => {
    printDom.innerHTML = ''
  }, 1000)
  return
}
export function redirectToRollsAndAccessManagement(user, redirectTo) {
  localStorage.setItem('currentActiveUser', JSON.stringify(user))
  localStorage.setItem('redirectTo', redirectTo)
  this.$router?.push({ name: 'roles-and-acccess-management', params: { id: user.id } })
}
export function dropdownTopBottom(index, element, bottomClass, topClass) {
  return (index === element.length - 1 && element.length !== 1) ||
    (index === element.length - 2 && element.length !== 1 && element.length !== 2)
    ? bottomClass
    : topClass
}
export function convertedSelectedTime(currentSelectedTime) {
  return moment(currentSelectedTime, [DATE_TIME.time12H]).format(DATE_TIME.time24H)
}
export function showColumnInDataTable(filtersData, compareValue, excludedList) {
  let returnVal = true
  if (
    (filtersData &&
      filtersData.displayedColumns &&
      filtersData.displayedColumns.length &&
      !filtersData.displayedColumns.some((column) => column.dataTableName === compareValue)) ||
    !filtersData.displayedColumns.length
  ) {
    returnVal = false
  }

  if (excludedList && excludedList.length && excludedList.some((val) => val === compareValue)) {
    returnVal = true
  }
  return returnVal
}

export const objectToFormData = (
  targetObject,
  helpingObject,
  doComparison = false,
  keepNullValues = true,
) => {
  const formData = new FormData()
  for (const [key, value] of Object.entries(targetObject)) {
    if (doComparison && targetObject.hasOwnProperty(key) && helpingObject[key]) continue
    if (!keepNullValues && !value) continue
    formData.append(key, value)
  }
  return formData
}

export const upComingRow = () => {
  return {
    background: '#F2FFF2 0% 0% no-repeat padding-box',
    boxShadow: '0px 4px 6px #267C2638',
    border: '1px solid #A3D9A3',
    cursor: 'pointer',
  }
}

export const arabicDigit = (number) => {
  let myArray = number.split('')
  myArray.forEach((singleDigit, index) => {
    switch (singleDigit) {
      case '0':
        myArray[index] = '٠'
        break
      case '1':
        myArray[index] = '١'
        break
      case 2:
        myArray[index] = '٢'
        break
      case 3:
        myArray[index] = '٣'
        break
      case 4:
        myArray[index] = '٤'
        break
      case 5:
        myArray[index] = '٥'
        break
      case 6:
        myArray[index] = '٦'
      case 7:
        myArray[index] = '٧'
        break
      case 8:
        myArray[index] = '٨'
        break
      case 9:
        myArray[index] = '٩'
        break
    }
  })
  return number
}

export const fullName = (user) => {
  return user.map((user) => ({
    ...user,
    full_name: `${user.first_name} ${user.last_name}`,
  }))
}

export const objectKeyIntoCapitalizeWords = (value) => {
  value = value
    .replaceAll('_', ' ')
    .split(' ')
    .map((val) => {
      val = val[0].toUpperCase() + val.slice(1)
      return val
    })
    .join(' ')
  return value
}

/**
 * Create array with range
 * @param {Number} start - Array start number
 * @param {Number} end - Array end number
 */

export function arrayRange(start, end) {
  var array = Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx)
  return array
}

export function addItemInArray(item = {}, list = []) {
  let newList = objectDeepCopy(list)
  if (!list?.some((value) => value.id === item?.id)) {
    newList.unshift(item)
  }
  return newList
}

/**
 * Add padd in string
 * @param {String Or Number} value - Value in which add padd
 * @param {String} position - Position where padd add
 * @param {Number} range - Limit of value
 * @param {String} padVal - Which value add when param value is less then the range
 */
export function addPadInString(value, position = 'start', range = 2, padVal = '0') {
  if (position === 'start') {
    return `${value}`.padStart(range, `${padVal}`)
  } else return `${value}`.padEnd(range, `${padVal}`)
}

/**
 * Convert UTC time to local time
 * @param {Time String} time - UTC Time
 * @return localTime
 */

export function convertUTCTimeIntoLocalTime(time, format = 'HH:mm:ss') {
  const covertedDate = moment(new Date()).format('YYYY/MM/DD')
  const date = new Date(`${covertedDate} ${time} UTC`).toString()
  const localTime = moment(date).format(format)
  return localTime
}

export function formatDate(date, format = 'YYYY-MM-DD') {
  const formatedDate = moment(date).format(format)
  return formatedDate
}

/**
 * Remove empty keys from object
 * @param {object} obj - Any Object
 * @return {object} Non Empty Keys Object
 */

export function removeEmptyKeysFromObject(obj) {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      removeNestedObjectEmptyKeys(obj)
    } else {
      if (
        obj[key] === '' ||
        obj[key] === null ||
        obj[key] === undefined ||
        (Array.isArray(obj[key]) && obj[key].length === 0)
      )
        delete obj[key]
    }
  })
}
/**
 * Remove nested object empty keys from object
 * @param {object} obj - Any Object
 * @return {object} Non Empty Keys Object
 */

export function removeNestedObjectEmptyKeys(obj) {
  Object.keys(obj).forEach((key) => {
    Object.keys(obj[key]).forEach((subKey) => {
      if (obj[key][subKey] === '' || obj[key][subKey] === null || obj[key][subKey] === undefined) {
        delete obj[key][subKey]
      }
    })
  })
}

/**
 * Use to Deep copy of and object
 * @param { Object, Array } obj - Javascript Object
 * @return {Object}
 */

export function objectDeepCopy(obj = {}) {
  return JSON.parse(JSON.stringify(obj))
}

/**
 * Check object in deep and set null value to empty Object
 * @param {Object} object
 * @return {Object} object- Object after setting value to empty from null
 */

export function setObjectToEmptyFromNull(object) {
  for (const key in object) {
    if (isEmptyDeep(object[key])) object[key] = {}
  }
  return object
}

/**
 * Check object deeply that its value is null or not
 * @param {Object} obj
 * @return {Boolean}
 */

function isEmptyDeep(obj) {
  if (isObject(obj)) {
    if (Object.keys(obj).length === 0) return true
    if (
      Object.values(obj).every((el) => el === undefined || el === '') ||
      every(map(obj, (v) => isEmptyDeep(v)))
    )
      return true
  } else if (isString(obj)) {
    return !obj.length
  }
  return false
}

/**
 * Filtered data from Map
 * @param {String} query any query
 * @param {Array} data Array of data
 * @param {Object} selectedItem Selected Item
 * @return {Array} filtered array
 */

export function filterdData(query, data, selectedItem) {
  if (selectedItem)
    return data.filter((item) => {
      if (selectedItem.id !== item.id)
        return Object.values(item).some((word) => String(word).toLowerCase().includes(query))
    })
  else
    return data.filter((item) => {
      return Object.values(item).some((word) => String(word).toLowerCase().includes(query))
    })
}
/**
 * Split error to field and error
 * @param {String} error generated error
 * @return {String} translated errot
 */

export function translatedError(error) {
  const fieldTitle = error.substring(4, this.title.length + 4)
  const errorTitle = error.substring(this.title.length + 5)
  return this.$t(`title.${fieldTitle}`) + ' ' + this.$t(`error.${errorTitle}`)
}

/**
 * Get Date from datetime
 * @param {DateTime} dateTime any datetime
 * @return {date} date
 */

export function getDate(dateTime) {
  return dateTime.split('T')[0]
}

/**
 * Get Time from datetime
 * @param {DateTime} dateTime any datetime
 * @return {time} date
 */
export function getTime(dateTime) {
  return dateTime.split('T')[1]
}

/**
 * Check object is empty or not
 * @params {Object} obj - Any Object
 * @return {Boolean}
 */
export function isObjectEmpty(obj) {
  return isEmpty(obj)
}

export function formatObjectKeyValues(obj, keys, function_name) {
  if (keys.length <= 0) return obj
  keys.forEach((key) => {
    obj[key] = obj[key][function_name]()
  })

  return obj
}

export function setObjectValuesToEmpty(obj) {
  Object.keys(obj).forEach((key) => {
    if (!isArray(obj[key])) obj[key] = ''
    else obj[key] = []
  })
  return obj
}
export function hasNumber(myString) {
  return /\d/.test(myString)
}

export function getCountFromObject(item, skipValue = '', keyType = '') {
  return Object.keys(item).filter(
    (key) =>
      ((item[key]?.length || hasNumber(item[key])) && key !== skipValue) ||
      typeof item[key] === keyType,
  ).length
}

export function getInitialsFromFirstAndLastName(firstName = 'N', lastName = 'A') {
  return `${firstName[0].toLocaleUpperCase() || ''}${lastName[0].toLocaleUpperCase() || ''}`
}

export function accessLevel(item, selectedCampusScope, selectedSectionScope, selectedClassScope) {
  if (
    !item.basedOn ||
    isRootLevelScoped(item, selectedCampusScope, selectedClassScope, selectedSectionScope) ||
    isCampusLevelScoped(item, selectedCampusScope, selectedClassScope, selectedSectionScope) ||
    isClassLevelScoped(item, selectedCampusScope, selectedClassScope, selectedSectionScope) ||
    isSectionLevelScoped(item, selectedSectionScope)
  )
    return true
  else return false
}

function isRootLevelScoped(
  item,
  selectedCampusScope = {},
  selectedClassScope = {},
  selectedSectionScope = {},
) {
  let root = false
  root =
    !!item.basedOn.includes('root-level') &&
    selectedCampusScope === '' &&
    selectedClassScope === '' &&
    selectedSectionScope === ''
  return root
}

function isCampusLevelScoped(
  item,
  selectedCampusScope = {},
  selectedClassScope = {},
  selectedSectionScope = {},
) {
  let isCampusLevelScoped = false
  isCampusLevelScoped =
    !!item.basedOn.includes('campus-level') &&
    selectedCampusScope !== '' &&
    selectedClassScope === '' &&
    selectedSectionScope === ''
  return isCampusLevelScoped
}
function isClassLevelScoped(
  item,
  selectedCampusScope = {},
  selectedClassScope = '',
  selectedSectionScope = {},
) {
  let isClassLevelScoped = false
  isClassLevelScoped =
    !!item.basedOn.includes('class-level') &&
    selectedCampusScope !== '' &&
    selectedClassScope !== '' &&
    selectedSectionScope === ''
  return isClassLevelScoped
}
function isSectionLevelScoped(item, selectedSectionScope) {
  let isSectionLevelScoped = false
  isSectionLevelScoped = !!item.basedOn.includes('section-level') && selectedSectionScope !== ''
  return isSectionLevelScoped
}

export function getDefaultRoute(
  role,
  selectedCampusScope,
  selectedSectionScope,
  defaultRoute = null,
) {
  let staff = 'staff'
  let sectionTeacher = 'section_teacher'
  let sectionStudent = 'section_student'
  let guardian = 'guardian'
  let pikcupPerson = 'pickup_person'
  switch (role) {
    case staff:
      return 'my-profile'
    case sectionTeacher:
      if (selectedCampusScope) return 'student-campus-attendance'
      else return 'my-profile'
    case pikcupPerson:
      return 'pickup-facility'
    case guardian:
      if (selectedSectionScope) return 'student-section-attendance'
      else return 'my-profile'
    case sectionStudent:
      if (selectedSectionScope) return 'student-section-attendance'
      else return 'my-profile'
    default:
      return defaultRoute ? defaultRoute : 'quick-actions'
  }
}

export function extractErrorMassage(error) {
  return error.response.data ? error.response.data.error : error.response.statusText
}
/**
 * Transform countries list from array to array of map
 * @return {Array of Object}
 */

export function getCountriesList() {
  return countries.map((country) => {
    /**
     * Transform countries array to array of map
     * i.e [['Pakistan','pk'],['United States','us']] = > [{title:'Pakistan',id:'pk}]
     */
    return { title: country[0], id: country[1] }
  })
}
export function toPascalCase(input = 'camelCase') {
  let text = input.replace(/([A-Z])/g, ' $1')
  return text.charAt(0).toUpperCase() + text.slice(1)
}
export function findCurrencyById() {
  return currenciesList.currenciesList.find((currency) => currency.id === defaultCurrency)
}
export function redirectToSignup(pathName) {
  if (!siteDomainHandler().isDomainFieldVisible) return

  let site = window.location.host
  if (site.includes('app')) {
    site = site.replace('app.', '')
  }
  let domain = currentDomainType(site)
  const path = pathName && typeof pathName === 'string' ? pathName : ''
  let protocol = window.location.protocol
  if (
    domain === siteConstants.MYQAMPUS_BETA_STATIC_SITE ||
    domain === siteConstants.MYQAMPUS_PROD_STATIC_SITE ||
    domain === siteConstants.MYQAMPUS_BETA_DOMAIN
  ) {
    window.location.replace(`${protocol}//${site}/${path}`)
  }
}

export const intToRoman = (num) => {
  const romanNumerals = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  }

  let number = ''

  for (let key in romanNumerals) {
    while (num >= romanNumerals[key]) {
      number += key
      num -= romanNumerals[key]
    }
  }

  return number
}
export function generateRandomArray(length, min = 50, max = 80) {
  return Array(length)
    .fill()
    .map(() => Math.floor(Math.random() * (max - min + 1)) + min)
}
