<template>
  <div>
    <template v-if="buttons.options">
      <UiOnClickOutside :do="closeDropdown">
        <div class="relative flex justify-center xl:mb-7">
          <button
            :class="[...buttons.classes, { borderBottomRadius: dropDownContent === true }]"
            type="button"
            class="splitBtn focus:outline-none focus:shadow-lg xl:min-w-[93%] min-w-[145px] xl:w-full w-max text-sm lg:text-base font-inter text-white bg-primary-purple-600 font-medium h-11 md:h-14 flex justify-between items-center px-5 md:px-7 rounded-lg gap-2.5"
            @click="showDropDown"
          >
            <div class="flex items-center">
              <div v-if="buttons.icon" class="ltr:pr-3 rtl:pl-3">
                <icon
                  :icon="buttons.icon"
                  :width="buttons.iconWidth"
                  :height="buttons.iconHeight"
                />
              </div>
              {{ buttons.icon }}
              <span class="2xl:text-base xl:text-sm text-xs">
                {{ $t(`rightBar.${buttons.title}`) }}
              </span>
            </div>
            <span>
              <icon
                class="icon"
                :icon="dropDownContent ? 'chevronUp' : 'chevronBottom'"
                height="9"
                width="15"
                color="white"
              />
            </span>
          </button>
          <transition name="slide">
            <div
              v-if="dropDownContent"
              :class="[
                $style.zIndexValue,
                ...(buttons.dropdownClassList || ['border-border-button']),
              ]"
              class="splitDropDownContent absolute bg-primary-white py-3 w-full border-t-0 rounded-b-lg border border-primary-purple-600 shadow-lg md:top-[56px] top-[44px]"
            >
              <ul>
                <li
                  v-for="(option, index) in buttons.options"
                  :key="index"
                  :class="[...(option.classList || ['text-primary-purple-600'])]"
                  class="font-roboto py-1 font-medium text-sm sm:text-base ltr:text-left rtl:text-right"
                >
                  <span
                    class="px-6 md:px-4 w-full block cursor-pointer"
                    @click="
                      ;[
                        option.action.name && dispatchAction(option),
                        option.action.func && option.action.func(),
                      ]
                    "
                  >
                    {{ $t(`rightBar.${option.title}`) }}
                  </span>
                </li>
              </ul>
            </div>
          </transition>
        </div>
      </UiOnClickOutside>
    </template>
    <template v-else>
      <button
        :class="[{ roundedBottom: dropDownContent }, ...buttons.classes]"
        class="splitBtn focus:outline-none xl:min-w-[93%] min-w-[145px] w-max xl:mb-7 font-medium font-inter text-white bg-primary-purple-600 rounded-lg text-sm sm:text-sm xl:text-base xl:h-11 md:h-10 h-9 text-center px-3"
        :disabled="buttons.disable || false"
        type="button"
        @click="
          ;[
            buttons.action.name && $store.dispatch(buttons.action.name),
            buttons.action.func && buttons.action.func(),
          ]
        "
      >
        <div class="flex items-center justify-center">
          <div v-if="buttons.icon" class="ltr:pr-4 rtl:pl-2">
            <icon
              :icon="buttons.icon"
              :width="buttons.iconWidth"
              :color="buttons.iconColor || 'white'"
              :height="buttons.iconHeight"
            />
          </div>
          <span v-i18n="rightBar" class="2xl:text-base xl:text-sm text-xs">
            {{ buttons.title }}
          </span>
          <div
            v-if="buttons.endDate"
            v-i18n="rightBar"
            class="ltr:pl-1 rtl:pr-1 rtl:mr-1.5 ltr:ml-1.5 text-xs md:text-base ltr:border-l rtl:border-r border-primary-grey visible"
            :end-date="buttons.endDate"
          >
            <span>{{ buttons.endDate }}</span>
          </div>
        </div>
      </button>
    </template>
  </div>
</template>

<script>
import icon from '@components/icons/icon.vue'
import UiOnClickOutside from '@components/UiElements/UiOnClickOutside.vue'
export default {
  components: {
    icon,
    UiOnClickOutside,
  },
  props: {
    buttons: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data() {
    return {
      dropDownContent: false,
      rightBar: 'rightBar',
    }
  },
  methods: {
    showDropDown() {
      this.dropDownContent = !this.dropDownContent
    },
    closeDropdown() {
      this.dropDownContent = false
    },
    dispatchAction(option) {
      this.$store.dispatch(option.action.name, option.action.payload)
      this.dropDownContent = false
    },
  },
}
</script>

<style lang="scss" module>
.zIndexValue {
  z-index: 1;
}
</style>

<style lang="scss">
.noClick {
  cursor: not-allowed;
  &:active {
    pointer-events: none;
  }
}
.splitDropDownContent {
  transition: transform 0.1s ease-in-out;
  transform-origin: top;
}
.roundedBottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.slide-enter,
.slide-leave-to {
  transform: scaleY(0);
}
.primary-button {
  svg {
    path {
      fill: white !important;
    }
  }
}
.borderBottomRadius {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
</style>

<style lang="scss">
.border_width {
  border-width: 1px;
  opacity: 100%;
}
</style>
