<template>
  <div
    v-if="modalShow"
    class="fixed top-0 left-0 z-[100] w-full h-full overflow-auto bg-[#00000080]"
    :class="modalShow ? 'text-xl' : 'text-4xl'"
  >
    <div>
      <!-- Modal content -->
      <div
        class="relative bg-white rounded-xl w-11/12 md:w-3/4 lg:w-[60%] 2xl:w-[45.138%] xl:w-[47.138%] m-auto modal-animate-top mb-7.5"
        :class="[modalClasses, { centered: !disablePositionCenter }]"
      >
        <header
          v-if="$slots.header"
          class="modal-content__header flex justify-between px-6 pt-6 pb-8 rounded-t-xl text-primary-gray-900 font-roboto font-semibold text-base sm:text-lg"
          :class="[headerClasses]"
        >
          <slot name="header"></slot>
          <slot name="closeBtn">
            <div v-if="showClose" class="close-btn cursor-pointer" @click="closeClick">
              <icon class="icon" icon="cross" color="primary-purple-700" />
            </div>
          </slot>
        </header>
        <div class="modal-content__body mx-6" :class="bodyClasses">
          <slot></slot>
        </div>
        <div
          v-if="$slots.footer"
          :class="[footerClasses]"
          class="modal-content__footer border-t border-primary-purple-100 flex-row flex mx-6 pt-8 pb-6 mt-8"
        >
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import icon from '@components/icons/icon.vue'

export default {
  name: 'UiModalContainer',
  components: {
    icon,
  },
  props: {
    preventOutSideClose: {
      type: Boolean,
      default: false,
    },
    disablePositionCenter: {
      type: Boolean,
      default: false,
    },
    modalClasses: {
      type: [Object, String],
      description: 'Modal dialog css classes',
      default: 'mt-9',
    },
    headerClasses: {
      type: [Object, String],
      description: 'Modal Header css classes',
      default: '',
    },
    bodyClasses: {
      type: [Object, String],
      description: 'Modal Body css classes',
      default: '',
    },
    footerClasses: {
      type: [Object, String],
      description: 'Modal Footer css classes',
      default: '',
    },
    modalShow: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      // eslint-disable-next-line vue/no-boolean-default
      default: true,
    },
    modalWidth: {
      type: Number,
      default: 50,
    },
    actionName: {
      type: String,
      default: 'save',
    },
    confirmEventName: {
      type: String,
      default: '',
    },
    modalHeader: {
      type: Boolean,
      default: false,
    },
    loadingActions: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['handleClick'],
  computed: {
    ...mapState({
      tabLeftNav: (state) => state.layout.tabLeftNav,
    }),
    modalStyle() {
      return {
        width: `${this.modalWidth}%`,
      }
    },
  },
  watch: {
    modalShow: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value || (window.matchMedia('(max-width: 1023px)').matches && this.tabLeftNav)) {
          this.$store.commit('layout/IS_MODAL_OPEN', true)
        } else {
          this.$store.commit('layout/IS_MODAL_OPEN', false)
        }
      },
    },
  },
  beforeUnmount() {
    this.$store.commit('layout/IS_MODAL_OPEN', false)
  },
  methods: {
    /**
     * handle click outside the component
     */
    handleClickOutside() {
      if (!this.preventOutSideClose) {
        this.cancelClick()
      }
    },
    /**
     * handle the cancel click
     * emit the cancel event to parent component
     */
    cancelClick() {
      if (!this.loadingActions) {
        this.$emit('handleClick', 'cancel')
      }
    },
    /**
     * handle the close click
     * emit the close event to parent component
     */
    closeClick() {
      this.$emit('handleClick', 'close')
    },
  },
}
</script>

<style lang="scss">
.modal-animate-top {
  animation: animatetop 0.4s;
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}
</style>
