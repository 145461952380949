export default {
  isAppLoading: false,
  leftBarContent: {},
  rightBarContent: {
    header: {},
    stats: {},
    profileInfo: {},
    status: {},
    bargraph: {},
  },
  campusDropdownHiddenOnRoutes: ['app-documentation', 'roles-and-acccess-management'],
  doRerenderLayout: 0,
  tabRightNav: localStorage.getItem('tabRightNav')
    ? JSON.parse(localStorage.getItem('tabRightNav'))
    : !window.matchMedia('(max-width: 1048px)').matches,

  tabLeftNav: localStorage.getItem('tabLeftNav')
    ? JSON.parse(localStorage.getItem('tabLeftNav'))
    : !window.matchMedia('(max-width: 767px)').matches,

  activeEvent: {},
  showClassDropdown: false,
  showSectionDropdown: false,
  BulkCheckSubmit: false,
  BulkCheckInModal: false,
  BulkCheckOutModal: false,
  createTopicModal: false,
  addScheduleModal: false,
  currentSelectedUser: null,
  showDisableModal: false,
  isDeleteScheduleModal: false,
  isLoading: false,
  resetPassword: false,
  enableLoginModal: false,
  currentSelectedUser: null,
  showDeactivateModal: false,
  showRoleSwitchingSuiteModal: false,
  applyLeaveModal: false,
  studentCheckInModal: false,
  studentCheckOutModal: false,
  checkInModal: false,
  checkOutModal: false,
  studentAddModal: false,
  resetPassword: false,
  showModal: false,
  showUiModal: false,
  studentsCheckInModal: false,
  submitForm: 0,
  teacherAddModal: false,
  teacherCheckInModal: false,
  teacherCheckOutModal: false,
  teacherAttendanceModal: false,
  classAddModal: false,
  stickyTooltip: false,
  tooltipRightbar: false,
  routineModal: false,
  isModalOpen: false,
  csv_upload: false,
  currentClassScope: localStorage.getItem('currentClassScope')
    ? JSON.parse(localStorage.getItem('currentClassScope'))
    : '',
  currentSectionScope: localStorage.getItem('currentSectionScope')
    ? JSON.parse(localStorage.getItem('currentSectionScope'))
    : '',
  currentSelectedSectionRole: {},
  currentSelectedSectionRoles: [],
  currentCampusScope: localStorage.getItem('currentCampusScope')
    ? JSON.parse(localStorage.getItem('currentCampusScope'))
    : '',
  associatedStudent: localStorage.getItem('guardianAssociatedStudents')
    ? JSON.parse(localStorage.getItem('guardianAssociatedStudents'))
    : '',
  salaryTypes: '',
  campuses: [],
  classList: [],
  activeRole: localStorage.getItem('selectedRoleId'),
  systemSettings: localStorage.getItem('setting')
    ? JSON.parse(localStorage.getItem('setting'))
    : {},
  generalSettings: localStorage.getItem('generalSettings')
    ? JSON.parse(localStorage.getItem('generalSettings'))
    : {},
  isDownloadCSV: false,
  currentRoute: '',
}
