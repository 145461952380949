import { AnnouncementTypesService } from '@/src/services/announcement-type.service'
export default {
  async getAnnouncementTypes({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await AnnouncementTypesService.getAnnouncementTypes(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  addAnnouncementType({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('layout/IS_LOADING', true, { root: true })
      return AnnouncementTypesService.addAnnouncementType(payload, commit)
        .then((response) => {
          resolve(response.data)
          commit(
            'toast/NEW',
            { message: 'Announcement Added Successfully', type: 'success' },
            { root: true },
          )
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  updateAnnouncementType({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('layout/IS_LOADING', true, { root: true })
      return AnnouncementTypesService.updateAnnouncementType(payload, commit)
        .then((response) => {
          resolve(response.data)
          commit(
            'toast/NEW',
            { message: 'Announcement Updated Successfully', type: 'success' },
            { root: true },
          )
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  async deletetAnnouncementType({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('layout/IS_LOADING', true, { root: true })
      return AnnouncementTypesService.deletetAnnouncementType(payload, commit)
        .then((response) => {
          resolve(response.data)
          commit(
            'toast/NEW',
            { message: 'Announcement Deleted Successfully', type: 'success' },
            { root: true },
          )
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
}
