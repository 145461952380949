import { by, del } from '@services/custom.service'
import { BaseService } from '@services/base.service'
import { TENNAT_API_URL } from '@services/.env'

export default class SectionServices extends BaseService {
  static addNewSection({ classes, id = null }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}sections`, classes, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not add department'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static deleteSection(id) {
    return new Promise((resolve, reject) => {
      return del(`${TENNAT_API_URL}sections/soft_delete`, `${id}`)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not delete routine'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getSectionsList(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}sections/campus_sections`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Something went wrong'
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static filterSectionsList(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}sections/filters`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Something went wrong'
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static filterUsersSectionsList(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}user/sections`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Something went wrong'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  // Load Class base classes
  static sectionClasses(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}section_enrollments/class_sections`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Something went wrong'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getClassSections(payload) {
    return new Promise((resolve, reject) => {
      return by(
        `${TENNAT_API_URL}classes/campus_class_sections
      `,
        payload,
      )
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not Get classes'
          reject(this.errorWrapper(error, message))
        })
    })
  }
}
