import { addItemInArray } from '@src/utils/generalUtil.js'
export default {
  SET_LEFTBAR_CONTENT(state, payload) {
    state.leftBarContent = payload
  },
  SET_SHOW_SECTION_DROPDOWN(state) {
    state.showSectionDropdown =
      state.leftBarContent.customeHeader &&
      state.leftBarContent?.SHOW_SECTION_DROPDOWN_TO?.includes(state.activeRole)
  },
  SET_SHOW_CLASS_DROPDOWN(state) {
    state.showClassDropdown = state.leftBarContent?.SHOW_CLASS_DROPDOWN_TO?.includes(
      state.activeRole,
    )
  },

  SET_RIGHTBAR_CONTENT(state, payload) {
    state.rightBarContent = payload
  },

  SET_ITEM_STATE(state, item) {
    const routeIndex = state.leftBarContent.routes.findIndex((route, idx) => {
      if (route.id === item.id) return true

      return false
    })

    state.leftBarContent.routes[routeIndex].showChilds = !item.showChilds
  },
  REMOVE_ITEM(state) {
    let array = state.leftBarContent.routes
    array.findIndex((route) => {
      if (route.isOwner) {
        route.isOwner = false
      }
    })
  },
  SET_CURRENT_SELECTED_USER(state, payload) {
    state.currentSelectedUser = payload === undefined ? null : payload
  },
  ENABLE_LOGIN_MODAL(state, payload) {
    state.enableLoginModal = payload === undefined ? !state.enableLoginModal : payload
  },
  RESET_PASSWORD_MODAL(state, payload) {
    state.resetPassword = payload === undefined ? !state.resetPassword : payload
  },
  SET_SHOW_DISABLE_MODAL(state, payload) {
    state.showDisableModal = payload === undefined ? !state.showDisableModal : payload
  },
  SET_CURRENT_SELECTED_USER(state, payload) {
    state.currentSelectedUser = payload === undefined ? null : payload
  },
  RESET_PASSWORD_MODAL(state, payload) {
    state.resetPassword = payload === undefined ? !state.resetPassword : payload
  },
  SET_ITEM_STATE_BY_VALUE(state, payload) {
    payload.item.showChilds = payload.value
  },
  SET_SHOW_MODAL(state, payload) {
    state.showModal = payload === undefined ? !state.showModal : payload
  },
  SET_SHOW_DEACTIVATE_MODAL(state, payload) {
    state.showDeactivateModal = payload === undefined ? !state.showDeactivateModal : payload
  },
  SET_SHOW_ROLE_SWITCHING_SUITE_MODAL(state, payload) {
    state.showRoleSwitchingSuiteModal =
      payload === undefined ? !state.showRoleSwitchingSuiteModal : payload
  },
  SET_CSV_UPLOAD(state) {
    state.csv_upload = !state.csv_upload
  },
  SET_ROUTINE_MODAL(state, payload) {
    state.routineModal = payload === undefined ? !state.routineModal : payload
  },
  SET_SHOW_UI_MODAL(state, payload) {
    state.showUiModal = payload === undefined ? !state.showUiModal : payload
  },
  SET_TEACHER_CHECK_IN_MODAL(state, payload) {
    state.teacherCheckInModal = payload === undefined ? !state.teacherCheckInModal : payload
  },
  SET_TEACHER_CHECK_OUT_MODAL(state, payload) {
    state.teacherCheckOutModal = payload === undefined ? !state.teacherCheckOutModal : payload
  },
  SET_CREATE_TOPIC_MODAL(state, payload) {
    state.createTopicModal = payload === undefined ? !state.createTopicModal : payload
  },
  SET_STUDENT_ADD_MODAL(state, payload) {
    state.studentAddModal = payload === undefined ? !state.studentAddModal : payload
  },
  SET_STUDENT_CHECK_IN_MODAL(state, payload) {
    state.studentCheckInModal = payload === undefined ? !state.studentCheckInModal : payload
  },
  SET_STUDENT_CHECK_OUT_MODAL(state, payload) {
    state.studentCheckOutModal = payload === undefined ? !state.studentCheckOutModal : payload
  },
  SET_CHECK_IN_MODAL(state, payload) {
    state.checkInModal = payload === undefined ? !state.checkInModal : payload
  },
  SET_CHECK_OUT_MODAL(state, payload) {
    state.checkOutModal = payload === undefined ? !state.checkOutModal : payload
  },
  SET_DELETE_SCHEDULE_IN_MODAL(state, payload) {
    state.isDeleteScheduleModal = payload === undefined ? !state.isDeleteScheduleModal : payload
  },
  SET_ADD_SCHEDULE_IN_MODAL(state, payload) {
    state.addScheduleModal = payload === undefined ? !state.addScheduleModal : payload
  },

  SET_APPLY_LEAVE_MODAL(state, payload) {
    state.applyLeaveModal = payload === undefined ? !state.applyLeaveModal : payload
  },
  STUDENTS_CHECK_IN_MODAL(state, payload) {
    state.studentsCheckInModal = payload === undefined ? !state.studentsCheckInModal : payload
  },
  SET_RIGHT_BAR_HEADER(state, payload) {
    state.rightBarContent.header = payload.header
  },
  SET_RIGHT_BAR_STATS(state, payload) {
    state.rightBarContent.stats = payload.stats
  },
  SET_TAB_RIGHT_BAR(state, payload) {
    if (payload === true || payload === false) {
      state.tabRightNav = payload
    } else state.tabRightNav = !state.tabRightNav
    localStorage.setItem('tabRightNav', state.tabRightNav)
  },
  SET_TAB_LEFT_BAR(state, payload) {
    if (payload === true || payload === false) {
      state.tabLeftNav = payload
    } else state.tabLeftNav = !state.tabLeftNav
    localStorage.setItem('tabLeftNav', state.tabLeftNav)
  },
  IS_LOADING(state, payload) {
    state.isLoading = payload === undefined ? !state.isLoading : payload
  },
  DONE_LOADING(state, payload) {
    state.isLoading = false
  },
  RENDER_COMPONENT(state) {
    state.doRerenderLayout++
  },
  SUBMIT_FORM(state, payload) {
    state.submitForm = state.submitForm + 1
  },
  SET_BULK_CHECK_SUBMIT(state, payload) {
    state.BulkCheckSubmit = !state.BulkCheckSubmit
  },
  SET_BULK_CHECKIN_MODAL(state, payload) {
    state.BulkCheckInModal = !state.BulkCheckInModal
  },
  SET_BULK_CHECKOUT_MODAL(state, payload) {
    state.BulkCheckOutModal = !state.BulkCheckOutModal
  },
  SET_CURRENT_SELECTED_CLASS_SCOPE(state, payload) {
    state.currentClassScope = payload
    localStorage.setItem('currentClassScope', JSON.stringify(payload))
  },
  SET_CURRENT_SELECTED_SECTION_SCOPE(state, payload) {
    state.currentSectionScope = payload
    localStorage.setItem('currentSectionScope', JSON.stringify(payload))
  },
  SET_CURRENT_SELECTED_CLASS_ROLE(state, payload) {
    state.currentSelectedSectionRole = payload
  },
  SET_CURRENT_SELECTED_CLASS_ROLES(state, payload) {
    state.currentSelectedSectionRoles = payload
  },
  SET_TEACHER_ADD_MODAL(state, payload) {
    state.teacherAddModal = payload === undefined ? !state.teacherAddModal : payload
  },
  SET_TEACHER_ATTENDANCE_MODAL(state, payload) {
    state.teacherAttendanceModal = payload === undefined ? !state.teacherAttendanceModal : payload
  },
  SET_CLASS_ADD_MODAL(state, payload) {
    state.classAddModal = payload === undefined ? !state.classAddModal : payload
  },
  SET_SELECTED_CAMPUSE(state, campuse) {
    state.currentCampusScope = campuse
    localStorage.setItem('currentCampusScope', JSON.stringify(campuse))
  },
  SET_INSTITUTES_HIERARCHY(state, { selectedCampus, selectedClass, selectedSection }) {
    state.currentCampusScope = selectedCampus
    state.currentClassScope = selectedClass
    state.currentSectionScope = selectedSection

    localStorage.setItem('currentCampusScope', JSON.stringify(selectedCampus))
    localStorage.setItem('currentClassScope', JSON.stringify(selectedClass))
    localStorage.setItem('currentSectionScope', JSON.stringify(selectedSection))
  },
  SET_ASSOCIATED_STUDENT(state, payload = []) {
    state.associatedStudent = payload
    if (payload.length > 0) localStorage.setItem('currentActiveStudent', JSON.stringify(payload[0]))
    localStorage.setItem('guardianAssociatedStudents', JSON.stringify(payload))
  },
  SET_CAMPUSES(state, campuses) {
    state.campuses = state.currentCampusScope?.id
      ? addItemInArray(state.currentCampusScope, campuses)
      : campuses
  },
  GET_ALL_CAMPUS(state, campuses) {
    state.campuses = state.currentCampusScope?.id
      ? addItemInArray(state.currentCampusScope, campuses)
      : campuses
  },
  SET_CLASS_LIST(state, classList) {
    state.classList = classList
  },
  ACTIVATE_EVENT(state, event) {
    state.activeEvent = event
  },
  SET_SELECTED_ROLE(state, role) {
    state.activeRole = role
    localStorage.setItem('selectedRoleId', role)
  },
  SET_SALARY_TYPES(state, payload) {
    state.salaryTypes = payload
  },
  SET_INSTITUE_COUNTS(state, payload) {
    if (payload) {
      var stats = payload
      if (stats && stats.campuses_count === 0) {
        localStorage.setItem('signUpSlider', true)
      } else {
        localStorage.setItem('signUpSlider', false)
      }
      localStorage.setItem('institutesCounts', JSON.stringify(stats))
    }
  },
  SHOW_TOOLTIP(state, payload) {
    state.tooltipRightbar = payload
  },
  IS_MODAL_OPEN(state, payload) {
    state.isModalOpen = payload
  },
  SET_APP_LOADING(state, payload) {
    state.isAppLoading = payload
  },
  SET_INSTITUE_SETTINGS(state, payload) {
    if (payload === null) {
      localStorage.setItem('signUpSlider', true)
    }
    state.systemSettings = payload
    localStorage.setItem('setting', JSON.stringify(payload))
  },
  SET_GENERAL_SETTINGS(state, payload) {
    state.generalSettings = payload
    localStorage.setItem('generalSettings', JSON.stringify(payload))
  },
  GET_FILTERED_CAMPUSES(state, payload) {
    state.campuses.splice(payload, 1)
  },
  SET_DOWNLOAD_CSV(state) {
    state.isDownloadCSV = !state.isDownloadCSV
  },
  SET_CURRENT_ROUTE(state, payload) {
    state.currentRoute = payload
  },
}
