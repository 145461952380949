import { FeeService } from '@/src/services/finance.service'
import i18n from '@src/i18n'
import { fullName } from '@src/utils/settings/tenant-user.util.js'

const t = i18n?.global?.t

export default {
  addExpenseCategory({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      FeeService.addExpenseCategory(payload)
        .then((response) => {
          commit(
            'toast/NEW',
            { type: 'success', message: t(`toast.Successfully Created`) },
            { root: true },
          )
          resolve(response)
        })
        .catch((error) => {
          commit('layout/IS_LOADING', false, { root: true })
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },
  getAllCategory({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      FeeService.getExpenseCategory()
        .then((response) => {
          commit('SET_EXPENSE_CATEGORIES', response.data)
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  // Receivable Fees

  getStudentsReceivableFees({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      FeeService.getStudentReceivableFee(payload)
        .then((response) => {
          response?.data?.records.forEach((record) => {
            fullName(record.student)
            record.student.student_info.guardians.forEach((guardian) => fullName(guardian))
          })
          commit('SET_STUDENT_RECEIVABLE_FEES', response?.data)
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  getStudentsPayableFees({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      FeeService.getStudentPayableFee(payload)
        .then((response) => {
          response?.data?.records.forEach((record) => {
            fullName(record.student)
            record.student.student_info.guardians.forEach((guardian) => fullName(guardian))
          })
          commit('SET_STUDENT_RECEIVABLE_FEES', response?.data)
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  // Received Fees
  getStudentsReceivedFees({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      FeeService.getStudentReceivedleFee(payload, rootState.layout)
        .then((response) => {
          let res = response?.data
          res.records.forEach((record) => {
            fullName(record.student)
            record.student_fee_receivings.reciepts = []
            let paymentMethodsSet = new Set()
            record.student_fee_receivings.forEach((payment) => {
              if (payment.received_by) {
                payment.received_by.full_name = `${payment.received_by.first_name} ${payment.received_by.last_name}`
              }
              if (payment.receipt_url) {
                record.student_fee_receivings.reciepts.push({
                  name: `View Receipt ${
                    record.student_fee_receivings.reciepts.length
                      ? record.student_fee_receivings.reciepts.length
                      : ''
                  }`,
                  isDynamicName: true,
                  func: function () {
                    location.href = payment.receipt_url
                  },
                })
              }
              /* USED PAYMENT METHODS */
              if (payment.payment_method === 'cash') {
                paymentMethodsSet.add('Cash')
              } else if (payment.payment_method === 'stripe_card') {
                paymentMethodsSet.add('Stripe Card')
              }
            })
            let unique_payment_methods_array = [...paymentMethodsSet]

            record.used_payment_methods = unique_payment_methods_array
              .map(function (val, ind) {
                let returnVal = val
                if (ind + 1 !== unique_payment_methods_array.length) {
                  returnVal += ', '
                }
                return returnVal
              })
              .join('')
            record.student.student_info.guardians.forEach((guardian) => {
              fullName(guardian)
            })
          })
          commit('SET_STUDENT_RECEIVED_FEES', res)
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  // Chnage Due_Date

  changeReceivableFeeDueDate({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FeeService.changeDueDate(payload, commit)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  // Discount Fees

  getFeeDiscount({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FeeService.getFeeDiscount(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  // Add Fee type

  addFeeType({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FeeService.addFeeType(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },
  // Get Fee type
  getFeeTypes({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FeeService.getFeeType(payload)
        .then((response) => {
          commit('SET_FEE_TYPES', response?.data)
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  getStudentFeeDetails({ commit }, id) {
    return new Promise((resolve, reject) => {
      FeeService.getStudentFeeDetails(id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },
  //  Add Fee Structure

  addFeeTemplate({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FeeService.addFeeTemplate(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  //  Get Fee Structure

  getFeeTemplate({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FeeService.getFeeTemplate(payload)
        .then((response) => {
          commit('SET_FEE_TEMPLATES', response?.data)

          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  //  Add Class Student Fees

  addStudentFees({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FeeService.addStudentFees(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  //  Get Class Student Fees
  getClassStudentFees({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FeeService.getClassStudentFees(payload)
        .then((response) => {
          commit('SET_CLASS_FEE', response?.data)
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  //  Add Single Student Fees

  addSingleStudentFees({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FeeService.addSingleStudentFees(payload)
        .then((response) => {
          const stuName =
            response?.data?.student?.first_name + ' ' + response?.data?.student?.last_name
          const amount = response?.data?.fee_amount
          const feeType = response?.data?.fee_type?.title
          commit(
            'toast/NEW',
            {
              type: 'success',
              message: `${feeType} of ${stuName} for ${amount} ` + t(`toast.is added successfully`),
            },
            { root: true },
          )
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  // Accept Student Fee

  acceptStudentFee({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FeeService.acceptStudentFee(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  // Add Fine in Fee

  addFineInFee({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FeeService.addFineInFee(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  // Add Discount in Fee

  addDiscountInFee({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FeeService.addDiscountInFee(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('layout/IS_LOADING', false, { root: true })
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },
  // Add General Discount in Fee

  addFeeDiscount({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FeeService.addFeeDiscount(payload)
        .then((response) => {
          commit('toast/NEW', { root: true })
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.massage)
        })
    })
  },
  // Add Discount Type

  addOrUpdateDiscountType({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FeeService.addOrUpdateDiscountType(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  // Get Fee type
  getDiscountTypes({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FeeService.getDiscountType(payload)
        .then((response) => {
          commit('SET_DISCOUNT_TYPE', response?.data)
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  // Send Fee Notification to user

  sendFeeNotification({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FeeService.sendFeeNotification(payload)
        .then((response) => {
          commit(
            'toast/NEW',
            { type: 'success', message: t(`toast.Notification Sent`) },
            { root: true },
          )
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  // Delete Student Fee
  deleteStudentFee({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FeeService.deleteStudentFee(payload)
        .then((response) => {
          commit(
            'toast/NEW',
            { type: 'success', message: t(`toast.Deleted Successfully`) },
            { root: true },
          )
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  async deleteFeePlanner({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await FeeService.deleteFeePlanner(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  // Delete Student Type
  deleteFeeType({ commit }, { title, id }) {
    return new Promise((resolve, reject) => {
      FeeService.deleteFeeType(id)
        .then((response) => {
          commit(
            'toast/NEW',
            { type: 'success', message: title + ' ' + t(`toast.is deleted successfully`) },
            { root: true },
          )
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  // Delete Class Fee
  deleteClassFee({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FeeService.deleteClassFee(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  getFeeReceivableStats({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FeeService.getFeeReceivableStats(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },
  getFeeReceivedStats({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FeeService.getFeeReceivedStats(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  // discount_stats
  getFeeDiscountStats({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FeeService.getFeeDiscountStats(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  async getStudentDiscountList({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await FeeService.getStudentDiscountList(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    } else {
      response?.data?.records.forEach((student) => {
        fullName(student)
      })
    }
    return [response, error]
  },
  getSingleStudentFeeStats({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FeeService.getSingleStudentFeeStats(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  async getStudentDiscountDetail({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await FeeService.getStudentDiscountDetail(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    return [response, error]
  },

  async getFeeDetailForSendingNotification({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await FeeService.getFeeDetailForSendingNotification(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    return [response, error]
  },

  getSectionFeeDetails({ commit }, id) {
    return new Promise((resolve, reject) => {
      FeeService.getSectionFeeDetails(id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },
  getPendingFeeStudentsList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FeeService.getPendingFeeStudentsList(payload)
        .then((response) => {
          response.data.records.forEach((student) => {
            fullName(student)
          })
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  getStudentFeeReceivings({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FeeService.getStudentFeeReceivings(payload)
        .then((response) => {
          response.data.records.forEach((fee) => {
            fullName(fee.student_fee.student)
            fullName(fee.received_by)
          })
          commit('SET_STUDENT_RECEIVED_INSTALLMENTS', response.data)
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },
}
