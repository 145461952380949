export default {
  SET_SHOW_MODAL(state, payload) {
    state.showModal = payload
  },
  SET_FEE_INVOICE(state, payload) {
    state.feeInvoice = payload
  },
  SET_EXPENSE_CATEGORIES(state, payload) {
    state.expenseCategories = payload
  },
  UPDATE_EXPENSE_CATEGORY(state, payload) {
    state.expenseCategories.push(payload)
  },
  SET_STUDENT_RECEIVABLE_FEES(state, payload) {
    state.studentReceivableFees = payload
  },
  SET_STUDENT_RECEIVED_FEES(state, payload) {
    state.studentReceivedFees = payload
  },
  SET_STUDENT_RECEIVED_INSTALLMENTS(state, payload) {
    state.studentReceivedInstallments = payload
  },
  INIT_RECEIVED_FEE_STATES(state) {
    state.studentReceivedFees = []
    state.studentReceivedInstallments = []
  },
  SET_STUDENTS_DISCOUNT(state, payload) {
    state.studentsDiscount = payload
  },
  SET_FEE_TYPES(state, payload) {
    state.feeTypes = payload
  },
  SET_DISCOUNT_TYPE(state, payload) {
    state.discountTypes = payload
  },
  ADD_FEE_DISCOUNT(state, payload) {
    state.feeTypes.records.push(payload)
  },

  ADD_DISCOUNT_TYPE(state, payload) {
    state.discountTypes.push(payload)
  },
  SET_FEE_TEMPLATES(state, payload) {
    state.feeTemplates = payload
  },
  ADD_FEE_TEMPLATE(state, payload) {
    state.feeTemplates.data.push(payload)
    state.feeTemplates.meta.counts++
  },
  ADD_FINE(state, payload) {
    state.studentReceivableFees.records[payload.index].fine_amount += payload.fine
    state.studentReceivableFees.records[payload.index].payable_amount += payload.fine
  },
  ADD_GENERAL_DISCOUNT(state, payload) {
    state.studentGeneralDiscounts.records[payload.index].fine_amount +=
      payload.generalDiscountDetails
    state.studentGeneralDiscounts.records[payload.index].payable_amount +=
      payload.generalDiscountDetails
  },
  ADD_DISCOUNT(state, payload) {
    state.studentReceivableFees.records[payload.index].discount_amount += payload.discount
    state.studentReceivableFees.records[payload.index].payable_amount -= payload.discount
  },
  DELETE_STUDENT_FEE(state, idx) {
    state.studentReceivableFees.records.splice(idx, 1)
  },
  DELETE_RECEIVED_STUDENT_FEE(state, idx) {
    state.studentReceivedFees.records.splice(idx, 1)
    state.studentReceivedFees.meta.counts--
  },
  ACCEPT_STUDENT_FEE_PAYMENT(state, payload) {
    state.studentReceivableFees.records[payload.index].payable_amount -= payload.payment
    state.studentReceivableFees.records[payload.index].paid_amount += payload.payment
  },
  CHANGE_DUE_DATE(state, payload) {
    state.studentReceivableFees.records[payload.index].due_date = payload.due_date
  },
  DELETE_FEE_PLANNER(state, payload) {
    state.feeTemplates.records.splice(payload, 1)
    state.feeTemplates.meta.counts--
  },
  DELETE_FEE_TYPE(state, payload) {
    state.feeTypes.records.splice(payload, 1)
  },
  SET_CLASS_FEE(state, payload) {
    state.classFees = payload
  },
  DELETE_CLASS_FEE(state, payload) {
    state.classFees.records.splice(payload, 1)
    state.classFees.meta.counts--
  },
  ADD_CLASS_FEE(state, payload) {
    if (state.classFees.meta.total_records.length < 10) state.classFees.records.push(payload)
    state.classFees.meta.total_records++
  },
}
