export default {
  expenseCategories: [],
  studentReceivableFees: [],
  studentReceivedFees: [],
  studentReceivedInstallments: [],
  studentsDiscount: [],
  studentGeneralDiscounts: [],
  feeTypes: [],
  discountTypes: [],
  feeTemplates: [],
  classFees: [],
  showModal: false,
  feeInvoice: null,
}
