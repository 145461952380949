import { TENNAT_API_URL } from '@services/.env'
import { by, get, del } from '@services/custom.service'
import { BaseService } from '@services/base.service'

export class CSVService extends BaseService {
  static downloadStudentCSV(payload, commit) {
    return by(`${TENNAT_API_URL}students/download_csv`, payload)
      .then((response) => {
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  }

  static downloadReceivableCSV(payload, commit) {
    return by(`${TENNAT_API_URL}student_fee_receivable/download_csv`, payload)
      .then((response) => {
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  }

  static downloadReceivedCSV(payload, commit) {
    return by(`${TENNAT_API_URL}student_fee_received/download_csv`, payload)
      .then((response) => {
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  }

  static downloadReceivedInstallementCSV(payload, commit) {
    return by(`${TENNAT_API_URL}student_fee_receivings/download_csv`, payload)
      .then((response) => {
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  }
}
