import { by, del } from '@services/custom.service'
import { BaseService } from '@services/base.service'
import { TENNAT_API_URL } from '@services/.env'

export default class RoutineService extends BaseService {
  static getClasses(query) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}sections/campus_sections`, query)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Classes list'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getSubjects(query) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}schedule/section_subjects`, query)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Subjects list'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  // pass campus id

  static getRoomsForClass(query) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}period/campus_rooms`, query)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get rooms list'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static addRoutine({ routine, id = null }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}routine`, routine, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not add routine'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static filterRoutine(query) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}routine/filters`, query)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get routine'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static deleteRoutine(id) {
    return new Promise((resolve, reject) => {
      return del(`${TENNAT_API_URL}routine`, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not delete routine'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  /*
   * Routine Deactivation endpoint
   */
  static deactivateRoutine(id) {
    return new Promise((resolve, reject) => {
      return del(`${TENNAT_API_URL}routine/deactivate`, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not deavtivate routine'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  // pass subject id

  static loadTeachers(query) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}schedule/subject_teachers`, query)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Teachers list'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  // pass class id

  static loadSubjects(query) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}schedule/section_subjects`, query)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get subjects list'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  // pass campus or class id
  static loadRooms(query) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}schedule/subject_teachers`, query)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get subjects list'
          reject(this.errorWrapper(error, message))
        })
    })
  }
}
