import i18n from '@src/i18n'
import RoutineService from './services'

const t = i18n?.global?.t

export default {
  getAllClasses({ commit }, payload) {
    return new Promise((resolve, reject) => {
      RoutineService.getClasses(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  getSubjects({ commit }, payload) {
    return new Promise((resolve, reject) => {
      RoutineService.getSubjects(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  getTeachers({ commit }, payload) {
    return new Promise((resolve, reject) => {
      RoutineService.getTeachers(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  getRoomsForClass({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      RoutineService.getRoomsForClass(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  addRoutine({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      RoutineService.addRoutine(payload)
        .then((response) => {
          commit(
            'toast/NEW',
            { message: t(`toast.Routine added successfully`), type: 'success' },
            { root: true },
          )
          resolve(response)
        })
        .catch((error) => {
          reject(error)
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  filterRoutine({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      RoutineService.filterRoutine(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  deleteRoutine({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      RoutineService.deleteRoutine(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },
  deactivateRoutine({ commit }, payload) {
    return new Promise((resolve, reject) => {
      RoutineService.deactivateRoutine(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  loadTeachers({ commit }, payload = {}) {
    return new Promise((resolve, reject) => {
      RoutineService.loadTeachers(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  loadSubjects({ commit }, payload = {}) {
    return new Promise((resolve, reject) => {
      RoutineService.loadSubjects(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  loadRooms({ commit }, payload = {}) {
    return new Promise((resolve, reject) => {
      RoutineService.loadRooms(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },
  setShowRoutineDeleteModal({ state, commit, rootState }, payload) {
    commit('SET_SHOW_ROUTINE_DELETE_MODAL', payload)
  },
}
