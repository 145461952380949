import i18n from '@src/i18n'
import ClassesService from '@services/classes.services'

const t = i18n?.global?.t

export default {
  /* NEW CLASSES(renamed from terms ) ACTIONS */
  addClasses({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      ClassesService.addClass(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('layout/IS_LOADING', false, { root: true })
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },
  deleteClass({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ClassesService.deleteClass(payload.id)
        .then((response) => {
          commit(
            'toast/NEW',
            {
              type: 'success',
              message: `${payload.title} ` + t(`toast.CLASS_SUCESSFULLY_DELETED`),
            },
            { root: true },
          )
          resolve(response)
        })
        .catch((error) => {
          if (error.errors) {
            const key = Object.keys(error.errors)[0]
            const message = error.message.includes('are still associated with class ID')
              ? 'are associated with this class'
              : error.message
            error.message = `${key} ${message}`
          }
          commit('layout/IS_LOADING', false, { root: true })
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  getClassesList({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      ClassesService.getAllClasses(payload)
        .then((response) => {
          commit('SET_CLASS_LIST', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  getUserClasses({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      ClassesService.getUserClasses(payload)
        .then((response) => {
          commit('SET_USERS_CLASS_LIST', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
}
