import { by, get } from '@services/custom.service'
import { BaseService } from '@services/base.service'
import { TENNAT_API_URL } from '@services/.env'

export default class LayoutService extends BaseService {
  static getCampusList(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}user/campuses`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get campuse list'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getUserSpecificSectionsList(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}sections/user_specific`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Class list'
          reject(this.errorWrapper(error, message))
        })
    })
  }
}
