<template>
  <UiModalContainer
    footer-classes="flex justify-end gap-3"
    :modal-show="showModel"
    :modal-width="50"
    @handleClick="handleClick"
  >
    <template v-slot:header>
      <span>PWA Guidelines</span>
    </template>
    <template v-slot>
      <div class="pb-10">
        <div
          class="font-inter font-semibold sm:text-[18px] text-base text-primary-gray-900 flex items-center gap-2"
        >
          <icon class="w-8 h-8" icon="logoheader" />
          {{ title }}
        </div>
        <div
          class="font-inter font-semibold sm:text-sm text-xs text-primary-gray-500 w-11/12 mt-3 pl-1"
        >
          {{ description }}
        </div>
      </div>
    </template>
  </UiModalContainer>
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import icon from '@components/icons/icon.vue'

export default {
  name: 'PwaGuideLines',
  components: {
    UiModalContainer,
    icon,
  },
  props: {
    showModel: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['handleClick'],
  data() {
    return {
      title: 'Install App',
      description:
        ' Click 3 dots on upper right side of browser. Scroll down and find Add to Home Screen or Install Button or Install to install the app',
    }
  },
  methods: {
    handleClick() {
      this.$emit('handleClick')
    },
  },
}
</script>
