import { by, del, get } from '@services/custom.service'
import { BaseService } from '@services/base.service'
import { TENNAT_API_URL } from '@services/.env'

export default class ChapterService extends BaseService {
  static getChapters(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}chapters/filter`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => resolve(this.errorWrapper(error)))
    })
  }
  static getTopicStats(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}/topics/topic_stats`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }
  static getChapterStats(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}/chapters/chapters_stats`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }
  static getTopic(payload) {
    let query = payload
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}topics/filter`, query)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static addTopic({ topic, id = null }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}topics`, topic, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  static deactivateTopic(id) {
    return new Promise((resolve, reject) => {
      return del(`${TENNAT_API_URL}topics/deactivate`, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }
  static addChapter({ chapter, id = null }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}chapters`, chapter, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  static deleteChapter(payload) {
    return new Promise((resolve, reject) => {
      return del(`${TENNAT_API_URL}chapters/deactivate`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }
}
