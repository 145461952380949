import { ExamService } from '@services/exams.service'

export default {
  getExamsList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ExamService.getExamsList(payload, commit)
        .then((response) => {
          commit('SET_ALL_EXAMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          commit(
            'toast/NEW',
            {
              message: error.message?.message ? error.message?.message : error.message,
              type: 'error',
            },
            { root: true },
          )
          reject(error)
        })
    })
  },

  getExamsSubjectsList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ExamService.getExamsSubjectsList(payload, commit)
        .then((response) => {
          response.data.records.forEach(
            (subjectExam) => (subjectExam['title'] = subjectExam.exam_schedule.title),
          )
          resolve(response)
        })
        .catch((error) => {
          commit(
            'toast/NEW',
            {
              message: error.message?.message ? error.message?.message : error.message,
              type: 'error',
            },
            { root: true },
          )
          reject(error)
        })
    })
  },

  addSubjectResult({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ExamService.addSubjectResult(payload, commit)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit(
            'toast/NEW',
            {
              message: error.message?.message ? error.message?.message : error.message,
              type: 'error',
            },
            { root: true },
          )
          reject(error)
        })
    })
  },
  addAndEditSectiontResult({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ExamService.addAndEditSectiontResult(payload, commit)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit(
            'toast/NEW',
            {
              message: error.message?.message ? error.message?.message : error.message,
              type: 'error',
            },
            { root: true },
          )
          reject(error)
        })
    })
  },
  updateSubjectResultWeightage({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ExamService.updateSubjectResultWeightage(payload, commit)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit(
            'toast/NEW',
            {
              message: error.message?.message ? error.message?.message : error.message,
              type: 'error',
            },
            { root: true },
          )
          reject(error)
        })
    })
  },
  getExamDetails({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ExamService.getExamDetails(payload, commit)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit(
            'toast/NEW',
            {
              message: error.message?.message ? error.message?.message : error.message,
              type: 'error',
            },
            { root: true },
          )
          reject(error)
        })
    })
  },
  createExam({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ExamService.createExam(payload, commit)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit(
            'toast/NEW',
            {
              message: error.message?.message ? error.message?.message : error.message,
              type: 'error',
            },
            { root: true },
          )
          reject(error)
        })
    })
  },

  createExamSubject({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ExamService.createExamSubject(payload, commit)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit(
            'toast/NEW',
            {
              message: error.message?.message ? error.message?.message : error.message,
              type: 'error',
            },
            { root: true },
          )
          reject(error)
        })
    })
  },
  async deleteExam({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await ExamService.deleteExam(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },
  async deleteSubjectExamResult({ commit }, payload) {
    const [response, error] = await ExamService.deleteSubjectExamResult(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    return [response, error]
  },
  async deleteSectionExamResult({ commit }, payload) {
    const [response, error] = await ExamService.deleteSectionExamResult(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    return [response, error]
  },

  async deleteExamSubject({ commit }, payload) {
    const [response, error] = await ExamService.deleteExamSubject(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    return [response, error]
  },

  async getSubjectAndExamTypes({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    let response = null,
      error = null
    try {
      response = await ExamService.getSubjectAndExamTypes(commit, payload)
    } catch (err) {
      commit(
        'toast/NEW',
        { message: err.message?.message ? err.message?.message : err.message, type: 'error' },
        { root: true },
      )
      error = err.message?.message ? err.message : err
    } finally {
      commit('layout/IS_LOADING', false, { root: true })
    }
    return [response, error]
  },

  async getExamTypes({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await ExamService.getExamTypes(commit, payload)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async createAndUpdateExamType({ commit }, payload) {
    const [response, error] = await ExamService.createAndUpdateExamType(payload, commit)

    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    return [response, error]
  },

  async deleteExamType({ commit }, payload) {
    const [response, error] = await ExamService.deleteExamType(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    return [response, error]
  },

  async getDataForAddEditSubjectExam({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    let response = null,
      error = null
    try {
      response = await ExamService.getDataForAddEditSubjectExam(payload, commit)
    } catch (err) {
      commit(
        'toast/NEW',
        { message: err.message?.message ? err.message?.message : err.message, type: 'error' },
        { root: true },
      )
      error = err.message?.message ? err.message : err
    } finally {
      commit('layout/IS_LOADING', false, { root: true })
    }
    return [response, error]
  },

  async getSubjectExam({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await ExamService.getSubjectExam(commit, payload)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },
  async viewSubjectExamGeneratedResult({ commit }, payload) {
    const [response, error] = await ExamService.viewSubjectExamGeneratedResult(commit, payload)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    return [response, error]
  },
  async viewSectionGeneratedResult({ commit }, payload) {
    const [response, error] = await ExamService.viewSectionGeneratedResult(commit, payload)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    return [response, error]
  },
  async createSubjectExam({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await ExamService.createSubjectExam(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async updateSubjectExam({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await ExamService.updateSubjectExam(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async deleteSubjectExam({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await ExamService.deleteSubjectExam(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async markCompleteSubjectExam({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await ExamService.markCompleteSubjectExam(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async markCanceledSubjectExam({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await ExamService.markCanceledSubjectExam(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async getSubjectExamResult({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await ExamService.getSubjectExamResult(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },
  async getPendingStudentList({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await ExamService.getPendingStudentList(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },
  async getSectionSubjectResultList({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await ExamService.getSectionSubjectResultList(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },
  async getGeneratedSubjectResultList({ commit }, payload) {
    const [response, error] = await ExamService.getGeneratedSubjectResultList(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    return [response, error]
  },
  async getSectionResultList({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await ExamService.getSectionResultList(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async getSubjectExamStudents({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await ExamService.getSubjectExamStudents(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async submitSingleResultOfSubjectExam({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await ExamService.submitSingleResultOfSubjectExam(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async updateSingleResultOfSubjectExam({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await ExamService.updateSingleResultOfSubjectExam(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async deleteSingleResultOfSubjectExam({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await ExamService.deleteSingleResultOfSubjectExam(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async submitBulkResultOfSubjectExam({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await ExamService.submitBulkResultOfSubjectExam(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async examResult({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await ExamService.examResult(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async singleStudentExamResult({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await ExamService.singleStudentExamResult(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },
  // Non GPA Grading Structure
  async getNonGPAStructureList({ commit }, payload) {
    const [response, error] = await ExamService.getNonGPAStructureList(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    return [response, error]
  },

  async getNonGPAStructureDetails({ commit }, id) {
    const [response, error] = await ExamService.getNonGPAStructureDetails(id, commit)
    if (error) commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    return [response, error]
  },
  async getSubjectExamDetails({ commit }, id) {
    const [response, error] = await ExamService.getSubjectExamDetails(id, commit)
    if (error) commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    return [response, error]
  },
  async getSectionExamDetails({ commit }, id) {
    const [response, error] = await ExamService.getSectionExamDetails(id, commit)
    if (error) commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    return [response, error]
  },
  async generateSubjectResult({ commit }, id) {
    const [response, error] = await ExamService.generateSubjectResult(id, commit)
    if (error) commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    return [response, error]
  },
  async reGenerateSubjectResult({ commit }, id) {
    const [response, error] = await ExamService.reGenerateSubjectResult(id, commit)
    if (error) commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    return [response, error]
  },
  async publishedSubjectResult({ commit }, id) {
    const [response, error] = await ExamService.publishedSubjectResult(id, commit)
    if (error) commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    return [response, error]
  },
  async publishedExamSchedule({ commit }, id) {
    const [response, error] = await ExamService.publishedExamSchedule(id, commit)
    if (error) commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    return [response, error]
  },
  async publishedSectionResult({ commit }, id) {
    const [response, error] = await ExamService.publishedSectionResult(id, commit)
    if (error) commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    return [response, error]
  },
  async generateSectionResult({ commit }, id) {
    const [response, error] = await ExamService.generateSectionResult(id, commit)
    if (error) commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    return [response, error]
  },
  async reGenerateSectionResult({ commit }, id) {
    const [response, error] = await ExamService.reGenerateSectionResult(id, commit)
    if (error) commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    return [response, error]
  },
  async addNonGPAGradingStructure({ commit }, id) {
    const [response, error] = await ExamService.addNonGPAGradingStructure(id, commit)
    if (error) commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    return [response, error]
  },
  async deleteNonGPAGradingStructure({ commit }, id) {
    const [response, error] = await ExamService.deleteNonGPAGradingStructure(id, commit)
    if (error) commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    return [response, error]
  },
  // GPA Grading Structure
  async getGPAStructureList({ commit }, payload) {
    const [response, error] = await ExamService.getGPAStructureList(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    return [response, error]
  },

  async getGPAStructureDetails({ commit }, id) {
    const [response, error] = await ExamService.getGPAStructureDetails(id, commit)
    if (error) commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    return [response, error]
  },

  async addGPAGradingStructure({ commit }, id) {
    const [response, error] = await ExamService.addGPAGradingStructure(id, commit)
    if (error) commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    return [response, error]
  },
  async deleteGPAGradingStructure({ commit }, id) {
    const [response, error] = await ExamService.deleteGPAGradingStructure(id, commit)
    if (error) commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    return [response, error]
  },
}
