<template>
  <UiOnClickOutside :do="closeDropdown">
    <span
      v-if="notificationStatus === 'unread'"
      class="font-roboto font-normal text-text-color text-xs sm:text-sm rounded-sm relative p-2.5"
      @click.stop="toggleDropdown"
    >
      <icon
        :width="increaseSize ? '6' : '4'"
        height="22"
        class="icon cursor-pointer"
        icon="verticalElipses"
      />
      <UiMenuDropDown
        v-if="notificationStatus === 'unread'"
        :class="$style.tableDropDown"
        :is-open="isOpen"
        class="font-roboto font-normal text-text-color text-xs sm:text-sm p-1 rounded-md border border-primary-grey"
        @toggle-is-open="toggleIsOpen"
      >
        <ul>
          <li
            v-if="notificationStatus === 'unread'"
            class="py-1.5 ltr:pl-1.5 rtl:pr-1.5 ltr:pr-4 rtl:pl-4 hover:bg-primary-purple-50 rounded cursor-pointer hover:text-primary-purple-700"
            @click="notificationAction('Read', id)"
          >
            {{ $t('notifications.Mark as Read') }}
          </li>
          <!-- <li
            v-if="notificationStatus === 'Read'"
            class="py-1.5 ltr:pl-1.5 rtl:pr-1.5 ltr:pr-4 rtl:pl-4 hover:bg-bg-color-light rounded cursor-pointer hover:text-primary-green"
            @click="notificationAction('Unread', id)"
          >
            {{ $t('notifications.Mark as Unread') }}
          </li> -->
        </ul>
      </UiMenuDropDown>
    </span>
  </UiOnClickOutside>
</template>

<script>
import icon from '@components/icons/icon.vue'
import UiMenuDropDown from '@components/UiElements/UiMenuDropDown.vue'
import UiOnClickOutside from '@components/UiElements/UiOnClickOutside.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    icon,
    UiMenuDropDown,
    UiOnClickOutside,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    notificationStatus: { type: String, default: '' },
    increaseSize: { type: Boolean, default: false },
  },
  emits: ['notificationAction'],
  data() {
    return {
      isActive: false,
      isOpen: false,
    }
  },
  methods: {
    ...mapActions('notifications', ['setNotificationStatus']),
    /**
     * Close Dropdown
     * @description Assigns false value to isActive and calls toggleIsOpen to close dropdown
     */
    closeDropdown() {
      if (this.isActive) {
        this.isActive = false
        this.toggleIsOpen()
      }
    },
    /**
     * Toggle Dropdown
     * @description Toggles the dropdown by negating the isActive and calling the toggleIsOpen()
     */
    toggleDropdown() {
      this.isActive = !this.isActive
      this.toggleIsOpen()
    },
    /**
     * Toggle Is Open
     * @param {boolean | *} - Boolean or any other type
     * @returns {void}
     * @description It gives boolean value to isOpen
     */
    toggleIsOpen(value) {
      this.isOpen = typeof value === 'boolean' ? value : !this.isOpen
    },
    /**
     * Notification Action
     * @param {string} action - Read or Unread
     * @param {number} id - id refers to notification_id on which we want to perform the action
     * @returns {void}
     * @description Emits notification action to parent component
     */
    // TODO: If includes emit word in function name, it would be more comprehensive
    notificationAction(action, id) {
      this.$emit('notificationAction', { action: action, id: id })
    },
  },
}
</script>

<style lang="scss" module>
.tableDropDown {
  top: 39px;
  right: -3px;
  z-index: 6666;
  width: 150px;
  &::before {
    position: absolute;
    top: -6px;
    right: 10px;
    width: 8px;
    height: 8px;
    content: '';
    background: var(--bg-white);
    border-top: 1px solid var(--primary-grey);
    border-left: 1px solid var(--primary-grey);
    transform: rotate(45deg);
  }
}
body[dir='rtl'] {
  .tableDropDown {
    right: -114px;
    &::before {
      right: 131px;
    }
  }
}
</style>
