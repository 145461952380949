import i18n from '@src/i18n'
import { es } from 'date-fns/locale'
import ChapterService from './services'

const t = i18n?.global?.t

export default {
  getChapters({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      ChapterService.getChapters(payload)
        .then((res) => {
          resolve(res.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getTopics({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      ChapterService.getTopic(payload)
        .then((res) => {
          resolve(res.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getTopicStats({ commit }, payload = '') {
    return new Promise((resolve, reject) => {
      return ChapterService.getTopicStats(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  getChapterStats({ commit }, payload = '') {
    return new Promise((resolve, reject) => {
      return ChapterService.getChapterStats(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  addTopic({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      ChapterService.addTopic(payload)
        .then((res) => {
          commit(
            'toast/NEW',
            { type: 'success', message: t(`toast.Successfully Created`) },
            { root: true },
          )
          resolve(res)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  deactivateTopic({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ChapterService.deactivateTopic(payload)
        .then((res) => {
          commit('toast/NEW', { type: 'success', message: `SUCCESSFULLY_DELETED` }, { root: true })
          resolve(res)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  addChapter({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      ChapterService.addChapter(payload)
        .then((res) => {
          if (payload.id)
            commit(
              'toast/NEW',
              { type: 'success', message: 'Successfully Updated' },
              { root: true },
            )
          else {
            commit(
              'toast/NEW',
              { type: 'success', message: t(`toast.Successfully Created`) },
              { root: true },
            )
          }
          resolve(res)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  deleteChapter({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      ChapterService.deleteChapter(payload)
        .then((res) => {
          commit(
            'toast/NEW',
            { type: 'success', message: t(`toast.SUCCESSFULLY_DELETED`) },
            { root: true },
          )
          resolve(res)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
}
