import { by, getById } from '@services/custom.service'
import { TENNAT_API_URL } from '@services/.env'
import { BaseService } from '@services/base.service'
import { extractErrorMassage } from '@utils/generalUtil'
export default class AddOns extends BaseService {
  static getEmailsProviderList(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}email_ad_ons/filters`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = extractErrorMassage(error)
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static addNewEmailConfiguration(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}email_ad_ons`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = extractErrorMassage(error)
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static getEmailProviderDetails(id) {
    return new Promise((resolve, reject) => {
      return getById(`${TENNAT_API_URL}email_ad_ons`, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = extractErrorMassage(error)
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static setEmailProviderDefault(id) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}email_ad_ons`, {}, `${id}/set_default`)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = extractErrorMassage(error)
          reject(this.errorWrapper(error, message))
        })
    })
  }
  // SMS Add-Ons
  static getSMSProviderList(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}sms_ad_ons/filters`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = extractErrorMassage(error)
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static addNewSMSConfiguration(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}sms_ad_ons`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = extractErrorMassage(error)
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static getSMSProviderDetails(id) {
    return new Promise((resolve, reject) => {
      return getById(`${TENNAT_API_URL}sms_ad_ons`, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = extractErrorMassage(error)
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static setSMSProviderDefault(id) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}sms_ad_ons`, {}, `${id}/set_default`)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = extractErrorMassage(error)
          reject(this.errorWrapper(error, message))
        })
    })
  }
}
