export const siteConstants = {
  DEDICATED_DOMAIN: 'dedicated',
  MYQAMPUS_BETA_DOMAIN: 'myqampusBeta',
  MYQAMPUS_PROD_MAIN_DOMAIN: 'myqampusProdMain',
  MYQAMPUS_OTHER_DOMAIN: 'myqampusOtherMain',
  MYQAMPUS_PROD_SUB_DOMAIN: 'myqampusProdSub',
  MYQAMPUS_PROD_DEMO_SUB_DOMAIN: 'myqampusProdDemoSub',
  MYQAMPUS_BETA_SUB_DOMAIN: 'subdomainBetaMyQampus',
  MYQAMPUS_BETA_STATIC_SITE: 'myqampusBetaStaticSite',
  MYQAMPUS_PROD_STATIC_SITE: 'myqampusProdStaticSite',
}

export const siteDomainHandler = () => {
  const host = window.location.host.split('.')
  const site = window.location.host
  const domainType = currentDomainType(site)
  let isDomainFieldVisible = true
  let domain = ''
  const isDedicatedDomain = domainType === siteConstants.DEDICATED_DOMAIN
  const isProdOrDevSubDomain = [
    siteConstants.MYQAMPUS_PROD_SUB_DOMAIN,
    siteConstants.MYQAMPUS_PROD_DEMO_SUB_DOMAIN,
    siteConstants.MYQAMPUS_BETA_SUB_DOMAIN,
  ].includes(domainType)
  if (isDedicatedDomain) {
    isDomainFieldVisible = false
    domain = site
  } else if (isProdOrDevSubDomain) {
    isDomainFieldVisible = false
    domain = host[0]
  }
  return { isDomainFieldVisible, domain }
}
/**
 * Pure Funcion to check wether the domain is dedicated or host
 */
// To run tests, use below syntax
export const currentDomainType = (host, pathname = null) => {
  let returnVal = ''
  /* CASE 1 if coming from myqampus */
  if (/^(.*)myqampus.com$/.test(host)) {
    if (/^(app)\.myqampus\.com$/.test(host)) {
      /* CASE 1.1 if myqampus own subdomains */
      returnVal = siteConstants.MYQAMPUS_PROD_MAIN_DOMAIN
    } else if (
      /^(.*)\.(dev|stage|staging)\.myqampus\.com$/.test(host) &&
      /^(?!app\.)[a-zA-Z0-9][\w\-]*\.(dev|stage|staging|dev[1-7])\.myqampus\.com$/.test(host)
    ) {
      /* CASE 1.2 if myqampus dev or stage subdomains  */
      returnVal = siteConstants.MYQAMPUS_BETA_SUB_DOMAIN
    } else if (/^(app)\.(stage|staging|dev|dev[1-7])\.myqampus\.com$/.test(host)) {
      /* CASE 1.1 if myqampus own subdomains */
      returnVal = siteConstants.MYQAMPUS_BETA_DOMAIN // dev.myqampus.com
    } else if (/^(jenkins|ci)\.myqampus\.com$/.test(host)) {
      /* CASE 1.1 if myqampus own subdomains */
      returnVal = siteConstants.MYQAMPUS_OTHER_DOMAIN
    } else if (
      /^(.*)\.myqampus\.com$/.test(host) &&
      /^(?!(app|demo|www|dev|stage|staging|jenkins|ci)\.)[a-zA-Z0-9][\w\-]*\.myqampus\.com$/.test(
        host,
      )
    ) {
      /* CASE 1.3 if myqampus live subdomains */
      returnVal = siteConstants.MYQAMPUS_PROD_SUB_DOMAIN
    } else if (host === 'demo.myqampus.com') {
      /* CASE 1.5 if pure myqampus */
      returnVal = siteConstants.MYQAMPUS_PROD_DEMO_SUB_DOMAIN
    } else if (/^(dev|stage|staging)\.myqampus\.com$/.test(host)) {
      /* CASE 1.1 if myqampus own subdomains */
      returnVal = siteConstants.MYQAMPUS_BETA_STATIC_SITE
    } else if (/^(www)\.myqampus\.com$/.test(host) || /^myqampus\.com$/.test(host)) {
      /* CASE 1.1 if myqampus own subdomains */
      returnVal = siteConstants.MYQAMPUS_PROD_STATIC_SITE
    }
  } else if (/^(localhost:)\+?\d+$/.test(host)) {
    /* CASE 2 if coming from localhost */
    returnVal = siteConstants.MYQAMPUS_BETA_DOMAIN
  } else if (!isNaN(parseInt(host))) {
    /* CASE 3 if ip address */
    returnVal = siteConstants.MYQAMPUS_BETA_DOMAIN
  } else {
    /* CASE 4 WHITELABEL DOMAIN */
    returnVal = siteConstants.DEDICATED_DOMAIN
  }
  /* EXCEPTIONAL CASSES */
  if (pathname === '/team/dashboard') {
    returnVal = siteConstants.MYQAMPUS_OTHER_DOMAIN
  }
  return returnVal
}

export default { currentDomainType, siteConstants }
