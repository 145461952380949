import { ResponseWrapper, ErrorWrapper } from './util'

export class BaseService {
  static get entity() {
    throw new Error('"entity" getter not defined')
  }
  /**
   * ------------------------------
   * @HELPERS
   * ------------------------------
   */

  static responseWrapper(...rest) {
    return new ResponseWrapper(...rest)
  }

  static errorWrapper(...rest) {
    return new ErrorWrapper(...rest)
  }

  /**
   * ------------------------------
   * @API_CALLS_PRIVATE
   * ------------------------------
   */

  static getById(id = window.required()) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}/${id}`)
        .then((response) => resolve(this.responseWrapper(response, response.data.data)))
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
}
