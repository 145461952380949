<template>
  <div>
    <div
      v-for="(notification, index) in notifications"
      :key="index"
      class="w-full rounded-lg hover:bg-primary-purple-25"
      :class="[notification.status === 'unread' ? 'bg-primary-purple-25' : 'bg-transparent']"
    >
      <div class="flex gap-1" @click="notificationAction({ action: 'Read', id: notification.id })">
        <router-link
          :to="notification.redirect_path ? notification.redirect_path : ''"
          class="w-full rounded-lg flex p-3"
        >
          <div class="flex flex-col mr-4">
            <div
              v-if="notification.related_user && notification.related_user.related_user_image"
              class="h-8 w-8"
            >
              <img
                :src="notification.related_user.related_user_image"
                alt=""
                class="object-cover h-full w-full rounded-full"
              />
            </div>
            <div
              v-else
              class="flex capitalize bg-primary-purple-50 text-sm font-medium text-primary-purple-700 justify-center rounded-full items-center h-8 w-8"
            >
              {{
                $filters.getInitial(
                  notification?.related_user?.related_user_first_name,
                  notification?.related_user?.related_user_last_name,
                )
              }}
            </div>
            <div
              class="flex flex-col text-primary-gray-500 mt-1 text-[8px]"
              :class="customStyle ? 'gap-5' : 'justify-between'"
            >
              <div class="w-10">
                {{ notificationTime(notification.inserted_at) }}
              </div>
              <div>
                {{ notificationDate(notification.inserted_at) }}
              </div>
            </div>
          </div>
          <div
            class="flex flex-col font-inter w-full gap-2"
            @click="$emit('markAsRead', notification.id)"
          >
            <div class="text-primary-gray-700 font-semibold text-xs cuttoff-text two-lines">
              {{ notificationTitle(notification.title, titleLimit) }}
            </div>

            <div class="text-primary-gray-500 font-inter text-10 cuttoff-text">
              {{ notification.body }}
            </div>
          </div>
        </router-link>
        <div class="flex justify-end" :class="{ 'w-5': increaseSize }">
          <NotificationsOptions
            :id="notification.id"
            :increase-size="increaseSize"
            :notification-status="notification.status"
            @notificationAction="notificationAction"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import DATETIME_CONSTANT from '@src/constants/date-time-constants.js'
import { formatDate } from '@src/utils/moment.util.js'
import NotificationsOptions from './notification-options.vue'
export default {
  components: {
    NotificationsOptions,
  },
  props: {
    notifications: { type: Array, default: () => [] },
    increaseSize: { type: Boolean, default: false },
    showMore: { type: Boolean, default: false },
    titleLimit: { type: Number, default: 29 },
    customStyle: { type: Boolean, default: false },
  },
  emits: ['notificationAction', 'markAsRead'],
  data() {
    return {
      dashboard: 'dashboard',
    }
  },
  methods: {
    ...mapActions('notifications', ['setNotificationStatus']),
    /**
     * Notification Title
     * @param {string} notification
     * @param {number} limit
     * @returns {string}
     * @description If notification length is greater 30 alphabets and limit is smaller than 30 alphabets then dots will add when limit exceeds
     */
    notificationTitle(notification, limit) {
      if (notification.length > 30) {
        return limit <= notification.length
          ? notification.substring(0, limit) + '...'
          : notification.substring(0, limit)
      } else {
        return notification
      }
    },
    /**
     * Notification Date
     * @param {string} date
     * @returns {string} - Formatted date
     * @description Gets the date then convert it according to local standard, then into string format and extract the exact required date and then format it.
     */
    // TODO: function name should consist format word for better understanding
    notificationDate(date) {
      var notificationDate = new Date(date).toDateString().slice(4)
      var output = [notificationDate.slice(0, 6)].join('')
      return output
    },
    /**
     * Notification Time
     * @param {string} date
     * @return {string} - Formatted time
     * @description Extracts the time from date and convert it into 12 hours format from 24 Hours format
     */
    notificationTime(date) {
      return formatDate(date, DATETIME_CONSTANT.time12H)
    },
    /**
     * Notification Action
     * @param {object} payload - Its properties are { action: 'Read', id: notification.id }
     * @returns {void}
     * @description Emits notificationAction to parent component
     */
    // TODO: Function name should be descriptive
    notificationAction(payload) {
      this.$emit('notificationAction', payload)
    },
  },
}
</script>

<style lang="scss" scoped>
.cuttoff-text {
  --max-lines: 3;

  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--max-lines);

  /* Two lines truncation */
  &.two-lines {
    --max-lines: 2;
  }
}
</style>
