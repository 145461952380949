import ClassService from './services'
export default {
  setCurrentClassAttendance({ state, commit, rootState }, payload) {
    commit('SET_CURRENT_CLASS_ATTENDANCE', payload)
  },

  getAllClasses({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      ClassService.getClasses()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  getSubjects({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      ClassService.getSubjects()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },
}
