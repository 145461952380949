// import { UsersService } from '@src/services/users.service'
import GuardianService from './services'
import { fullName } from '@src/utils/settings/tenant-user.util.js'

export default {
  createGuardian({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return GuardianService.createGuardian(payload)
        .then((response) => {
          commit(
            'toast/NEW',
            { message: 'Guardian created successfully', type: 'success' },
            { root: true },
          )
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  getAllGuardians({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return GuardianService.getAllGuardians(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  getGuardiansWithFilter({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return GuardianService.getGuardiansWithFilter(payload)
        .then((response) => {
          response?.data?.records.forEach((user) => {
            fullName(user)
          })
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  getGuardianStats({ commit }, payload = '') {
    return new Promise((resolve, reject) => {
      return GuardianService.getGuardianStats(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  editGuardian({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return GuardianService.editGuardian(payload)
        .then((response) => {
          commit('toast/NEW', { message: 'Updated Successfully', type: 'success' }, { root: true })
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  deactivateGuardian({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return GuardianService.deactivateGuardian(payload)
        .then((response) => {
          commit(
            'toast/NEW',
            { message: 'Deactivated Successfully', type: 'success' },
            { root: true },
          )
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
}
