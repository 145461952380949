import { TENNAT_API_URL } from '@services/.env'
import { BaseService } from './base.service'
import { by, get, del } from './custom.service'
import { extractErrorMassage } from '@utils/generalUtil'

export class NotificationServices extends BaseService {
  static getAllNotifications(Data) {
    return new Promise((resolve, reject) => {
      return get(`${TENNAT_API_URL}notifications`, { params: Data })
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  static getAllNotificationsAndAnnouncemnts() {
    return new Promise((resolve, reject) => {
      return get(`${TENNAT_API_URL}notifications_announcements`)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  static notificationStatus(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}notifications/mark_read`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  static retryAnnouncementRequest(id) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}announcments`, {}, `${id}/retry`)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }
  static resendAnnouncementRequest(id) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}announcments`, {}, `${id}/resend`)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  static getStaffData(payload, commit) {
    return Promise.all([
      by(`${TENNAT_API_URL}departments/campus_departments`, payload?.department),
      get(`${TENNAT_API_URL}staffs`, { params: payload?.staff }),
    ])
      .then((response) => [
        response.map((res) => {
          return this.responseWrapper(res, res?.data)
        }),
        null,
      ])
      .catch((error) => {
        const message = extractErrorMassage(error)
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static getNotificationTriggers({ payload, path }, commit) {
    return by(`${TENNAT_API_URL}${path}/filter`, payload)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = extractErrorMassage(error)
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static createNotificationTrigger({ payload, path }, commit) {
    return by(`${TENNAT_API_URL}${path}`, payload)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = extractErrorMassage(error)
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }
  static updateNotificationTrigger({ payload, path }, commit) {
    return by(`${TENNAT_API_URL}${path}`, payload, payload.id)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = extractErrorMassage(error)
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static deleteNotificationTrigger({ payload, path }, commit) {
    return del(`${TENNAT_API_URL}${path}/deactivate`, payload.id)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = extractErrorMassage(error)
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static disableNotificationTrigger({ payload, path }, commit) {
    return by(`${TENNAT_API_URL}${path}/disable`, payload, payload.id)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = extractErrorMassage(error)
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static enableNotificationTrigger({ payload, path }, commit) {
    return by(`${TENNAT_API_URL}${path}/enable`, payload, payload.id)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = extractErrorMassage(error)
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static createAnnouncemnt(payload, commit) {
    return by(`${TENNAT_API_URL}announcments`, payload)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = extractErrorMassage(error)
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static getAnnouncemnt(payload, commit) {
    return by(`${TENNAT_API_URL}announcments/filter`, payload)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = extractErrorMassage(error)
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static getAnnouncemntById(payload, commit) {
    return get(`${TENNAT_API_URL}announcments/${payload.announcementId}`, {
      params: payload.range,
    })
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = extractErrorMassage(error)
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static getSmsEmailConfig(commit) {
    return get(`${TENNAT_API_URL}email_sms_config_status`)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = extractErrorMassage(error)
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }
  static getPreSendAnnouncementStats(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}announcments/pre_send_stats`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get schedule !'
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static getAnnouncementStats(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}announcments/announcment_stats`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get stats !'
          reject(this.errorWrapper(error, message))
        })
    })
  }
}
