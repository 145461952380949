import { objectDeepCopy } from '@src/utils/generalUtil.js'
const graphConstants = {
  PIE: 'pie',
  BAR: 'bar',
  Graph_Color: {
    lightOrange: '#FFB55A',
    lightRed: '#FD7F6F',
    lightBlue: '#7EB0D5',
    softYellowGreen: '#B2E061',
    lightPink: '#BD7EBE',
    lightYellow: '#FFEE65',
    paleNavy: '#BEB9DB',
    palePink: '#FDCCE5',
    lightCyan: '#8bd3c7',
  },
  BAR_CHART: function (labels, value, title, labelString) {
    const BAR_GRAPH = {
      chart: {
        type: this.BAR,
        data: {
          labels: labels,

          datasets: [
            {
              data: value,
              backgroundColor: Object.values(this.Graph_Color),
              maxBarThickness: 20,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      },
      LEGEND_DATA: [
        {
          LEGENDS: LEGEND_TITLE_COLOR(title, Object.values(this.Graph_Color)),
        },
      ],
    }
    return BAR_GRAPH
  },
  PIE_CHART: function (Labels, Value, chartColor, Title) {
    let pieColor = objectDeepCopy(chartColor)
    const PIE_GRAPH = {
      chart: {
        type: this.PIE,
        data: {
          labels: Labels,
          datasets: [
            {
              data: Value,
              backgroundColor: chartColor,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      },
      chartname: 'bar',
      LEGEND_DATA: [
        {
          LEGENDS: LEGEND_TITLE_COLOR(Title, pieColor.reverse()),
        },
      ],
    }
    return PIE_GRAPH
  },
}
function LEGEND_TITLE_COLOR(TITLE_NAME, COLOR) {
  let LEGENDS = []

  TITLE_NAME.forEach((element, index) => {
    let LEGEND = {
      color: COLOR[index],
      text: element,
    }
    LEGENDS.push(LEGEND)
  })
  return LEGENDS
}

export default graphConstants
