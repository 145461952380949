export default {
  SET_ACTIVE_USER(state, activeUserData) {
    state.activeUser = activeUserData
  },
  SET_CURRENT_ROLE(state, currentRole) {
    state.currentRole = currentRole
  },
  ADD_USER_TO_LOCALSTORAGE(state, payload) {
    window.localStorage.setItem('users', JSON.stringify(payload))
  },
}
