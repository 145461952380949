<template>
  <div>
    <UiOnClickOutside :do="closeDropdown">
      <div
        v-if="!tabLeftNav"
        class="xl:mt-6 mt-[15px] flex justify-center cursor-pointer"
        @click="mobileLeftBar"
      >
        <div
          class="rounded-full min-w-[36px] h-9 lg:w-[54px] lg:h-[54px] lg:min-w-[54px] flex justify-center items-center"
        >
          <span
            v-if="!userImage"
            class="bg-primary-purple-200 border-border-color-light border text-primary-purple-600 w-full h-full font-inter text-xs leading-[18px] md:text-sm font-medium sm:text-sm rounded-full uppercase flex justify-center items-center"
          >
            {{ userInitials }}
          </span>
          <span v-else class="min-w-[36px] h-9 lg:w-[54px] lg:h-[54px] lg:min-w-[54px]">
            <img
              class="object-cover h-full w-full z-50 rounded-full"
              :src="userImage"
              alt="user image no found"
            />
          </span>
        </div>
      </div>
      <div
        v-else
        class="flex flex-col gap-2.5 cursor-pointer my-6 relative"
        @click="toggleDropdown"
      >
        <div
          class="flex items-center justify-between w-full bg-[#FFFFFF99]"
          :class="dropdownContainerClass"
        >
          <div class="flex items-center justify-between gap-1 lg:gap-2 truncate">
            <div
              class="rounded-full min-w-[36px] h-9 lg:w-[54px] lg:h-[54px] lg:min-w-[54px] flex justify-center items-center"
            >
              <!-- * Will enable it when modal fixed -->
              <!-- @click="changeProfilePicture" -->
              <span
                v-if="!userImage"
                class="bg-primary-purple-200 text-primary-purple-600 w-full h-full font-inter text-xs leading-[18px] font-medium sm:text-sm rounded-full uppercase flex justify-center items-center"
              >
                {{ userInitials }}
              </span>
              <span v-else class="w-9 min-w-[36px] h-9 lg:w-[54px] lg:h-[54px] lg:min-w-[54px]">
                <img
                  class="object-cover h-full w-full z-50 rounded-full"
                  :src="userImage"
                  alt="user image no found"
                />
              </span>
            </div>
            <div class="font-inter text-left truncate">
              <div
                class="text-primary-gray-700 text-xs leading-[18px] lg:text-sm font-medium truncate"
              >
                {{ updateNamerange(fullName) }}
              </div>
              <div
                class="text-primary-gray-400 text-10 lg:text-xs leading-[18px] font-normal truncate"
              >
                <span>{{ currentUserEmail }}</span>
              </div>
            </div>
          </div>
          <div class="ml-1">
            <icon :icon="chevronIconDirection" :color="chevronIconColor" width="12" height="6" />
          </div>
        </div>
        <UiMenuDropDown
          class="flex flex-col absolute bg-primary-purple-50 pt-2.5 w-full border-x-[1.5px] border-x-white border-t-white profile-dropdown"
          :class="dropdownBorderClass"
          :is-open="isOpen"
          :disabled-styles="true"
          @toggle-is-open="toggleIsOpen"
        >
          <StudentSwitchDropdown
            v-if="userRole === TENANT_ROLES.GUARDIAN"
            @closeStudentSwitchDropdown="closeDropdown"
            @openStudentSwitchDropdown="openStudentSwitchDropdown"
          />
          <ul
            class="flex flex-col gap-[15px] px-2.5 pt-[15px] font-inter font-normal text-primary-gray-700 text-sm border-b-primary-purple-100 border-b pb-2.5"
          >
            <li
              v-if="isMyScheduleEnabled"
              class="flex gap-2 items-center text-sm font-medium text-primary-gray-700"
              @mouseover="profileActionMouseOver(0)"
              @mouseleave="profileActionMouseleave()"
            >
              <span class="h-6 w-6 flex items-center justify-center">
                <icon height="14" width="14" icon="schedule-icon" color="menu" />
              </span>
              <router-link to="/my-schedule">
                <a>
                  <span
                    class="w-full"
                    :class="{ 'text-primary-purple-700': profileActionIconColor === 0 }"
                  >
                    My Schedule
                  </span>
                </a>
              </router-link>
            </li>
            <li
              v-if="isControlPanelVisible"
              class="flex gap-1.5 md:gap-2 items-center text-xs leading-[18px] md:text-sm font-medium text-primary-gray-700"
              @mouseover="profileActionMouseOver(0)"
              @mouseleave="profileActionMouseleave()"
            >
              <span class="h-6 w-6 flex items-center justify-center">
                <icon
                  icon="controlPanel"
                  :color="profileActionIconColorClass(0)"
                  :width="iconWidth"
                  :height="iconHeight"
                />
              </span>
              <router-link to="/control-panel/dashboard">
                <a>
                  <span
                    v-i18n="header"
                    class="w-full"
                    :class="{ 'text-primary-purple-700': profileActionIconColor === 0 }"
                  >
                    Control Panel
                  </span>
                </a>
              </router-link>
            </li>
            <li
              v-permission="salaryTabPermissions"
              class="flex gap-2 items-center text-sm font-medium text-primary-gray-700"
              @mouseover="profileActionMouseOver()"
              @mouseleave="profileActionMouseleave()"
            >
              <span class="h-6 w-6 flex items-center justify-center">
                <icon height="14" width="14" icon="history" color="menu" />
              </span>
              <router-link to="/salary-manager/my-salary">
                <a>
                  <span
                    v-i18n="header"
                    class="w-full"
                    :class="{ 'text-primary-purple-700': profileActionIconColor }"
                  >
                    MY_SALARY
                  </span>
                </a>
              </router-link>
            </li>
            <li
              v-if="isPicker"
              class="flex gap-2 items-center text-sm font-medium text-primary-gray-700"
              @mouseover="profileActionMouseOver(4)"
              @mouseleave="profileActionMouseleave()"
            >
              <span class="h-6 w-6 flex items-center justify-center">
                <icon height="14" width="14" icon="bus" color="primary-gray-700" />
              </span>
              <router-link to="/user-pickup-facility">
                <a>
                  <span
                    v-i18n="header"
                    class="w-full"
                    :class="{ 'text-primary-purple-700': profileActionIconColor === 4 }"
                  >
                    STUDENT_DISPATCHER
                  </span>
                </a>
              </router-link>
            </li>
            <li
              v-if="isPickupSupportPerson"
              class="flex gap-2 items-center text-sm font-medium text-primary-gray-700"
              @mouseover="profileActionMouseOver(5)"
              @mouseleave="profileActionMouseleave()"
            >
              <span class="h-6 w-6 flex items-center justify-center">
                <icon height="14" width="14" icon="bus" color="'primary-gray-700" />
              </span>
              <router-link to="/user-pickup-support">
                <a>
                  <span
                    v-i18n="header"
                    class="w-full"
                    :class="{ 'text-primary-purple-700': profileActionIconColor === 5 }"
                  >
                    STUDENT_DISPATCHER
                  </span>
                </a>
              </router-link>
            </li>
            <li
              class="flex gap-1.5 md:gap-2 items-center text-xs leading-[18px] md:text-sm font-medium text-primary-gray-700"
              @mouseover="profileActionMouseOver(1)"
              @mouseleave="profileActionMouseleave()"
            >
              <span class="h-6 w-6 flex items-center justify-center">
                <icon
                  icon="installApp"
                  :color="profileActionIconColorClass(1)"
                  :width="iconWidth"
                  :height="iconHeight"
                />
              </span>
              <span
                v-i18n="header"
                class="w-full"
                :class="{ 'text-primary-purple-700': profileActionIconColor === 1 }"
                @click="installPWA"
              >
                Install App{{ profileActionIconColor }}
              </span>
            </li>
            <li
              v-permission="myProfilePermissions"
              class="flex gap-1.5 md:gap-2 items-center text-xs leading-[18px] md:text-sm font-medium text-primary-gray-700"
              @mouseover="profileActionMouseOver(2)"
              @mouseleave="profileActionMouseleave()"
            >
              <span class="h-6 w-6 flex items-center justify-center">
                <icon
                  icon="user"
                  :color="profileActionIconColorClass(2)"
                  :width="iconWidth"
                  :height="iconHeight"
                />
              </span>
              <router-link to="/myprofile">
                <a>
                  <span
                    v-i18n="header"
                    class="w-full"
                    :class="{ 'text-primary-purple-700': profileActionIconColor === 2 }"
                  >
                    MY_PROFILE
                  </span>
                </a>
              </router-link>
            </li>
            <!-- *Comment it till personal settings implemented -->
            <!-- <li
              v-permission="personalSettingsPerission"
              class="flex gap-2 items-center text-sm font-medium text-primary-gray-700"
              @mouseover="profileActionMouseOver()"
              @mouseleave="profileActionMouseleave()"
            >
              <span class="h-6 w-6 flex items-center justify-center">
                <icon height="16" width="16" icon="AppDoc" color="menu" />
              </span>
              <router-link to="/app-documentation">
                <a>
                  <span
                    v-i18n="header"
                    class="w-full"
                    :class="{ 'text-primary-purple-700': profileActionIconColor }"
                  >
                    APP_DOCUMENT
                  </span>
                </a>
              </router-link>
            </li> -->
            <li
              class="flex gap-1.5 md:gap-2 items-center text-xs leading-[18px] md:text-sm font-medium text-primary-gray-700"
              @mouseover="profileActionMouseOver(3)"
              @mouseleave="profileActionMouseleave()"
            >
              <span class="h-6 w-6 flex items-center justify-center">
                <icon
                  icon="logout"
                  :color="profileActionIconColorClass(3)"
                  :width="iconWidth"
                  :height="iconHeight"
                />
              </span>
              <span
                v-i18n="header"
                class="w-full"
                :class="{ 'text-primary-purple-700': profileActionIconColor === 3 }"
                @click="signOut"
              >
                Sign Out
              </span>
            </li>
          </ul>
        </UiMenuDropDown>
      </div>
    </UiOnClickOutside>
    <!-- Image modal -->
    <ValidationObserver @submit="uploadPicture">
      <UiModalContainer
        v-if="showProfileModal"
        footer-classes="flex justify-end"
        :modal-show="showProfileModal"
        :disable-position-center="true"
        modal-classes="mt-44"
        @handleClick="handleClick"
      >
        <template v-slot:header>
          <div class="flex rtl:flex-row-reverse">
            <span v-i18n="dashboard">Change Image</span>
          </div>
        </template>
        <template v-slot>
          <div
            class="items-center my-6 max-w-min lg:max-w-screen-lg md:max-w-screen-sm overflow-x-scroll px-8"
          >
            <div class="upload-block my-5">
              <div class="flex flex-col justify-center gap-2 mt-2">
                <Field as="div" class="input-field w-100" name="file">
                  <input
                    id="img-btn"
                    ref="imgUpload"
                    type="file"
                    class="input-field w-100"
                    hidden
                    rules="imageRules"
                    name="file"
                    accept="image/png, image/jpeg, image/jpg"
                    @input="changeImage"
                  />
                </Field>

                <div ref="errorContainer" class="flex justify-end h-5">
                  <ErrorMessage v-slot="{ message }" :name="name === '' ? title : name">
                    <small
                      class="error-msg text-sm text-text-color-danger italic rtl:mt-1 ltr:-mt-0"
                    >
                      <p>{{ message }}</p>
                    </small>
                  </ErrorMessage>
                </div>
                <label
                  for="img-btn"
                  class="flex w-56 cursor-pointer border border-primary-green justify-center items-center px-2 sm:px-5 py-2 rounded-sm"
                >
                  <span>
                    <icon
                      class="icon ltr:mr-2 rtl:ml-2"
                      icon="cloudUpload"
                      color="primary-green"
                      height="20"
                      width="20"
                    />
                  </span>
                  <span
                    v-i18n="dashboard"
                    class="font-roboto font-normal text-xs sm:text-base text-menu"
                  >
                    Choose File
                  </span>
                </label>
                <span
                  v-i18n="dashboard"
                  class="font-roboto font-normal text-xs sm:text-sm text-text-color"
                >
                  ALLOWED_TYPE_OF_IMAGE
                </span>
              </div>
              <span v-if="image.name !== ''" class="mt-2 font-roboto font-normal text-sm">
                {{ image.name }}
              </span>
              <img v-if="image.url" :src="image.url" alt="" srcset="" width="300" height="300" />
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="flex gap-3 rtl:gap-x-4">
            <UIButton @click="handleClick('close')">Cancel</UIButton>
            <UIButton button="primary">
              <template v-if="imageUploading">
                <Loader />
              </template>
              <template v-else>
                <span>Save</span>
              </template>
            </UIButton>
          </div>
        </template>
      </UiModalContainer>
    </ValidationObserver>
    <teleport to="body">
      <PWAGuideLinesModal :show-model="showPwaModal" @handleClick="hidePwaModel" />
    </teleport>
  </div>
</template>

<script>
import UiOnClickOutside from '@components/UiElements/UiOnClickOutside.vue'
import UiMenuDropDown from '@components/UiElements/UiMenuDropDown.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import icon from '@components/icons/icon.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { Form as ValidationObserver } from 'vee-validate'
import RULE_CONSTANTS from '@src/constants/rule.constants.js'
import AuthMixins from '@src/mixins/auth-mixin.js'
import setPWA from '../../utils/pwa-utils'
import PWAGuideLinesModal from '@components/pwa/PWAGuideLinesModal.vue'
import StudentSwitchDropdown from '@src/modules/header/student-switch-dropdown/StudentSwitchDropdown.vue'
import { Field, configure, ErrorMessage } from 'vee-validate'
import { validationConfiguration } from '@src/vee-validate/index.js'
import UIButton from '@src/components/UiElements/UIButton.vue'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'

configure(validationConfiguration)
export default {
  components: {
    UiOnClickOutside,
    UiMenuDropDown,
    ErrorMessage,
    Field,
    UiModalContainer,
    icon,
    Loader,
    ValidationObserver,
    UIButton,
    PWAGuideLinesModal,
    StudentSwitchDropdown,
  },
  mixins: [AuthMixins],
  data() {
    return {
      TENANT_ROLES,
      salaryTabPermissions: {
        env: ['local'],
        roles: ['campus_admin', 'section_teacher', 'staff'],
      },
      isOpenStudentSwitchDropdown: false,
      isActive: false,
      isOpen: false,
      dashboard: 'dashboard',
      header: 'header', // i18ln
      showProfileModal: false,
      imageUploading: false,
      image: {
        name: '',
        url: null,
      },
      isMobile: window.matchMedia('(max-width: 1023px)').matches,
      showPwaModal: false,
      personalSettingsPerission: {
        env: ['local'],
      },
      profileActionIconColor: null,

      smallScreenQuery: window.matchMedia('(max-width: 768px)'),
      iconWidth: '24',
      iconHeight: '24',
    }
  },
  computed: {
    ...mapState({
      tabLeftNav: (state) => state?.layout?.tabLeftNav,
      currentCampusScope: (state) => state.layout.currentCampusScope,
      currentSectionScope: (state) => state.layout.currentSectionScope,
    }),
    profileActionIconColorClass() {
      return (index) => {
        return this.profileActionIconColor === index ? 'primary-purple-700' : 'primary-gray-700'
      }
    },
    dropdownBorderClass() {
      return this.userRole === TENANT_ROLES.GUARDIAN && this.isOpenStudentSwitchDropdown
        ? 'border-t-primary-purple-100'
        : 'rounded-tl-lg rounded-tr-lg border-t-[1.5px]'
    },
    chevronIconColor() {
      return this.isActive ? 'primary-purple-700' : 'primary-gray-700'
    },
    chevronIconDirection() {
      return this.isActive ? 'chevronUp' : 'chevronBottom'
    },
    dropdownContainerClass() {
      return this.isActive
        ? 'border-t-0 border-[1.5px] border-x-white border-b-white rounded-bl-lg rounded-br-lg bg-primary-purple-50 p-2.5'
        : 'border-[1.5px] border-white rounded-lg p-2.5'
    },
    isSmallScreen() {
      return this.smallScreenQuery.matches
    },

    isPicker() {
      return ['guardian'].includes(this.userRole)
    },
    isPickupSupportPerson() {
      return ['section_teacher', 'staff'].includes(this.userRole)
    },
    myProfilePermissions() {
      let isAllowed = {}
      if (['section_teacher'].includes(this.userRole))
        isAllowed = {
          roles: ['section_teacher'],
          basedOn: ['campus-level', 'section-level'],
        }
      else if (['section_student', 'guardian'].includes(this.userRole)) {
        isAllowed = {
          roles: ['section_student', 'guardian'],
          basedOn: ['section-level'],
        }
      }
      return isAllowed
    },

    /**
     * Schedule Enabled
     * @returns {boolean} - Returns true or false on the basis of user role value
     * @description This method returns boolean value on the basis role assigned to user
     */
    isMyScheduleEnabled() {
      if (!this.selectedCampusId) return false
      // Will enable it when schedule feature enabled
      // if (['section_teacher', 'class student'].includes(this.userRole)) return true
      if ([].includes(this.userRole)) return true
      return false
    },
    isControlPanelVisible() {
      return ['super_admin', 'campus_admin'].includes(this.userRole)
    },
    /**
     * Image Rules
     * @returns {string} - Required size of image
     * @description It gives required size of image according to the defined rules.
     */
    imageRules() {
      return `required|${RULE_CONSTANTS.SIZE}`
    },
    userInitials() {
      return this.nameInitials && this.nameInitials !== '0' && this.nameInitials
    },
    ...mapGetters('auth', [
      'fullName',
      'userImage',
      'nameInitials',
      'currentUserId',
      'currentUserEmail',
    ]),
    ...mapGetters('layout', ['selectedCampusId', 'userRole']),
  },
  mounted() {
    this.smallScreenQuery.addListener(this.updateScreenSize)
    this.updateScreenSize()
  },
  beforeUnmount() {
    this.smallScreenQuery.removeListener(this.updateScreenSize)
  },
  methods: {
    openStudentSwitchDropdown(payload) {
      this.isOpenStudentSwitchDropdown = payload
    },
    mobileLeftBar() {
      this.$store.commit('layout/SET_TAB_LEFT_BAR')
    },
    updateScreenSize() {
      if (this.isSmallScreen) {
        this.iconWidth = '20' // Adjust width for small screens
        this.iconHeight = '20' // Adjust height for small screens
      } else {
        this.iconWidth = '24' // Default width for large screens
        this.iconHeight = '24' // Default height for large screens
      }
    },

    profileActionMouseOver(index) {
      this.profileActionIconColor = index
    },
    profileActionMouseleave() {
      this.profileActionIconColor = null
    },
    /**
     * Update Name Range
     * @param {string} name
     * @returns {string} - Name
     * @description It takes name as a paramter and returns name and if name length is greater than 12 then it will show dots after 12 alphabets
     */
    updateNamerange(name) {
      if (name.length > 12) {
        return `${name.slice(0, 12)}...`
      } else {
        return name
      }
    },
    /**
     * Toggle Dropdown
     * @description Toggles the dropdown between open and close
     */
    toggleDropdown() {
      this.isActive = !this.isActive
      this.profileActionIconColor = null
      this.isOpenStudentSwitchDropdown = false
      this.toggleIsOpen()
    },
    /**
     * Toggle Is Open
     * @param { boolean | * } value - boolean or any other value
     * @returns {void}
     * @description Assigns the boolean value to isOpen variable
     */
    toggleIsOpen(value) {
      this.isOpen = typeof value === 'boolean' ? value : !this.isOpen
    },
    /**
     * Close Dropdown
     * @description Closes the dropdown
     */
    closeDropdown() {
      if (this.isActive) {
        this.isActive = false
        this.isOpenStudentSwitchDropdown = false
        this.profileActionIconColor = null
        this.toggleIsOpen()
      }
    },

    /**
     * Change Profile Picture
     * @description It negates the value of ProfileModal variable
     */
    changeProfilePicture() {
      this.showProfileModal = !this.showProfileModal
    },
    /**
     * Handle Click
     * @param {string} eventName
     * @returns {void}
     * @description It gives values to variables on the basis of the cases of the switch statement
     */
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.showProfileModal = false
          this.image = {
            name: '',
            url: null,
          }
          this.$refs['img-upload'].value = null
          break
        case 'cancel':
          break
        case 'save':
          break
      }
    },
    /**
     * Upload Picture
     * @param  {boolean} invalid
     * @returns {void}
     * @description Uploads the picture of user by calling uploadUserImage API
     */
    uploadPicture() {
      this.imageUploading = true
      const data = new FormData()
      data.append('user_id', this.currentUserId)
      data.append('files[]', this.$refs['img-upload'].files[0])
      this.uploadUserImage(data)
        .then((response) => {
          this.$store.commit('auth/CHANGE_CURRENT_USER_IAMGE', response[0].url)
          this.showProfileModal = false
          this.imageUploading = false
          this.image = {
            name: '',
            url: null,
          }
        })
        .finally(() => {
          this.imageUploading = false
        })
    },

    /**
     * Change Image
     * @description Changes the image
     */

    changeImage(event) {
      const input = this.$refs.imgUpload
      const file = input.files
      if (file && file[0]) {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.image.url = event.target.result
          this.image.fileName = file[0].name
        }
        reader.readAsDataURL(file[0])
      }
    },
    /**
     * Install PWA
     * @description install pwa within the app
     */
    installPWA() {
      setPWA()

      const promptEvent = window.deferredPrompt
      if (!promptEvent) {
        if (this.isMobile) this.showPwaModal = true
        else return
      }
      promptEvent.prompt()
      window.deferredPrompt = null
    },

    hidePwaModel(eventName) {
      this.showPwaModal = false
    },
    ...mapActions('users', ['uploadUserImage']),
  },
}
</script>

<style lang="scss" scoped>
.profile-dropdown {
  bottom: 56px;
}
@media screen and (min-width: 1024px) {
  .profile-dropdown {
    bottom: 74px;
  }
}
</style>
