import { SalaryService } from '@services/salary.service'

export default {
  setLoading({ commit }, payload) {
    commit('SET_LOADING', payload)
  },
  setModalLoading({ commit }, payload) {
    commit('SET_MODAL_LOADING', payload)
  },

  showLoading({ commit }) {
    commit('SET_LOADING', true)
  },

  hideLoading({ commit }) {
    commit('SET_LOADING', false)
  },

  setModal({ commit }, payload) {
    commit('SET_MODAL', payload)
  },

  showModal({ commit }) {
    commit('SET_MODAL_SHOW', true)
  },

  hideModal({ commit }) {
    commit('SET_MODAL_HIDE', false)
  },

  async getSalaryGenerators({ commit }, payload) {
    commit('SET_LOADING', true)
    const [response, error] = await SalaryService.getSalaryGenerators(commit, payload)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_LOADING', false)
    return [response, error]
  },

  async createSalaryGenerator({ commit }, payload) {
    commit('SET_MODAL_LOADING', true)
    const [response, error] = await SalaryService.createSalaryGenerator(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_MODAL_LOADING', false)
    return [response, error]
  },

  async updateSalaryGenerator({ commit }, payload) {
    commit('SET_MODAL_LOADING', true)
    const [response, error] = await SalaryService.updateSalaryGenerator(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_MODAL_LOADING', false)
    return [response, error]
  },

  async deleteSalaryGenerator({ commit }, payload) {
    commit('SET_MODAL_LOADING', true)
    const [response, error] = await SalaryService.deleteSalaryGenerator(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_MODAL_LOADING', false)
    return [response, error]
  },

  /**
   * Salary Allowance Types
   */
  async getAllowanceTypes({ commit }, payload) {
    commit('SET_LOADING', true)
    const [response, error] = await SalaryService.getAllowanceTypes(commit, payload)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_LOADING', false)
    return [response, error]
  },

  async createAllowanceType({ commit }, payload) {
    commit('SET_MODAL_LOADING', true)

    const [response, error] = await SalaryService.createAllowanceType(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_MODAL_LOADING', false)

    return [response, error]
  },

  async updateAllowanceType({ commit }, payload) {
    commit('SET_MODAL_LOADING', true)

    const [response, error] = await SalaryService.updateAllowanceType(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_MODAL_LOADING', false)

    return [response, error]
  },

  async deleteAllowanceType({ commit }, payload) {
    commit('SET_MODAL_LOADING', true)
    const [response, error] = await SalaryService.deleteAllowanceType(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_MODAL_LOADING', false)
    return [response, error]
  },

  /**
   * Salary Bonus Types
   */

  async getBonusTypes({ commit }, payload) {
    commit('SET_LOADING', true)
    const [response, error] = await SalaryService.getBonusTypes(commit, payload)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_LOADING', false)
    return [response, error]
  },

  async createBonusType({ commit }, payload) {
    commit('SET_MODAL_LOADING', true)
    const [response, error] = await SalaryService.createBonusType(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_MODAL_LOADING', false)
    return [response, error]
  },

  async updateBonusType({ commit }, payload) {
    commit('SET_MODAL_LOADING', true)
    const [response, error] = await SalaryService.updateBonusType(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_MODAL_LOADING', false)
    return [response, error]
  },

  async deleteBonusType({ commit }, payload) {
    commit('SET_MODAL_LOADING', true)
    const [response, error] = await SalaryService.deleteBonusType(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_MODAL_LOADING', false)
    return [response, error]
  },

  /**
   * Salary Deduction Types
   */

  async getDeductionTypes({ commit }, payload) {
    commit('SET_LOADING', true)
    const [response, error] = await SalaryService.getDeductionTypes(commit, payload)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_LOADING', false)
    return [response, error]
  },

  async createDeductionType({ commit }, payload) {
    commit('SET_MODAL_LOADING', true)
    const [response, error] = await SalaryService.createDeductionType(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_MODAL_LOADING', false)
    return [response, error]
  },

  async updateDeductionType({ commit }, payload) {
    commit('SET_MODAL_LOADING', true)
    const [response, error] = await SalaryService.updateDeductionType(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_MODAL_LOADING', false)
    return [response, error]
  },

  async deleteDeductionType({ commit }, payload) {
    commit('SET_MODAL_LOADING', true)
    const [response, error] = await SalaryService.deleteDeductionType(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_MODAL_LOADING', false)
    return [response, error]
  },

  /**
   * Salary Extra Working Unit
   */

  async getExtraWorkingUnits({ commit }, payload) {
    commit('SET_LOADING', true)
    const [response, error] = await SalaryService.getExtraWorkingUnits(commit, payload)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_LOADING', false)
    return [response, error]
  },

  async createExtraWorkingUnit({ commit }, payload) {
    commit('SET_MODAL_LOADING', true)
    const [response, error] = await SalaryService.createExtraWorkingUnit(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_MODAL_LOADING', false)
    return [response, error]
  },

  async updateExtraWorkingUnit({ commit }, payload) {
    commit('SET_MODAL_LOADING', true)
    const [response, error] = await SalaryService.updateExtraWorkingUnit(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_MODAL_LOADING', false)
    return [response, error]
  },

  async deleteExtraWorkingUnit({ commit }, payload) {
    commit('SET_MODAL_LOADING', true)
    const [response, error] = await SalaryService.deleteExtraWorkingUnit(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_MODAL_LOADING', false)
    return [response, error]
  },

  /* SALARY TEMPLATES */
  async getSalaryTemplates({ commit }, payload) {
    commit('SET_LOADING', true)
    const [response, error] = await SalaryService.getSalaryTemplates(commit, payload)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_LOADING', false)
    return [response, error]
  },

  async createSalaryTemplate({ commit }, payload) {
    commit('SET_MODAL_LOADING', true)
    const [response, error] = await SalaryService.createSalaryTemplate(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_MODAL_LOADING', false)
    return [response, error]
  },

  async updateSalaryTemplate({ commit }, payload) {
    commit('SET_MODAL_LOADING', true)
    const [response, error] = await SalaryService.updateSalaryTemplate(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_MODAL_LOADING', false)
    return [response, error]
  },

  async deleteSalaryTemplate({ commit }, payload) {
    commit('SET_MODAL_LOADING', true)
    const [response, error] = await SalaryService.deleteSalaryTemplate(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_MODAL_LOADING', false)
    return [response, error]
  },

  async getStaffData({ commit }, payload) {
    commit('SET_LOADING', true)
    const [response, error] = await SalaryService.getStaffData(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_LOADING', false)
    return [response, error]
  },

  /* SALARY TEMPLATES */
  async getSalaries({ commit }, payload) {
    commit('SET_LOADING', true)
    const [response, error] = await SalaryService.getSalaries(commit, payload)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_LOADING', false)
    return [response, error]
  },

  async getSalaryHistory({ commit }, payload) {
    commit('SET_LOADING', true)
    const [response, error] = await SalaryService.getSalaryHistory(commit, payload)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_LOADING', false)
    return [response, error]
  },

  async createSalary({ commit }, payload) {
    commit('SET_MODAL_LOADING', true)
    const [response, error] = await SalaryService.createSalary(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_MODAL_LOADING', false)
    return [response, error]
  },

  async updateSalary({ commit }, payload) {
    commit('SET_MODAL_LOADING', true)
    const [response, error] = await SalaryService.updateSalary(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_MODAL_LOADING', false)
    return [response, error]
  },

  async deleteSalary({ commit }, payload) {
    commit('SET_MODAL_LOADING', true)
    const [response, error] = await SalaryService.deleteSalary(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_MODAL_LOADING', false)
    return [response, error]
  },

  async publishSalary({ commit }, payload) {
    commit('SET_MODAL_LOADING', true)
    const [response, error] = await SalaryService.publishSalary(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_MODAL_LOADING', false)
    return [response, error]
  },

  async unPublishSalary({ commit }, payload) {
    commit('SET_MODAL_LOADING', true)
    const [response, error] = await SalaryService.unPublishSalary(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_MODAL_LOADING', false)
    return [response, error]
  },

  async generateSalariesFromTemplates({ commit }, payload) {
    commit('SET_MODAL_LOADING', true)
    const [response, error] = await SalaryService.generateSalariesFromTemplates(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_MODAL_LOADING', false)
    return [response, error]
  },

  async getSalaryDetail({ commit }, payload) {
    commit('SET_MODAL_LOADING', true)
    const [response, error] = await SalaryService.getSalaryDetail(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_MODAL_LOADING', false)
    return [response, error]
  },

  async getSalaryAllowance({ commit }, payload) {
    commit('SET_MODAL_LOADING', true)
    const [response, error] = await SalaryService.getSalaryAllowance(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_MODAL_LOADING', false)
    return [response, error]
  },

  async getSalaryBonuses({ commit }, payload) {
    commit('SET_MODAL_LOADING', true)
    const [response, error] = await SalaryService.getSalaryBonuses(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_MODAL_LOADING', false)
    return [response, error]
  },

  async getSalaryDeduction({ commit }, payload) {
    commit('SET_MODAL_LOADING', true)
    const [response, error] = await SalaryService.getSalaryDeduction(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_MODAL_LOADING', false)
    return [response, error]
  },
}
