export default {
  currentUser: getSavedState('auth.currentUser'),
  email: '',
  phone: '',
  send_at: '',
  option: '',
  pickupAssignee: getSavedState('pickupAssignee'),
  domain: getDomain('subDomain'),
}
function getSavedState(key) {
  const user = window.localStorage.getItem(key)
  if (user !== 'undefined') return JSON.parse(user)
  else return null
}

function getDomain(key) {
  return JSON.parse(window.localStorage.getItem(key))
}
