<template>
  <transition name="slide-fade">
    <div
      v-if="isActive"
      :class="{
        [$style.dropDownRight]: isRight && !disabledStyles,
        [$style.dropDown]: !isRight && !disabledStyles,
        'bg-bg-white py-2 px-2.5': !disabledStyles,
      }"
    >
      <slot />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'UiMenuDropDown',
  props: {
    isOpen: { type: Boolean },
    isRight: { type: Boolean, default: false },
    disabledStyles: { type: Boolean, default: false },
  },
  emits: ['toggle-is-open'],
  computed: {
    /**
     * Is Active Method
     * @param {void}
     * @returns {boolean}
     * @description Providing the current boolean value of the "isOpen" data property.
     */
    isActive() {
      return this.isOpen
    },
  },
  methods: {
    /**
     * Close Dropdown
     * @param {window event} e
     * @returns {void}
     * @description Emits the message to parent component
     */
    // TODO: This method isn't being called in any given part of this component.
    closeDropdown(e) {
      e.stopPropagation()
      this.$emit('toggle-is-open')
    },
  },
}
</script>

<style lang="scss" module>
.dropDown {
  position: absolute;
  top: 22px;
  right: -11px;
  z-index: 55;
  border: 1px solid var(--border);
}

.dropDownRight {
  position: absolute;
  top: 22px;
  right: -11px;
  z-index: 55;
  border: 1px solid var(--border);
  &::before {
    position: absolute;
    top: -6px;
    right: 10px;
    width: 10px;
    height: 10px;
    content: '';
    background: var(--bg-white);
    border-top: 1px solid var(--border);
    border-left: 1px solid var(--border);
    transform: rotate(45deg);
  }
}
</style>
