<template>
  <section>
    <div v-if="isLogin">
      <DefaultLayout>
        <NotFound404 />
      </DefaultLayout>
    </div>
    <div v-else>
      <NotFound404 />
    </div>
  </section>
</template>

<script>
import NotFound404 from '@src/components/view/404.vue'
import layoutConstants from '@src/constants/layout-constant.js'
import DefaultLayout from '@src/router/layouts/defaultLayout.vue'
import mainLayoutRoutes from '@router/layouts/main-layout/main-layout-routes.js'
import { mapActions } from 'vuex'

export default {
  page: {
    title: '404',
    meta: [{ name: 'description', content: '404 Error Page' }],
  },
  components: {
    NotFound404,
    DefaultLayout,
  },
  data() {
    return {
      menuOverlay: false,
      isLogin: localStorage.getItem('auth.currentUser'),
    }
  },
  created() {
    this.setLeftbarContent({
      customeHeader: 'left-bar-class-list',
      title: this.$route.params.className,
      SHOW_SECTION_DROPDOWN_TO: layoutConstants.showSectionDropdownTo,
      SHOW_CLASS_DROPDOWN_TO: layoutConstants.showClassDropdownTo,
      routes: mainLayoutRoutes,
    })
  },
  methods: {
    ...mapActions('layout', ['setLeftbarContent', 'modifyLeftBarDropdown']),
    toggleOverlay() {
      this.menuOverlay = !this.menuOverlay
      this.menuOverlay = false
    },
    falseOverlay() {
      this.menuOverlay = false
    },
  },
}
</script>
