import { by } from '@services/custom.service'
import { BaseService } from '@services/base.service'
import { TENNAT_API_URL } from '@services/.env'

export default class ClassService extends BaseService {
  static getClasses(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}classes/by`, { query: {} })
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Student list'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getSubjects(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}subjects/by`, { query: {} })
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Student list'
          reject(this.errorWrapper(error, message))
        })
    })
  }
}
