<template>
  <div>
    <UiSingleSelect
      v-model="currentSection"
      title="SECTION"
      label="title"
      :select-object-without-reduce-key="true"
      :search-through-api="true"
      :options="usersSectionList"
      class="flex-1"
      :disabled="!currentClassScope"
      drop-down-container="w-full text-xxs sm:text-xs md:text-xs lg:text-sm md:top-11 top-8"
      select-box-classes="lg:h-11 md:h-9 sm:h-11 h-8"
      container-classes="lg:min-w-[210px] md:min-w-[158px] min-w-[140px]"
      title-classes="lg:text-sm md:text-xxs text-[9px]"
      placeholder="SELECT_SECTION"
      input-value-classes="text-xxs md:text-xs lg:text-sm"
      @search="filterSections"
      @deSelect="switchSection(currentSection)"
      @change="switchSection(currentSection)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import { buildWhereQuery } from '@utils/filters/index'
import UiSingleSelect from '@components/UiElements/UiSingleSelect.vue'
export default {
  components: {
    UiSingleSelect,
  },
  data() {
    return {
      currentSection: '',
    }
  },
  computed: {
    ...mapState({
      currentCampusScope: (state) => state.layout.currentCampusScope,
      currentClassScope: (state) => state.layout.currentClassScope,
      currentSectionScope: (state) => state.layout.currentSectionScope,
      usersSectionList: (state) => state.section.usersSectionList,
    }),
  },
  watch: {
    currentCampusScope: {
      handler() {
        this.filterUsersSectionsList()
        this.setcurrentSelectedSection('')
        this.currentSection = ''
      },
    },
    currentClassScope: {
      handler() {
        this.setcurrentSelectedSection('')
        this.filterUsersSectionsList()
        this.currentSection = ''
      },
    },
  },
  /**
   * Created Hook
   * @description If campus is selected then calls getClassSectionsList()
   */
  created() {
    if (this.currentCampusScope && this.currentClassScope) {
      this.filterUsersSectionsList()
      this.currentSection = this.currentSectionScope
    }
  },
  methods: {
    switchSection(section) {
      this.setcurrentSelectedSection(section)
      this.getClassSections()
    },

    /**
     * Search Class
     * @description Searches class on the basis of the query provided with debounce
     */
    filterSections(query = '') {
      const payload = {
        $where: { ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query) },
      }
      this.filterUsersSectionsList(payload)
    },
    ...mapActions('section', ['getClassSections', 'filterUsersSectionsList']),
    ...mapActions('layout', ['setcurrentSelectedSection']),
  },
}
</script>

<style lang="scss" module>
.dropDownPosition {
  top: 47px;
  width: 248px;
  border-radius: 9px;
  box-shadow: 1px 1px 15px #0003;
  @media only screen and (max-width: 1440px) {
    width: 199px;
  }
  @media only screen and (max-width: 1024px) {
    width: 271px;
  }
  @media only screen and (max-width: 600px) {
    width: 208px;
  }
}

body[dir='ltr'] {
  .dropDownPosition {
    right: -32px;
    left: -9px;
  }
}

body[dir='rtl'] {
  .dropDownPosition {
    right: -9px;
    left: -32px;
  }
}
</style>

<style lang="scss" scoped>
.borderBottom {
  border-bottom: 0.5px solid rgb(219, 216, 216);
}
.borderBottom:last-child {
  border-bottom: none;
}
.section-dropdown {
  width: 112px;
  @media only screen and (max-width: 1024px) {
    width: 63px;
  }
  @media only screen and (max-width: 1023px) {
    width: 112px;
  }
  @media only screen and (max-width: 475px) {
    width: 63px;
  }
}
</style>
