import AddOns from './services'

export default {
  getEmailsProviderList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AddOns.getEmailsProviderList(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  addNewEmailConfiguration({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AddOns.addNewEmailConfiguration(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  getEmailProviderDetails({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AddOns.getEmailProviderDetails(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  setEmailProviderDefault({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AddOns.setEmailProviderDefault(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  // SMS Add-Ons
  getSMSProviderList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AddOns.getSMSProviderList(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  addNewSMSConfiguration({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AddOns.addNewSMSConfiguration(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  getSMSProviderDetails({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AddOns.getSMSProviderDetails(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  setSMSProviderDefault({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AddOns.setSMSProviderDefault(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
}
