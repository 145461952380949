<template>
  <button
    class="focus:outline-none focus:shadow-md text-sm sm:text-base font-semibold text-white font-roboto gap-2 flex justify-center items-center"
    :class="buttonType"
    :type="type"
    :disabled="disabled"
    @click="$emit('click', $event)"
  >
    <span v-if="icon">
      <icon class="icon" icon="arrowLeft" color="primary-purple-700" height="11.67" width="11.67" />
    </span>
    <slot>Add Title</slot>
  </button>
</template>

<script>
import icon from '@components/icons/icon.vue'

export default {
  components: {
    icon,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    button: {
      type: String,
      default: '',
    },
    icon: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],

  computed: {
    buttonType() {
      return this.button === 'primary'
        ? 'primary-button'
        : this.button === 'primary-previous'
        ? 'primary-previous-button'
        : 'primary-button-outline'
    },
  },
}
</script>
