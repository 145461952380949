import AuthLayout from '@src/router/layouts/auth/layout.vue'
import ROUTE from '@src/constants/route-constants.js'
import { getDefaultRoute } from '@utils/generalUtil'
import store from '@src/state/store'

const userRole = store?.getters['layout/userRole']
const selectedCampus = store?.state?.layout?.currentCampusScope || ''
const SelectedSection = store?.state?.layout?.currentSectionScope || ''
const redirectRoute = getDefaultRoute(userRole, selectedCampus, SelectedSection)
export default [
  {
    path: '/signin',
    name: 'login',
    component: () => import('@views/auth/signin.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        let loggedIn = localStorage.getItem('auth.currentUser') || null
        // If the user is already logged in
        if (loggedIn) {
          // Redirect to the home page instead
          next({
            name: 'default',
          })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },

  {
    path: '/',
    meta: {
      authRequired: false,
    },
    component: AuthLayout,
    children: [
      {
        path: '',
        name: 'home',
        beforeEnter(routeTo, routeFrom, next) {
          let loggedIn = localStorage.getItem('auth.currentUser') || null
          // If the user is already logged in
          if (loggedIn) {
            // Redirect to the home page instead
            next({
              name: redirectRoute,
            })
          } else next({ name: 'login' })
        },
      },
      {
        path: 'privacy-policy',
        name: 'privacy-policy',
        component: () => import('@src/router/views/privacy-policy/privacy-policy.vue'),
      },
      {
        path: '/setup-slider',
        name: 'setup-slider',
        component: () => import('@views/setup-slider/SetupSlider.vue'),
        beforeEnter(routeTo, routeFrom, next) {
          let stats = JSON.parse(localStorage.getItem('institutesCounts')) || null
          if (!stats || stats.campuses_count) {
            next({
              name: redirectRoute,
            })
          } else next()
        },
      },
      {
        path: '',
        name: 'default',
        redirect: { name: ROUTE?.dashboard?.name },
        meta: {
          authRequired: true,
        },
        component: () => import('@src/router/layouts/defaultLayout.vue'),
        children: [
          // Passes through main layout and with default left bar
          {
            path: '',
            component: () => import('@/src/router/layouts/main-layout/mainLayout.vue'),
            children: [
              // Admin Dashboard
              {
                path: ROUTE?.dashboard?.path,
                name: ROUTE?.dashboard?.name,
                component: () => import('@views/dashboard/DashboardView.vue'),
              },
              /* User Profile*/
              {
                path: 'myprofile',
                name: 'my-profile',
                component: () => import('@src/router/views/my-profile/MyProfileView.vue'),
              },

              /* Quick Actions */
              {
                path: 'quick-actions',
                name: 'quick-actions',
                component: () => import('@views/quick-actions/QuickActions.vue'),
              },
              /* PERIOD Attendance */
              {
                path: 'attendance',
                redirect: { name: 'section-attendance' },
                component: () => import('@/src/router/layouts/attendance/layout.vue'),
                children: [
                  {
                    path: 'section',
                    name: 'section-attendance',
                    redirect: { name: 'student-section-attendance' },
                    component: () => import('@src/router/layouts/attendance/section/layout.vue'),
                    children: [
                      {
                        path: 'students',
                        name: 'student-section-attendance',
                        component: () =>
                          import(
                            '@/src/router/views/attendance/section-attendance/student/StudentSectionAttendanceView.vue'
                          ),
                      },
                      {
                        path: 'students/:id/detail',
                        name: 'student-section-attendance-detail',
                        component: () =>
                          import(
                            '@/src/router/views/attendance/section-attendance/student/SectionStudentAttendanceDetail.vue'
                          ),
                      },

                      {
                        path: 'teacher',
                        name: 'teacherCheckInOut',
                        component: () =>
                          import(
                            '@/src/router/views/attendance/staff-check-in-out/staffCheckInOut.vue'
                          ),
                      },
                    ],
                  },
                  {
                    path: 'campus',
                    name: 'campus-attendance',
                    redirect: { name: 'student-campus-attendance' },
                    component: () => import('@/src/router/layouts/attendance/section/layout.vue'),
                    children: [
                      {
                        path: 'students',
                        name: 'student-campus-attendance',
                        component: () =>
                          import(
                            '@/src/router/views/attendance/campus-attendance/CampusStudentAttendanceView.vue'
                          ),
                      },
                    ],
                  },
                  {
                    path: 'period',
                    redirect: { name: 'students-period-attendance' },
                    name: 'period-attendance',
                    component: () => import('@/src/router/layouts/attendance/section/layout.vue'),
                    children: [
                      {
                        path: 'students',
                        name: 'students-period-attendance',
                        component: () =>
                          import(
                            '@/src/router/views/attendance/period-attendance/student/StudentPeriodAttendanceView.vue'
                          ),
                      },
                      {
                        path: 'students/:id/detail',
                        name: 'students-period-attendance-details',
                        component: () =>
                          import(
                            '@views/attendance/period-attendance/student/StudentPeriodAttendanceDetailView.vue'
                          ),
                      },
                    ],
                  },
                ],
              },
              // Time Table
              {
                path: '/time-table',
                redirect: { name: 'time-table-automator' },
                component: () => import('@/src/router/views/time-table/layout.vue'),
                children: [
                  {
                    path: 'automator',
                    name: 'time-table-automator',
                    component: () =>
                      import(
                        '@/src/router/views/time-table/time-table-automator/TimeTableAutomatorView.vue'
                      ),
                  },
                  {
                    path: 'current',
                    name: 'current-time-table',
                    component: () =>
                      import('@views/time-table/current-time-table/CurrentTimeTableView.vue'),
                  },
                ],
              },
              // Assignment Types
              {
                path: '/assignment',
                redirect: { name: 'assignmens-view' },
                component: () => import('@src/router/layouts/assignment/assignment-layout.vue'),
                children: [
                  {
                    path: 'types',
                    name: 'assignment-types',
                    component: () =>
                      import('@src/router/views/assignments/types/AssignmentTypesView.vue'),
                  },
                  {
                    path: 'view',
                    name: 'assignmens-view',
                    component: () => import('@views/assignments/assignments/AssignmentsView.vue'),
                  },
                  {
                    path: ':id/detail',
                    name: 'assignmens-detail-view',
                    component: () =>
                      import('@views/assignments/assignments/detail-view/AssignmentDetailView.vue'),
                  },
                ],
              },

              // Syllabus Manager
              {
                path: '/syllabus',
                redirect: { name: 'subjects' },
                component: () => import('@/src/router/views/topics/layout.vue'),
                children: [
                  {
                    path: 'subjects',
                    name: 'subjects',
                    component: () => import('@/src/router/views/subjects/SubjectsView.vue'),
                  },
                  {
                    path: 'chapters',
                    name: 'chapters',
                    component: () =>
                      import('@/src/router/views/lesson-planner/chapter/ChaptersView.vue'),
                  },
                  {
                    path: 'topics',
                    name: 'topics',
                    component: () => import('@/src/router/views/topics/TopicsView.vue'),
                  },
                  {
                    path: 'lessons',
                    name: 'lessons',
                    component: () =>
                      import('@/src/router/views/lesson-planner/lesson/LessonsView.vue'),
                  },
                  {
                    path: 'lesson-planner',
                    name: 'lesson-planner',
                    component: () =>
                      import('@/src/router/views/lesson-planner/lesson-planner/LessonPlanner.vue'),
                  },
                ],
              },
              {
                path: '/subject-syllabus-template',
                name: 'subject-syllabus-template-layout',
                redirect: { name: 'subject-syllabus-template' },
                component: () => import('@src/router/layouts/syllabus-template/layout.vue'),
                children: [
                  {
                    path: 'course',
                    name: 'subject-syllabus-template-course',
                    component: () => import('@views/syllabus/template/CourseProviderView.vue'),
                  },
                  {
                    path: 'view',
                    name: 'subject-syllabus-template',
                    component: () => import('@views/syllabus/template/SyllabusTemplateView.vue'),
                  },
                  {
                    path: 'detail',
                    name: 'detail',
                    redirect: { name: 'subject-syllabus-template' },
                  },
                  {
                    path: 'detail/:id/view',
                    name: 'subject-syllabus-template-detail-view',
                    component: () => import('@views/syllabus/template/DetailView.vue'),
                  },
                  {
                    path: 'import/:id',
                    name: 'subject-syllabus-template-import',
                    component: () => import('@views/syllabus/template/ImportTemplateView.vue'),
                  },
                ],
              },
              // Expense Manager
              {
                path: 'expense',
                name: 'expense',
                redirect: { name: 'expenses' },
                component: () => import('@src/router/layouts/expense/expense-layout.vue'),
                children: [
                  {
                    path: 'categories',
                    name: 'categories',
                    component: () =>
                      import(
                        '@/src/router/views/expense-manager/categories/ExpenseCategoryView.vue'
                      ),
                  },
                  {
                    path: 'expenses',
                    name: 'expenses',
                    component: () => import('@src/router/views/expense-manager/ExpensesView.vue'),
                  },
                ],
              },
              // Pickup Facility
              {
                path: 'pickup-facility',
                name: 'pickup-facility',
                redirect: { name: 'active-request' },
                component: () => import('@src/router/layouts/pickup/layout.vue'),
                children: [
                  {
                    path: 'active',
                    name: 'active-request',
                    component: () =>
                      import('@src/router/views/pickup/pickup-facility/ActiveRequestsView.vue'),
                  },
                  {
                    path: 'all',
                    name: 'all-requests',
                    component: () =>
                      import('@src/router/views/pickup/pickup-facility/AllRequestsView.vue'),
                  },
                ],
              },
              // Pickup Support
              {
                path: 'pickup-support',
                name: 'pickup-support',
                redirect: { name: 'active-request' },
                component: () => import('@src/router/layouts/pickup/layout.vue'),
                children: [
                  {
                    path: 'active',
                    name: 'support-active-request',
                    component: () =>
                      import('@src/router/views/pickup/pickup-support/ActiveRequestsView.vue'),
                  },

                  {
                    path: 'all',
                    name: 'support-all-requests',
                    component: () =>
                      import('@src/router/views/pickup/pickup-support/AllRequestsView.vue'),
                  },
                ],
              },
              {
                path: 'user-pickup-facility',
                name: 'user-pickup-facility',
                redirect: { name: 'facility-user-active-request' },
                component: () => import('@views/pickup/user-layouts/facility/FacilityLayout.vue'),
                children: [
                  {
                    path: 'active',
                    name: 'facility-user-active-request',
                    component: () =>
                      import('@src/router/views/pickup/pickup-facility/ActiveRequestsView.vue'),
                  },
                  {
                    path: 'all',
                    name: 'facility-user-all-requests',
                    component: () =>
                      import('@src/router/views/pickup/pickup-facility/AllRequestsView.vue'),
                  },
                ],
              },
              //pickup request
              {
                path: 'pickup-request/:id/detail',
                name: 'request-details',
                component: () => import('@views/pickup/misc/RequestDetailView.vue'),
              },
              // Lesson Types
              {
                path: 'lesson-types',
                name: 'lesson-types',
                component: () => import('@src/router/views/lesson/LessonTypesView.vue'),
              },
              // Grading Structure
              {
                path: '/grading-structure',
                redirect: { name: 'non-gpa-grading-structure' },
                component: () => import('@/src/router/layouts/exam-planner/layout.vue'),
                children: [
                  {
                    path: 'non-gpa',
                    name: 'non-gpa-grading-structure',
                    component: () =>
                      import('@views/exam-planner/non-gpa-grading/NonGPAGradingStructureView.vue'),
                  },
                  {
                    path: 'non-gpa/:id/detail',
                    name: 'non-gpa-grading-structure-detail-view',
                    component: () =>
                      import('@src/router/views/exam-planner/non-gpa-grading/DetailView.vue'),
                  },
                  {
                    path: 'gpa',
                    name: 'gpa-grading-structure',
                    component: () =>
                      import('@views/exam-planner/gpa-grading/GPAGradingStructureView.vue'),
                  },
                  {
                    path: 'gpa/:id/detail',
                    name: 'gpa-grading-structure-detail-view',
                    component: () =>
                      import('@src/router/views/exam-planner/gpa-grading/DetailView.vue'),
                  },
                ],
              },
              // Exam Planner
              {
                path: '/exam-planner',
                redirect: { name: 'exams' },
                component: () => import('@/src/router/layouts/exam-planner/layout.vue'),
                children: [
                  {
                    path: 'exams',
                    name: 'exams',
                    component: () => import('@views/exam-planner/exams/ExamsView.vue'),
                  },
                  {
                    path: 'exams/:id/detail',
                    name: 'exams-detail-view',
                    component: () => import('@src/router/views/exam-planner/exams/DetailView.vue'),
                  },
                  {
                    path: 'types',
                    name: 'exam-types',
                    component: () => import('@/src/router/views/exam-planner/types/ExamTypes.vue'),
                  },
                  {
                    path: 'exams/:examId/exam-result',
                    name: 'exam-result',
                    component: () => import('@/src/router/views/exam-planner/ExamResult.vue'),
                  },
                  {
                    path: 'exams/:id/detail/:subjectId/MarkDetail',
                    name: 'exam-result-view',
                    component: () =>
                      import(
                        '@src/router/views/exam-planner/exam-subject-result/ResultDetailView.vue'
                      ),
                  },
                ],
              },
              // Fee Manager
              {
                path: '/fee',
                name: 'fees',
                redirect: { name: 'receivable' },
                component: () => import('@src/router/layouts/finance-layout/fees-layout.vue'),
                children: [
                  {
                    path: 'receivable',
                    name: 'receivable',
                    component: () =>
                      import('@/src/router/views/finance/fees/receivable/Receivable.vue'),
                  },
                  {
                    path: 'accept-payment',
                    name: 'accept-payment',
                    component: () =>
                      import('@views/finance/fees/accept-payment/AcceptPaymentView.vue'),
                  },
                  {
                    path: 'payable',
                    name: 'payable',
                    component: () =>
                      import('@/src/router/views/finance/fees/receivable/Receivable.vue'),
                  },
                  {
                    path: 'types',
                    name: 'fee-types',
                    component: () => import('@/src/router/views/finance/fees/types/Fee-Types.vue'),
                  },
                  {
                    path: 'monthly-fee-generator',
                    name: 'monthly-fee-generator',
                    component: () =>
                      import(
                        '@/src/router/views/finance/fees/monthly-fee-generator/MonthlyFeeGeneratorView.vue'
                      ),
                  },

                  {
                    path: 'received',
                    name: 'received',
                    component: () =>
                      import('@/src/router/views/finance/fees/received/Received.vue'),
                  },
                  {
                    path: 'paid',
                    name: 'student-paid',
                    component: () =>
                      import('@/src/router/views/finance/fees/received/Received.vue'),
                  },
                  {
                    path: ':id/detail',
                    name: 'fee-details',
                    component: () => import('@views/finance/fees/FeeDetailView.vue'),
                  },
                  {
                    path: 'discount-types',
                    name: 'discount-types',
                    component: () =>
                      import('@/src/router/views/finance/fees/discount/DiscountTypes.vue'),
                  },
                  {
                    path: 'student-discounts',
                    name: 'student-discounts',
                    component: () =>
                      import(
                        '@/src/router/views/finance/fees/discount/StudentDiscountListView.vue'
                      ),
                  },
                  {
                    path: 'student-discounts/:id/detail',
                    name: 'student-discounts-detail',
                    component: () =>
                      import(
                        '@/src/router/views/finance/fees/discount/StudentDiscountDetailView.vue'
                      ),
                  },
                  {
                    path: 'section',
                    name: 'section',
                    component: () => import('@views/finance/fees/section-fee/SectionFeeView.vue'),
                  },
                  {
                    path: 'section/:id/details',
                    name: 'section-fee-details',
                    component: () =>
                      import('@views/finance/fees/section-fee/detail-view/DetailedView.vue'),
                  },
                ],
              },
              // Exam Result
              {
                path: '/exam-result',
                redirect: { name: 'section-result' },
                component: () => import('@src/router/layouts/exam-result/layout.vue'),
                children: [
                  {
                    path: 'section-result',
                    name: 'section-result',
                    component: () =>
                      import(
                        '@src/router/views/exam-planner/section-result/non-gpa/SectionExamResultView.vue'
                      ),
                  },
                  {
                    path: 'section-result/:id/detail',
                    name: 'section-result-detail-view',
                    component: () =>
                      import(
                        '@src/router/views/exam-planner/section-result/non-gpa/DetailView.vue'
                      ),
                  },
                  {
                    path: 'section-result/:id/view',
                    name: 'section-result-view',
                    component: () =>
                      import(
                        '@views/exam-planner/section-result/non-gpa/ViewSectionResultDetail.vue'
                      ),
                  },
                  {
                    path: 'section-result/:id/view/:resultId/student-result',
                    name: 'student-result-detail',
                    component: () =>
                      import(
                        '@src/router/views/exam-planner/section-result/non-gpa/SingleStudentResultView.vue'
                      ),
                  },

                  {
                    path: 'subject-result',
                    name: 'subject-result',
                    component: () =>
                      import(
                        '@views/exam-planner/generate-subject-exam-result/SubjectExamResultsView.vue'
                      ),
                  },
                  {
                    path: 'subject-result/:id/detail',
                    name: 'subject-result-detail',
                    component: () =>
                      import(
                        '@src/router/views/exam-planner/generate-subject-exam-result/DetailView.vue'
                      ),
                  },
                  {
                    path: 'subject-result/:id/view',
                    name: 'subject-result-view',
                    component: () =>
                      import(
                        '@src/router/views/exam-planner/generate-subject-exam-result/ViewSubjectExamResultDetail.vue'
                      ),
                  },
                ],
              },
              // Users
              {
                path: '/users',
                name: 'users',
                redirect: { name: 'pickup-person' },
                component: () => import('@src/router/layouts/users/users-layout.vue'),
                children: [
                  /* All Users View*/
                  {
                    path: 'all-users',
                    name: 'all-users',
                    component: () => import('@src/router/views/all-users/AllUserView.vue'),
                  },
                  /* Super Admin View */
                  {
                    path: 'super-admin',
                    name: 'super-admin',
                    component: () => import('@src/router/views/super-admin/SuperAdminView.vue'),
                  },
                  //pickup member
                  {
                    path: 'pickup-person',
                    name: 'pickup-person',
                    component: () => import('@views/pickup/pickup-person/PickupIndiviualsView.vue'),
                  },
                  //guardian
                  {
                    path: '/guardians',
                    name: 'guardians',
                    component: () => import('@src/router/views/guardians/GuardiansView.vue'),
                  },
                  //campus-admin
                  {
                    path: '/campus-admin',
                    name: 'campus-admin',
                    component: () => import('@src/router/views/campus-admin/CampusAdminView.vue'),
                  },
                  // Staff
                  {
                    path: '/staff',
                    name: 'staff',
                    component: () => import('@/src/router/views/staff/StaffView.vue'),
                  },
                  // Students
                  {
                    path: '/students',
                    name: 'students',
                    component: () => import('@/src/router/views/student/StudentsView.vue'),
                  },

                  // Teachers
                  {
                    path: '/teachers',
                    name: 'teachers',
                    component: () => import('@/src/router/views/teacher/TeacherView.vue'),
                  },
                ],
              },

              // Campuses
              {
                path: '/campuses',
                name: 'campuses',
                component: () => import('@src/router/views/campuses/CampusesView.vue'),
              },

              // Rooms
              {
                path: '/rooms',
                name: 'rooms',
                component: () => import('@/src/router/views/rooms/RoomView.vue'),
              },

              // Classes
              {
                path: '/classes',
                name: 'classes',
                component: () => import('@/src/router/views/classes/classes.vue'),
              },
              // Sections
              {
                path: '/sections',
                name: 'sections',
                component: () => import('@src/router/views/sections/SectionsView.vue'),
              },
              // AllNotification
              {
                path: 'notifications',
                name: 'allNotifications',
                component: () => import('@/src/modules/header/allNotifications.vue'),
              },
              // Single Notification
              {
                path: '/single-notification',
                name: 'single-notification',
                component: () => import('@/src/router/views/notifications/AnnouncementView.vue'),
              },

              {
                path: 'settings',
                redirect: { name: 'general-settings' },
                component: () => import('@views/settings/layout.vue'),
                children: [
                  {
                    path: 'notification',
                    name: 'notificationSettings',
                    component: () =>
                      import('@views/settings/notifications/notificationSettings.vue'),
                  },
                ],
              },
              /* NOTIFICATIONS LAYOUT */
              {
                path: 'notification',
                redirect: { name: 'announcement' },
                component: () => import('@/src/router/views/notifications/layout.vue'),
                children: [
                  {
                    path: 'announcement',
                    name: 'announcement',
                    component: () =>
                      import('@/src/router/views/notifications/AnnouncementView.vue'),
                  },
                  {
                    path: 'announcement/:announcementId/detail',
                    name: 'announcement-detail',
                    component: () =>
                      import('@/src/router/views/notifications/DetailView/AnnouncementDetail.vue'),
                  },
                  {
                    path: 'announcement-types',
                    name: 'announcement-types',
                    component: () =>
                      import('@/src/router/views/occasions/AnnouncementTypesView.vue'),
                  },
                ],
              },
              /* Registration Process */
              {
                path: 'registration',
                redirect: { name: 'registration-list' },
                component: () => import('@router/layouts/registration-process/Layout.vue'),
                children: [
                  {
                    path: 'list',
                    name: 'registration-list',
                    component: () =>
                      import('@views/registration-process/registrations/RegistrationsView.vue'),
                  },
                  {
                    path: 'form-generator',
                    name: 'form-generator',
                    component: () =>
                      import('@views/registration-process/form-generator/FormGeneratorView.vue'),
                  },
                  {
                    path: 'form/:title',
                    name: 'form',
                    component: () =>
                      import(
                        '@views/registration-process/form-generator/registration-types/Form.vue'
                      ),
                  },
                ],
              },
              /* SALARY LAYOUT */
              {
                path: 'salary-manager',
                redirect: { name: 'salaries' },
                component: () => import('@/src/router/views/salary-manager/layout.vue'),
                children: [
                  {
                    path: 'my-salary',
                    name: 'my-salary',
                    component: () =>
                      import('@/src/router/views/salary-manager/UserSalaryHistory.vue'),
                  },
                  {
                    path: 'salaries/:id/detail',
                    name: 'user-salary-detail',
                    component: () =>
                      import('@/src/router/views/salary-manager/UserSalaryHistoryDetail.vue'),
                  },
                  {
                    path: 'my-salary/:id/detail',
                    name: 'my-salary-detail',
                    component: () =>
                      import('@/src/router/views/salary-manager/UserSalaryHistoryDetail.vue'),
                  },
                  {
                    path: 'salaries',
                    name: 'salaries',
                    component: () => import('@/src/router/views/salary-manager/Salaries.vue'),
                  },
                  {
                    path: 'allowence-type',
                    name: 'allowence-type',
                    component: () => import('@/src/router/views/salary-manager/AllowenceType.vue'),
                  },
                  {
                    path: 'deduction-type',
                    name: 'deduction-type',
                    component: () => import('@/src/router/views/salary-manager/DeductionType.vue'),
                  },
                  {
                    path: 'bonuses-type',
                    name: 'bonuses-type',
                    component: () => import('@/src/router/views/salary-manager/BonusesType.vue'),
                  },
                  {
                    path: 'extra-working-unit',
                    name: 'extra-working-unit',
                    component: () =>
                      import('@src/router/views/salary-manager/ExtraWorkingUnit.vue'),
                  },
                  {
                    path: 'salary-generator',
                    name: 'salary-generator',
                    component: () =>
                      import('@/src/router/views/salary-manager/SalaryGenerator.vue'),
                  },
                  {
                    path: 'salary-generator/:id/salary-template',
                    name: 'salary-template',
                    component: () => import('@/src/router/views/salary-manager/SalaryTemplate.vue'),
                  },
                ],
              },
            ],
          },
          {
            path: '/users/:id',
            component: () =>
              import('@/src/router/layouts/roles-and-access-layout/roles-and-access-layout.vue'),
            children: [
              {
                path: 'access',
                name: 'roles-and-acccess-management',
                component: () => import('@/src/components/RolesAndAccessManagement.vue'),
              },
            ],
          },
          {
            path: '/student/:student_id',
            redirect: { name: 'student-profile-mode' },
            component: () =>
              import('@/src/router/layouts/profile-layout/student-profile-layout.vue'),
            children: [
              {
                path: 'profile',
                name: 'student-profile-mode',
                component: () => import('@router/views/student/profile/profile.vue'),
              },
              {
                path: 'dashboard',
                name: 'student-profile-dashboard',
                component: () => import('@router/views/student/profile/dashboard.vue'),
              },
              {
                path: 'fee',
                redirect: { name: 'student-fee-receivable' },
                component: () => import('@router/layouts/profile-layout/student-fee-layout.vue'),
                children: [
                  {
                    path: 'payable',
                    name: 'student-fee-receivable',
                    component: () => import('@router/views/student/profile/receivable.vue'),
                  },
                  {
                    path: 'paid',
                    name: 'student-fee-received',
                    component: () => import('@router/views/student/profile/received.vue'),
                  },
                ],
              },
            ],
          },
          {
            path: 'user-pickup-support',
            name: 'user-pickup-support',
            redirect: { name: 'support-user-active-request' },
            component: () => import('@views/pickup/user-layouts/support/SupportLayout.vue'),
            children: [
              {
                path: 'active',
                name: 'support-user-active-request',
                component: () =>
                  import('@src/router/views/pickup/pickup-support/ActiveRequestsView.vue'),
              },
              {
                path: 'all',
                name: 'support-user-all-requests',
                component: () =>
                  import('@src/router/views/pickup/pickup-support/AllRequestsView.vue'),
              },
            ],
          },
          /* CONTROL PANEL */
          {
            path: 'control-panel',
            name: 'control-panel',
            component: () => import('@/src/router/layouts/control-panel/layout.vue'),
            redirect: { name: 'control-panel-settings' },
            children: [
              /* DASHBOARD */
              {
                path: 'dashboard',
                name: 'control-panel-dashboard',
                component: () => import('@/src/router/views/control-panel/dashboard.vue'),
              },
              /* OWNERSHIP */
              {
                path: 'ownership',
                name: 'ownership',
                component: () => import('@src/router/views/transfer_ownership/OwnerShipView.vue'),
              },

              /* Feature Setting */
              {
                path: 'features-1',
                name: 'features-1',
                component: () => import('@src/router/views/Feature-Setting/featurelayout.vue'),
              },
              /* PAYMENT METHODS */
              {
                path: 'payment-methods',
                name: 'payment-methods',
                component: () =>
                  import('@/src/router/views/payment-methods/PaymentMethodsView.vue'),
              },
              /* ADD-ONS */
              {
                path: 'add-ons',
                name: 'add-ons',
                redirect: { name: 'email-add-ons' },
                component: () => import('@router/layouts/add-ons/layout.vue'),
                children: [
                  {
                    path: 'sms',
                    name: 'sms-add-ons',
                    component: () =>
                      import('@src/router/views/settings/ad-ons/SMS/SMSAddOnsview.vue'),
                  },
                  {
                    path: 'email',
                    name: 'email-add-ons',
                    component: () =>
                      import('@/src/router/views/settings/ad-ons/Email/EmailAdOnsview.vue'),
                  },
                  {
                    path: 'email/:id/detail',
                    name: 'email-provider-detail-page',
                    component: () =>
                      import('@src/router/views/settings/ad-ons/Email/EmailAdOnDetailView.vue'),
                  },
                  {
                    path: 'sms/:id/detail',
                    name: 'sms-provider-detail-page',
                    component: () =>
                      import('@src/router/views/settings/ad-ons/SMS/SMSAddOnsDetailView.vue'),
                  },
                ],
              },
              {
                path: 'pickup',
                name: 'pickup-settings',
                component: () => import('@views/control-panel/pickup/settings.vue'),
              },
              /* Fearure Settings */
              {
                path: 'feature-permissions',
                redirect: { name: 'fee-settings' },
                component: () => import('@/src/router/views/settings/system-settings/layout.vue'),
                children: [
                  {
                    path: 'campus-attendance',
                    name: 'campus-attendance-settings',
                    component: () =>
                      import(
                        '@/src/router/views/settings/system-settings/CampusAttendancePermission.vue'
                      ),
                  },
                  {
                    path: 'period-attendance',
                    name: 'period-attendance-settings',
                    component: () =>
                      import(
                        '@/src/router/views/settings/system-settings/PeriodAttendancePermission.vue'
                      ),
                  },
                  {
                    path: 'section-attendance',
                    name: 'section-attendance-settings',
                    component: () =>
                      import(
                        '@/src/router/views/settings/system-settings/SectionAttendancePermission.vue'
                      ),
                  },
                  {
                    path: 'syllabus-automator',
                    name: 'syllabus-automator-permission',
                    component: () =>
                      import(
                        '@src/router/views/settings/system-settings/SyllabusTemplatePermission.vue'
                      ),
                  },
                  {
                    path: 'fee',
                    name: 'fee-settings',
                    component: () =>
                      import('@/src/router/views/settings/system-settings/FeeSettingsView.vue'),
                  },
                  {
                    path: 'staff',
                    name: 'staff-permission',
                    component: () =>
                      import('@src/router/views/settings/system-settings/StaffPermission.vue'),
                  },
                  {
                    path: 'syllabus',
                    name: 'syllabus-settings-permission',
                    component: () =>
                      import(
                        '@/src/router/views/settings/system-settings/SyllabusSettingsView.vue'
                      ),
                  },
                  {
                    path: 'schedule',
                    name: 'schedule-settings',
                    component: () =>
                      import(
                        '@/src/router/views/settings/system-settings/ScheduleSettingsView.vue'
                      ),
                  },
                  {
                    path: 'lesson-planner',
                    name: 'lesson-planner-settings',
                    component: () =>
                      import(
                        '@/src/router/views/settings/system-settings/LessonPlannerSettingsView.vue'
                      ),
                  },
                  {
                    path: 'teacher',
                    name: 'teacher-settings',
                    component: () =>
                      import('@src/router/views/settings/system-settings/TeacherPermission.vue'),
                  },
                  {
                    path: 'exam',
                    name: 'exam-settings',
                    component: () =>
                      import('@/src/router/views/settings/system-settings/ExamSettingsView.vue'),
                  },
                  {
                    path: 'pickup',
                    name: 'pickup-settings-permission',
                    component: () =>
                      import(
                        '@/src/router/views/settings/system-settings/PickUpPermissionSettings.vue'
                      ),
                  },
                  {
                    path: 'class',
                    name: 'class-permission',
                    component: () =>
                      import('@/src/router/views/settings/system-settings/ClassPermission.vue'),
                  },
                  {
                    path: 'section',
                    name: 'section-permission',
                    component: () =>
                      import('@/src/router/views/settings/system-settings/SectionPermission.vue'),
                  },
                  {
                    path: 'exam-planner',
                    name: 'exam-planner-permission',
                    component: () =>
                      import(
                        '@/src/router/views/settings/system-settings/ExamPlannerPermission.vue'
                      ),
                  },
                ],
              },
              /* Features Settings */
              {
                path: 'features',
                redirect: { name: 'general-settings' },
                component: () => import('@src/router/layouts/features-settings/layout.vue'),
                children: [
                  {
                    path: 'general',
                    name: 'feature-general-settings',
                    component: () =>
                      import(
                        '@views/settings/system-settings/features-settings/FeaturesGeneralSettings.vue'
                      ),
                  },
                  {
                    path: 'pickup',
                    name: 'pickup-feature-settings',
                    component: () => import('@views/control-panel/pickup/settings.vue'),
                  },
                ],
              },

              /* System Settings */
              {
                path: 'system',
                redirect: { name: 'general-settings' },
                component: () => import('@/src/router/views/settings/system-settings/layout.vue'),
                children: [
                  {
                    path: 'general',
                    name: 'general-settings',
                    component: () =>
                      import('@/src/router/views/settings/system-settings/generalSettings.vue'),
                  },
                  {
                    path: 'contact-settings',
                    name: 'contact-settings',
                    component: () =>
                      import('@/src/router/views/settings/system-settings/contactSettings.vue'),
                  },
                  {
                    path: 'billing',
                    name: 'billing-settings',
                    component: () =>
                      import('@/src/router/views/settings/system-settings/billingSettingView.vue'),
                  },
                  {
                    path: 'site',
                    name: 'site-settings',
                    component: () =>
                      import('@/src/router/views/settings/system-settings/siteSettings.vue'),
                  },
                ],
              },
              /* Notification Settings */
              {
                path: 'notifications',
                redirect: { name: 'fee-notification-settings' },
                component: () => import('@/src/router/layouts/settings/notifications/layout.vue'),
                children: [
                  {
                    path: 'fee',
                    name: 'fee-notification-settings',
                    component: () => import('@/src/router/views/settings/notifications/fee.vue'),
                  },
                  {
                    path: 'campus-attendance',
                    name: 'campus-attendance-notification',
                    component: () =>
                      import(
                        '@views/settings/notifications/attendance/campus/CampusAttendanceNotificationView.vue'
                      ),
                  },
                  {
                    path: '',
                    path: 'class',
                    name: 'class-notification',
                    component: () =>
                      import(
                        '@src/router/views/settings/notifications/class/ClassNotification.vue'
                      ),
                  },
                  {
                    path: 'section-attendance',
                    name: 'section-attendance-notification',
                    component: () =>
                      import(
                        '@views/settings/notifications/attendance/section/SectionAttendanceNotificationView.vue'
                      ),
                  },
                  {
                    path: 'period-attendance',
                    name: 'period-attendance-notification',
                    component: () =>
                      import(
                        '@views/settings/notifications/attendance/period/PeriodAttendanceNotificationView.vue'
                      ),
                  },
                  {
                    path: 'syllabus-manager',
                    name: 'syllabus-manager-notification',
                    component: () =>
                      import(
                        '@views/settings/notifications/syllabus-manager/SyllabusManagerNotificationView.vue'
                      ),
                  },
                  {
                    path: 'pickup-facility',
                    name: 'pickup-facility-notification',
                    component: () =>
                      import('@src/router/views/settings/notifications/PickUpNotificationView.vue'),
                  },
                  {
                    path: '',
                    path: 'time-table',
                    name: 'time-table-notification',
                    component: () =>
                      import(
                        '@src/router/views/settings/notifications/time-table/TimeTableNotificationView.vue'
                      ),
                  },
                  {
                    path: '',
                    path: 'lesson-planner',
                    name: 'lesson-planner-notification',
                    component: () =>
                      import(
                        '@src/router/views/settings/notifications/lesson-planner/LessonPlannerNotification.vue'
                      ),
                  },
                  {
                    path: 'syllabus-automator',
                    name: 'syllabus-automator-notification',
                    component: () =>
                      import(
                        '@src/router/views/settings/notifications/syllabus-manager/syllabus-template/SyllabusTemplateNotificationView.vue'
                      ),
                  },
                  {
                    path: 'exam-planner',
                    name: 'exam-planner-notification',
                    component: () =>
                      import(
                        '@src/router/views/settings/notifications/exam-planner/ExamPlannerNotificationView.vue'
                      ),
                  },
                  {
                    path: 'exam',
                    name: 'exam-notification-settings',
                    component: () => import('@/src/router/views/settings/notifications/exam.vue'),
                  },
                  {
                    path: 'staff',
                    name: 'staff-notification-settings',
                    component: () => import('@/src/router/views/settings/notifications/staff.vue'),
                  },
                  {
                    path: 'class',
                    name: 'class-notification-settings',
                    component: () => import('@/src/router/views/settings/notifications/class.vue'),
                  },
                  {
                    path: 'section',
                    name: 'section-notification',
                    component: () =>
                      import(
                        '@src/router/views/settings/notifications/attendance/section/SectionNotificationView.vue'
                      ),
                  },
                  {
                    path: 'staff',
                    name: 'staff-notification',
                    component: () =>
                      import(
                        '@src/router/views/settings/notifications/staff/StaffNotification.vue'
                      ),
                  },
                  {
                    path: 'pickup-person',
                    name: 'pickup-person-notification',
                    component: () =>
                      import(
                        '@src/router/views/settings/notifications/pickup-person/PickupPersonNotification.vue'
                      ),
                  },
                  {
                    path: 'student',
                    name: 'student-notification-settings',
                    component: () =>
                      import(
                        '@/src/router/views/settings/notifications/student/StudentNotification.vue'
                      ),
                  },
                  {
                    path: 'teacher',
                    name: 'teacher-notification-settings',
                    component: () =>
                      import(
                        '@src/router/views/settings/notifications/teacher/TeacherNotification.vue'
                      ),
                  },
                  {
                    path: 'guardian',
                    name: 'guardian-notification-settings',
                    component: () =>
                      import('@/src/router/views/settings/notifications/guardian.vue'),
                  },

                  {
                    path: 'setting',
                    name: 'all-notifications-settings',
                    component: () =>
                      import('@/src/router/views/settings/notifications/AllSettingsView.vue'),
                  },
                ],
              },

              // Promotion
              {
                path: 'promote',
                name: 'promote',
                component: () => import('@views/promotion/StudentPromotionView.vue'),
              },

              /* SETTINGS */
              {
                path: 'settings',
                name: 'control-panel-settings',
                redirect: { name: 'control-panel-dashboard' },
                component: () => import('@/src/router/layouts/control-panel/settings/layout.vue'),
                children: [],
              },
            ],
          },
          /* APP DOCUMENT */
          {
            path: 'app-documentation',
            name: 'app-documentation',
            component: () => import('@/src/router/layouts/app-document/documentationLayout.vue'),
            redirect: { name: 'fee-documentation' },
            children: [
              {
                path: 'web-document',
                name: 'app-documentation-web-document',
                component: () => import('@/src/router/layouts/app-document/app-document.vue'),
              },
              /* Fee Manager Documentation */
              {
                path: 'fee-documentation',
                name: 'fee-documentation',
                component: () =>
                  import(
                    '@/src/router/layouts/app-document/fee-documentation/FeeManager-questions.vue'
                  ),
              },
              {
                path: 'fee/:id',
                name: 'fee-question',
                component: () =>
                  import(
                    '@/src/router/layouts/app-document/fee-documentation/feeQuestionAnswer.vue'
                  ),
              },
              /* Syllabus Manager Documentation */
              {
                path: 'syllabus-manager-documentation',
                name: 'syllabus-manager-documentation',
                component: () =>
                  import(
                    '@/src/router/layouts/app-document/syllabus-automater-documentation/syllabusManager-questions.vue'
                  ),
              },
              {
                path: 'syllabus/:id',
                name: 'syllabus-question',
                component: () =>
                  import(
                    '@/src/router/layouts/app-document/syllabus-automater-documentation/syllabusQuestionAnswer.vue'
                  ),
              },
              /* Exam Planner & Exam Results Documentation */
              {
                path: 'exam-planner-documentation',
                name: 'exam-planner-documentation',
                component: () =>
                  import(
                    '@/src/router/layouts/app-document/exam-planner-documentation/examPlanner-questions.vue'
                  ),
              },
              {
                path: 'exam/:id',
                name: 'exam-question',
                component: () =>
                  import(
                    '@/src/router/layouts/app-document/exam-planner-documentation/examQuestionAnswer.vue'
                  ),
              },
            ],
          },
          // Routes related to user settings passes through user setting layout
          {
            path: '/settings/user',
            redirect: { name: 'perosnal-info' },
            component: () => import('@src/router/layouts/setting-layout/setting-layout.vue'),
            children: [
              {
                path: 'perosnal-info',
                name: 'perosnal-info',
                component: () => import('@views/settings/profile-settings/PersonalInformation.vue'),
              },
              {
                path: 'contact-info',
                name: 'contact-info',
                component: () => import('@views/settings/profile-settings/ContactInformation.vue'),
              },
              {
                path: 'professional-info',
                name: 'professional-info',
                component: () =>
                  import('@views/settings/profile-settings/ProfessionalInformation.vue'),
              },
              {
                path: 'salary-info',
                name: 'salary-info',
                component: () => import('@views/settings/profile-settings/SalaryInformation.vue'),
              },
              {
                path: 'change-password',
                name: 'change-password',
                component: () => import('@views/settings/security-settings/ChangePassword.vue'),
              },
              {
                path: 'change-email',
                name: 'change-email',
                component: () => import('@views/settings/security-settings/ChangeEmail.vue'),
              },
              {
                path: 'email',
                name: 'email',
                component: () => import('@views/settings/security-settings/Email.vue'),
              },
              {
                path: 'phone',
                name: 'phone',
                component: () => import('@views/settings/security-settings/Phone.vue'),
              },
              {
                path: 'change-phone',
                name: 'change-phone',
                component: () => import('@views/settings/security-settings/ChangePhone.vue'),
              },
              {
                path: 'theme-settings',
                name: 'theme-settings',
                component: () => import('@views/settings/theme-settings/theme-settings.vue'),
              },
            ],
          },
          // Student Mode routes

          {
            path: '/teacher-mode',
            redirect: { name: 'teacher-profile' },
            component: () =>
              import('@src/router/layouts/teacher-layout/teacher-profile-layout.vue'),
            children: [
              {
                path: 'dashboard',
                name: 'teacher-profile-dashboard',
                component: () => import('@/src/router/views/teacher/profile/DashboardView.vue'),
              },
              {
                path: 'profile',
                name: 'teacher-profile',
                component: () => import('@/src/router/views/teacher/profile/ProfileView.vue'),
              },
              {
                path: 'schedules',
                name: 'teacher-schedules',
                component: () => import('@views/teacher/profile/SchedulesView.vue'),
              },
            ],
          },
        ],
      },
    ],
  },

  {
    path: '/owner',
    name: 'owner',
    redirect: { name: 'owner-forget-password' },
    component: () => import('@src/router/layouts/auth/owner-forget-password.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        let loggedIn = localStorage.getItem('auth.currentUser') || null
        if (loggedIn)
          next({
            name: redirectRoute,
          })
        else next()
      },
    },
    children: [
      {
        path: 'forget-password',
        name: 'owner-forget-password',
        component: () =>
          import('@src/router/views/auth/owner-forget-password/ForgetPasswordView.vue'),
      },
      {
        path: 'reset-password',
        name: 'owner-reset-password',
        component: () =>
          import('@src/router/views/auth/owner-forget-password/ResetPasswordView.vue'),
      },
    ],
  },
  {
    path: '/user',
    name: 'user',
    redirect: { name: 'user-forget-password' },
    component: () => import('@src/router/layouts/auth/owner-forget-password.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        let loggedIn = localStorage.getItem('auth.currentUser') || null
        if (loggedIn)
          next({
            name: redirectRoute,
          })
        else next()
      },
    },
    children: [
      {
        path: 'setup-password',
        name: 'user-setup-password',
        component: () =>
          import('@src/router/views/auth/user-setup-password/SetUserPasswordView.vue'),
      },
      {
        path: 'forget-password',
        name: 'user-forget-password',
        component: () =>
          import('@src/router/views/auth/user-foget-password/ForgetPasswordView.vue'),
      },
      {
        path: 'choose-forget-option',
        name: 'choose-forget-option',
        component: () =>
          import('@src/router/views/auth/user-foget-password/ChooseForgetOptionView.vue'),
      },
      {
        path: 'reset-password',
        name: 'user-reset-password',
        component: () => import('@src/router/views/auth/user-foget-password/ResetPasswordView.vue'),
      },
    ],
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch('auth/logOut')

        const authRequiredOnPreviousRoute = routeFrom.matched.some(
          (route) => route.meta.authRequired,
        )
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute
            ? {
                name: 'home',
              }
            : {
                ...routeFrom,
              },
        )
      },
    },
  },
  {
    path: '/404',
    name: '404',
    component: require('@views/_404.vue').default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '/:catchAll(.*)',
    redirect: '404',
  },
]
