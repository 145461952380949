import { isObject, every, map, isString } from 'lodash'

export default {
  data() {
    return {
      selectedTheme: JSON.parse(localStorage.getItem('theme'))
        ? JSON.parse(localStorage.getItem('theme'))?.name
        : 'green',
    }
  },
  computed: {
    Logo() {
      return require(`@assets/images/logo-${this.selectedTheme.split('-')[0]}.svg`)
    },
  },
  methods: {
    initials(name) {
      let initials = ''
      name.split(' ').forEach((el, index) => {
        if (el !== '') initials += el[0]
      })
      return initials.substring(0, 2).toUpperCase()
    },
    isEmptyDeep(obj) {
      if (isObject(obj)) {
        if (Object.keys(obj).length === 0) return true
        if (
          Object.values(obj).every((el) => el === undefined || el === '') ||
          every(map(obj, (v) => this.isEmptyDeep(v)))
        )
          return true
      } else if (isString(obj)) {
        return !obj.length
      }
      return false
    },
    paginationCounts(totalCounts, filterLimit) {
      return Math.ceil(totalCounts / filterLimit)
    },

    createArray(start, end) {
      return Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx)
    },
  },
}
