// import ScheduleService from './services'
import { CampusService } from '@services/campus.service'
import i18n from '@src/i18n'

const t = i18n?.global?.t

export default {
  createCampus({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      CampusService.createCampuses(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('layout/IS_LOADING', false, { root: true })
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  getCampusList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CampusService.getCampusList(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  deactivateCampus({ commit }, { id, title }) {
    return new Promise((resolve, reject) => {
      CampusService.deactivateCampus(id)
        .then((response) => {
          commit(
            'toast/NEW',
            {
              type: 'success',
              message: `${title} ` + t(`Campus is Successfully Deactivated`),
            },
            { root: true },
          )

          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
}
