import { TENNAT_API_URL, TEAM_TENNAT_API_URL } from '@services/.env'
import { by, put, get, del } from './custom.service'
import { BaseService } from './base.service'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import FILTER_KEYS from '@src/constants/filter-constants.js'
export class UsersService extends BaseService {
  static get entity() {
    return 'users'
  }

  static getUserById(id, payload) {
    return new Promise((resolve, reject) => {
      return get(`${TENNAT_API_URL}/user_details/${id}/user_role_details`, {
        params: new URLSearchParams(payload),
      })
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get users'
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static login(data) {
    return new Promise((resolve, reject) => {
      return by(`${TEAM_TENNAT_API_URL}auth/login`, data)
        .then((response) => {
          // login successful if there's a jwt token in the response
          const data = {
            user: response?.data?.user,
            jwt: response?.data?.jwt,
            campuses: response?.data?.campuses || [],
            institute_title: response.data?.setting?.title,
            institute_counts: response?.data?.stats || null,
            setting: response?.data?.setting || null,
            generalSettings: response?.data?.general_feature_settings || null,
            institute: response?.data?.institute,
            students: response?.data?.students || [],
            sub_domain: response?.data?.sub_domain || null,
            is_pickup_assignee: response?.data?.is_pickup_assignee || null,
          }

          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('user', JSON.stringify(data.user))
          localStorage.setItem('selectedRoleId', response.data.user.user_roles[0].role_id)
          localStorage.setItem('pickupAssignee', JSON.stringify(data.is_pickup_assignee))
          resolve(this.responseWrapper(response, data))
        })
        .catch((error) => {
          const message = ''
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getMyPickupPersonById(id, payload) {
    return new Promise((resolve, reject) => {
      return get(`${TENNAT_API_URL}/user_details/${id}/associated_pickup_persons`, {
        params: new URLSearchParams(payload),
      })
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get users'
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static getGuardianOfById(id, payload) {
    return new Promise((resolve, reject) => {
      return get(`${TENNAT_API_URL}/user_details/${id}/associated_guardians`, {
        params: new URLSearchParams(payload),
      })
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get users'
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static loadUserDetails(id) {
    return by(`${TENNAT_API_URL}profiles/by`, {
      $select: ['image', 'short_bio'],
      $where: { ...buildWhereQuery(FILTER_KEYS.EQUAL, 'user_id', id) },
    })
      .catch((error) => error)
      .then((user) => {
        return user
      })
  }

  static logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user')
    localStorage.removeItem('auth.currentUser')
    localStorage.removeItem('jwt')
  }

  /* ENABLE & DISABLE USER LOGIN */
  static enableUserLogin({ user, id = null }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}institute_users/activate_account`, user, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  static disableUserLogin(id) {
    return new Promise((resolve, reject) => {
      return put(`${TENNAT_API_URL}institute_users/deactivate_account/${id}`)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  // Forgot Password
  static forgotPassword(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}auth/forgot_password`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Cannot deal with the forgot password request right now'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static resetOption(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}auth/send_otp`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Option is Invalid'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static resetPassword(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}auth/password_reset`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Cannot reset password right now'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static uploadUserImage(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}user_attachments/upload_attachments`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could ADD User'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  // Forgot Owner Password
  static forgotOwnerPassword(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}auth/owner/forgot_password`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Cannot deal with the forgot password request right now'
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static resetOwnerPassword(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}auth/owner/reset_password`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Cannot deal with the forgot password request right now'
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static resetPassword(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}auth/reset_password`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Cannot deal with the forgot password request right now'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  // Forgot User Password
  static getForgetPasswordPreInfo(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}auth/forgot_password_pre_info`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Cannot deal with the forgot password request right now'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static forgotPassword(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}auth/forgot_password`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Option is Invalid'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static setupUserPassword(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}auth/setup_password`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Cannot deal with the forgot password request right now'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static resendUserSetupPassword(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}auth/send_setup_password_otp`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Cannot deal with the forgot password request right now'
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static getPickupPersonStudents(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}//user_details/pickup_person/students`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Cannot get data'
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static getGuardianStudents(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}/user_details/guardian/students`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Cannot get data'
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static getTeacherClassesSections(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}/user_details/teacher/classes_sections_details`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Cannot get data'
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static getStudentClassesSections(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}/user_details/student/classes_sections_details`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Cannot get data'
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static revokeUserEntityPermission(id) {
    return new Promise((resolve, reject) => {
      return del(`${TENNAT_API_URL}users_roles_entities/delete`, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Cannot deal with the forgot password request right now'
          reject(this.errorWrapper(error, message))
        })
    })
  }
}
