import currencies from '@/src/constants/settings/currenciesList.json'
import allCurrencies from '@/src/constants/settings/currenciesList.json'
export const themes = ['green', 'maroon', 'green dark', 'maroon dark']
export const langs = ['en', 'ur', 'ar']
export const themesObjArray = [
  { id: 0, label: 'Green', reduce: 'green' },
  { id: 1, label: 'Maroon', reduce: 'maroon' },
  { id: 2, label: 'Green dark', reduce: 'green dark' },
  { id: 3, label: 'Maroon dark', reduce: 'maroon dark' },
]
export const weekdaysArray = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']
export const currenciesList = currencies.currenciesList
