import { NotificationServices } from '@services/notifications.service'
export default {
  getNotification({ commit }, payload) {
    const query = {
      skip: payload.skip,
      limit: payload.limit,
    }
    return new Promise((resolve, reject) => {
      NotificationServices.getAllNotifications(query)
        .then((response) => {
          commit('SET_ALL_NOTIFICATION', response.data.notifications)
          commit('SET_NEW_NOTIFICATION', response.data.new_notifications || [])
          resolve(response)
        })
        .catch((error) => {
          if (error.message === 'Unauthorized') {
            reject(error)
          } else {
            commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
            reject(error)
          }
        })
    })
  },

  async enableNotificationTrigger({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await NotificationServices.enableNotificationTrigger(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    } else {
      commit(
        'toast/NEW',
        { message: 'Notification trigger enabled successfully', type: 'success' },
        { root: true },
      )
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async disableNotificationTrigger({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await NotificationServices.disableNotificationTrigger(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  getNotificationAndAnnouncements({ commit }) {
    return new Promise((resolve, reject) => {
      NotificationServices.getAllNotificationsAndAnnouncemnts()
        .then((response) => {
          commit('SET_NOTIFICATION', response.data.notifications)
          commit('SET_ANNOUNCEMENTS', response.data.announcements || [])
          resolve(response)
        })
        .catch((error) => {
          if (error.message === 'Unauthorized') {
            reject(error)
          } else {
            commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
            reject(error)
          }
        })
    })
  },

  setNotificationStatus({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      NotificationServices.notificationStatus(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  getPreSendAnnouncementStats({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      NotificationServices.getPreSendAnnouncementStats(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  getAnnouncementStats({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      NotificationServices.getAnnouncementStats(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  retryAnnouncementRequest({ state, commit, rootState }, id) {
    return new Promise((resolve, reject) => {
      NotificationServices.retryAnnouncementRequest(id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  resendAnnouncementRequest({ state, commit, rootState }, id) {
    return new Promise((resolve, reject) => {
      NotificationServices.resendAnnouncementRequest(id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  async getStaffData({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await NotificationServices.getStaffData(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async createAnnouncemnt({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await NotificationServices.createAnnouncemnt(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    } else {
      commit(
        'toast/NEW',
        { message: 'Announcement created successfully', type: 'success' },
        { root: true },
      )
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async getAnnouncemnt({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await NotificationServices.getAnnouncemnt(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async getAnnouncemntById({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await NotificationServices.getAnnouncemntById(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async getSmsEmailConfig({ commit }) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await NotificationServices.getSmsEmailConfig(commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async getNotificationTriggers({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await NotificationServices.getNotificationTriggers(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },
  async createNotificationTrigger({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await NotificationServices.createNotificationTrigger(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    } else {
      commit(
        'toast/NEW',
        { message: 'Notification trigger created successfully', type: 'success' },
        { root: true },
      )
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async updateNotificationTrigger({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await NotificationServices.updateNotificationTrigger(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    } else {
      commit(
        'toast/NEW',
        { message: 'Notification trigger updated successfully', type: 'success' },
        { root: true },
      )
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async deleteNotificationTrigger({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await NotificationServices.deleteNotificationTrigger(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    } else {
      commit(
        'toast/NEW',
        { message: 'Notification trigger deleted successfully', type: 'success' },
        { root: true },
      )
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },
}
