import * as baseService from './base.api.service'

const filterArgs = (args) => {
  let id = null
  let options = {}

  if (args.length === 2) {
    id = args[0]
    options = args[1]
  } else if (args.length === 1) {
    if (typeof args[0] === 'string' || typeof args[0] === 'number') {
      id = args[0]
    } else {
      options = args[0]
    }
  }

  return [id, options]
}

export function by(url, data, ...args) {
  const [id, options] = filterArgs(args)
  if (id) {
    return baseService.put(url + `/${id}`, data, options)
  } else {
    return baseService.post(url, data, options)
  }
}

export function get(url, data = {}) {
  return baseService.get(url, data)
}
export function getById(url, id, data = {}) {
  return baseService.getById(url, id, data)
}
export function put(url, data) {
  return baseService.put(url, data)
}

export function del(url, id, data = {}) {
  return baseService.del(url + `/${id}`, data)
}

export function getCommonService(element) {
  if (!element.query) {
    return baseService
      .get(element.endPoint)
      .then((response) => {
        let data = []
        data = response.data.map((row) => {
          const Temp = {}
          if (!element.fields || element.fields.length < 1) {
            return row
          } else {
            element.fields.forEach((field) => {
              Temp[field] = row[field]
            })
            return Temp
          }
        })
        return data
      })
      .catch((errors) => {
        console.log(errors)
      })
  } else {
    return baseService
      .post(element.endPoint, element.query)
      .then((response) => {
        const Results = {
          data: [],
          count: 0,
        }
        Results.count = response.data.meta.count
        Results.data = response.data.records.map((row) => {
          const ResultColumns = {}
          if (!element.fields || element.fields.length < 1) {
            return row
          } else {
            element.fields.forEach((field) => {
              ResultColumns[field] = row[field]
            })
            return ResultColumns
          }
        })
        return Results
      })
      .catch((errors) => {
        console.log(errors.code)
      })
  }
}
