import { addItemInArray } from '@src/utils/generalUtil.js'
export default {
  /* FROM NEW CLASSES(rename from terms) */

  SET_CLASS_LIST(state, payload) {
    state.classesList = payload.records
  },
  SET_USERS_CLASS_LIST(state, payload) {
    let currentClassScope = localStorage.getItem('currentClassScope')
    state.usersClassList = currentClassScope?.id
      ? addItemInArray(currentClassScope, payload)
      : payload
  },
}
