<template>
  <div class="lg:w-[270px] lg:h-[67px] sm:w-[160px] sm:h-9 w-[133px] h-[46px]">
    <UiSingleSelect
      v-model="currentCampus"
      title="CAMPUS"
      label="title"
      :select-object-without-reduce-key="true"
      :search-through-api="true"
      :options="campuses"
      class="flex-1"
      drop-down-container="w-full text-xxs sm:text-xs md:text-xs lg:text-sm sm:top-11 top-8"
      select-box-classes="lg:h-11 sm:h-9 h-7"
      container-classes="lg:min-w-[270] sm:min-w-[160] md:min-w-[160] min-w-[113px]"
      title-classes="pl-3 sm:pl-0 lg:text-sm sm:text-xxs text-[9px]"
      placeholder="SELECT_CAMPUS"
      input-value-classes="text-xxs sm:text-xs md:text-xs lg:text-sm"
      @search="getCampus"
      @deSelect="switchCampus(currentCampus)"
      @change="switchCampus(currentCampus)"
    />
  </div>
</template>

<script>
import UiSingleSelect from '@components/UiElements/UiSingleSelect.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import ROUTE from '@/src/constants/route-constants.js'
import { objectDeepCopy } from '@src/utils/generalUtil'
import { buildWhereQuery } from '@/src/utils/filters/index'
import FILTER_KEYS from '@src/constants/filter-constants.js'

export default {
  components: {
    UiSingleSelect,
  },
  data() {
    return {
      ROUTE,
      currentCampus: '',
    }
  },
  computed: {
    ...mapState('layout', ['currentCampusScope', 'campuses', 'activeRole']),
  },
  watch: {
    /**
     * Get Campus based settings and apply it every where when we are not on settings page
     */
    currentCampusScope: {
      handler() {
        if (this.$route.name !== 'feature-general-settings') this.getSettings()
      },
    },
  },

  created() {
    if (this.currentCampusScope?.id) {
      this.currentCampus = this.currentCampusScope
    } else {
      this.setcurrentSelectedSection('')
    }
  },

  methods: {
    getCampus(query = '') {
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      this.getCampusList(payload)
    },
    getSettings() {
      this.getFeaturesGeneralSettings().then((res) => {
        this.$store.commit('layout/SET_GENERAL_SETTINGS', objectDeepCopy(res))
      })
    },
    /**
     * GoTo Institue Level
     * @returns {void}
     * @description Function is performing following tasks:
     * - Reset the selected campus and selected class
     * - Shows the institue summary on dashboard
     * - Shows the list of campuses
     */
    goToInstitueLevel() {
      this.$store.commit('layout/SET_CURRENT_SELECTED_SECTION_SCOPE', '')
      this.$store.commit('layout/SET_CURRENT_SELECTED_CLASS_SCOPE', '')
      this.$store.commit('layout/SET_SELECTED_CAMPUSE', '')
      this.setcurrentSelectedSection('')
      this.setCurrentSelectedClass('')
      this.getCampusList()
    },

    switchCampus(campus) {
      this.setSelectedCampuse(campus)
      this.getCampusList()
    },
    ...mapActions('layout', [
      'setSelectedCampuse',
      'setcurrentSelectedSection',
      'setCurrentSelectedClass',
      'getCampusList',
    ]),
    ...mapActions('settings', ['getFeaturesGeneralSettings']),
  },
}
</script>
