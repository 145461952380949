// Register each file as a corresponding Vuex module. Module nesting
// will mirror [sub-]directory hierarchy and modules are namespaced
// as the camelCase equivalent of their file name.

import camelCase from 'lodash/camelCase'

const modules = {}
const requireModule = require.context(
  './',
  true,
  /^(?!.*(actions|mutations|getters|state|index|services)).*\.js$/,
)

requireModule.keys().forEach((fileName) => {
  const moduleName = camelCase(fileName.split('/')[1].replace(/(\.\/\|\.js)/g, ''))
  if (moduleName)
    modules[moduleName] = {
      namespaced: true,
      ...requireModule(fileName).default,
    }
})

export default modules
