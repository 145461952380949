// import { UsersService } from '@src/services/users.service'
import i18n from '@src/i18n'
import CampusAdminService from './services'

const t = i18n?.global?.t

export default {
  createCampusAdmin({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return CampusAdminService.createCampusAdmin(payload)
        .then((response) => {
          commit(
            'toast/NEW',
            { message: t(`toast.Successfully Created`), type: 'success' },
            { root: true },
          )
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  getCampusAdminStats({ commit }, payload = '') {
    return new Promise((resolve, reject) => {
      return CampusAdminService.getCampusAdminStats(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  getCampusAdmin({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return CampusAdminService.getCampusAdmin(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  editSuperAdmin({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return SuperAdminService.editSuperAdmin(payload)
        .then((response) => {
          commit('toast/NEW', { message: 'Updated Successfully', type: 'success' }, { root: true })
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  editCampusAdmin({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return CampusAdminService.editCampusAdmin(payload)
        .then((response) => {
          commit('toast/NEW', { message: 'Updated Successfully', type: 'success' }, { root: true })
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
}
