import { addItemInArray } from '@src/utils/generalUtil.js'
export default {
  SET_CLASS_SECTIONS(state, payload) {
    state.classSections = payload
  },
  SET_SECTIONS_LIST(state, payload) {
    state.sectionList = payload
  },
  SET_USER_SECTIONS_LIST(state, payload) {
    let currentSectionScope = localStorage.getItem('currentSectionScope')
    state.usersSectionList = currentSectionScope?.id
      ? addItemInArray(currentSectionScope, payload)
      : payload
  },
}
