import { currenciesList } from '@src/constants/settings/index'
export default {
  selectedCampusId(state) {
    return state.currentCampusScope?.id || null
  },

  userRole(state) {
    return state.activeRole || null
  },

  allowedCurrencies(state) {
    let currencies = []
    currenciesList.forEach((currenncy) => {
      if (state.generalSettings.allowed_currencies.includes(currenncy.id)) {
        currencies.push(currenncy)
      }
    })
    return currencies
  },
  defaultCurrency(state) {
    return state.generalSettings.default_currency
  },
  campusTimeZone(state) {
    return state.generalSettings?.default_timezone || ''
  },
  isManualPasswordResetAllowed(state) {
    return state.systemSettings.manual_password_reset_allowed || null
  },
}
