import { TENNAT_API_URL } from '@services/.env'
import { by, get, del } from '@services/custom.service'
import { BaseService } from '@services/base.service'
import { extractErrorMassage } from '@utils/generalUtil'

export default class StudentService extends BaseService {
  static markAttendanceForStudent(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}student_attendance`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not mart attendance'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static SubmitAttendance(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}student_subj_att`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch(() => {
          const message = 'Could notSubmit Attendance'
          reject(message)
        })
    })
  }

  static setStudentsCheckIn(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}student_check_in_outs`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Student list'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static setStudentsCheckOut(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}student_check_in_outs`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Student list'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getStudentCheckHistory(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}check_in_out_history`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Student list'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getStudentAttendanceHistory(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}student_attendance/filters`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Student Attendance list'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  /// ////Class Attendance Works
  static getClasses(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}classes/by`, { query: {} })
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Student list'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getSubjects(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}classes/subjects_list`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Student list'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getAllClasses(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}classes/class_subjects`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Student list'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getStudentPeriodAttendance(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}period_attendance/filter`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Student attendance list'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getScheduledStudents(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}period/period_students`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Student attendance list'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getTimeForSubjects(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}teacher_schedules`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Student attendance list'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static loadStudentCheckInAndOut(payload) {
    return new Promise((resolve, reject) => {
      const options = {}
      if (payload.filterClass) {
        options.filterClass = payload.filterClass
      }
      return by(`${TENNAT_API_URL}student_check_in_outs/filters`, payload, options)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Student attendance check in and out'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static loadSectionAttendance(payload) {
    return new Promise((resolve, reject) => {
      const options = {}
      if (payload.filterClass) {
        options.filterClass = payload.filterClass
      }
      return by(`${TENNAT_API_URL}section_attendance/filter`, payload, options)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Student attendance check in and out'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static loadCampusStudentsAttendance(payload) {
    return new Promise((resolve, reject) => {
      const options = {}
      if (payload.filterClass) {
        options.filterClass = payload.filterClass
      }
      return by(`${TENNAT_API_URL}campus_attendance/filters`, payload, options)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Student attendance check in and out'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static editStudent({ student, id = null }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}students/update_student`, student, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Students'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static loadStudents(payload) {
    return new Promise((resolve, reject) => {
      return get(`${TENNAT_API_URL}institute_users/section/${payload}`)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Students'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static loadCampusStudents(data) {
    return new Promise((resolve, reject) => {
      return get(`${TENNAT_API_URL}institute_users/campus_students`, { params: data })
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Students'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getStudentsWithFilters(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}students/filters`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Students'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getStudentsStats(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}students/student_stats`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Students'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static markStudentCheckInOut(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}section_attendance`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Students'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static markStudentCampusAttendance(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}campus_attendance`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Students'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static addNewStudent(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}students`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could ADD User'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static seedStudent(payload, limit = { timeout: 60000 }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}students/create_bulk_students_from_csv`, payload, limit)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  static getMarkedAttendanceStudentList(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}student_attendance/marked_attendance`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Student list'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getPublishedExamsStudents(query) {
    return new Promise((resolve, reject) => {
      return get(`${TENNAT_API_URL}exam_schedule_summary`, {
        params: new URLSearchParams(query),
      })
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Student list'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getPublishedExamsForPromotions(payload) {
    return new Promise((resolve, reject) => {
      return get(`${TENNAT_API_URL}exam_schedules/published_exam_schedules`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  static getStudentForPromotions() {
    return new Promise((resolve, reject) => {
      return get(`${TENNAT_API_URL}promotions/get_students`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }
  static closeCurrentSection(id) {
    return new Promise((resolve, reject) => {
      return del(`${TENNAT_API_URL}promotions/close_section`, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  static promoteStudents(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}promotions`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  /** /
   * Student Mode Endpoints
   */

  static getStudentSchedule(payload) {
    return new Promise((resolve, reject) => {
      let data = payload
      const options = {}

      if (payload.filterClass) {
        data = payload.filter_by
        options.filterClass = payload.filterClass
      }

      return by(`${TENNAT_API_URL}student/schedule/filters`, data, options).then((response) => {
        resolve(this.responseWrapper(response, response.data))
      })
    })
  }

  static getStudentCheckInOut(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}student/student_check_in_outs/filters`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Student attendance check in and out'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getStudentFee(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}student/student_fees/receivable_filter`, {
        page_type: data.page_type,
        skip: data.skip,
        limit: data.limit,
      })
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getStudentFeeReceived(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}student/student_fees/received_filter`, {
        page_type: data.page_type,
        skip: data.skip,
        limit: data.limit,
      })
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  /**
   * Enrolls student in campus and sections
   * @param {Object} student_enrollment_object
   */

  static studentEnrollment(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}section_enrollments`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static enrollNewStudent(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}section_enrollments/enroll_student`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static studentPromotion(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}promotions/promote_student`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static passedStudentOut(id) {
    return new Promise((resolve, reject) => {
      return del(`${TENNAT_API_URL}institute_users/moved_out`, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }
  static associateStudentWithGuardian(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}guardians/associate_student`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static unAssociateStudentWithGuardian(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}guardians/unassociate_student`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getSingleStudentGaurdians(payload, commit) {
    return get(`${TENNAT_API_URL}students/student_guardians/${payload.id}`, { params: payload })
      .then((response) => {
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static studentEnrolledClasses(id) {
    return new Promise((resolve, reject) => {
      return get(`${TENNAT_API_URL}students/${id}/enrolled_classes`)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = extractErrorMassage(error)
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static studentEnrolledSections(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}students/enrolled_sections`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = extractErrorMassage(error)
          reject(this.errorWrapper(error, message))
        })
    })
  }
}
