import { TENNAT_API_URL } from '@services/.env'
import { by, get } from '@services/custom.service'
import { BaseService } from '@services/base.service'

export class PaymentMethods extends BaseService {
  static getAllAccounts(commit) {
    return get(`${TENNAT_API_URL}payment_gateway/accounts`)
      .then((response) => {
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  }

  static createStripeStandardAccount(data, commit) {
    return by(`${TENNAT_API_URL}payment_gateway/stripe/accounts`, data)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'User stripe account created successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static onBoardUser(data, commit) {
    return by(`${TENNAT_API_URL}payment_gateway/stripe/accounts/onboarding`, data)
      .then((response) => {
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  }

  static deAuthorize(data, commit) {
    return by(`${TENNAT_API_URL}payment_gateway/stripe/accounts/deauthorize`, data)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'User stripe account deauthorized successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static payStudentFees(data, commit) {
    return by(`${TENNAT_API_URL}student_online_fee_pay`, data)
      .then((response) => {
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }
}
