import { TENNAT_API_URL } from '@/src/services/.env'
import { by, get } from '@/src/services/custom.service'
import { BaseService } from '@services/base.service'

export default class RoomServices extends BaseService {
  static addRoom(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}rooms`, payload)
        .then((response) => {
          resolve(response, response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  static getRooms(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}rooms/filter`, payload)
        .then((response) => {
          resolve(response, response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
