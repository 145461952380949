export default {
  SET_CURRENT_SELECTED_STUDENT(state, currentStudent) {
    state.currentSelectedStudent = currentStudent
  },
  SET_CURRENT_STUDENT_LIST(state, list) {
    state.currentStudentList = list
  },
  SET_CLASS_LIST(state, classes) {
    state.classList = classes
  },
  SET_CURRENT_CLASS_ATTENDANCE(state, payload) {
    state.currentStudentAttendance.time = payload.time
    state.currentStudentAttendance.date = payload.date
  },
  SET_CURRENT_COURSE(state, payload) {
    state.currentCourse = payload
  },
  SET_CURRENT_ATTENDANCE_FILTER(state, payload) {
    state.currentAttendanceFilter = { ...payload }
  },
  SET_SCHEDULED_STUDENT_LIST(state, payload) {
    state.studentListScheduleBase = payload
  },
  SET_CURRENT_SCHEDULE(state, payload) {
    state.currentSchulde = payload
  },
  SET_STUDENT_RECEIVED_FEES(state, payload) {
    state.studentReceivedFees = payload
  },
  // Student Mode Mutations
  SET_STUDENT_RECEIVABLE_FEES(state, payload) {
    state.studentReceivableFees = payload
  },
  SET_LISTEN_STORAGE(state, payload) {
    state.listenStorage = payload
  },
  // Registration Process
  SET_STEP_TYPE(state, payload) {
    state.currentStepForRegistration = payload
  },
  SET_FORM_TYPE_MODAL(state, payload) {
    state.toggleFormStepModal = payload
  },
}
