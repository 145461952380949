import i18n from '@src/i18n'
import LessonService from './services'

const t = i18n?.global?.t

export default {
  getLessons({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      LessonService.getLessons(payload)
        .then((res) => {
          resolve(res.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  getLessonStats({ commit }, payload = '') {
    return new Promise((resolve, reject) => {
      return LessonService.getLessonStats(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  addLesson({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      LessonService.addLesson(payload)
        .then((res) => {
          resolve(res.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  deleteLesson({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      LessonService.deleteLesson(payload)
        .then((res) => {
          commit(
            'toast/NEW',
            { message: t(`toast.Successfully Deleted!`), type: 'success' },
            { root: true },
          )

          resolve(res.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  assignLessonToPeriod({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      LessonService.assignLessonToPeriod(payload)
        .then((res) => {
          commit(
            'toast/NEW',
            { message: `Successfully assigned!`, type: 'success' },
            { root: true },
          )
          resolve(res.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  unAssignLessonToPeriod({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      LessonService.unAssignLessonToPeriod(payload)
        .then((res) => {
          commit(
            'toast/NEW',
            { message: `Successfully Unassigned!`, type: 'success' },
            { root: true },
          )
          resolve(res.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  getUnassignedLessons({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      LessonService.getUnassignedLessons(payload)
        .then((res) => {
          resolve(res.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
}
