<template>
  <div id="app" class="print:hidden">
    <!--
    Even when routes use the same component, treat them
    as distinct and create the component again.
    -->
    <template v-if="isAppLoading"><Loader class="mt-44 mb-40" :content="true" /></template>
    <RouterView v-else />
    <UiToastList />
  </div>
</template>

<script>
import UiToastList from '@components/UiElements/UiToastList.vue'
import appConfig from '@src/app.config'
import { mapActions, mapState } from 'vuex'
import firebaseInitialize from './firebase'
import { RouterView } from 'vue-router'
import Loader from '@components/BaseComponent/Loader.vue'

export default {
  name: 'App',
  components: {
    UiToastList,
    RouterView,
    Loader,
  },

  computed: {
    ...mapState('layout', ['systemSettings', 'isAppLoading']),
  },

  page: {
    titleTemplate(title) {
      let instituteTitle = localStorage.getItem('generalSettingSiteTitle')

      title = typeof title === 'function' ? title(this.$store) : title
      return instituteTitle
        ? title
          ? `${instituteTitle} | ${title}`
          : `${instituteTitle}`
        : title
        ? `${appConfig.title} | ${title}`
        : `${appConfig.title} `
    },
  },
  created() {
    firebaseInitialize()
    const authToken = localStorage.getItem('jwt') || null
    const settings = localStorage.getItem('setting') || null
    if (authToken && !this.$socket) this.$connectSocket('/tenant-notifire-socket', authToken)
    this.getShow()
    this.setLanguage()
    this.initGeneralSetting()
    if (settings) this.loadSiteSettings(JSON.parse(settings))
  },
  methods: {
    ...mapActions('users', ['getShow']),
    ...mapActions('settings', ['initGeneralSetting', 'loadSiteSettings']),
    setLanguage() {
      const language = JSON.parse(localStorage.getItem('curLanguage')) || {}
      if (language.short) {
        this.$i18n.locale = language.short
      } else {
        this.$i18n.locale = 'en'
        localStorage.setItem(
          'curLanguage',
          JSON.stringify({
            short: 'en',
            long: 'English',
            dir: 'ltr',
          }),
        )
        this.$i18n.locale = 'en'
        this.$store.commit('base/BODY_DIR', 'ltr')
      }
    },
  },
}
</script>
