import axios from 'axios'
import i18n from '@src/i18n/index'
import { DOMAIN_TITLE } from '@src/constants/general-constants'
import { UsersService } from '@src/services/users.service'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
const t = i18n?.global?.t

export default {
  init({ state, dispatch }) {
    setDefaultAuthHeaders(state)
  },
  removedOwnership(context, payload) {
    localStorage.setItem('auth.currentUser', JSON.stringify(payload))
  },
  logOut({ state, commit, rootState }, payload) {
    commit('LOGOUT_CURRENT_USER', '')
  },
  setupJwt(context, payload) {
    context.commit('SETUP_JWT', { context, payload })
  },
  // Logs in the current user.
  logIn({ commit, dispatch, getters }, payload) {
    return UsersService.login(payload)
      .then(({ data }) => {
        const defaultCampus = data?.campuses[0] || ''
        const defaultClass = data?.campuses[0]?.classes[0] || ''
        const defaultSection = data?.campuses[0]?.classes[0]?.sections[0] || ''
        const currentRole = data?.user?.user_roles[0]?.role_id || null
        const instituteTitle = data?.institute_title || null
        const setting = data?.setting || []
        const generalSettings = data?.generalSettings || []
        const instituteCounts = data?.institute_counts || null
        const guardianAssociatedStudents = data?.students || null
        const subDomain = data?.sub_domain || null
        // Set First campus and Section

        if (currentRole !== TENANT_ROLES.SUPER_ADMIN)
          commit('layout/SET_SELECTED_CAMPUSE', defaultCampus, { root: true })

        if (
          ![TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN, TENANT_ROLES.STAFF].includes(
            currentRole,
          )
        ) {
          commit('layout/SET_CURRENT_SELECTED_CLASS_SCOPE', defaultClass, { root: true })
          commit('layout/SET_CURRENT_SELECTED_SECTION_SCOPE', defaultSection, { root: true })
        }

        if (currentRole === TENANT_ROLES.GUARDIAN)
          commit('layout/SET_ASSOCIATED_STUDENT', guardianAssociatedStudents, { root: true })

        // Set current Role
        commit('layout/SET_SELECTED_ROLE', currentRole, { root: true })

        // Set Institute Counts
        commit('layout/SET_INSTITUE_COUNTS', instituteCounts, { root: true })

        // Set Institute Counts
        commit('SET_DOMAIN', subDomain)

        // Set Institute Settings
        commit('layout/SET_INSTITUE_SETTINGS', data.setting, { root: true })
        commit('layout/SET_GENERAL_SETTINGS', generalSettings, { root: true })

        // Call Action To Set JWT Token
        dispatch('setupJwt', data.jwt)

        // Set Current User Data
        commit('SET_CURRENT_USER', data)

        /* GENERAL SETTINGS INITIALIZE */
        dispatch(
          'settings/initGeneralSetting',
          {
            logo: data?.institute?.image,
            favicon: data?.institute?.favicon,
            institute_name: data?.institute?.title,
            site_title: data.setting?.site_title?.site_title || DOMAIN_TITLE,
            reset: true,
          },
          { root: true },
        )

        /* SITE SETTINGS INITIALIZE */
        dispatch('settings/loadSiteSettings', data.setting, { root: true })

        saveToLocalStorage({
          setting: setting,
          instituteTitle: instituteTitle,
        })
      })
      .catch((error) => {
        commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        throw error
      })
      .finally(() => commit('layout/DONE_LOADING', {}, { root: true }))
  },
  /* ENABLE DISABLE USER LOGIN */
  enableUserLogin({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UsersService.enableUserLogin(payload)
        .then((response) => {
          commit(
            'toast/NEW',
            { message: 'User Login Enabled Successfully', type: 'success' },
            { root: true },
          )
          resolve(response)
        })
        .catch((error) => {
          commit('layout/IS_LOADING', false, { root: true })
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  disableUserLogin({ commit }, id) {
    return new Promise((resolve) => {
      UsersService.disableUserLogin(id)
        .then((response) => {
          commit(
            'toast/NEW',
            { message: 'User Login Disabled Successfully', type: 'success' },
            { root: true },
          )
          resolve(response)
        })
        .catch((error) => {
          commit('layout/IS_LOADING', false, { root: true })
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  // Forgot Owner Password
  forgotOwnerPassword({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      UsersService.forgotOwnerPassword(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  resetOwnerPassword({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      UsersService.resetOwnerPassword(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  // Forgot User Password
  getForgetPasswordPreInfo({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      UsersService.getForgetPasswordPreInfo(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  forgotPassword({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      UsersService.forgotPassword(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  setupUserPassword({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      UsersService.setupUserPassword(payload)
        .then((response) => {
          commit(
            'toast/NEW',
            { type: 'success', message: 'Password Set Successfully' },
            { root: true },
          )
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  resetPassword({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      UsersService.resetPassword(payload)
        .then((response) => {
          commit(
            'toast/NEW',
            { type: 'success', message: t(`toast.Reset Password Successfully`) },
            { root: true },
          )
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  resendUserSetupPassword({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      UsersService.resendUserSetupPassword(payload)
        .then((response) => {
          commit(
            'toast/NEW',
            { type: 'success', message: 'Email resent successfully' },
            { root: true },
          )
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
}
function setDefaultAuthHeaders(state) {
  axios.defaults.headers.common.Authorization = state.currentUser ? state.currentUser.token : ''
}

function saveToLocalStorage(data) {
  let instituteCounts = JSON.parse(localStorage.getItem('institutesCounts'))
  if (
    (instituteCounts && !instituteCounts.campuses_count) ||
    (instituteCounts && !instituteCounts.classes_count) ||
    (instituteCounts && !instituteCounts.sectiones_count)
  )
    localStorage.setItem('instituteTitle', JSON.stringify(data.instituteTitle))

  localStorage.setItem('setting', JSON.stringify(data.setting))
}
