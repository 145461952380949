import SyllabusService from '@src/services/syllabus-manager.service.js'

export default {
  addNewCourseProvider({ commit }, payload) {
    return new Promise((resolve, reject) => {
      SyllabusService.addNewCourseProvider(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  deactivateCourseProvider({ commit }, id) {
    return new Promise((resolve, reject) => {
      SyllabusService.deactivateCourseProvider(id)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },
  getCourseProvidersList({ commit }, id) {
    return new Promise((resolve, reject) => {
      SyllabusService.getCourseProvidersList(id)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },
  // Syllabus Template

  createAndUpdateSyllabusTemplate({ commit }, payload) {
    return new Promise((resolve, reject) => {
      SyllabusService.createAndUpdateSyllabusTemplate(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  deleteSyllabusTemplate({ commit }, payload) {
    return new Promise((resolve, reject) => {
      SyllabusService.deleteSyllabusTemplate(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  getSubjectSyllabusTemplateList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      SyllabusService.getSubjectSyllabusTemplateList(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },
  getSyllabusTemplateDetail({ commit }, payload) {
    return new Promise((resolve, reject) => {
      SyllabusService.getSyllabusTemplateDetail(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  // Syllabus Template Subject

  createAndUpdateSyllabusTemplateSubject({ commit }, payload) {
    return new Promise((resolve, reject) => {
      SyllabusService.createAndUpdateSyllabusTemplateSubject(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  deleteSyllabusTemplateSubject({ commit }, payload) {
    return new Promise((resolve, reject) => {
      SyllabusService.deleteSyllabusTemplateSubject(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  getSyllabusTemplateSubjectList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      SyllabusService.getSyllabusTemplateSubjectList(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  // Syllabus Template Chapter

  createAndUpdateSyllabusTemplateChapter({ commit }, payload) {
    return new Promise((resolve, reject) => {
      SyllabusService.createAndUpdateSyllabusTemplateChapter(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  deleteSyllabusTemplateChapter({ commit }, payload) {
    return new Promise((resolve, reject) => {
      SyllabusService.deleteSyllabusTemplateChapter(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  getSyllabusTemplateChapterList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      SyllabusService.getSyllabusTemplateChapterList(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },
  // Syllabus Template Topic

  createAndUpdateSyllabusTemplateTopic({ commit }, payload) {
    return new Promise((resolve, reject) => {
      SyllabusService.createAndUpdateSyllabusTemplateTopic(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  deleteSyllabusTemplateTopic({ commit }, payload) {
    return new Promise((resolve, reject) => {
      SyllabusService.deleteSyllabusTemplateTopic(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  getSyllabusTemplateTopicList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      SyllabusService.getSyllabusTemplateTopicList(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },
  // Syllabus Template Lesson

  createAndUpdateSyllabusTemplateLesson({ commit }, payload) {
    return new Promise((resolve, reject) => {
      SyllabusService.createAndUpdateSyllabusTemplateLesson(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  deleteSyllabusTemplateLesson({ commit }, payload) {
    return new Promise((resolve, reject) => {
      SyllabusService.deleteSyllabusTemplateLesson(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  getSyllabusTemplateLessonList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      SyllabusService.getSyllabusTemplateLessonList(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  importSyllabusTemplateToSection({ commit }, payload) {
    return new Promise((resolve, reject) => {
      SyllabusService.importSyllabusTemplateToSection(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },
}
