import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'

const layoutConstants = {
  showSectionDropdownTo: [
    TENANT_ROLES.SUPER_ADMIN,
    TENANT_ROLES.SECTION_TEACHER,
    TENANT_ROLES.SECTION_STUDENT,
    TENANT_ROLES.GUARDIAN,
    TENANT_ROLES.CAMPUS_ADMIN,
  ],
  showClassDropdownTo: [
    TENANT_ROLES.SUPER_ADMIN,
    TENANT_ROLES.SECTION_TEACHER,
    TENANT_ROLES.SECTION_STUDENT,
    TENANT_ROLES.GUARDIAN,
    TENANT_ROLES.CAMPUS_ADMIN,
  ],
}

export default layoutConstants
