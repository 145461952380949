import moment from 'moment'
import DATETIME_CONSTANT from '@src/constants/date-time-constants.js'
import { weekDays } from '../constants/general-constants'
import 'moment-timezone'

/**
 * Check time comes before or after
 * @param {String} timeOne - Time
 * @param {String} timeTwo - Time
 * @return {Boolean}
 */

export function timeIsbefore(timeOne, timeTwo) {
  return moment(timeOne, DATETIME_CONSTANT.time12H).isBefore(
    moment(timeTwo, DATETIME_CONSTANT.time12H),
  )
}

/**
 * Check time comes before or after
 * @param {String} timeOne - Time
 * @param {String} timeTwo - Time
 * @return {Boolean}
 */

export function timeIsAfter(timeOne, timeTwo) {
  return moment(timeOne, DATETIME_CONSTANT.time12H).isAfter(
    moment(timeTwo, DATETIME_CONSTANT.time12H),
  )
}

/**
 * Format time from one format to other
 * @param {String} time - Time
 * @param {String} currentFormat - Time Format
 * @param {String} convertingFormat - Time Format
 * @return {String} -Converted Time
 */

export function formatTimeOnly(time, currentFormat, convertingFormat) {
  return moment(time, currentFormat).format(convertingFormat)
}
/**
 * Gives timezone in specific format
 * @param {String} date - Date
 * @param {String} date - In specific format

 */
export function formatTimeZone(date) {
  return moment(date).format('Z').split(':').join('')
}
/**
 * Add specific time in current time
 * @param {String} time - Time
 * @param {String} type - Time of type e.g. hours
 * @param {String} addedTime - time want to add
 * @param {String} format - After adding time in which format you want to convert
 * @return {String} -Added time
 */

export function addTimeInCurrentTime(time, type, addedTime, format) {
  return moment(time, DATETIME_CONSTANT.time12H).add(addedTime, type).format(format)
}

/**
 * Get date from DATETIME_CONSTANT with adding UTC time
 * @param {String} dateTime
 * @return {String} date
 */

export function getTimeFromDateWithUTC(dateTime, format = DATETIME_CONSTANT.time12H) {
  return moment(dateTime).format(format)
}

export function getTimeOnly(dateTime, format = DATETIME_CONSTANT.time12H, extention = 'HH:mm') {
  return moment(dateTime, extention).format(format)
}

export function currentUTCDateTimeWithZone() {
  let date = moment(new Date()).format(DATETIME_CONSTANT.DATE_TIME_24)
  return date
}

export function formatDateString(date, dateStringFormat = DATETIME_CONSTANT.DATE) {
  return moment(date).format(dateStringFormat)
}

export function getWeekDayFromDate(date) {
  return weekdays(moment(date).day())
}

function weekdays(day) {
  return weekDays[day - 1]
}

export function formatDate(date = new Date(), format = DATETIME_CONSTANT.DATE) {
  const formatedDate = moment(date).utcOffset(date).format(format)
  return formatedDate
}

export function formatDateFromLocalToUTC(date = new Date(), format = DATETIME_CONSTANT.DATE) {
  const formatedDate = moment(date).format(format)
  return formatedDate
}

export function fromNowMoment(date) {
  const formatedDate = moment(date).fromNow()
  return formatedDate
}

export function getDay(date, format = DATETIME_CONSTANT.DAY_FIRST_LETTER) {
  return moment(date).format(format)
}

/**
 * Get Date from DATETIME_CONSTANT
 * @param {DateTime} dateTime any DATETIME_CONSTANT
 * @return {date} date
 */

export function getDate(dateTime) {
  return dateTime.split('T')[0]
}

/**
 * Get Time from DATETIME_CONSTANT
 * @param {DateTime} dateTime any DATETIME_CONSTANT
 * @return {time} date
 */
export function getTime(dateTime) {
  return dateTime.split('T')[1]
}

export function getMonthStartAndEndDate(date = new Date()) {
  return {
    startOfMonth: moment(date).startOf('month').format(DATETIME_CONSTANT.DATE_TIME_24),
    endOfMonth: moment(date).endOf('month').format(DATETIME_CONSTANT.DATE_TIME_24),
  }
}

export function getTimeZoneList() {
  let timeZone = moment.tz.names()
  let date = formatDateFromLocalToUTC()
  let list = timeZone.map((country, index) => {
    let timeDifference = moment(date).tz(country).format('Z')
    return {
      id: index,
      timeDifference: timeDifference.split(':').join(''),
      title: `(UTC${timeDifference}) ${country.split('/').join(', ')}`,
      zone: country,
      timeDifferenceTitle: timeDifference,
    }
  })
  return list
}

export function getStartTimeOfTheDay(date = new Date(), format = DATETIME_CONSTANT.DATE_TIME_24) {
  return moment(date).startOf('day').format(format)
}

export function getEndTimeOfTheDay(date = new Date(), format = DATETIME_CONSTANT.DATE_TIME_24) {
  return moment(date).endOf('day').format(format)
}

export function currentTimeWithZone(zone = 'Asia/Karachi') {
  let date = moment().tz(zone).format(DATETIME_CONSTANT.DATE_TIME_24)
  return date
}
export function getDaysInMonth(date) {
  if (!date) {
    return 30
  }
  const momentDate = moment(date)
  return momentDate.daysInMonth()
}
export function formatDateRange(startDate, endDate) {
  const start = moment(startDate)
  const end = moment(endDate)
  if (start.isSame(end, 'month') && start.isSame(end, 'year')) {
    return `${start.date()}-${end.date()} ${start.format('MMMM')}, ${start.year()}`
  }
  if (start.isSame(end, 'year')) {
    return `${start.date()}${start.format('MMM')}-${end.date()} ${end.format(
      'MMM',
    )}, ${start.year()}`
  }
  return `${start.format('DD MMM YY')}, ${end.format('DD MMM YYYY')}`
}
export function isDateInFuture(date) {
  const givenDate = moment(date)

  const currentDate = moment()

  if (givenDate.isSameOrAfter(currentDate, 'day')) {
    return true
  } else {
    return false
  }
}
export function isFutureYear(date) {
  // Create a moment object for the given date
  const givenDate = moment(date)

  // Get the current year
  const currentYear = moment().year()

  // Get the year of the given date
  const givenYear = givenDate.year()

  // Compare the years
  if (givenYear >= currentYear) {
    return true
  } else {
    return false
  }
}
export const formatTime = (value) => {
  // Create a moment object with the given value in minutes
  const time = moment().startOf('day').add(value, 'minutes')

  // Format the time as desired
  return time.format('h:mm a')
}
export function getFormattedTitle(date) {
  // Create a moment object from the given date
  const selectDate = moment(`${date.year}-${date.month + 1}-${date.day}`, 'YYYY-MM-DD')

  // Format the title with day, date, month, and year
  const dateWithDayName = selectDate.format('ddd D MMM YYYY')

  return dateWithDayName
}
