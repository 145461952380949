import { by, del } from '@services/custom.service'
import { BaseService } from '@services/base.service'
import { TENNAT_API_URL } from '@services/.env'
import { extractErrorMassage } from '@utils/generalUtil'

export default class LessonService extends BaseService {
  static getLessons(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}lessons/filter`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = extractErrorMassage(error)
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getLessonStats(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}/lessons/lesson_stats`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }
  static addLesson({ lesson, id = null }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}lessons`, lesson, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  static deleteLesson(payload) {
    return new Promise((resolve, reject) => {
      return del(`${TENNAT_API_URL}lessons/deactivate`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  static assignLessonToPeriod(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}lesson_planner/assign_lesson`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }
  static unAssignLessonToPeriod(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}lesson_planner/unassign_lesson`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }
  static getUnassignedLessons(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}lesson_planner/unassigned_lessons_filter`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }
}
