import { by, get, getById } from '@services/custom.service'
import { BaseService } from '@services/base.service'
import { TENNAT_API_URL } from '@services/.env'

export default class StaffService extends BaseService {
  /**
   * Add new staff member
   * @param {Object} data staff data
   */

  static createStaff(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}staffs/add_staff`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Error in creating staff'
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static getStaffStats(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}/staffs/staff_stats`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  /**
   * Update existing staff member
   * @param {Object} data and id of staff
   */

  static updateStaff({ staff, id }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}staffs/update_staff`, staff, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Error in creating staff'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  /**
   * Get all users
   * @param {Object} payload user_role eg. section_teacher
   */

  static getAllUsers(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}institute_users/filters`, payload, payload.options)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get users'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getUserById(id, payload) {
    return new Promise((resolve, reject) => {
      return get(`${TENNAT_API_URL}/user_details/${id}`, {
        params: new URLSearchParams(payload),
      })
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get users'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getGuardianInformationById(id, query) {
    return new Promise((resolve, reject) => {
      return get(`${TENNAT_API_URL}user_details/${id}/guardian_information`, {
        params: new URLSearchParams(query),
      })
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get users'
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static getStaffById(id, query) {
    return new Promise((resolve, reject) => {
      return get(`${TENNAT_API_URL}user_details/${id}/staff_details`, {
        params: new URLSearchParams(query),
      })
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get users'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getOnlyUserofDetailsById(id, query) {
    return new Promise((resolve, reject) => {
      return get(`${TENNAT_API_URL}user_details/${id}/user_roles`, {
        params: new URLSearchParams(query),
      })
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get users'
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static getCampusAdminById(id, query) {
    return new Promise((resolve, reject) => {
      return get(`${TENNAT_API_URL}user_details/${id}/campus_admin_details`, {
        params: new URLSearchParams(query),
      })
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get users'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getSectionTeacherById(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}user_details/section_teacher_details`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get users'
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static getSectionStudentById(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}user_details/section_student_details`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get users'
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static getGuardianById(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}user_details/guardian_details`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get users'
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static getPickupPersonById(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}user_details/pickup_person_details`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get users'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  /**
   * Set new role to users
   * @param {Object} payload Role data
   *
   */

  static setUserRole(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}users_roles_entities`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get users'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  /**
   * Get all staff
   * @param {Object} payload pagination object
   */

  static getAllStaff(query) {
    return new Promise((resolve, reject) => {
      return get(`${TENNAT_API_URL}/staffs`, { params: new URLSearchParams(query) })
        .then((response) => {
          resolve(this.responseWrapper(response, response?.data))
        })
        .catch((error) => {
          const message = 'Could not get users'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getStaffWithFilters(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}/staffs/filters`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response?.data))
        })
        .catch((error) => {
          const message = 'Could not get staff'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static loadStaffAttendance(payload) {
    return new Promise((resolve, reject) => {
      const options = {}
      if (payload.filterClass) {
        options.filterClass = payload.filterClass
      }
      return by(`${TENNAT_API_URL}staff_check_in_outs/filters`, payload, options)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Student attendance check in and out'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static markStaffAttendance(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}staff_check_in_outs`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Try Again'
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static fetchStaffAndTeacherList(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}staffs/staff_and_teachers`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }
}
