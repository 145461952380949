import { AssignmentService } from '@src/services/assignments.service.js'
import { fullName } from '@src/utils/settings/tenant-user.util.js'

export default {
  getAssignmentTypes({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AssignmentService.getAssignmentTypes(payload)
        .then((response) => {
          resolve(response, response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  uploadAssignmentAttachments({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AssignmentService.uploadAssignmentAttachments(payload)
        .then((response) => {
          resolve(response, response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  addAndEditAssignmentTypes({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AssignmentService.addAndEditAssignmentTypes(payload)
        .then((response) => {
          resolve(response, response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  deleteAssignmentType({ commit }, id) {
    return new Promise((resolve, reject) => {
      AssignmentService.deleteAssignmentType(id)
        .then((response) => {
          resolve(response, response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  // Assignment

  getAssignments({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AssignmentService.getAssignments(payload)
        .then((response) => {
          resolve(response, response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  getAssignmentDetails({ commit }, id) {
    return new Promise((resolve, reject) => {
      AssignmentService.getAssignmentDetails(id)
        .then((response) => {
          resolve(response, response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  addAndEditAssignments({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AssignmentService.addAndEditAssignments(payload)
        .then((response) => {
          resolve(response, response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  deleteAssignments({ commit }, id) {
    return new Promise((resolve, reject) => {
      AssignmentService.deleteAssignments(id)
        .then((response) => {
          resolve(response, response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  assignAssignmentToStudent({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AssignmentService.assignAssignmentToStudent(payload)
        .then((response) => {
          resolve(response, response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  assignAssignmentToSection({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AssignmentService.assignAssignmentToSection(payload)
        .then((response) => {
          resolve(response, response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  filterAssignmentWebLinks({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AssignmentService.filterAssignmentWebLinks(payload)
        .then((response) => {
          resolve(response, response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  filterAssignmentCheckList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AssignmentService.filterAssignmentCheckList(payload)
        .then((response) => {
          resolve(response, response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  filterAssignmentStudents({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AssignmentService.filterAssignmentStudents(payload)
        .then((response) => {
          resolve(response, response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  filterAssignmentStudentList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AssignmentService.filterAssignmentStudentList(payload)
        .then((response) => {
          response?.data?.records.forEach((student) => {
            fullName(student)
          })
          resolve(response, response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
}
