import { by, del, getById } from './custom.service'
import { BaseService } from './base.service'
import { TENNAT_API_URL } from '@services/.env'

export default class SyllabusService extends BaseService {
  // Course Provider
  static addNewCourseProvider({ course, id }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}syllabus_providers`, course, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static deactivateCourseProvider(id) {
    return new Promise((resolve, reject) => {
      return del(`${TENNAT_API_URL}syllabus_providers`, `deactivate/${id}`)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static getCourseProvidersList(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}syllabus_providers/filter`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  // Syllabus Template

  static createAndUpdateSyllabusTemplate({ course, id }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}subject_templates`, course, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static deleteSyllabusTemplate(id) {
    return new Promise((resolve, reject) => {
      return del(`${TENNAT_API_URL}subject_templates`, `deactivate/${id}`)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static getSubjectSyllabusTemplateList(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}subject_templates/filter`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static getSyllabusTemplateDetail(id) {
    return new Promise((resolve, reject) => {
      return getById(`${TENNAT_API_URL}subject_templates`, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  // Template subject

  static createAndUpdateSyllabusTemplateSubject({ subject, id }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}subject_templates`, subject, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static deleteSyllabusTemplateSubject(id) {
    return new Promise((resolve, reject) => {
      return del(`${TENNAT_API_URL}subject_templates`, `deactivate/${id}`)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static getSyllabusTemplateSubjectList(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}subject_templates/filter`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  // Template chapter

  static createAndUpdateSyllabusTemplateChapter({ chapter, id }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}chapter_templates`, chapter, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static deleteSyllabusTemplateChapter(id) {
    return new Promise((resolve, reject) => {
      return del(`${TENNAT_API_URL}chapter_templates`, `deactivate/${id}`)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static getSyllabusTemplateChapterList(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}chapter_templates/filter`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  // Template topic

  static createAndUpdateSyllabusTemplateTopic({ topic, id }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}topic_templates`, topic, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static deleteSyllabusTemplateTopic(id) {
    return new Promise((resolve, reject) => {
      return del(`${TENNAT_API_URL}topic_templates`, `deactivate/${id}`)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static getSyllabusTemplateTopicList(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}topic_templates/filter`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  // Template lesson

  static createAndUpdateSyllabusTemplateLesson({ lesson, id }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}lesson_templates`, lesson, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static deleteSyllabusTemplateLesson(id) {
    return new Promise((resolve, reject) => {
      return del(`${TENNAT_API_URL}lesson_templates`, `deactivate/${id}`)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static getSyllabusTemplateLessonList(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}lesson_templates/filter`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static importSyllabusTemplateToSection(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}subject_templates/import`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
}
