const dateTimeFormat = {
  DATE: 'YYYY-MM-DD',
  DATE_TIME_12: 'YYYY-MM-DD hh:mm A',
  time12H: 'hh:mm A',
  TIME12H: 'hh:mm a',
  time24H: 'HH:mm:ss',
  day: 'dddd',
  DAY_FROM_DATE: 'DD',
  DATE_TIME_24: 'YYYY-MM-DDTHH:mm:ss',
  TIME_12_RESET_MINUTES: 'hh:00 a',
  dayStartTime: '09:00 am',
  dayEndTime: '01:00 pm',
  HOUR_24: 'HH',
  HOUR_12: 'hh',
  MINUTES: 'mm',
  AM: 'AM',
  PM: 'PM',
  DAY_FIRST_LETTER: 'D',
  YEAR: 'YYYY',
  MONTH: 'MMMM',
}

export const DATE_MODE = {
  DATE: 'date',
  START_DATE_TIME: 'start-date-time',
  END_DATE_TIME: 'end-date-time',
  DATE_TIME_RANGE: 'date-time-range',
}
export const DATE_DURATION = {
  WEEK: 'Week',
  MONTH: 'Month',
}
export const DATE_UNITS = ['date', 'month', 'year']
export const WEEK_DAYS = ['Mo', 'Tu', 'We', 'Th', 'Fri', 'Sat', 'Su']
export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
export default dateTimeFormat
