import { CSVService } from '@services/csv.service'

export default {
  setLoading({ commit }, payload) {
    commit('SET_LOADING', payload)
  },
  setModalLoading({ commit }, payload) {
    commit('SET_MODAL_LOADING', payload)
  },

  async downloadStudentCSV({ commit }, payload) {
    commit('SET_LOADING', true)
    const [response, error] = await CSVService.downloadStudentCSV(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    return [response, error]
  },

  async downloadReceivableCSV({ commit }, payload) {
    commit('SET_LOADING', true)
    const [response, error] = await CSVService.downloadReceivableCSV(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    return [response, error]
  },

  async downloadReceivedCSV({ commit }, payload) {
    commit('SET_LOADING', true)
    const [response, error] = await CSVService.downloadReceivedCSV(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    return [response, error]
  },

  async downloadReceivedInstallementCSV({ commit }, payload) {
    commit('SET_LOADING', true)
    const [response, error] = await CSVService.downloadReceivedInstallementCSV(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    return [response, error]
  },
}
