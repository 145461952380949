import { TENNAT_API_URL } from '@services/.env'
import { BaseService } from './base.service'
import { by, get, del } from './custom.service'

export class CampusService extends BaseService {
  static createCampus(formData) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}institutes`, formData).then((response) => {
        resolve(this.responseWrapper(response, response.data))
      })
    })
  }

  static createCampuses({ campus, id }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}campuses`, campus, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const messgae = ''
          reject(this.errorWrapper(error, messgae))
        })
    })
  }

  static getCampusList(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}campuses/filters`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get campuse list'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static deactivateCampus(id) {
    return new Promise((resolve, reject) => {
      return del(`${TENNAT_API_URL}campuses/soft_delete`, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }
}
