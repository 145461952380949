export default {
  set(state, payload) {
    state[payload[0]] = payload[1]
  },
  SET_NOTIFICATION(state, payload) {
    state.notifications = payload
  },
  SET_ALL_NOTIFICATION(state, payload) {
    state.allnotifications = payload
  },
  SET_NEW_NOTIFICATION(state, payload) {
    state.newNotifications = payload
  },
  SET_ANNOUNCEMENTS(state, payload) {
    state.announcements = payload
  },
  SET_NOTIFICATION_COUNT(state, payload) {
    state.notificationCount = payload
  },
}
