import { TENNAT_API_URL } from '@services/.env'
import { by, get, del } from '@services/custom.service'
import { BaseService } from '@services/base.service'

export class SalaryService extends BaseService {
  static getSalaryGenerators(commit, payload) {
    return get(`${TENNAT_API_URL}salary_generators/`, { params: payload })
      .then((response) => {
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  }

  static createSalaryGenerator(data, commit) {
    return by(`${TENNAT_API_URL}salary_generators`, data)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'Salary Generator created successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_MODAL_LOADING', false)
      })
  }

  static updateSalaryGenerator(data, commit) {
    return by(`${TENNAT_API_URL}salary_generators`, data, data.id)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'Salary Generator updated successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_MODAL_LOADING', false)
      })
  }

  static deleteSalaryGenerator(data, commit) {
    return del(`${TENNAT_API_URL}salary_generators/deactivate`, data)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'Salary Generator deleted successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_MODAL_LOADING', false)
      })
  }
  /** Allowance Types */
  static getAllowanceTypes(commit, payload) {
    return get(`${TENNAT_API_URL}allowance_types`, { params: payload })
      .then((response) => {
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  }

  static createAllowanceType(data, commit) {
    return by(`${TENNAT_API_URL}allowance_types`, data)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'Allowance Type created successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_MODAL_LOADING', false)
      })
  }

  static updateAllowanceType(data, commit) {
    return by(`${TENNAT_API_URL}allowance_types`, data, data.id)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'Allowance Type updated successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_MODAL_LOADING', false)
      })
  }

  static deleteAllowanceType(data, commit) {
    return del(`${TENNAT_API_URL}allowance_types/deactivate`, data)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'Allowance Type deleted successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_MODAL_LOADING', false)
      })
  }

  /**
   * Bonus Type Api's
   */
  static getBonusTypes(commit, payload) {
    return get(`${TENNAT_API_URL}bonus_types`, { params: payload })
      .then((response) => {
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  }

  static createBonusType(data, commit) {
    return by(`${TENNAT_API_URL}bonus_types`, data)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'Bonus Type created successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_MODAL_LOADING', false)
      })
  }

  static updateBonusType(data, commit) {
    return by(`${TENNAT_API_URL}bonus_types`, data, data.id)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'Bonus Type updated successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_MODAL_LOADING', false)
      })
  }

  static deleteBonusType(data, commit) {
    return del(`${TENNAT_API_URL}bonus_types/deactivate`, data)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'Bonus Type deleted successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_MODAL_LOADING', false)
      })
  }

  /**
   * Deduction Api's
   */

  static getDeductionTypes(commit, payload) {
    return get(`${TENNAT_API_URL}deduction_types`, { params: payload })
      .then((response) => {
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  }

  static createDeductionType(data, commit) {
    return by(`${TENNAT_API_URL}deduction_types`, data)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'Deduction Type created successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_MODAL_LOADING', false)
      })
  }

  static updateDeductionType(data, commit) {
    return by(`${TENNAT_API_URL}deduction_types`, data, data.id)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'Deduction Type updated successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_MODAL_LOADING', false)
      })
  }

  static deleteDeductionType(data, commit) {
    return del(`${TENNAT_API_URL}deduction_types/deactivate`, data)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'Deduction Type deleted successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_MODAL_LOADING', false)
      })
  }

  /**
   * Extra Working Unit Api's
   */

  static getExtraWorkingUnits(commit, payload) {
    return get(`${TENNAT_API_URL}extra_working_units`, { params: payload })
      .then((response) => {
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  }

  static createExtraWorkingUnit(data, commit) {
    return by(`${TENNAT_API_URL}extra_working_units`, data)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'Extra Working Unit created successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_MODAL_LOADING', false)
      })
  }

  static updateExtraWorkingUnit(data, commit) {
    return by(`${TENNAT_API_URL}extra_working_units`, data, data.id)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'Extra Working Unit updated successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_MODAL_LOADING', false)
      })
  }

  static deleteExtraWorkingUnit(data, commit) {
    return del(`${TENNAT_API_URL}extra_working_units/deactivate`, data)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'Extra Working Unit deleted successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_MODAL_LOADING', false)
      })
  }

  /**Salary Templates */

  static getSalaryTemplates(commit, payload) {
    return get(`${TENNAT_API_URL}salary_generators/templates/${payload.id}`, {
      params: payload.pagination,
    })
      .then((response) => {
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  }

  static createSalaryTemplate(data, commit) {
    return by(`${TENNAT_API_URL}salary_templates`, data)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'Salary Template created successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_MODAL_LOADING', false)
      })
  }

  static updateSalaryTemplate(data, commit) {
    return by(`${TENNAT_API_URL}salary_templates`, data, data.id)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'Salary Template updated successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_MODAL_LOADING', false)
      })
  }

  static deleteSalaryTemplate(data, commit) {
    return del(`${TENNAT_API_URL}salary_templates/deactivate`, data)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'Salary Template deleted successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_MODAL_LOADING', false)
      })
  }

  static getStaffData(payload, commit) {
    return get(`${TENNAT_API_URL}staffs`, { params: payload })
      .then((response) => {
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  }

  static getSalaries(commit, payload) {
    return get(`${TENNAT_API_URL}salaries`, { params: payload })
      .then((response) => {
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  }

  static getSalaryHistory(commit, payload) {
    return get(`${TENNAT_API_URL}salaries/staff_salaries`, { params: payload })
      .then((response) => {
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  }

  static createSalary(data, commit) {
    return by(`${TENNAT_API_URL}salaries`, data)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'Salary created successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_MODAL_LOADING', false)
      })
  }

  static updateSalary(data, commit) {
    return by(`${TENNAT_API_URL}salaries`, data, data.id)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'Salary updated successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_MODAL_LOADING', false)
      })
  }

  static deleteSalary(data, commit) {
    return del(`${TENNAT_API_URL}salaries/deactivate`, data)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'Salary deleted successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_MODAL_LOADING', false)
      })
  }

  static publishSalary(id, commit) {
    return by(`${TENNAT_API_URL}salaries/publish_salary`, {}, id)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'Salary published successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_MODAL_LOADING', false)
      })
  }

  static unPublishSalary(id, commit) {
    return by(`${TENNAT_API_URL}salaries/un_publish_salary`, {}, id)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'Salary Unpublished successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_MODAL_LOADING', false)
      })
  }

  static generateSalariesFromTemplates(id, commit) {
    return by(`${TENNAT_API_URL}salary_generators/generate_salaries`, {}, id)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'Salaries Generated successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_MODAL_LOADING', false)
      })
  }

  static getSalaryDetail({ salary_id }, commit) {
    return get(`${TENNAT_API_URL}salaries/${salary_id}`)
      .then((response) => {
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  }

  static getSalaryAllowance(payload, commit) {
    return get(`${TENNAT_API_URL}salaries/${payload.salary_id}/staff_salary_allowances`, {
      params: payload,
    })
      .then((response) => {
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  }

  static getSalaryBonuses(payload, commit) {
    return get(`${TENNAT_API_URL}salaries/${payload.salary_id}/staff_salary_bonuses`, {
      params: payload,
    })
      .then((response) => {
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  }

  static getSalaryDeduction(payload, commit) {
    return get(`${TENNAT_API_URL}salaries/${payload.salary_id}/staff_salary_deductions`, {
      params: payload,
    })
      .then((response) => {
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  }
}
