import { InstituteDashboardService } from '@src/services/instituteDashboard.service'

export default {
  getInstituteCount(context, payload) {
    context.commit('layout/IS_LOADING', true, { root: true })
    return new Promise((resolve, reject) => {
      InstituteDashboardService.getInstituteCount()
        .then((response) => {
          context.commit('SET_INSTITUTE_COUNT', response.data)
          context.commit('LOGIN_COUNT_STATS', response.data)
          context.commit('layout/IS_LOADING', false, { root: true })
          resolve(response)
        })
        .catch((error) => console.log(error))
    })
  },
  getCampusAttendanceStats({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return InstituteDashboardService.getCampusAttendanceStats(payload, commit)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  getLastMarkedCampusAttendance({ commit }) {
    return new Promise((resolve, reject) => {
      return InstituteDashboardService.getLastMarkedCampusAttendance(commit)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
}
