const ROUTE = {
  dashboard: {
    path: 'dashboard',
    name: 'dashboard',
  },
  subjectAttendance: {
    path: 'subject-attendance',
    teacher: {
      path: 'teacher',
      name: 'myattendance',
    },
    student: {
      path: 'students',
      name: 'studentAttendance',
      mark: {
        path: 'students/mark',
        name: 'studentMarkAttendance',
      },
    },
  },
  attendance: {
    path: 'attendance',
  },
  QUICK_ACTIONS: { name: 'quick-actions' },
  schedule: { name: 'schedule' },
  profile: { name: 'profile' },
  PICKUP_FACILITY: { name: 'pickup-facility' },
}
export default ROUTE
