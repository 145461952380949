export default {
  SET_LOADING(state, payload) {
    state.isLoading = payload
  },
  SET_MODAL_LOADING(state, payload) {
    state.isModalLoading = payload
  },
  SET_MODAL(state, payload) {
    state.showModal = payload
  },
  SET_MODAL_SHOW(state) {
    state.showModal = true
  },
  SET_MODAL_HIDE(state) {
    state.showModal = false
  },
}
