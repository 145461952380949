import { TENNAT_API_URL } from '@services/.env'
import { BaseService } from './base.service'
import { by, get, del, getById } from './custom.service'
import i18n from '@src/i18n/index'
const t = i18n?.global.t
export class FeeService extends BaseService {
  static addExpenseCategory(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}expense_categories`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getExpenseCategory() {
    return new Promise((resolve, reject) => {
      return get(`${TENNAT_API_URL}expense_categories`)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getStudentReceivableFee(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}student_fees/receivable_filter`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getStudentPayableFee(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}student_fees/payable_filter`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getStudentFeeDetails(id) {
    return new Promise((resolve, reject) => {
      return getById(`${TENNAT_API_URL}student_fees`, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getStudentReceivedleFee(data, state) {
    if (['section_student', 'guardian'].includes(state.activeRole)) {
      return new Promise((resolve, reject) => {
        return by(`${TENNAT_API_URL}student_fees/paid_filter`, data)
          .then((response) => {
            resolve(this.responseWrapper(response, response.data))
          })
          .catch((error) => {
            const message = error.response.data
              ? error.response.data.error
              : error.response.statusText
            reject(this.errorWrapper(error, message))
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        return by(`${TENNAT_API_URL}student_fees/received_filter`, data)
          .then((response) => {
            resolve(this.responseWrapper(response, response.data))
          })
          .catch((error) => {
            const message = error.response.data
              ? error.response.data.error
              : error.response.statusText
            reject(this.errorWrapper(error, message))
          })
      })
    }
  }

  // Change Receivable Due_Date
  static changeDueDate(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}student_fees/change_due_date`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  //  Add Fees

  static addFeeType({ type, id = null }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}fee_types`, type, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  // Get Fees types

  static getFeeType(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}fee_types/filter`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  // Add Fees Template

  static addFeeTemplate({ templateData, id = null }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}fee_planners`, templateData, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  // Get Fees Template

  static getFeeTemplate(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}fee_planners/filter`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  // Add Class Student Fees

  static addStudentFees(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}section_fees`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
  // Get Class Student Fees

  static getClassStudentFees(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}section_fees/filter`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
  // Add Single  Student Fees

  static addSingleStudentFees(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}student_fees`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  // Accept Student fee

  static acceptStudentFee(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}student_fee_receivings`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  // Add Fine in fee

  static addFineInFee(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}student_fees/add_student_fine`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  // Add Discount modal

  static addDiscountInFee(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}student_fees/add_student_discount`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
  // Add general discount
  static addFeeDiscount({ discount, id }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}student_discounts`, discount, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
  // get general discount
  static getFeeDiscount(payload) {
    let query = payload
    return new Promise((resolve, reject) => {
      return get(`${TENNAT_API_URL}student_discounts`, {
        params: new URLSearchParams(query),
      })
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  // Add Discount type

  static addOrUpdateDiscountType({ discount, id }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}discount_types`, discount, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
  // Get discount type
  static getDiscountType(payload) {
    let query = payload
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}discount_types/filter`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  //  Send Notification to User of his pending fee

  static sendFeeNotification(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}student_fees/send_notification`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  //  Delete Student Fee

  static deleteStudentFee(id) {
    return new Promise((resolve, reject) => {
      return del(`${TENNAT_API_URL}student_fees`, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  //  Delete Fee Planner

  static deleteFeePlanner({ id, title }, commit) {
    // console.log(dataId.id, 'data is coming after del')
    return del(`${TENNAT_API_URL}fee_planners/deactivate`, id)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: title + ' ' + t(`toast.is deleted successfully`), type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  //  Delete Fee Type

  static deleteFeeType(id) {
    return new Promise((resolve, reject) => {
      return del(`${TENNAT_API_URL}fee_types/deactivate`, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  //  Delete Class Fee

  static deleteClassFee(id) {
    return new Promise((resolve, reject) => {
      return del(`${TENNAT_API_URL}section_fees/deactivate`, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  // Fee Receivable Stats For Right Side Bar(RSB)
  static getFeeReceivableStats(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}student_fees/fee_receivable_stats`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
  // Fee Received Stats For Right Side Bar(RSB)
  static getFeeReceivedStats(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}student_fees/fee_received_stats`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  // getFeeDiscountStats

  static getFeeDiscountStats(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}student_fees/student_fee_discount_stats`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
  // Single student fee stats

  static getSingleStudentFeeStats(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}student_fees/student_fee_receivable_stats`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getStudentDiscountList(payload, commit) {
    return by(`${TENNAT_API_URL}student_fees/applied_student_discounts`, payload)
      .then((response) => {
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static getStudentDiscountDetail(payload, commit) {
    return get(`${TENNAT_API_URL}student_fees/single_student_discounts`, { params: payload })
      .then((response) => {
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static getFeeDetailForSendingNotification(payload, commit) {
    return get(`${TENNAT_API_URL}student_fees/send_notification`, { params: payload })
      .then((response) => {
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static getSectionFeeDetails(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}student_fees/filter`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getStudentFeeReceivings(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}student_fee_receivings/receiving_filter`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static getPendingFeeStudentsList(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}student_fees/pending_fee_students`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
}
