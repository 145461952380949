import store from '@src/state/store'
import { DOMAIN_TITLE } from '@src/constants/general-constants'
var manifestJSON = {
  name: DOMAIN_TITLE,
  short_name: DOMAIN_TITLE,
  start_url: window.location.href,
  display: 'standalone',
  icons: [
    {
      src: 'https://qampus-dev.s3.amazonaws.com/ippm_ab/assignments/image%20(1).png',
      type: 'image/png',
      sizes: '200x200',
    },
  ],
  theme_color: '#ffffff',
  background_color: '#ffffff',
  orientation: 'portrait',
  description: 'You can serve MyQampus as Application',
}

function setPWA() {
  manifestJSON.start_url = window.location.href
  manifestJSON.name = store.getters['settings/generalSettingInstituteNameForPWA']
  manifestJSON.short_name = store.getters['settings/generalSettingInstituteNameForPWA']
  manifestJSON.icons = manifestJSON.icons.map(function (element, index, array) {
    return {
      type: array[index].type,
      src: store.getters['settings/generalSettingLogoURLForPWA'],
      sizes: array[index].sizes,
    }
  })

  const stringManifest = JSON.stringify(manifestJSON)

  const blob = new Blob([stringManifest], { type: 'application/json' })
  const manifestURL = URL.createObjectURL(blob)

  document.querySelector('#dynamic-menifest').setAttribute('href', manifestURL)
}

export default setPWA
