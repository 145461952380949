import {
  themes,
  langs,
  themesObjArray,
  weekdaysArray,
  currenciesList,
} from '@src/constants/settings/index'
export default {
  generalSettingLogoURL: '',
  generalSettingFaviconURL: '',
  generalSettingInstituteName: '',
  generalSettingSiteTitle: '',
  contactSettings: [],
  billingSetting: {
    address: '',
    email: '',
    institute_name: '',
    phone: '',
    title: '',
  },
  siteSettingsDefaults: {
    themes,
    langs,
    themesObjArray,
    defaultDateArray: ['YYYY-MM-DD', 'YYYY-DD-MM'],
    defaultTimeZoneArray: ['+5', '+4', '+3', '+2', '+1', '0', '-1', '-2', '-3', '-4', '-5'],
    weekdaysArray,
    currenciesList,
    fileSizeArray: ['500', '1024', '2048'],
  },
  siteSetting: {},
}
