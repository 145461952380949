import { TEAM_TENNAT_API_URL, TENNAT_API_URL } from '@services/.env'
import { BaseService } from './base.service'
import { by, del, put } from './custom.service'

export class InstituteService extends BaseService {
  // Institute Setup

  static instituteSetup(data) {
    return new Promise((resolve, reject) => {
      return by(`${TEAM_TENNAT_API_URL}setup_tenant/institute`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Class list'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  // Campus Setup

  static campusSetup(data) {
    return new Promise((resolve, reject) => {
      return by(`${TEAM_TENNAT_API_URL}setup_tenant/campus`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Class list'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  // Section Setup

  static sectionSetup(data) {
    return new Promise((resolve, reject) => {
      return by(`${TEAM_TENNAT_API_URL}setup_tenant/section`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Class list'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  // Institute Logo

  static setInstituteLogo({ payload, id }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}institutes/upload_image`, payload, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Class list'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  // Favicon Icon

  static setInstituteFaviconIcon({ payload, id }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}institutes/upload_favicon`, payload, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Class list'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static deactivateInstituteUser(id, password) {
    return new Promise((resolve, reject) => {
      return del(`${TENNAT_API_URL}institute_users/deactivate/`, id, {
        params: new URLSearchParams({ password: password }),
      })
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Class list'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static resetUserPassword({ id = null, password = null }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}auth/reset_password_manually`, {
        user_id: id,
        password: password,
      })
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Students'
          reject(this.errorWrapper(error, message))
        })
    })
  }
}
