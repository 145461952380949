import { by, del, get } from '@services/custom.service'
import { BaseService } from '@services/base.service'
import { TENNAT_API_URL } from '@services/.env'

export default class SuperAdminService extends BaseService {
  /**
   * Get all super admins
   * @param {Object} payload
   */

  static getAllSuperAdmin(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}super_admins/filter`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get admin'
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static getSuperAdminStats(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}super_admins/super_admin_stats`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  static editSuperAdmin({ admin, id }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}super_admins`, admin, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }
  static transferOwnership(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}institutes/transfer_ownership`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Password is incorrect'
          reject(this.errorWrapper(error, message))
        })
    })
  }
}
