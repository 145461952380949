import i18n from '@src/i18n'
import PeriodService from './services'

const t = i18n?.global?.t

export default {
  getPeriod({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      PeriodService.getPeriod(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  getPeriodsStats({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      PeriodService.getPeriodsStats(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },
  getPaginatedPeriod({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      PeriodService.getPaginatedPeriod(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  deletePeriod({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      PeriodService.deletePeriod(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  getSubjects({ state, commit, rootState }) {
    return new Promise((resolve, reject) => {
      PeriodService.getSubjects({
        section_id: rootState.layout.currentSectionScope.id,
      })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  getSectionSubjectTeacher({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      PeriodService.getSectionSubjectTeacher(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  getRooms({ state, commit, rootState }) {
    return new Promise((resolve, reject) => {
      PeriodService.getRooms({
        section_id: rootState.layout.currentSectionScope.id,
      })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  storeSchedule({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      PeriodService.storeSchedule(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  filterPeriod({ commit }, payload) {
    return new Promise((resolve, reject) => {
      PeriodService.filterPeriod(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  addPeriod({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      PeriodService.addPeriod(payload)
        .then((response) => {
          commit(
            'toast/NEW',
            { message: t(`toast.Schedule created successfully`), type: 'success' },
            { root: true },
          )
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },
}
