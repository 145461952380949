<template>
  <div>
    <div
      class="flex px-2.5 py-3.5 bg-white items-center rounded-md border border-text-color-info font-roboto"
      :class="[
        `tooltip-${direction}`,
        rightBarTooltip
          ? 'gap-1 sm:gap-1.5 xl:gap-2.5 rtl:justify-end flip-tooltip-right-bar'
          : 'gap-2.5 flip-tooltip',
        customTooltip,
        minWidth,
        tooltip,
      ]"
    >
      <div v-if="!hideIcon" class="flip-tooltip self-start pt-1 sm:pt-0 ltr:order-1 rtl:order-2">
        <icon class="icon" icon="infoCircle" />
      </div>
      <div class="flex flex-col gap-1 flip-tooltip ltr:order-2 rtl:order-1">
        <div v-if="title" class="text-xs lg:text-sm font-medium text-text-color title-max-size">
          {{ title }}
        </div>
        <div
          class="ltr:text-left rtl:text-right"
          :class="[textExtraSmall ? 'text-xxs' : 'text-xxs lg:text-xs']"
        >
          <div v-if="campus" class="text-primary-grey-light capitalize">{{ campus }}</div>
          <div v-if="text" class="text-primary-grey-light capitalize">{{ text }}</div>
          <div v-if="description" class="text-primary-grey-light capitalize">
            {{ description }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import icon from '@components/icons/icon.vue'

export default {
  components: {
    icon,
  },
  props: {
    title: { type: [String, Number], default: '' },
    text: { type: String, default: '' },
    campus: { type: String, default: '' },
    hideIcon: { type: Boolean, default: false },
    description: { type: String, default: '' },
    minWidth: { type: String, default: '' },
    textExtraSmall: { type: Boolean, default: false },
    direction: { type: String, default: 'right' },
    rightBarTooltip: { type: Boolean, default: false },
    customTooltip: { type: String, default: '' },
    tooltip: { type: String, default: '.tooltip-top' },
  },
}
</script>

<style lang="scss" scoped>
.title-max-size {
  max-inline-size: auto;
}
@media (min-width: 1023px) {
  .title-max-size {
    inline-size: max-content;
  }
}

.tooltip-top {
  &::before {
    position: absolute;
    top: -7px;
    width: 13px;
    height: 13px;
    content: '';
    background: var(--bg-white);
    border-top: 1px solid var(--text-color-info);
    border-left: 1px solid var(--text-color-info);
    transform: rotate(45deg);
  }
  &-right {
    &::before {
      right: 28px;
      @media (max-width: 450px) {
        right: 95px;
      }
    }
  }
  &-left {
    &::before {
      left: 17px;
    }
  }
}
.tooltip-bottom {
  &::before {
    position: absolute;
    bottom: -7px;
    width: 13px;
    height: 13px;
    content: '';
    background: var(--bg-white);
    border-right: 1px solid var(--text-color-info);
    border-bottom: 1px solid var(--text-color-info);
    transform: rotate(45deg);
  }
  &-right {
    &::before {
      right: 28px;
      @media (max-width: 450px) {
        right: 95px;
      }
    }
  }
  &-left {
    &::before {
      left: 17px;
    }
  }
}
.tooltip-shadow {
  box-shadow: 1px 1px 15px #0003;
  &::before {
    margin-left: 12px;
    border: none;
  }
}
.tooltip-border {
  border: none;
}
.tooltip-width {
  min-width: 132px;
}
.tooltip-padding {
  padding: 2px;
  padding-left: 10px;
}
.tooltip-margin {
  margin-left: -10px;
}

body[dir='ltr'] {
  .flip-tooltip-right-bar {
    -ms-filter: fliph; /* IE */
    filter: fliph; /* IE */
    -moz-transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -o-transform: scaleX(1);
    transform: scaleX(1);
  }
}

body[dir='rtl'] {
  .flip-tooltip,
  .flip-tooltip-right-bar {
    -ms-filter: fliph; /* IE */
    filter: fliph; /* IE */
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  .tooltip {
    &::before {
      top: -8px;
    }
  }
}

@media (max-width: 769px) {
  body[dir='ltr'] {
    .tooltip {
      &-right {
        &::before {
          right: 16px;
        }
      }
    }
  }

  body[dir='rtl'] {
    .tooltip {
      &-right {
        &::before {
          top: -7px;
          right: 16px;
        }
      }
    }
  }
}

/* IPAD TO LOWER UPTO 810 PX WIDTH */
@media screen and (max-width: 810px) {
  body[dir='ltr'] {
    .tooltip {
      &-left {
        &::before {
          top: -7px;
          right: 16px;
        }
      }
    }
  }

  body[dir='rtl'] {
    .tooltip {
      &-left {
        &::before {
          left: 15px !important;
        }
      }
    }
  }
}

/* UPTO 600 PX WIDTH */
@media screen and (max-width: 600px) {
  body[dir='rtl'] {
    .tooltip {
      &-left {
        &::before {
          left: 13px !important;
        }
      }
    }
  }
}
</style>

<style lang="scss" module>
.toolTip {
  top: 70px;
  right: 195px;
  z-index: 5;
}
</style>
