<template>
  <p
    v-i18n="attendanceTranslation"
    class="lg:text-4xl md:text-2xl text-xl text-center py-5 text-action-bg"
  >
    {{ text }}
  </p>
</template>

<script>
export default {
  name: 'NoRecordFound',
  props: {
    text: {
      type: String,
      default: 'NO_RECORD_FOUND',
    },
  },
  data() {
    return {
      attendanceTranslation: 'attendanceTranslation',
    }
  },
}
</script>
