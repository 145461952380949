import { DOMAIN_TITLE } from '@src/constants/general-constants'
export default {
  generalSettingLogoURLForPWA(state) {
    return (
      state.generalSettingLogoURL ||
      'https://qampus-dev.s3.amazonaws.com/ippm_ab/assignments/image%20(1).png'
    )
  },

  generalSettingInstituteNameForPWA(state) {
    return state.generalSettingInstituteName || DOMAIN_TITLE
  },
}
