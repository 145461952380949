export default {
  SET_INSTITUTE_COUNT(state, payload) {
    state.instituteCount = payload
  },
  LOGIN_COUNT_STATS(state, payload) {
    state.loginStatCount = payload
  },
  INCREMENT_INSTITUTE_COUNT(state, payload) {
    if (state.instituteCount) state.instituteCount[payload]++
  },
}
