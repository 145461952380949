/* eslint-disable no-unused-vars */
import { TENNAT_API_URL } from '@services/.env'
// eslint-disable-next-line no-unused-vars
// import { response } from 'express'

import { BaseService } from './base.service'
import { by, get } from './custom.service'

export class InstituteDashboardService extends BaseService {
  static getInstituteCount() {
    return get(`${TENNAT_API_URL}institutes/stats`)
      .then((response) => this.responseWrapper(response, response.data))
      .catch((error) => this.errorWrapper(error, error.message))
  }
  static getCampusAttendanceStats(payload, commit) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}campus_attendance/stats`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
        .finally(() => {
          commit('layout/IS_LOADING', false, { root: true })
        })
    })
  }
  static getLastMarkedCampusAttendance() {
    return get(`${TENNAT_API_URL}campus_attendance/campus/last_marked`)
      .then((response) => this.responseWrapper(response, response.data))
      .catch((error) => this.errorWrapper(error, error.message))
  }
}
