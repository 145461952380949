import { TENNAT_API_URL } from '@services/.env'
import { BaseService } from './base.service'
import { by, del } from './custom.service'

export class AnnouncementTypesService extends BaseService {
  static getAnnouncementTypes(payload, commit) {
    return by(`${TENNAT_API_URL}announcement_types/filter`, payload)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static addAnnouncementType(payload, commit) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}announcement_types`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
        .finally(() => {
          commit('layout/IS_LOADING', false, { root: true })
        })
    })
  }

  static updateAnnouncementType(payload, commit) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}announcement_types`, payload, payload.id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
        .finally(() => {
          commit('layout/IS_LOADING', false, { root: true })
        })
    })
  }

  static deletetAnnouncementType(payload, commit) {
    return new Promise((resolve, reject) => {
      return del(`${TENNAT_API_URL}announcement_types/deactivate`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
        .finally(() => {
          commit('layout/IS_LOADING', false, { root: true })
        })
    })
  }
}
