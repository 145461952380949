import { by, del, get } from '@services/custom.service'
import { BaseService } from '@services/base.service'
import { TENNAT_API_URL } from '@services/.env'

export default class StaffService extends BaseService {
  /**
   * Add new staff member
   * @param {Object} data staff data
   */

  static createGuardian(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}guardians`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  static getAllGuardians(query) {
    return new Promise((resolve, reject) => {
      return get(`${TENNAT_API_URL}guardians`, {
        params: new URLSearchParams(query),
      })
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }
  static getGuardiansWithFilter(query) {
    const options = {}
    if (query && query.filterClass) {
      options.filterClass = query.filterClass
    }

    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}guardians/filters`, query, options)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }
  static getGuardianStats(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}guardians/guardian_stats`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  static editGuardian({ guardian, id }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}guardians`, guardian, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  static deactivateGuardian(id) {
    return new Promise((resolve, reject) => {
      return del(`${TENNAT_API_URL}guardians/deactivate`, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }
}
