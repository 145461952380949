import { by, del, get } from '@services/custom.service'
import { BaseService } from '@services/base.service'
import { TENNAT_API_URL } from '@services/.env'

export class AssignmentService extends BaseService {
  static getAssignmentTypes(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}assignment_types/filter`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }
  static uploadAssignmentAttachments(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}assignments/upload_attachment`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }
  static addAndEditAssignmentTypes({ assignmentType, id }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}assignment_types`, assignmentType, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }
  static deleteAssignmentType(id) {
    return new Promise((resolve, reject) => {
      return del(`${TENNAT_API_URL}assignment_types/deactivate`, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }
  // Assignments
  static getAssignments(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}assignments/filter`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }
  static getAssignmentDetails(id) {
    return new Promise((resolve, reject) => {
      return get(`${TENNAT_API_URL}assignments/${id}`)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }
  static addAndEditAssignments({ assignment, id }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}assignments`, assignment, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }
  static deleteAssignments(id) {
    return new Promise((resolve, reject) => {
      return del(`${TENNAT_API_URL}assignments/deactivate`, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  static assignAssignmentToStudent(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}student_assignments/assign_assignment`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }
  static assignAssignmentToSection(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}student_assignments/assign_assignment_to_section`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  static filterAssignmentWebLinks(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}assignments/web_links`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }
  static filterAssignmentCheckList(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}assignments/checklists`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }
  static filterAssignmentStudents(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}student_assignments/filter`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  static filterAssignmentStudentList(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}student_assignments/filter_assignment_students`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }
}
