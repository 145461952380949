<template>
  <div>
    <div class="flex items-center">
      <span
        class="lock mt-1"
        :class="{ unlocked: !isActive, deactivate: deactivate }"
        @click="updateEvent"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiLock',
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },

  props: {
    lockValue: { type: Boolean, default: false },
    deactivate: { type: Boolean, default: false },
    modelValue: { type: Boolean, default: false },
  },
  emits: ['update:modelValue'],
  data() {
    return { isActive: this.lockValue }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(val) {
        this.isActive = val
      },
    },
  },
  methods: {
    updateEvent() {
      if (!this.deactivate) {
        this.$emit('update:modelValue', !this.isActive)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.lock {
  position: relative;
  width: 15.74px;
  height: 14.02px;
  cursor: pointer;
  background-color: var(--primary-grey-light);
  border: 2px solid var(--primary-grey-light);
  border-radius: 3px;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.deactivate {
  background-color: var(--bg-color-danger);
  border-color: var(--bg-color-danger);
  &.lock::before {
    border-color: var(--bg-color-danger);
  }
}
.lock::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 5px;
  height: 5px;
  margin: -3.5px 0 0 -2px;
  content: '';
  background: white;
  border-radius: 5px;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.lock::before {
  position: absolute;
  bottom: 100%;
  left: 50%;
  display: block;
  width: 11px;
  height: 9px;
  margin-left: -5.5px;
  content: '';
  border: 2px solid var(--primary-grey-light);
  border-bottom: 0;
  border-top-left-radius: 70%;
  border-top-right-radius: 70%;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.lock:hover::before {
  height: 10px;
}
.unlocked {
  background-color: var(--primary-purple-600);
}
.unlocked::before {
  bottom: 110%;
  left: 31%;
  margin-left: 6px;
  transform: rotate(0deg);
}
.unlocked,
.unlocked::before {
  border-color: var(--primary-purple-600);
}
.unlocked::after {
  background: white;
}
</style>
