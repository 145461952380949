import i18n from '@src/i18n'
import SectionServices from '@/src/services/section.service'

const t = i18n?.global?.t

export default {
  addNewSection({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      SectionServices.addNewSection(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('layout/IS_LOADING', false, { root: true })
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  deleteSection({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      SectionServices.deleteSection(payload.id)
        .then((response) => {
          commit(
            'toast/NEW',
            {
              type: 'success',
              message: `${payload.title} ` + t(`toast.SECTION_SUCESSFULLY_DELETED`),
            },
            { root: true },
          )
          resolve(response)
        })
        .catch((error) => {
          commit('layout/IS_LOADING', false, { root: true })
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  getSectionsList({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      SectionServices.getSectionsList(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  sectionClasses({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      SectionServices.sectionClasses(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },
  getClassSections({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      SectionServices.getClassSections(payload)
        .then((response) => {
          commit('SET_CLASS_SECTIONS', response.data.records)
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  filterSectionsList({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      SectionServices.filterSectionsList(payload)
        .then((response) => {
          commit('SET_SECTIONS_LIST', response.data.records)
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  filterUsersSectionsList({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      SectionServices.filterUsersSectionsList(payload)
        .then((response) => {
          commit('SET_USER_SECTIONS_LIST', response.data)
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },
}
