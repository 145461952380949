<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="110.63"
    height="110.63"
    viewBox="0 0 110.63 110.63"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_1843"
          data-name="Rectangle 1843"
          width="110.63"
          height="110.63"
          rx="5"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        y1="1"
        x2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#c51e22" />
        <stop offset="1" stop-color="#d9484b" />
      </linearGradient>
    </defs>
    <g id="Mask_Group_18" data-name="Mask Group 18" clip-path="url(#clip-path)">
      <path
        id="Path_2308"
        data-name="Path 2308"
        d="M-8890.409-11697.9s-43.312-3.486-41.726,34.625c3.7-4.676,7.591-13.1,22.4-12.887.9-9.407.7-17.008,17.521-21.074A8.915,8.915,0,0,0-8890.409-11697.9Z"
        transform="translate(8932.153 11697.89)"
        fill="url(#linear-gradient)"
      />
    </g>
  </svg>
</template>
