import { createApp } from 'vue'
import { formatDate } from '@src/utils/moment.util.js'
import { capitalizeCharacterFromSentense } from '@src/utils/generalUtil'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import DATE_TIME_CONSTANT from '@src/constants/date-time-constants.js'
import { ROLES_LIST } from '@src/constants/user-roles-constants.js'
import { getInitialsFromFirstAndLastName } from '@utils/generalUtil'
import { getTimeOnly, getTimeFromDateWithUTC } from '@utils/moment.util'

const filters = {
  getInitial(firstName, lastName) {
    return getInitialsFromFirstAndLastName(firstName, lastName)
  },

  capitalizeCharacterFromSentense: (sentence) => capitalizeCharacterFromSentense(sentence),

  getTimeOnly: (val, dateTimeFormatVal) =>
    !val ? GENERAL_CONSTANTS.NOT_APPLICABLE : formatDate(val, dateTimeFormatVal),

  dateFormat: (val) => getTimeOnly(val),

  dateFormatInUTC: (val, dateTimeFormatVal) =>
    !val ? GENERAL_CONSTANTS.NOT_APPLICABLE : getTimeFromDateWithUTC(val, dateTimeFormatVal),

  formattedDate(val, format = DATE_TIME_CONSTANT.DATE) {
    return formatDate(val, format)
  },
  filteredUserRole(value) {
    return ROLES_LIST.find((role) => value === role.value).title
  },
}
export default filters
