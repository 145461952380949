<template>
  <div class="campus-detail-info mb-3">
    <div
      v-if="!isEmpty(stats.title)"
      class="font-base font-medium font-inter text-primary-gray-800"
    >
      {{ stats.title }}
    </div>
    <div
      v-if="stats?.categories?.length"
      class="mt-4 border rounded-lg border-primary-purple-100 p-4"
    >
      <div v-for="category in stats.categories" :key="category.text">
        <div v-if="isStatItemAllowed(category)">
          <UniStatsItem :category="category" :category-length="stats.categories.length" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UniStatsItem from '@components/UniStatsItem.vue'
import isEmpty from 'lodash/isEmpty'
import { accessLevel } from '@utils/generalUtil'
import { mapState } from 'vuex'

export default {
  name: 'Stats',
  components: {
    UniStatsItem,
  },
  props: {
    stats: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      toolTip: false,
      rightBar: 'rightBar',
    }
  },
  computed: {
    ...mapState('layout', [
      'currentSectionScope',
      'currentCampusScope',
      'currentClassScope',
      'activeRole',
    ]),
  },
  methods: {
    isEmpty,
    isStatItemAllowed(item) {
      let isLevelAuthentic = accessLevel(
        item,
        this.currentCampusScope,
        this.currentSectionScope,
        this.currentClassScope,
      )
      let isRoleAllowed = item.roles ? item.roles.includes(this.activeRole) : true

      return isLevelAuthentic && isRoleAllowed
    },
  },
}
</script>
