const userRoles = {
  GUARDIAN_OF: 'GUARDIAN_OF',
  PICKUP_PERSON_OF: 'PICKUP_PERSON_OF',
  MY_PICKUP_PERSON: 'MY_PICKUP_PERSON',
  STAFF: 'STAFF',
  TEACHER: 'TEACHER',
  GUARDIAN: 'GUARDIAN',
  CAMPUS_ADMIN: 'CAMPUS_ADMIN',
  SUPER_ADMIN: 'SUPER_ADMIN',
  OWNER: 'OWNER',
  MY_GUARDIAN: 'MY_GUARDIAN',
  STUDENT: 'STUDENT',
  SECTION_ADMIN: 'SECTION_ADMIN',
}
export default userRoles

export const ROLES_LIST = [
  { id: '0', title: 'Teacher', value: 'section_teacher' },
  { id: '1', title: 'Super Admin', value: 'super_admin' },
  { id: '2', title: 'Campus Admin', value: 'campus_admin' },
  { id: '3', title: 'Staff', value: 'staff' },
  { id: '4', title: 'Pickup Person', value: 'pickup_person' },
  { id: '5', title: 'Student', value: 'section_student' },
  { id: '6', title: 'Guardian', value: 'guardian' },
]

export const TENANT_ROLES = {
  STAFF: 'staff',
  SECTION_TEACHER: 'section_teacher',
  CAMPUS_ADMIN: 'campus_admin',
  SUPER_ADMIN: 'super_admin',
  GUARDIAN: 'guardian',
  SECTION_STUDENT: 'section_student',
  PICKUP_PERSON: 'pickup_person',
  SECTION_ADMIN: 'section_admin',
  SECTION_MODERATOR: 'section_moderator',
  CAMPUS_MODERATOR: 'campus_moderator',
}
