import LayoutService from './services'
export default {
  setLeftbarContent({ state, commit, rootState }, payload) {
    commit('SET_LEFTBAR_CONTENT', payload)
    commit('SET_SHOW_SECTION_DROPDOWN')
    commit('SET_SHOW_CLASS_DROPDOWN')
  },
  setRightbarContent({ state, commit, rootState }, payload) {
    commit('SET_RIGHTBAR_CONTENT', payload)
  },
  setItemState({ state, commit, rootState }, payload) {
    commit('SET_ITEM_STATE', payload)
  },
  setAlert({ state, commit, rootState }, payload) {
    // console.log('It Worked')
  },
  setTeacherCheckInModal({ state, commit, rootState }, payload) {
    commit('SET_TEACHER_CHECK_IN_MODAL', payload)
  },
  setTeacherCheckOutModal({ state, commit, rootState }, payload) {
    commit('SET_TEACHER_CHECK_OUT_MODAL', payload)
  },
  setShowModal({ state, commit, rootState }, payload) {
    commit('SET_SHOW_MODAL', payload)
  },
  setRoutineModal({ state, commit, rootState }, payload) {
    commit('SET_ROUTINE_MODAL', payload)
  },
  setShowUiModal({ state, commit, rootState }, payload) {
    commit('SET_SHOW_UI_MODAL', payload)
  },
  setCreateTopicModal({ state, commit, rootState }, payload) {
    commit('SET_CREATE_TOPIC_MODAL', payload)
  },
  setStudentAddModal({ commit }, payload) {
    commit('SET_STUDENT_ADD_MODAL', payload)
  },
  setStudentCheckInModal({ state, commit, rootState }, payload) {
    commit('SET_STUDENT_CHECK_IN_MODAL', payload)
  },
  setStudentCheckOutModal({ state, commit, rootState }, payload) {
    commit('SET_STUDENT_CHECK_OUT_MODAL', payload)
  },
  setCheckInModal({ state, commit, rootState }, payload) {
    commit('SET_CHECK_IN_MODAL', payload)
  },
  setCheckOutModal({ state, commit, rootState }, payload) {
    commit('SET_CHECK_OUT_MODAL', payload)
  },
  setApplyLeaveModal({ state, commit, rootState }, payload) {
    commit('SET_APPLY_LEAVE_MODAL', payload)
  },
  setAddScheduleModal({ state, commit, rootState }, payload) {
    commit('SET_ADD_SCHEDULE_IN_MODAL', payload)
  },
  setDeleteConfirmScheduleModal({ state, commit, rootState }, payload) {
    commit('SET_DELETE_SCHEDULE_IN_MODAL', payload)
  },
  setRightBarHeader({ state, commit, rootState }, payload) {
    commit('SET_RIGHT_BAR_HEADER', payload)
  },
  setRightBarStats({ state, commit, rootState }, payload) {
    commit('SET_RIGHT_BAR_STATS', payload)
  },
  isLoading({ state, commit, rootState }, payload) {
    commit('IS_LOADING', payload)
  },
  doneLoading({ state, commit, rootState }, payload) {
    commit('DONE_LOADING', payload)
  },
  setStudentsCheckInModal({ state, commit, rootState }, payload) {
    commit('STUDENTS_CHECK_IN_MODAL', payload)
  },
  setTeacherAddModal({ commit }, payload) {
    commit('SET_TEACHER_ADD_MODAL', payload)
  },
  setTeacherAttendanceModal({ state, commit, rootState }, payload) {
    commit('SET_TEACHER_ATTENDANCE_MODAL', payload)
  },
  setClassAddModal({ commit }, payload) {
    commit('SET_CLASS_ADD_MODAL', payload)
  },
  setcurrentSelectedSection({ state, commit, rootState }, payload) {
    commit('SET_CURRENT_SELECTED_SECTION_SCOPE', payload)
  },
  setCurrentSelectedClass({ state, commit, rootState }, payload) {
    commit('SET_CURRENT_SELECTED_CLASS_SCOPE', payload)
  },
  setcurrentSelectedSectionRole({ state, commit, rootState }, payload) {
    commit('SET_CURRENT_SELECTED_CLASS_ROLE', payload)
  },
  setcurrentSelectedSectionRoles({ state, commit, rootState }, payload) {
    commit('SET_CURRENT_SELECTED_CLASS_ROLES', payload)
  },
  // modifyLeftBarDropdown  it change the leftbar dropdown open state when route change
  modifyLeftBarDropdown({ state, commit, rootState }, payload) {
    state.leftBarContent.routes.map((item) => {
      if (item.routesName && item.routesName?.includes(payload)) {
        commit('SET_ITEM_STATE_BY_VALUE', { item: item, value: true })
      } else if (item.routesName) {
        commit('SET_ITEM_STATE_BY_VALUE', { item: item, value: false })
      }
    })
  },
  submitForm({ state, commit, rootState }) {
    commit('SUBMIT_FORM')
  },
  setBulkCheckSubmit({ state, commit, rootState }) {
    commit('SET_BULK_CHECK_SUBMIT')
  },
  setBulkCheckInModal({ state, commit, rootState }) {
    commit('SET_BULK_CHECKIN_MODAL')
  },
  setBulkCheckOutModal({ state, commit, rootState }) {
    commit('SET_BULK_CHECKOUT_MODAL')
  },

  setSelectedCampuse({ state, commit, rootState }, payload) {
    commit('SET_SELECTED_CAMPUSE', payload)
  },
  setRoles({ state, commit, rootState }, payload) {
    commit('SET_SELECTED_ROLE', payload)
    localStorage.setItem('selectedRoleId', payload)
  },
  setCampuses({ state, commit, rootState }, payload) {
    commit('SET_CAMPUSES', payload)
  },
  setClassList({ commit }, payload) {
    commit('SET_CLASS_LIST', payload)
  },

  getCampusList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      LayoutService.getCampusList(payload)
        .then((response) => {
          commit('GET_ALL_CAMPUS', response.data)
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },
  getUserSpecificSectionsList({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      LayoutService.getUserSpecificSectionsList({
        campus_id: payload?.campuseId || rootState.layout.currentCampusScope.id,
        skip: payload?.skip || 0,
        limit: payload?.limit || 10,
        include: payload?.include || '',
      })
        .then((response) => {
          commit('SET_CLASS_LIST', response.data)
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  getFilteredClassList({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      LayoutService.getUserSpecificSectionsList(payload)
        .then((response) => {
          // return response.data
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  activateEvent({ commit }, payload) {
    commit('ACTIVATE_EVENT', payload)
  },
  setUploadCSV({ commit }) {
    commit('SET_CSV_UPLOAD')
  },
  setDownloadTemplate({ commit }) {
    commit('SET_DOWNLOAD_CSV')
  },
}
