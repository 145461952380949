import { TENNAT_API_URL } from '@services/.env'
import { BaseService } from './base.service'
import { by, get, del } from './custom.service'

export class ExpenseService extends BaseService {
  // add and update expense categories
  static addAndupdateExpenseCategories({ category, id }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}expense_categories`, category, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  // Get expense type

  static getExpenseCategories(payload) {
    let query = payload
    return new Promise((resolve, reject) => {
      return get(`${TENNAT_API_URL}expense_categories`, {
        params: new URLSearchParams(query),
      })
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  //  Delete Student Fee

  static deleteExpenseCategories(id) {
    return new Promise((resolve, reject) => {
      return del(`${TENNAT_API_URL}expense_categories/deactivate`, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static updateExistingExpense({ expense, id }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}expenses`, expense, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static addNewExpense(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}expenses`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  // Get expense type

  static getExpenses(payload) {
    let query = payload
    return new Promise((resolve, reject) => {
      return get(`${TENNAT_API_URL}expenses`, {
        params: new URLSearchParams(query),
      })
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  //  Delete Student Fee

  static deleteExpenses(id) {
    return new Promise((resolve, reject) => {
      return del(`${TENNAT_API_URL}expenses/deactivate`, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static addExtraAttachment(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}expenses/upload_files`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Failed to upload attachment please try again'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static removeAttachmentFromExpense(id) {
    return new Promise((resolve, reject) => {
      return del(`${TENNAT_API_URL}expense_attachments/delete_attachments`, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Failed to remove attachment'
          reject(this.errorWrapper(error, message))
        })
    })
  }
}
