// import { UsersService } from '@src/services/users.service'
import SuperAdminService from './services'

export default {
  getSuperAdmin({ commit }, payload) {
    return new Promise((resolve, reject) => {
      SuperAdminService.getAllSuperAdmin(payload)
        .then((response) => {
          resolve(response, response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  getSuperAdminStats({ commit }, payload = '') {
    return new Promise((resolve, reject) => {
      return SuperAdminService.getSuperAdminStats(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  editSuperAdmin({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return SuperAdminService.editSuperAdmin(payload)
        .then((response) => {
          commit('toast/NEW', { message: 'Updated Successfully', type: 'success' }, { root: true })
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  // Transfer Ownership

  transferOwnership({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return SuperAdminService.transferOwnership(payload)
        .then((response) => {
          commit(
            'toast/NEW',
            { message: 'Ownership is successfully transferred', type: 'success' },
            { root: true },
          )
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
}
