import { formatTime, getFormattedTitle } from '@src/utils/moment.util.js'
export const afterDraw = (graphData, isdata) => {
  const { datasets } = graphData.value.data
  isdata.value = false

  for (let dataset of datasets) {
    if (dataset.data.length > 0 && dataset.data.some((item) => item !== 0)) {
      isdata.value = true
      break
    }
  }
}

export const getGradient = (ctx, chartArea, gradient, width, height) => {
  const chartWidth = chartArea.right - chartArea.left
  const chartHeight = chartArea.bottom - chartArea.top
  if (!gradient || width !== chartWidth || height !== chartHeight) {
    width = chartWidth
    height = chartHeight
    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top)
    gradient.addColorStop(0, 'rgba(127, 86, 217, 0)')
    gradient.addColorStop(1, 'rgba(127, 86, 217, .2)')
  }
  return gradient
}

export const configureLegend = (options, chart) => {
  options.plugins.legend.labels.generateLabels = (chart) =>
    chart.data.datasets
      .map((ds, i) => ({
        text: ds.label,
        datasetIndex: i,
        fillStyle: chart.data.datasets[i].backgroundColor,
        strokeStyle: chart.data.datasets[i].backgroundColor,
        hidden: chart.getDatasetMeta(i).hidden,
      }))
      .filter((ds, i) => i % 3 == 0)

  options.plugins.legend.onClick = (event, legendItem, legend) => {
    let hidden = !legend.chart.getDatasetMeta(legendItem.datasetIndex).hidden

    if (legendItem.datasetIndex === 0) {
      ;[0, 1, 2].forEach((i) => (legend.chart.getDatasetMeta(i).hidden = hidden))
    } else if (legendItem.datasetIndex === 3) {
      ;[3, 4, 5].forEach((i) => (legend.chart.getDatasetMeta(i).hidden = hidden))
    } else if (legendItem.datasetIndex === 6) {
      ;[6, 7, 8].forEach((i) => (legend.chart.getDatasetMeta(i).hidden = hidden))
    }

    legend.chart.update()
  }
}

export const configureDatalabels = (options, startTimeInMinutes) => {
  options.plugins.datalabels.formatter = (value, ctx) => {
    const pres = ctx.dataset.pres ? ctx.dataset.pres[ctx.dataIndex] : 'N/A'
    if (value) return `${ctx.dataset.label[ctx.dataIndex]}\nPres: ${pres}`
  }

  if (options.scales && options.scales.y) {
    options.scales.y.ticks.callback = (value) => {
      return formatTime(value + startTimeInMinutes)
    }
  }
}
export const setDatasetFill = (datasets) => {
  datasets.forEach((dataset) => {
    if (dataset.fill) {
      dataset.fill.above = function (context) {
        const chart = context.chart
        const { ctx, chartArea } = chart
        if (!chartArea) {
          return null
        }
        return getGradient(ctx, chartArea)
      }
    }
  })
}
export const configureTooltip = (options, graphData, props) => {
  if (!options.plugins.tooltip) {
    options.plugins.tooltip = {}
  }

  if (props.tooltip == 'campusAttendanceDashboard') {
    options.plugins.tooltip.callbacks = {
      title: function (tooltipItems) {
        let title = tooltipItems[0].parsed.x
        if (title !== null) {
          const date = graphData.value.date
          date.day = title + 1
          return getFormattedTitle(date)
        }
        return title
      },
      label: function (tooltipItem) {
        let label = tooltipItem.dataset.label || ''
        if (label) {
          label += ': '
        }
        label += tooltipItem.parsed.y
        return label
      },
      labelTextColor: function () {
        return '#667085'
      },
    }
  }
}
