export default {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.currentUser
  },
  currentUser(state) {
    return state.currentUser
  },
  userRoles(state) {
    return state.currentUser?.user_roles || null
  },
  isOwner(state) {
    return state.currentUser?.is_owner || null
  },

  profilePicture(state) {
    return state.currentUser?.image || null
  },

  fullName(state) {
    return `${state.currentUser?.first_name || ''} ${state.currentUser?.last_name || ''}`
  },

  nameInitials(state) {
    return `${state.currentUser?.first_name?.[0] || -''}${state.currentUser?.last_name?.[0] || ''}`
  },
  allcampus(state) {
    return state.allcampus
  },
  userImage(state) {
    return state?.currentUser?.image
  },

  currentUserId(state) {
    return state?.currentUser?.id
  },
  currentUserEmail(state) {
    return state?.currentUser?.email
  },
  subDomain(state) {
    return state?.domain || null
  },
}
