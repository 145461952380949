<template>
  <div class="page-not-found">
    <div class="mt-20">
      <img class="center block mx-auto" src="@assets/images/404.svg" alt="404" />
      <div class="text-center mt-8 md:mt-12">
        <h1 class="text-4xl md:text-6xl">
          <strong class="not-found font-roboto">Page Not Found</strong>
        </h1>
        <p class="mt-3 text-sm not-found-content">Your requested page is not found</p>
        <div
          class="text-sm sm:text-base text-white font-roboto py-4 px-6 sm:px-8 rounded-lg btn font-bold mt-8 sm:mt-10 inline-block"
          @click="$router.go(-1)"
        >
          Back To Home
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CP404',
}
</script>

<style lang="scss" scoped>
.center {
  width: 30%;
}
.page-not-found {
  color: #0a1c2f;
}
.not-found-content {
  color: #42505f;
}
.btn {
  color: var(--white);
  background-image: var(--bg-linear-gradient-btn);
}
</style>
