import i18n from '@src/i18n'
const t = i18n?.global.t

export const DOMAIN_TITLE = 'MyQampus'
const generalConstants = {
  TIME_ZONE: ' GMT +08:00',
  MINUTES: ' Minutes',
  imageSize: 500,
  RECORD_LIMIT: 10,
  RECORD_LIMIT_FOR_FILTERS: 500,
  RECORD_SKIP: 0,
  EMPTY_RECORD: 0,
  MODAL_RECORD_LIMIT: 5,
  NOT_APPLICABLE: 'N/A',
  FOR_ALL_CAMPUSES: 'For All Campuses',
  PASS: 'pass',
  FAIL: 'fail',
  emailSetupMessage: 'Are you sure you want to send password setup email to ',
  LESSON_TYPES: ['Theory', 'Lab', 'Assignment'],
  NO_RECORD_FOUND: 'No Record found',
  DAY_RANGE: ['monday', 'sunday'],
  TIME_RANGE: ['0:00 am', '0:00 am'],
  WEEK_START_DAY: 'Monday',
  MONTH: 'Month',
  WEEK: 'Week',
  DAY: 'Day',
  AMOUNT_ZERO: '0',
  LOGIN_ENABLED: 'Login Enabled',
  LOGIN_DISABLED: 'Login Disabled',
  PUBLISHED: 'Published',
  NOT_PUBLISHED: 'Not Published',
  GENERATED: 'Generated',
  NOT_GENERATED: 'Not Generated',
  LEARN_MORE: 'Learn More',
  ENABLED: 'Enabled',
  DISABLED: 'Disabled',
  BLANKED: '- -',
  ACTIVE: 'Active',
  NOT_ACTIVE: 'Not Active',
  STATS_AND_SUMMARY: 'Stats and Summary',
  LESS_THAN: 'Less Than',
  GREATER_THAN: 'Greate Than',
}

export const SCOPE_LEVELS = {
  ROOT_LEVEL: 'root-level',
  CAMPUS_LEVEL: 'campus-level',
  CLASS_LEVEL: 'class-level',
  SECTION_LEVEL: 'section-level',
}
export const ATTENDANCE = {
  ABSENT: 'absent',
  PRESENT: 'present',
}

export const ENV = {
  DEV: 'dev',
  LOCAL: 'local',
  PROD: 'prod',
}

export const IDENTITY_TYPE_OPTIONS = [
  { title: t(`dashboard.TM_P2_DD2_O1_L1`), value: 'cnic' },
  { title: t(`dashboard.TM_P2_DD2_O2_L2`), value: 'driving_licence' },
  { title: t(`dashboard.TM_P2_DD2_O3_L3`), value: 'passport' },
]
export const ROLES_LIST = [
  { id: '0', name: 'Student' },
  { id: '1', name: 'Teacher' },
  { id: '2', name: 'Gaurdian' },
  { id: '3', name: 'Staff' },
  { id: '4', name: 'All Student' },
  { id: '5', name: 'All Teacher' },
  { id: '6', name: 'All Gaurdian' },
  { id: '7', name: 'All Staff' },
]

export const MEDIUM_LIST = [
  { id: '1', name: 'email' },
  { id: '2', name: 'sms' },
]

/**
 * User Constants
 */
export const USER_CONSTANTS = {
  GENDERS: [
    { title: 'Male', value: 'male' },
    { title: 'Female', value: 'female' },
  ],
  MAJOR_ROLES: [
    { title: 'Section Teacher', value: 'section_teacher' },
    { title: 'Super Admin', value: 'super_admin' },
    { title: 'Campus Admin', value: 'campus_admin' },
    { title: 'Campus Moderator', value: 'campus_moderator' },
  ],
  LOGIN_ENABLED_STATUSES: [
    { title: 'Enable', value: true },
    { title: 'Disable', value: false },
  ],
}

export const NOTIFICATION_STATUS = {
  READ: 'Read',
  UNREAD: 'Unread',
  READ_ALL_SMALL: 'read',
  UNREAD_ALL_SMALL: 'unread',
}

export const COMMUNICATION_MEDIUMS = {
  WEBPUSH: 'webpush',
  SMS: 'sms',
  EMAIL: 'email',
}

export const weekDays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
export const NOTIFICATION_COMMUNICATION_MEDIUMS_LIST = [
  { id: '1', name: 'email' },
  { id: '2', name: 'sms' },
  { id: '3', name: 'webpush' },
]
export const RESULT_GENERATED_LIST = [
  { title: 'Generated', value: 'generated' },
  { title: 'Not Generated', value: 'not_generated' },
]
export const RESULT_PUBLISHED_LIST = [
  { title: 'Published', value: 'yes' },
  { title: 'Not Published', value: 'no' },
]

export default generalConstants
