import { mapActions } from 'vuex'
import setPWA from '../utils/pwa-utils'
export default {
  methods: {
    /**
     * Logout
     * @description It Calls logOut() from map actions, setDefaultIDataAfterLougout()
     * and clearStates() to clear the values of fields and to logout from the system and returning to signin page
     */
    signOut() {
      this.logOut()
      setTimeout(() => {
        this.clearStates()
      })
      this.destroyGeneralSettings()
      this.setDefaultIDataAfterLougout()
      setPWA()
      this.$router?.push({ name: 'login' })
      if (this.$socket.isConnected()) {
        this.$socket.disconnect()
        this.$socket.channel = null
      }
    },

    /**
     * Clear States
     * @description It gives empty string to variables
     */
    clearStates() {
      this.$store.commit('layout/SET_SELECTED_CAMPUSE', '')
      this.$store.commit('layout/SET_CAMPUSES', [])
      this.$store.commit('layout/SET_CURRENT_SELECTED_CLASS_SCOPE', '')
      this.$store.commit('layout/SET_CURRENT_SELECTED_SECTION_SCOPE', '')
      this.$store.commit('layout/SET_SELECTED_ROLE', '')
    },

    /**
     * Set Default Data After Lougout
     * @description Sets the default values when user logouts
     */
    setDefaultIDataAfterLougout() {
      let adminJWT, superUser
      const lang = localStorage.getItem('curLanguage')
      const theme = localStorage.getItem('theme')
      const tab = localStorage.getItem('tabLeftNav')
      const fcmToken = localStorage.getItem('fcmToken')
      if (localStorage.getItem('adminJwt')) {
        adminJWT = localStorage.getItem('adminJwt')
        superUser = localStorage.getItem('superUser')
      }
      localStorage.clear()
      localStorage.setItem('curLanguage', lang)
      localStorage.setItem('theme', theme)
      localStorage.setItem('tabLeftNav', tab)
      localStorage.setItem('fcmToken', fcmToken)
      if (adminJWT) {
        localStorage.setItem('adminJwt', adminJWT)
        localStorage.setItem('superUser', superUser)
      }
    },
    ...mapActions('settings', ['destroyGeneralSettings']),
    ...mapActions('auth', ['logOut']),
  },
}
