const filterKeys = {
  ILIKE: '$ilike',
  LIKE: '$like',
  NOT_LIKE: '$not_like',
  NOT_ILIKE: '$not_ilike',
  LESS_THAN: '$lt',
  LESS_THAN_EQUAL: '$lte',
  GREATER_THAN: '$gt',
  GREATER_THAN_EQUAL: '$gte',
  BETWEEN: '$between',
  NOT_BETWEEN: '$not_between',
  IN: '$in',
  NOT_IN: '$not_in',
  IS_NIL: '$is_nil',
  IS_NOT_NULL: '$not_null',
  EQUAL: '$equal',
}
export default filterKeys
export const FILTERS_LIST = {
  DATE_OF_BIRTH_LESS_THAN: {
    option: filterKeys.LESS_THAN_EQUAL,
    key: 'date_of_birth',
  },
  DATE_OF_BIRTH_GREATER_THAN: {
    option: filterKeys.GREATER_THAN_EQUAL,
    key: 'date_of_birth',
  },
  FIRST_NAME: {
    option: filterKeys.ILIKE,
    key: 'first_name',
  },
  LAST_NAME: {
    option: filterKeys.ILIKE,
    key: 'last_name',
  },
  USER_NAME: {
    option: filterKeys.ILIKE,
    key: 'username',
  },
  RELIGION: {
    option: filterKeys.ILIKE,
    key: 'religion',
  },
  EMAIL: {
    option: filterKeys.ILIKE,
    key: 'email',
  },
  PHONE: {
    option: filterKeys.LIKE,
    key: 'phone',
  },
  GENDER: {
    option: filterKeys.EQUAL,
    key: 'gender',
  },
  LOCATION: {
    option: filterKeys.ILIKE,
    key: 'location',
  },
  CITY: {
    option: filterKeys.ILIKE,
    key: 'city',
  },
  CONTACT_NO: {
    option: filterKeys.LIKE,
    key: 'contact_no',
  },
  ADDRESS: {
    option: filterKeys.ILIKE,
    key: 'address',
  },
  LOGIN_ENABLED_STATUS: {
    option: filterKeys.EQUAL,
    key: 'login_enabled',
  },
  STUDENTS: {
    option: filterKeys.IN,
    key: 'student_ids',
  },
  MYQAMPUS_ROLES: {
    option: filterKeys.IN,
    key: 'role_ids',
  },
  TITLE: {
    option: filterKeys.ILIKE,
    key: 'title',
  },
  SUBJECT: {
    option: filterKeys.EQUAL,
    key: 'subject_id',
  },
  LESSON: {
    option: filterKeys.EQUAL,
    key: 'lesson_id',
  },
  SUBJECTS: {
    option: filterKeys.IN,
    key: 'subject_ids',
  },
  NUMBER: {
    option: filterKeys.EQUAL,
    key: 'number',
  },
  JOIN_DATE: {
    option: filterKeys.EQUAL,
    key: 'join_date',
  },
  CHAPTER: {
    option: filterKeys.EQUAL,
    key: 'chapter_id',
  },
  TOPIC: {
    option: filterKeys.EQUAL,
    key: 'topic_id',
  },
  STUDENT: {
    option: filterKeys.EQUAL,
    key: 'student_id',
  },
  PICKUP_PERSON: {
    option: filterKeys.EQUAL,
    key: 'pickup_person_id',
  },
  CAMPUS: {
    option: filterKeys.EQUAL,
    key: 'campus_id',
  },
  CLASS: {
    option: filterKeys.EQUAL,
    key: 'class_id',
  },
  SECTION: {
    option: filterKeys.EQUAL,
    key: 'section_id',
  },
  INSERTED_DATE_GREATER_THAN_EQUAL: {
    option: filterKeys.GREATER_THAN_EQUAL,
    key: 'inserted_at',
  },
  INSERTED_DATE_LESS_THAN_EQUAL: {
    option: filterKeys.LESS_THAN_EQUAL,
    key: 'inserted_at',
  },
  PUBLISHED_DATE_GREATER_THAN_EQUAL: {
    option: filterKeys.GREATER_THAN_EQUAL,
    key: 'published_at',
  },
  PUBLISHED_DATE_LESS_THAN_EQUAL: {
    option: filterKeys.LESS_THAN_EQUAL,
    key: 'published_at',
  },
  FINE_AMOUNT_GREATER_THAN_EQUAL: {
    option: filterKeys.GREATER_THAN_EQUAL,
    key: 'fine_amount',
  },
  FINE_AMOUNT_LESS_THAN_EQUAL: {
    option: filterKeys.LESS_THAN_EQUAL,
    key: 'fine_amount',
  },
  FEE_AMOUNT_RANGE: {
    initalOption: filterKeys.GREATER_THAN_EQUAL,
    secondaryOption: filterKeys.LESS_THAN_EQUAL,
    key: 'fee_amount',
  },
  GENERATED_DAY_RANGE: {
    initalOption: filterKeys.GREATER_THAN_EQUAL,
    secondaryOption: filterKeys.LESS_THAN_EQUAL,
    key: 'generate_on_day_of_month',
  },

  PAID_AMOUNT_RANGE: {
    initalOption: filterKeys.GREATER_THAN_EQUAL,
    secondaryOption: filterKeys.LESS_THAN_EQUAL,
    key: 'paid_amount',
  },
  PAYABLE_AMOUNT_RANGE: {
    initalOption: filterKeys.GREATER_THAN_EQUAL,
    secondaryOption: filterKeys.LESS_THAN_EQUAL,
    key: 'payable_amount',
  },
  FINE_AMOUNT_RANGE: {
    initalOption: filterKeys.GREATER_THAN_EQUAL,
    secondaryOption: filterKeys.LESS_THAN_EQUAL,
    key: 'fine_amount',
  },
  DISCOUNT_AMOUNT_RANGE: {
    initalOption: filterKeys.GREATER_THAN_EQUAL,
    secondaryOption: filterKeys.LESS_THAN_EQUAL,
    key: 'discount_amount',
  },
  DUE_DATE_MONTH_RANGE: {
    initalOption: filterKeys.GREATER_THAN_EQUAL,
    secondaryOption: filterKeys.LESS_THAN_EQUAL,
    key: 'due_date_on_day_of_month',
  },
  FEE_AMOUNT_GREATER_THAN_EQUAL: {
    option: filterKeys.GREATER_THAN_EQUAL,
    key: 'fee_amount',
  },
  FEE_AMOUNT_LESS_THAN_EQUAL: {
    option: filterKeys.LESS_THAN_EQUAL,
    key: 'fee_amount',
  },
  PAYABLE_AMOUNT_GREATER_THAN_EQUAL: {
    option: filterKeys.GREATER_THAN_EQUAL,
    key: 'payable_amount',
  },
  PAYABLE_AMOUNT_LESS_THAN_EQUAL: {
    option: filterKeys.LESS_THAN_EQUAL,
    key: 'payable_amount',
  },
  PAID_AMOUNT_GREATER_THAN_EQUAL: {
    option: filterKeys.GREATER_THAN_EQUAL,
    key: 'paid_amount',
  },
  PAID_AMOUNT_LESS_THAN_EQUAL: {
    option: filterKeys.LESS_THAN_EQUAL,
    key: 'paid_amount',
  },
  PAID_DATE_LESS_THAN_EQUAL: {
    option: filterKeys.LESS_THAN_EQUAL,
    key: 'paid_date',
  },
  PAID_DATE_GREATER_THAN_EQUAL: {
    option: filterKeys.GREATER_THAN_EQUAL,
    key: 'paid_date',
  },

  DISCOUNT_AMOUNT_LESS_THAN_EQUAL: {
    option: filterKeys.LESS_THAN_EQUAL,
    key: 'discount_amount',
  },
  DISCOUNT_AMOUNT_GREATER_THAN_EQUAL: {
    option: filterKeys.GREATER_THAN_EQUAL,
    key: 'discount_amount',
  },
  DUE_DATE_LESS_THAN_EQUAL: {
    option: filterKeys.LESS_THAN_EQUAL,
    key: 'due_date',
  },
  DUE_DATE_GREATER_THAN_EQUAL: {
    option: filterKeys.GREATER_THAN_EQUAL,
    key: 'due_date',
  },
  MARKED_AT_LESS_THAN_EQUAL: {
    option: filterKeys.LESS_THAN_EQUAL,
    key: 'marked_at',
  },
  MARKED_AT_GREATER_THAN_EQUAL: {
    option: filterKeys.GREATER_THAN_EQUAL,
    key: 'marked_at',
  },
  STARTED_AT_LESS_THAN_EQUAL: {
    option: filterKeys.LESS_THAN_EQUAL,
    key: 'started_at',
  },
  STARTED_AT_GREATER_THAN_EQUAL: {
    option: filterKeys.GREATER_THAN_EQUAL,
    key: 'started_at',
  },
  ENDED_AT_LESS_THAN_EQUAL: {
    option: filterKeys.LESS_THAN_EQUAL,
    key: 'ended_at',
  },
  ENDED_AT_GREATER_THAN_EQUAL: {
    option: filterKeys.GREATER_THAN_EQUAL,
    key: 'ended_at',
  },

  GENERATED_DAY_LESS_THAN_EQUAL: {
    option: filterKeys.LESS_THAN_EQUAL,
    key: 'generate_on_day_of_month',
  },
  GENERATED_DAY_GREATER_THAN_EQUAL: {
    option: filterKeys.GREATER_THAN_EQUAL,
    key: 'generate_on_day_of_month',
  },
  DUE_DATE_DAY_LESS_THAN_EQUAL: {
    option: filterKeys.LESS_THAN_EQUAL,
    key: 'due_date_on_day_of_month',
  },
  DUE_DATE_DAY_GREATER_THAN_EQUAL: {
    option: filterKeys.GREATER_THAN_EQUAL,
    key: 'due_date_on_day_of_month',
  },
  MULTIPLE_STUDENTS: {
    option: filterKeys.IN,
    key: 'student_id',
  },
  TEACHER: {
    option: filterKeys.IN,
    key: 'teacher_id',
  },
  ROOM: {
    option: filterKeys.EQUAL,
    key: 'room_id',
  },
  MULTIPLE_USERS: {
    option: filterKeys.IN,
    key: 'user_id',
  },
  STATUS: {
    option: filterKeys.EQUAL,
    key: 'status',
  },
  EXAM_TYPE: {
    option: filterKeys.EQUAL,
    key: 'exam_type_id',
  },
  CHECKED_TYPE: {
    option: filterKeys.EQUAL,
    key: 'checked_type',
  },
  LESSON_STATUS: {
    option: filterKeys.EQUAL,
    key: 'lesson_status',
  },
  TEACHER_STATUS: {
    option: filterKeys.EQUAL,
    key: 'teacher_status',
  },
  GENERATED: {
    option: filterKeys.EQUAL,
    key: 'generated_at',
  },
  PUBLISHED: {
    option: filterKeys.EQUAL,
    key: 'is_published',
  },
  FEE_TYPE: {
    option: filterKeys.EQUAL,
    key: 'fee_type_id',
  },

  GUARDIANS: { option: filterKeys.IN, key: 'guardian_ids' },
}
