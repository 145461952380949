// import { UsersService } from '@src/services/users.service'
import i18n from '@src/i18n'
import RoomServices from './services'

const t = i18n?.global?.t

export default {
  createRoom({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return RoomServices.addRoom(payload)
        .then((response) => {
          commit(
            'toast/NEW',
            { message: t(`toast.Successfully Created`), type: 'success' },
            { root: true },
          )
          resolve(response.data)
        })
        .catch((error) => {
          commit('layout/IS_LOADING', false, { root: true })
          reject(error)
        })
    })
  },
  getRooms({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return RoomServices.getRooms(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
