import i18n from '@src/i18n'
import PickupMembers from './services'
import { fullName } from '@src/utils/settings/tenant-user.util.js'

const t = i18n?.global?.t

export default {
  createPickupMember({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return PickupMembers.createPickupMember(payload)
        .then((response) => {
          commit(
            'toast/NEW',
            { message: t(`toast.Successfully Created`), type: 'success' },
            { root: true },
          )
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  getPickupPersonStats({ commit }, payload = '') {
    return new Promise((resolve, reject) => {
      return PickupMembers.getPickupPersonStats(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  getPickupMembers({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return PickupMembers.getPickupMembers(payload)
        .then((response) => {
          response.data.records.forEach((user) => {
            fullName(user)
          })
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  editPickupMember({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return PickupMembers.editPickupMember(payload)
        .then((response) => {
          commit('toast/NEW', { message: 'Updated Successfully', type: 'success' }, { root: true })
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  deactivatePickupPerson({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return PickupMembers.deactivatePickupPerson(payload)
        .then((response) => {
          commit(
            'toast/NEW',
            { message: 'Deactivated Successfully', type: 'success' },
            { root: true },
          )
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  assosiateStudentsWithPickupMembers({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return PickupMembers.assosiateStudentsWithPickupMembers(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  unAssosiateStudentsWithPickupMembers({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return PickupMembers.unAssosiateStudentsWithPickupMembers(payload)
        .then((response) => {
          commit(
            'toast/NEW',
            { type: 'success', message: 'Successfully Unassociated' },
            { root: true },
          )
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  // Pickup Facility

  generateNewPickupRequest({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return PickupMembers.generateNewPickupRequest(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit(
            'toast/NEW',
            {
              message:
                error.message === 'Invalid Parameters'
                  ? 'Pickup Person is not Selected'
                  : error.message,
              type: 'error',
            },
            { root: true },
          )
          reject(error)
        })
    })
  },
  getAllPickupRequests({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return PickupMembers.getAllPickupRequests(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  markStudentIsPickedUp({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return PickupMembers.markStudentIsPickedUp(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  closeActivePickupRequest({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return PickupMembers.closeActivePickupRequest(payload)
        .then((response) => {
          resolve(response.data)
          commit(
            'toast/NEW',
            { message: 'Request Closed Successfully', type: 'success' },
            { root: true },
          )
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  rejectActivePickupRequest({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return PickupMembers.rejectActivePickupRequest(payload)
        .then((response) => {
          resolve(response.data)
          commit(
            'toast/NEW',
            { message: 'Request Rejected Successfully', type: 'success' },
            { root: true },
          )
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  getAssociatedStudents({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return PickupMembers.getAssociatedStudents(payload)
        .then((response) => {
          response.data?.records.forEach((user) => {
            fullName(user)
          })
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  reReuquestPickupRequest({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return PickupMembers.reReuquestPickupRequest(payload)
        .then((response) => {
          commit(
            'toast/NEW',
            { message: 'Rerequest has been send', type: 'success' },
            { root: true },
          )
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  respondToPickupMemvber({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return PickupMembers.respondToPickupMemvber(payload)
        .then((response) => {
          commit(
            'toast/NEW',
            {
              type: 'success',
              message: 'Response has send successfully',
            },
            { root: true },
          )
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  getRequestDetails({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return PickupMembers.getRequestDetails(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
}
