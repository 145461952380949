import i18n from '@src/i18n/index.js'

const $t = i18n?.global?.t

export default {
  mounted(element, binding) {
    if (binding.expression)
      element.innerText = $t(binding.expression + '.' + element.innerText.trim())
    else if (binding.value) element.innerText = $t(binding.value + '.' + element.innerText.trim())

    element.innerText = $t(element.innerText)
  },
}

let a = 10
