import axios from 'axios'

export default {
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue ? newValue.user : null
    saveState('auth.currentUser', newValue?.user)
    setDefaultAuthHeaders(newValue?.jwt)
  },
  LOGOUT_CURRENT_USER(state) {
    state.currentUser = null
    state.loggedIn = false
  },
  SET_ATOKEN_EXP_DATE(state, expDate) {
    state.accessTokenExpDate = expDate
  },

  SETUP_JWT(state, { payload, context }) {
    window.localStorage.setItem('jwt', payload)
  },

  ADD_ROLE(state, payload) {
    state.currentUser.user_roles.push(payload)
    localStorage.setItem('auth.currentUser', JSON.stringify(state.currentUser))
  },
  SET_DOMAIN(state, payload) {
    state.domain = payload
    localStorage.setItem('subDomain', JSON.stringify(payload))
  },
  CHANGE_CURRENT_USER_IAMGE(state, payload) {
    state.currentUser.image = payload
    localStorage.setItem('user', JSON.stringify(state.currentUser))
  },
}
function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}
function setDefaultAuthHeaders(state) {
  axios.defaults.headers.common.Authorization = state.jwt ? state.jwt : ''
}
