import axios from 'axios'
import $store from '@state/store'
import { BASE_API_URL } from './.env'
import router from '@router/index'

export const HTTP = axios.create({
  baseURL: BASE_API_URL,
  timeout: 15000,
})

export function get(url, data = {}) {
  addToken()
  return HTTP.get(url, data)
}
export function getById(url, id, data) {
  addToken()
  return HTTP.get(`${url}/${id}`, data)
}

export function post(url, data, options = {}) {
  addToken(options)
  return HTTP.post(url, data, { timeout: options?.timeout })
}

export function put(url, data) {
  addToken()
  return HTTP.put(url, data)
}

export function del(url, payload) {
  addToken()
  return HTTP.delete(url, payload)
}

function addToken(options = {}) {
  const jwt = window.localStorage.getItem('jwt') || ''
  const role = $store.state.layout.activeRole || window.localStorage.getItem('selectedRoleId')

  // Set Default Header

  setDefaultAuthHeaders(options)

  if (jwt.length > 0) {
    HTTP.defaults.headers.common.Authorization = `Bearer ${jwt}`
    HTTP.defaults.headers.common['X-ROLE-ID'] = `${role}`
  }
}

function setDefaultAuthHeaders(options) {
  const skipSectionHeader = options?.skipSectionHeader || null
  const skipClassHeader = options?.skipSectionHeader || null
  const skipCampusHeader = options?.skipCampusHeader || null

  setCampusIdHeader(skipCampusHeader)
  setClassIdHeader(skipClassHeader)
  setSectionIdHeader(skipSectionHeader)
  setStudentIdHeader()
  setTeacherIdHeader()
}

function setCampusIdHeader(skipCampusHeader) {
  const currentCampusScope =
    JSON.parse(window.localStorage.getItem('currentCampusScope')) !== ''
      ? JSON.parse(window.localStorage.getItem('currentCampusScope'))?.id
      : null

  if (currentCampusScope && !skipCampusHeader)
    HTTP.defaults.headers.common['X-CAMPUS-ID'] = `${currentCampusScope}`
  else if (skipCampusHeader || !currentCampusScope)
    delete HTTP.defaults.headers.common['X-CAMPUS-ID']
}

function setClassIdHeader(skipClassHeader) {
  const currentClassScope =
    JSON.parse(window.localStorage.getItem('currentClassScope')) !== ''
      ? JSON.parse(window.localStorage.getItem('currentClassScope'))?.id
      : null
  if (currentClassScope && !skipClassHeader)
    HTTP.defaults.headers.common['X-CLASS-ID'] = `${currentClassScope}`
  else if (skipClassHeader || !currentClassScope) delete HTTP.defaults.headers.common['X-CLASS-ID']
}

function setSectionIdHeader(skipSectionHeader) {
  const currentSectionScope =
    JSON.parse(window.localStorage.getItem('currentSectionScope')) !== ''
      ? JSON.parse(window.localStorage.getItem('currentSectionScope'))?.id
      : null

  if (currentSectionScope && !skipSectionHeader) {
    HTTP.defaults.headers.common['X-SECTION-ID'] = `${currentSectionScope}`
  } else if (skipSectionHeader || !currentSectionScope) {
    delete HTTP.defaults.headers.common['X-SECTION-ID']
  }
}

function setStudentIdHeader() {
  let currentActiveStudent = router?.currentRoute?.value?.params?.student_id || null
  const role = $store?.state?.layout?.activeRole || window.localStorage.getItem('selectedRoleId')
  const getStudentFromLocalStorage = ['guardian']
  if (getStudentFromLocalStorage.includes(role)) {
    const student = JSON.parse(window.localStorage.getItem('currentActiveStudent'))
    currentActiveStudent = student ? student?.id || student?.user_id : null
  }
  if (currentActiveStudent)
    HTTP.defaults.headers.common['X-VIEW-STUDENT-ID'] = `${currentActiveStudent}`
  else delete HTTP.defaults.headers.common['X-VIEW-STUDENT-ID']
}

function setTeacherIdHeader() {
  const currentActiveTeacher = JSON.parse(localStorage.getItem('currentActiveTeacher'))?.id || null
  if (currentActiveTeacher)
    HTTP.defaults.headers.common['X-VIEW-TEACHER-ID'] = `${currentActiveTeacher}`
  else delete HTTP.defaults.headers.common['X-VIEW-TEACHER-ID']
}
