import i18n from '@src/i18n'
import SettingsService from '@services/settings.services'
import { isEmpty } from 'lodash'
import { siteSettingParser } from '@utils/settings/settings-utils'
import { objectDeepCopy } from '@src/utils/generalUtil'
import setPWA from '@/src/utils/pwa-utils'
import { DOMAIN_TITLE } from '@src/constants/general-constants'

const t = i18n?.global?.t

export default {
  // Set Default Settings
  setDefaultSettings({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      SettingsService.setDefaultSettings(payload)
        .then((response) => {
          commit(
            'toast/NEW',
            { type: 'success', message: t(`toast.Setting Applied`) },
            { root: true },
          )
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  // Get Default Settins
  getDefaultSettings({ commit }) {
    return new Promise((resolve, reject) => {
      SettingsService.getDefaultSettings()
        .then((response) => {
          commit('SET_DEFAULT_SETTINGS', response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  /* GENERAL SETTINGS */
  async setLogoAndFavicon({ commit }, payload) {
    const [response, error] = await SettingsService.setLogoAndFavicon(payload)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    } else {
      commit(
        'toast/NEW',
        { message: 'Image uploaded successfully', type: 'success' },
        { root: true },
      )
      localStorage.setItem('generalSettingLogo', response.data.image)
      !isEmpty(response.data?.favicon) &&
        localStorage.setItem('generalSettingFavicon', response.data.favicon)
    }
    return [response, error]
  },
  async setInstituteNameAndTitle({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.setInstituteNameAndTitle(payload)
    if (!error) {
      localStorage.setItem('generalSettingInstituteName', response.data.title)
      localStorage.setItem('generalSettingSiteTitle', response.data.site_title)
    } else {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },
  initGeneralSetting({ commit }, payload) {
    let institute_name, logo, favicon, site_title
    if (payload?.reset == true) {
      logo = payload.logo
      favicon = payload.favicon
      institute_name = payload.institute_name
      site_title = payload.site_title
      !isEmpty(logo) && localStorage.setItem('generalSettingLogo', logo)
      !isEmpty(favicon) && localStorage.setItem('generalSettingFavicon', favicon)
      !isEmpty(institute_name) &&
        localStorage.setItem('generalSettingInstituteName', institute_name)
      !isEmpty(site_title) && localStorage.setItem('generalSettingSiteTitle', site_title)
    } else {
      logo = localStorage.getItem('generalSettingLogo')
      favicon = localStorage.getItem('generalSettingFavicon')
      institute_name = localStorage.getItem('generalSettingInstituteName')
      site_title = localStorage.getItem('generalSettingSiteTitle')
    }
    if (!isEmpty(logo)) {
      commit('GS_SET_LOGO', logo)
    }
    if (!isEmpty(favicon)) {
      commit('GS_SET_FAVICON', favicon)
    }
    if (!isEmpty(institute_name)) {
      commit('GS_SET_INSTITUTE_NAME', institute_name)
    }
    if (!isEmpty(site_title)) {
      commit('GS_SET_SITE_TITLE', site_title)
    }
    setPWA()
  },
  destroyGeneralSettings({ commit }) {
    localStorage.removeItem('generalSettingLogo')
    localStorage.removeItem('generalSettingFavicon')
    localStorage.removeItem('generalSettingInstituteName')
    localStorage.removeItem('generalSettingSiteTitle')
    commit('GS_SET_LOGO', null)
    commit('GS_SET_FAVICON', '/faviconLogo.png')
    commit('GS_SET_INSTITUTE_NAME', null)
    commit('GS_SET_SITE_TITLE', 'Qampus')
  },
  async getContactSettings({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.getContactSettings(payload, commit)
    if (!error) {
      commit('SET_CONTACT_SETTINGS', response.data)
    } else {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
      commit('SET_CONTACT_SETTINGS', [])
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },
  async addNewContactSetting({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.addAndUpdateContactSetting(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
      commit('SET_CONTACT_SETTINGS', [])
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },
  async updateContactSetting({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.addAndUpdateContactSetting(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
      commit('SET_CONTACT_SETTINGS', [])
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },
  async deleteContactSetting({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.deleteContactSetting(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
      commit('SET_CONTACT_SETTINGS', [])
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },
  async getBillingSetting({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.getBillingSetting(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    } else {
      commit('SET_BILLING_SETTINGS', response.data)
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },
  async setOrUpdateBillingSetting({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.setOrUpdateBillingSetting(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    } else {
      commit(
        'toast/NEW',
        { message: 'settings saved successfully', type: 'success' },
        { root: true },
      )
      commit('SET_BILLING_SETTINGS', response.data)
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },
  loadSiteSettings({ commit }, payload) {
    let settingObj = objectDeepCopy(payload)
    for (let key in settingObj) {
      settingObj[key] = siteSettingParser(key, settingObj[key])
    }
    localStorage.setItem('setting', JSON.stringify(settingObj))
    commit('SET_SITE_SETTINGS', settingObj)
  },
  async postSiteSettings({ commit, dispatch }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.postSiteSettings(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    } else {
      commit(
        'toast/NEW',
        { message: 'settings saved successfully', type: 'success' },
        { root: true },
      )
      dispatch('loadSiteSettings', response.data)
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async getPublicSettings({ commit, dispatch }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.getPublicSettings(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    } else {
      dispatch('initGeneralSetting', {
        logo: response.data.image,
        favicon: response.data.favicon,
        site_title: response.data.title || DOMAIN_TITLE,
        reset: true,
      })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  // Get Default Settins
  getGeneralSettings({ commit }) {
    return new Promise((resolve, reject) => {
      SettingsService.getGeneralSettings()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  /*EXAM PLANNER SETTINGS */

  async getExamPlannerSettings({ commit, dispatch }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.getExamPlannerSettings(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },
  async updateExamPlannerSettings({ commit, dispatch }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.updateExamPlannerSettings(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    } else {
      commit(
        'toast/NEW',
        { message: 'Settings Updated successfully', type: 'success' },
        { root: true },
      )
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  /*TEACHER SETTINGS */

  async getTeacherSettings({ commit, dispatch }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.getTeacherSettings(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },
  async updateTeacherSettings({ commit, dispatch }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.updateTeacherSettings(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    } else {
      commit(
        'toast/NEW',
        { message: 'Settings Updated successfully', type: 'success' },
        { root: true },
      )
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  /* CLASS SETTINGS */
  async getClassSettings({ commit, dispatch }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.getClassSettings(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },
  async updateClassSettings({ commit, dispatch }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.updateClassSettings(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    } else {
      commit(
        'toast/NEW',
        { message: 'Settings Updated successfully', type: 'success' },
        { root: true },
      )
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  /* TIMETABLE SETTINGS */

  async getTimeTableSettings({ commit, dispatch }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.getTimeTableSettings(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },
  async updateTimeTableSettings({ commit, dispatch }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.updateTimeTableSettings(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    } else {
      commit(
        'toast/NEW',
        { message: 'Settings Updated successfully', type: 'success' },
        { root: true },
      )
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  /* SECTION SETTINGS */

  async getSectionSettings({ commit, dispatch }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.getSectionSettings(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },
  /* STAFF SETTINGS */

  async getStaffSettings({ commit, dispatch }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.getStaffSettings(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },
  async updateSectionSettings({ commit, dispatch }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.updateSectionSettings(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    } else {
      commit(
        'toast/NEW',
        { message: 'Settings Updated successfully', type: 'success' },
        { root: true },
      )
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async updateStaffSettings({ commit, dispatch }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.updateStaffSettings(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    } else {
      commit(
        'toast/NEW',
        { message: 'Settings Updated successfully', type: 'success' },
        { root: true },
      )
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  /* ATTANDANCE SETTINGS */

  async getCampusAttandaceSettings({ commit, dispatch }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.getCampusAttandaceSettings(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async updateCampusAttandaceSettings({ commit, dispatch }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.updateCampusAttandaceSettings(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    } else {
      commit(
        'toast/NEW',
        { message: 'Settings Updated successfully', type: 'success' },
        { root: true },
      )
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async getSectionAttandaceSettings({ commit, dispatch }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.getSectionAttandaceSettings(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async updateSectionAttandaceSettings({ commit, dispatch }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.updateSectionAttandaceSettings(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    } else {
      commit(
        'toast/NEW',
        { message: 'Settings Updated successfully', type: 'success' },
        { root: true },
      )
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async getPeriodAttandaceSettings({ commit, dispatch }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.getPeriodAttandaceSettings(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },
  async getSyllabusTemplateSettings({ commit, dispatch }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.getSyllabusTemplateSettings(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async updatePeriodAttandaceSettings({ commit, dispatch }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.updatePeriodAttandaceSettings(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    } else {
      commit(
        'toast/NEW',
        { message: 'Settings Updated successfully', type: 'success' },
        { root: true },
      )
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },
  async updateSyllabusTemplateSettings({ commit, dispatch }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.updateSyllabusTemplateSettings(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    } else {
      commit(
        'toast/NEW',
        { message: 'Settings Updated successfully', type: 'success' },
        { root: true },
      )
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  /* FEE_TYPES SETTINGS */
  async getFeePermissionSettings({ commit, dispatch }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.getFeePermissionSettings(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async updateFeePermissionSettings({ commit, dispatch }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.updateFeePermissionSettings(payload, commit)

    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    } else {
      commit(
        'toast/NEW',
        { message: 'Settings Updated successfully', type: 'success' },
        { root: true },
      )
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  /* SYLLABUS PERMISSION SETTINGS */
  async getSyllabusPermissionSettings({ commit, dispatch }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.getSyllabusPermissionSettings(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async updateSyllabusPermissionSettings({ commit, dispatch }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.updateSyllabusPermissionSettings(
      payload,
      commit,
    )
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  /* SALARY PERMISSION SETTINGS */
  async getSalaryPermissionSettings({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.getSalaryPermissionSettings(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async updateSalaryPermissionSettings({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.updateSalaryPermissionSettings(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  /* SCHEDULE PERMISSION SETTINGS */
  async getSchedulePermissionSettings({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.getSchedulePermissionSettings(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async updateSchedulePermissionSettings({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.updateSchedulePermissionSettings(
      payload,
      commit,
    )
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  /* LESSON PLANNER PERMISSION SETTINGS */
  async getLessonPlannerPermissionSettings({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.getLessonPlannerPermissionSettings(
      payload,
      commit,
    )
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async updateLessonPlannerPermissionSettings({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.updateLessonPlannerPermissionSettings(
      payload,
      commit,
    )
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  /* Exam PERMISSION SETTINGS */
  async getExamPermissionSettings({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.getExamPermissionSettings(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async updateExamPermissionSettings({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.updateExamPermissionSettings(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  /* PICKUP PERMISSION SETTINGS */
  async getPickupPermissionSettings({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await SettingsService.getPickupPermissionSettings(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async updatePickupPermissionSettings({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })

    const [response, error] = await SettingsService.updatePickupPermissionSettings(payload, commit)

    if (error) commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })

    return [response, error]
  },

  getPickupSettings({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return SettingsService.getPickupSettings(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  setPickcupSettings({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return SettingsService.setPickcupSettings(payload)
        .then((response) => {
          resolve(response.data)
          commit(
            'toast/NEW',
            { message: 'Settings has saved successfully', type: 'success' },
            { root: true },
          )
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  updateFeaturesGeneralSettings({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return SettingsService.updateFeaturesGeneralSettings(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  getFeaturesGeneralSettings({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return SettingsService.getFeaturesGeneralSettings(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
}
