import { TENNAT_API_URL } from '@services/.env'
import { by, get, del, getById } from '@services/custom.service'
import { BaseService } from '@services/base.service'

export class ExamService extends BaseService {
  static getExamsList(payload, commit) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}exam_schedules/filters`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  static publishedExamSchedule(id, commit) {
    return by(`${TENNAT_API_URL}exam_schedules/publish_exam_schedule`, {}, id)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
  }
  static getExamsSubjectsList(payload, commit) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}subject_exam_schedules/filter`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          const responseError = this.errorWrapper(error, message)
          reject(responseError)
        })
        .finally(() => {
          commit('layout/IS_LOADING', false, { root: true })
        })
    })
  }
  static getExamDetails(id, commit) {
    return getById(`${TENNAT_API_URL}exam_schedules`, id)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
  }

  static createExam({ exam, id }, commit) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}exam_schedules`, exam, id)
        .then((response) => {
          let message = id ? 'Exams update successfully' : 'Exams created successfully'
          commit('toast/NEW', { message: message, type: 'success' }, { root: true })
          resolve(response)
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          const responseError = this.errorWrapper(error, message)
          reject(responseError)
        })
    })
  }
  static createExamSubject({ subject, id }, commit) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}subject_exam_schedules`, subject, id)
        .then((response) => {
          let message = id
            ? 'Exams Subject update successfully'
            : 'Exams Subject created successfully'
          commit('toast/NEW', { message: message, type: 'success' }, { root: true })
          resolve(response)
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          const responseError = this.errorWrapper(error, message)
          reject(responseError)
        })
    })
  }

  static deleteExam(data, commit) {
    return del(`${TENNAT_API_URL}exam_schedules/deactivate`, data)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'Exams deleted successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }
  static deleteExamSubject(data, commit) {
    return del(`${TENNAT_API_URL}/subject_exam_schedules/deactivate`, data)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'Subject exam deleted successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static getSubjectAndExamTypes(commit, { subjectPayload, examPayload }) {
    return Promise.all([
      by(`${TENNAT_API_URL}exam_types/filter`, examPayload),
      by(`${TENNAT_API_URL}subjects/filter`, subjectPayload),
    ])
      .then((response) => {
        return response.map((res) => {
          return this.responseWrapper(res, res?.data)
        })
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        throw new Error(responseError)
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static getExamTypes(commit, payload) {
    return by(`${TENNAT_API_URL}exam_types/filter`, payload)
      .then((response) => {
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static createAndUpdateExamType({ exam, id }, commit) {
    return by(`${TENNAT_API_URL}exam_types`, exam, id)
      .then((response) => {
        let message = id ? 'Exam Type updated successfully' : 'Exam Type created successfully'
        commit('toast/NEW', { message: message, type: 'success' }, { root: true })
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static deleteExamType(data, commit) {
    return del(`${TENNAT_API_URL}exam_types/deactivate`, data)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'Exam Type deleted successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static getDataForAddEditSubjectExam(data, commit) {
    return Promise.all([
      get(`${TENNAT_API_URL}exam_schedules/${data.examId}`),
      get(`${TENNAT_API_URL}staffs`),
      by(`${TENNAT_API_URL}subjects/section_subjects`),
      get(`${TENNAT_API_URL}rooms`),
    ])
      .then((response) =>
        response.map((res) => {
          return this.responseWrapper(res, res?.data)
        }),
      )
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        throw new Error(responseError)
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static getSubjectExam(commit, payload) {
    return by(`${TENNAT_API_URL}subject_exam_schedules/filters`, payload)
      .then((response) => {
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static createSubjectExam(data, commit) {
    return by(`${TENNAT_API_URL}subject_exam_schedules`, data)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'Subject Exam created successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static updateSubjectExam(data, commit) {
    return by(`${TENNAT_API_URL}subject_exam_schedules`, data, data.id)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'Subject Exam updated successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static deleteSubjectExam(data, commit) {
    return del(`${TENNAT_API_URL}subject_exam_schedules/deactivate`, data)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'Subject Exam deleted successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static markCompleteSubjectExam(data, commit) {
    return by(`${TENNAT_API_URL}subject_exam_schedules/update_status_conducted`, {}, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static markCanceledSubjectExam(data, commit) {
    return by(`${TENNAT_API_URL}subject_exam_schedules/update_status_cancelled`, {}, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static getSubjectExamResult(data, commit) {
    return by(`${TENNAT_API_URL}subject_exam_schedule_marks/filter`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }
  static getPendingStudentList(data, commit) {
    return by(`${TENNAT_API_URL}subject_exam_schedule_marks/filter_pending_students`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }
  static getSectionSubjectResultList(data, commit) {
    return by(`${TENNAT_API_URL}subject_results/filter`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
  }
  static getGeneratedSubjectResultList(data, commit) {
    return by(`${TENNAT_API_URL}subject_results/generated_subject_results`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
  }
  static addSubjectResult({ structure, id }, commit) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}subject_results`, structure, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }
  static updateSubjectResultWeightage({ result, id }, commit) {
    return by(`${TENNAT_API_URL}subject_exam_schedule_weightages`, result, id)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
  }

  static deleteSubjectExamResult(id, commit) {
    return del(`${TENNAT_API_URL}subject_results/deactivate`, id)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }
  static getSectionSubjectResultList(data, commit) {
    return by(`${TENNAT_API_URL}subject_results/filter`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
  }
  static getSectionResultList(data, commit) {
    return by(`${TENNAT_API_URL}section_results/filter`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
  }

  static addAndEditSectiontResult({ exam, id }, commit) {
    return by(`${TENNAT_API_URL}section_results`, exam, id)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
  }
  static updateSubjectResultWeightage({ result, id }, commit) {
    return by(`${TENNAT_API_URL}subject_exam_schedule_weightages`, result, id)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
  }
  static publishedSubjectResult(id, commit) {
    return by(`${TENNAT_API_URL}subject_results/publish_subject_result`, {}, id)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
  }
  static publishedSectionResult(id, commit) {
    return by(`${TENNAT_API_URL}section_results/publish_section_result`, {}, id)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
  }
  static deleteSubjectExamResult(id, commit) {
    return del(`${TENNAT_API_URL}subject_results/deactivate`, id)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static deleteSubjectExamResult(id, commit) {
    return del(`${TENNAT_API_URL}subject_results/deactivate`, id)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static deleteSectionExamResult(id, commit) {
    return del(`${TENNAT_API_URL}section_results/deactivate`, id)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
  }

  static getSubjectExamStudents(data, commit) {
    return by(`${TENNAT_API_URL}section_enrollments/section_students`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static submitSingleResultOfSubjectExam(data, commit) {
    return by(`${TENNAT_API_URL}subject_exam_results`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static updateSingleResultOfSubjectExam({ data, id }, commit) {
    return by(`${TENNAT_API_URL}subject_exam_schedule_marks`, data, id)
      .then((response) => {
        commit('toast/NEW', { message: 'Update successfully', type: 'success' }, { root: true })
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static deleteSingleResultOfSubjectExam(data, commit) {
    return del(`${TENNAT_API_URL}subject_exam_schedule_marks/deactivate`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static submitBulkResultOfSubjectExam({ payload, id }, commit) {
    return by(`${TENNAT_API_URL}subject_exam_schedule_marks/create_marks_in_bulk`, payload, id)
      .then((response) => {
        commit('toast/NEW', { message: 'Add mark successfully', type: 'success' }, { root: true })
        return [this.responseWrapper(response, 0?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static examResult(data, commit) {
    return get(`${TENNAT_API_URL}exam_schedule_summary`, { params: data })
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static singleStudentExamResult(id, commit) {
    return getById(`${TENNAT_API_URL}section_results/student_section_result`, id)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  // Non Grading Structure

  static getNonGPAStructureList(data, commit) {
    return by(`${TENNAT_API_URL}non_gpa_gradings/filter`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
  }

  static getNonGPAStructureDetails(id, commit) {
    return getById(`${TENNAT_API_URL}non_gpa_gradings`, id)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
  }
  static getSubjectExamDetails(id, commit) {
    return getById(`${TENNAT_API_URL}subject_results`, id)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
  }
  static getSectionExamDetails(id, commit) {
    return getById(`${TENNAT_API_URL}section_results`, id)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
  }
  static generateSubjectResult(id, commit) {
    return by(`${TENNAT_API_URL}subject_results/generate_subject_result`, {}, id)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
  }
  static reGenerateSubjectResult(id, commit) {
    return by(`${TENNAT_API_URL}subject_results/regenerate_subject_result`, {}, id)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
  }
  static viewSubjectExamGeneratedResult(commit, payload) {
    return by(`${TENNAT_API_URL}subject_results/view_subject_result`, payload)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
  }
  static viewSectionGeneratedResult(commit, payload) {
    return by(`${TENNAT_API_URL}section_results/view_section_result`, payload)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
  }

  static generateSectionResult(id, commit) {
    return by(`${TENNAT_API_URL}section_results/generate_section_result`, {}, id)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
  }
  static reGenerateSectionResult(id, commit) {
    return by(`${TENNAT_API_URL}section_results/regenerate_section_result`, {}, id)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
  }
  static addNonGPAGradingStructure({ structure, id }, commit) {
    return by(`${TENNAT_API_URL}non_gpa_gradings`, structure, id)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
  }
  static deleteNonGPAGradingStructure(id, commit) {
    return del(`${TENNAT_API_URL}/non_gpa_gradings/deactivate`, id)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'Grading Structure deleted successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
  }
  // Grading Structure

  static getGPAStructureList(data, commit) {
    return by(`${TENNAT_API_URL}gpa_gradings/filter`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
  }

  static getGPAStructureDetails(id, commit) {
    return getById(`${TENNAT_API_URL}gpa_gradings`, id)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
  }

  static addGPAGradingStructure({ structure, id }, commit) {
    return by(`${TENNAT_API_URL}gpa_gradings`, structure, id)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
  }
  static deleteGPAGradingStructure(id, commit) {
    return del(`${TENNAT_API_URL}/gpa_gradings/deactivate`, id)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'Grading Structure deleted successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
  }
}
