import { TENNAT_API_URL } from '@services/.env'
import { BaseService } from './base.service'
import { by } from './custom.service'

export class RegistrationService extends BaseService {
  static generateForm(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}tenant_registration_step_data_forms`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
}
