import { objectDeepCopy } from '@src/utils/generalUtil'

export default {
  //
  GS_SET_LOGO(state, payload) {
    state.generalSettingLogoURL = payload
  },
  GS_SET_FAVICON(state, payload) {
    state.generalSettingFaviconURL = payload
    let HtmlFavIcon = document.querySelector("link[rel~='icon']")
    if (payload) HtmlFavIcon.href = payload
  },
  GS_SET_INSTITUTE_NAME(state, payload) {
    state.generalSettingInstituteName = payload
  },
  GS_SET_SITE_TITLE(state, payload) {
    state.generalSettingSiteTitle = payload
    document.title = payload
  },
  SET_CONTACT_SETTINGS(state, payload) {
    state.contactSettings = payload
  },
  SET_BILLING_SETTINGS(state, payload) {
    state.billingSetting = payload
  },
  SET_SITE_SETTINGS(state, payload) {
    state.siteSetting = objectDeepCopy(payload)
  },
}
