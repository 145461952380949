<template>
  <div
    class="bar relative w-4 md:w-4 lg:w-7 ml-2 rounded-sm-screen md:rounded-t-md"
    :style="barStyle"
    @click="hover = true"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div v-if="hover" class="absolute tooltip-position-wrapper">
      <div>
        <div
          class="tooltip z-10 w-max h-10 overflow-hidden px-1 py-1 bg-white gap-2.5 items-center rounded-md border border-text-color-info font-roboto flip-tooltip"
        >
          <div class="gap-1 flip-tooltip ltr:order-2 rtl:order-1">
            <div class="font-bold text-xxs md:text-xs text-text-color ltr:text-left rtl:text-right">
              {{ tooltipTitle }}
            </div>
            <div class="font-bold text-xxs md:text-xs text-text-color ltr:text-left rtl:text-right">
              {{ tooltipInfo }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiBarGraph',
  props: {
    color: { default: '#eee', type: String },
    value: { default: 0, type: Number },
    height: { default: 0, type: Number },
    tooltip: { default: '', type: String },
    info: { default: '', type: String },
  },
  data() {
    return { hover: false }
  },
  computed: {
    /*
     * Sets the style properties for the bar chart bars, according to the data properties passed in from the parent component.
     * @param {void} Nothing.
     * @retuns {object} object - containing style properties for bar chart bars.
     */
    barStyle() {
      return {
        'background-color': this.color,
        height: `${this.height}%`,
      }
    },
    /*
     * Sets the tool tip description for the bar chart bars, according to the data properties passed in from the parent component.
     * @param {void} Nothing.
     * @retuns {string} string - containing the description for each bar in bar chart.
     */
    tooltipTitle() {
      return `${this.tooltip}: ${this.value}`
    },
    tooltipInfo() {
      return this.info
    },
  },
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 450px) {
  .rounded-sm-screen {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}
.tooltip {
  &::before {
    position: absolute;
    top: 35px;
    left: 29px;
    width: 10px;
    height: 10px;
    content: '';
    background: var(--bg-white);
    border-top: 1px solid var(--text-color-info);
    border-left: 1px solid var(--text-color-info);
    transform: rotate(225deg);
  }
  &-right {
    &::before {
      right: 30px;
    }
  }
  &-left {
    &::before {
      left: 30px;
    }
  }
}
.tooltip-position-wrapper {
  @media screen and (max-width: 450px) {
    left: -28px;
  }

  top: -49px;
  left: -22px;
  z-index: 1;
  min-width: 80px;
}

body[dir='rtl'] {
  .tooltip-position-wrapper {
    right: -15px;
    @media screen and (max-width: 450px) {
      right: -20px;
    }
  }
  .tooltip {
    &::before {
      top: 35px;
      left: 49px;
    }
  }
  // .flip-tooltip {
  //   -ms-filter: fliph; /* IE */
  //   filter: fliph; /* IE */
  //   -moz-transform: scaleX(-1);
  //   -webkit-transform: scaleX(-1);
  //   -o-transform: scaleX(-1);
  //   transform: scaleX(-1);
  // }
}
</style>

<style lang="scss" module>
.toolTip {
  top: 30px;
  right: 0;
  z-index: 5;
}
</style>
