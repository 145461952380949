<template>
  <div class="lg:w-[170px] lg:h-[67px] w-[130px] sm:h-[56px] h-[46px] xs:w-[94px]">
    <UiSingleSelect
      v-model="currentRole"
      title="ROLE"
      :hide-un-select-icon="true"
      select-box-classes=" lg:h-11 sm:h-9 h-7"
      container-classes="min-w-[130] md:min-w-[130] xs:min-w-[94px]"
      input-value-classes="text-xxs sm:text-xs md:text-xs lg:text-sm"
      label="title"
      drop-down-container="w-full text-xxs sm:text-xs md:text-xs lg:text-sm sm:top-11 top-8"
      title-classes="pl-3 sm:pl-0 lg:text-sm sm:text-xxs text-[9px]"
      reduce="value"
      :options="filteredRoles"
      class="flex-1"
      @change="switchRoles(currentRole)"
    />
  </div>
</template>

<script>
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import { ROLES_LIST, TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import { mapGetters, mapActions, mapState } from 'vuex'
import ROUTE from '@/src/constants/route-constants.js'
import { getDefaultRoute } from '@utils/generalUtil'

export default {
  components: {
    UiSingleSelect,
  },
  data() {
    return {
      ROUTE,
      currentRole: '',
      isClassSectionNullFor: [TENANT_ROLES.CAMPUS_ADMIN],
    }
  },
  computed: {
    ...mapGetters('auth', ['userRoles']),
    ...mapState({
      activeRole: (state) => state.layout.activeRole,
      campuses: (state) => state.layout.campuses,
    }),

    filteredRoles() {
      return ROLES_LIST.filter((role) => {
        return this.userRoles.some((user) => user.role_id === role.value)
      })
    },
    isSuperAdmin() {
      return this.activeRole === TENANT_ROLES.SUPER_ADMIN
    },
    isPickupPerson() {
      return this.activeRole === TENANT_ROLES.PICKUP_PERSON
    },
  },
  mounted() {
    this.currentRole = this.activeRole
  },
  methods: {
    /**
     * Switch Roles
     * @param {number} id - role_id
     * @returns {void}
     * @description: Function is getting ID and switch the role for example: section_teacher and super_admin
     * and providing relevant access according to the role.
     */

    async switchRoles(role) {
      if (this.activeRole === role) return false
      this.setRoles(role)

      this.$store.commit('layout/SET_APP_LOADING', true)

      let selectedCampus = ''
      let selectedClass = ''
      let selectedSection = ''
      let campusList = []
      let classList = []
      let sectionList = []

      if (this.isPickupPerson) {
        this.redirectToAuthenticPage(this.selectedCampus, this.selectedClass, this.selectedSection)
        return
      }

      const campusListReponse = await this.getCampusList()
      campusList = campusListReponse?.data || []

      if (this.isSuperAdmin) {
        this.redirectToAuthenticPage(selectedCampus, selectedClass, selectedSection)
        this.$store.commit('layout/SET_CAMPUSES', campusList)
        return
      }

      selectedCampus = campusList.length ? campusList[0] : ''
      localStorage.setItem('currentCampusScope', JSON.stringify(selectedCampus))

      if (selectedCampus && !this.isClassSectionNullFor.includes(this.activeRole)) {
        classList = await this.getUserClasses()
        selectedClass = classList.length ? classList[0] : ''
        localStorage.setItem('currentClassScope', JSON.stringify(selectedClass))
      }

      if (selectedClass && !this.isClassSectionNullFor.includes(this.activeRole)) {
        const sectionListResponse = await this.getUserSpecificSectionsList()
        sectionList = sectionListResponse?.data || []
        selectedSection = sectionList.length ? sectionList[0] : ''
      }

      this.redirectToAuthenticPage(selectedCampus, selectedClass, selectedSection)

      this.$store.commit('layout/SET_CAMPUSES', campusList)
      this.$store.commit('classes/SET_USERS_CLASS_LIST', classList)
      this.$store.commit('section/SET_CLASS_SECTIONS', sectionList)
    },

    redirectToAuthenticPage(selectedCampus = '', selectedClass = '', selectedSection = '') {
      const activeRole = this.$store.state.layout.activeRole
      const route = getDefaultRoute(activeRole, selectedCampus, selectedSection)
      this.$router?.push({ name: route })

      setTimeout(() => {
        this.$store.commit('layout/SET_INSTITUTES_HIERARCHY', {
          selectedCampus,
          selectedClass,
          selectedSection,
        })
        this.$store.commit('layout/SET_APP_LOADING', false)
      }, 1500)
    },
    ...mapActions('layout', ['setRoles', 'getUserSpecificSectionsList', 'getCampusList']),
    ...mapActions('classes', ['getUserClasses']),
  },
}
</script>
