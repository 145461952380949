import { SCOPE_LEVELS } from '@src/constants/general-constants'
import ROUTE from '@/src/constants/route-constants.js'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import store from '@src/state/store'
const userRole = store?.getters['layout/userRole']

export default [
  {
    id: '1',
    name: 'My Profile',
    route: { name: 'my-profile' },
    routesName: ['my-profile'],
    role: [
      TENANT_ROLES.SUPER_ADMIN,
      TENANT_ROLES.SECTION_TEACHER,
      TENANT_ROLES.SECTION_STUDENT,
      TENANT_ROLES.GUARDIAN,
      TENANT_ROLES.CAMPUS_ADMIN,
      TENANT_ROLES.STAFF,
      TENANT_ROLES.PICKUP_PERSON,
    ],
    icon: 'users',
  },
  {
    id: '2',
    name: 'Dashboard',
    route: { name: ROUTE.dashboard.name },
    routesName: [ROUTE.dashboard.name],
    role: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN],
    icon: 'dashboard',
  },
  {
    id: '3',
    name: 'LB_QA',
    icon: 'dashboard',
    route: { name: 'quick-actions' },
    role: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN],
    basedOn: [
      SCOPE_LEVELS.ROOT_LEVEL,
      SCOPE_LEVELS.CAMPUS_LEVEL,
      SCOPE_LEVELS.CLASS_LEVEL,
      SCOPE_LEVELS.SECTION_LEVEL,
    ],
  },
  {
    id: '4',
    icon: 'attendance',
    name: 'ATTENDANCE',
    role: [
      TENANT_ROLES.SUPER_ADMIN,
      TENANT_ROLES.SECTION_TEACHER,
      TENANT_ROLES.SECTION_STUDENT,
      TENANT_ROLES.GUARDIAN,
      TENANT_ROLES.CAMPUS_ADMIN,
    ],
    showChilds: false,
    basedOn: [SCOPE_LEVELS.CAMPUS_LEVEL, SCOPE_LEVELS.CLASS_LEVEL, SCOPE_LEVELS.SECTION_LEVEL],
    routesName: [
      'student-section-attendance-detail',
      'mark-student-section-attendance',
      'student-campus-attendance',
      'student-section-attendance',
      'students-period-attendance-details',
      'students-period-attendance',
      'teacherCheckInCheckOut',
      'studentBulkCheckIn',
      'staffCheckInOut',
      'staffBulkCheckIn',
      'staffBulkCheckOut',
      'teacherCheckInOut',
    ],
    childs: [
      {
        name: 'CAMPUS_STUDENT_ATTENDANCE',
        route: { name: 'student-campus-attendance' },
        basedOn: [SCOPE_LEVELS.CAMPUS_LEVEL, SCOPE_LEVELS.CLASS_LEVEL, SCOPE_LEVELS.SECTION_LEVEL],
      },
      {
        name: 'PERIOD_STUDENT_ATTENDANCE',
        route: { name: 'students-period-attendance' },
        basedOn: [SCOPE_LEVELS.SECTION_LEVEL],
      },
      {
        name: 'STUDENT_SECTION_ATTENDANCE',
        route: { name: 'student-section-attendance' },
        basedOn: [SCOPE_LEVELS.CLASS_LEVEL, SCOPE_LEVELS.SECTION_LEVEL],
      },
      {
        name: 'SECTION_STUDENT_ATTENDANCE_DETAIL',
        route: { name: 'student-section-attendance-detail' },
        isDynamicRoute: true,
        basedOn: [SCOPE_LEVELS.SECTION_LEVEL],
      },
      {
        name: 'MARK_SECTION_STUENT_ATTENDANCE',
        route: { name: 'mark-student-section-attendance' },
        isDynamicRoute: true,
        basedOn: [SCOPE_LEVELS.SECTION_LEVEL],
      },
    ],
  },
  {
    id: '9',
    name: 'Time Table',
    icon: 'timeTable',
    basedOn: [SCOPE_LEVELS.SECTION_LEVEL],
    role: [
      TENANT_ROLES.SUPER_ADMIN,
      TENANT_ROLES.SECTION_TEACHER,
      TENANT_ROLES.SECTION_STUDENT,
      TENANT_ROLES.GUARDIAN,
      TENANT_ROLES.CAMPUS_ADMIN,
    ],
    showChilds: false,
    routesName: ['time-table-automator', 'current-time-table'],
    childs: [
      {
        name: 'TIME_TABLE_AUTOMATOR',
        route: { name: 'time-table-automator' },
        role: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.SECTION_TEACHER, TENANT_ROLES.CAMPUS_ADMIN],
        basedOn: [SCOPE_LEVELS.SECTION_LEVEL],
      },
      {
        name: 'CURRENT_TIME_TABLE',
        route: { name: 'current-time-table' },
        role: [
          TENANT_ROLES.SUPER_ADMIN,
          TENANT_ROLES.SECTION_TEACHER,
          TENANT_ROLES.SECTION_STUDENT,
          TENANT_ROLES.GUARDIAN,
          TENANT_ROLES.CAMPUS_ADMIN,
        ],
        basedOn: [SCOPE_LEVELS.SECTION_LEVEL],
      },
    ],
  },
  {
    id: '11',
    icon: 'dashboard',
    name: 'Campuses',
    route: { name: 'campuses' },
    role: TENANT_ROLES.SUPER_ADMIN,
    basedOn: [SCOPE_LEVELS.ROOT_LEVEL],
  },
  {
    id: '13',
    icon: 'users',
    name: 'USERS',
    showChilds: false,
    role: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN, TENANT_ROLES.SECTION_TEACHER],
    basedOn: [
      SCOPE_LEVELS.ROOT_LEVEL,
      SCOPE_LEVELS.CAMPUS_LEVEL,
      SCOPE_LEVELS.SECTION_LEVEL,
      SCOPE_LEVELS.CLASS_LEVEL,
    ],
    routesName: [
      'staff',
      'teachers',
      'super-admin',
      'campus-admin',
      'guardians',
      'students',
      'all-users',
      'pickup-person',
    ],
    childs: [
      {
        name: 'SUPER_ADMIN',
        route: { name: 'super-admin' },
        role: [TENANT_ROLES.SUPER_ADMIN],
        basedOn: [SCOPE_LEVELS.ROOT_LEVEL],
      },
      {
        name: 'CAMPUS_ADMIN',
        route: { name: 'campus-admin' },
        role: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN],
        basedOn: [SCOPE_LEVELS.ROOT_LEVEL, SCOPE_LEVELS.CAMPUS_LEVEL],
      },
      {
        name: 'Staff',
        route: { name: TENANT_ROLES.STAFF },
        role: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN],
        basedOn: [SCOPE_LEVELS.ROOT_LEVEL, SCOPE_LEVELS.CAMPUS_LEVEL],
      },
      {
        name: 'Teachers',
        route: { name: 'teachers' },
        role: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN],
        basedOn: [
          SCOPE_LEVELS.ROOT_LEVEL,
          SCOPE_LEVELS.CAMPUS_LEVEL,
          SCOPE_LEVELS.SECTION_LEVEL,
          SCOPE_LEVELS.CLASS_LEVEL,
        ],
      },
      {
        name: 'Students',
        route: { name: 'students' },
        role: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN],
        routesName: ['students-roles-and-acccess-management'],
        basedOn: [
          SCOPE_LEVELS.ROOT_LEVEL,
          SCOPE_LEVELS.CAMPUS_LEVEL,
          SCOPE_LEVELS.SECTION_LEVEL,
          SCOPE_LEVELS.CLASS_LEVEL,
        ],
      },
      {
        name: 'Students',
        icon: 'users',
        route: { name: 'students' },
        role: [TENANT_ROLES.SECTION_TEACHER],
        basedOn: [SCOPE_LEVELS.SECTION_LEVEL],
      },
      {
        name: 'RV_LB_G',
        route: { name: 'guardians' },
        role: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN],
        basedOn: [
          SCOPE_LEVELS.ROOT_LEVEL,
          SCOPE_LEVELS.CAMPUS_LEVEL,
          SCOPE_LEVELS.SECTION_LEVEL,
          SCOPE_LEVELS.CLASS_LEVEL,
        ],
      },
      {
        role: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN],
        basedOn: [SCOPE_LEVELS.ROOT_LEVEL, SCOPE_LEVELS.CAMPUS_LEVEL],
        name: 'PICKUP_MEMBERS',
        route: { name: 'pickup-person' },
      },
      {
        name: 'All Users',
        route: { name: 'all-users' },
        role: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN],
        basedOn: [SCOPE_LEVELS.ROOT_LEVEL, SCOPE_LEVELS.CAMPUS_LEVEL],
      },
    ],
  },

  {
    id: '14',
    icon: 'dashboard',
    name: 'Classes',
    route: { name: 'classes' },
    role: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN],
    basedOn: [SCOPE_LEVELS.CAMPUS_LEVEL, SCOPE_LEVELS.ROOT_LEVEL],
  },
  {
    id: '16',
    name: 'RV_LB_T',
    icon: 'dashboard',
    route: { name: 'rooms' },
    role: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN],
    basedOn: [SCOPE_LEVELS.CAMPUS_LEVEL],
  },
  {
    id: '17',
    name: 'Sections',
    icon: 'dashboard',
    route: { name: 'sections' },
    role: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN],
    basedOn: [SCOPE_LEVELS.CAMPUS_LEVEL, SCOPE_LEVELS.CLASS_LEVEL],
  },

  {
    id: '23',
    icon: 'expenseManager',
    name: 'Finance',
    route: { name: 'salaries' },
    role: TENANT_ROLES.SUPER_ADMIN,
    basedOn: [SCOPE_LEVELS.SECTION_LEVEL],
  },
  {
    id: '24',
    icon: 'lessonPlanner',
    role: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN],
    basedOn: [SCOPE_LEVELS.ROOT_LEVEL],
    name: 'LTV_RB_H',
    route: { name: 'lesson-types' },
  },

  {
    id: '26',
    icon: 'lessonPlanner',
    name: 'Syllabus Manager',
    role: [
      TENANT_ROLES.SUPER_ADMIN,
      TENANT_ROLES.SECTION_TEACHER,
      TENANT_ROLES.SECTION_STUDENT,
      TENANT_ROLES.GUARDIAN,
      TENANT_ROLES.CAMPUS_ADMIN,
    ],
    basedOn: [SCOPE_LEVELS.CLASS_LEVEL, SCOPE_LEVELS.SECTION_LEVEL],
    route: { name: 'syllabus' },
    showChilds: false,
    routesName: ['lessons', 'topics', 'chapters', 'subjects', 'lesson-planner'],
    childs: [
      {
        name: 'Subjects',
        route: { name: 'subjects' },
      },
      {
        name: 'Chapters',
        route: { name: 'chapters' },
      },
      {
        name: 'Topics',
        route: { name: 'topics' },
      },
      {
        name: 'Lessons',
        route: { name: 'lessons' },
      },
      {
        name: 'LESSON_PLANNER',
        route: { name: 'lesson-planner' },
        role: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.SECTION_TEACHER, TENANT_ROLES.CAMPUS_ADMIN],
        basedOn: [SCOPE_LEVELS.SECTION_LEVEL],
      },
    ],
  },
  {
    id: '026',
    icon: 'examPlanner',
    name: 'ASSIGNMENT',
    role: [TENANT_ROLES.SUPER_ADMIN],
    basedOn: [SCOPE_LEVELS.CLASS_LEVEL, SCOPE_LEVELS.SECTION_LEVEL],
    route: { name: 'assignment' },
    showChilds: false,
    routesName: ['assignment-types', 'assignmens-view'],
    childs: [
      {
        name: 'ASSIGNMENT_TYPES',
        route: { name: 'assignment-types' },
        basedOn: [SCOPE_LEVELS.CLASS_LEVEL, SCOPE_LEVELS.SECTION_LEVEL],
      },
      {
        name: 'ASSIGNMENTS',
        route: { name: 'assignmens-view' },
        basedOn: [SCOPE_LEVELS.CLASS_LEVEL, SCOPE_LEVELS.SECTION_LEVEL],
      },
      {
        name: 'ASSIGNMENTS_DETAILS',
        route: { name: 'assignmens-detail-view' },
        basedOn: [SCOPE_LEVELS.CLASS_LEVEL, SCOPE_LEVELS.SECTION_LEVEL],
        isDynamicRoute: true,
      },
    ],
  },
  {
    id: '0026',
    icon: 'lessonPlanner',
    name: 'SYLLABUS_AUTO',
    role: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.SECTION_TEACHER, TENANT_ROLES.CAMPUS_ADMIN],
    basedOn: [SCOPE_LEVELS.ROOT_LEVEL, SCOPE_LEVELS.CAMPUS_LEVEL],
    route: { name: 'subject-syllabus-template-layout' },
    showChilds: false,
    routesName: [
      'subject-syllabus-template-course',
      'subject-syllabus-template',
      'subject-syllabus-template-import',
      'subject-syllabus-template-detail-view',
      'subject-syllabus-template-import',
    ],
    childs: [
      {
        name: 'COURSE_PROVIDER',
        route: { name: 'subject-syllabus-template-course' },
      },
      {
        name: 'LB_TEMP',
        route: { name: 'subject-syllabus-template' },
      },
      {
        name: 'TEMP_DETAIL_VIEW',
        route: { name: 'subject-syllabus-template-detail-view' },
        isDynamicRoute: true,
      },
      {
        name: 'IMPORT/CLONE',
        isDynamicRoute: true,
        route: { name: 'subject-syllabus-template-import' },
      },
    ],
  },
  {
    id: '27',
    icon: 'expenseManager',
    name: 'Fee Manager',
    role: [
      TENANT_ROLES.SUPER_ADMIN,
      TENANT_ROLES.SECTION_TEACHER,
      TENANT_ROLES.SECTION_STUDENT,
      TENANT_ROLES.GUARDIAN,
      TENANT_ROLES.CAMPUS_ADMIN,
    ],
    basedOn: [SCOPE_LEVELS.CAMPUS_LEVEL, SCOPE_LEVELS.SECTION_LEVEL, SCOPE_LEVELS.CLASS_LEVEL],
    route: { name: 'receivable' },
    showChilds: false,
    routesName: [
      'fee-types',
      'receivable',
      'payable',
      'received',
      'monthly-fee-generator',
      'fee-details',
      'section',
      'discount-types',
      'student-paid',
      'student-discounts',
      'student-discounts-detail',
      'section-fee-details',
      'accept-payment',
    ],
    childs: [
      {
        name: 'Fee Types',
        route: { name: 'fee-types' },
        role: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN],
      },
      {
        name: [TENANT_ROLES.SECTION_STUDENT, TENANT_ROLES.GUARDIAN].includes(userRole)
          ? 'Payable'
          : 'Receivable',
        route: {
          name: [TENANT_ROLES.SECTION_STUDENT, TENANT_ROLES.GUARDIAN].includes(userRole)
            ? 'payable'
            : 'receivable',
        },
      },
      {
        name: [TENANT_ROLES.SECTION_STUDENT, TENANT_ROLES.GUARDIAN].includes(userRole)
          ? 'Paid'
          : 'Received',
        route: {
          name: [TENANT_ROLES.SECTION_STUDENT, TENANT_ROLES.GUARDIAN].includes(userRole)
            ? 'student-paid'
            : 'received',
        },
      },
      {
        name: 'ACCEPT_PAYMENT',
        route: { name: 'accept-payment' },
        role: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN],
      },
      {
        name: 'Section Fee',
        route: { name: 'section' },
        role: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.SECTION_TEACHER, TENANT_ROLES.CAMPUS_ADMIN],
      },
      {
        name: 'Monthly Fee Generator',
        route: { name: 'monthly-fee-generator' },
        role: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN, TENANT_ROLES.SECTION_TEACHER],
      },

      {
        name: 'Discount Types',
        route: { name: 'discount-types' },
        role: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN],
      },
      {
        name: 'STUDENT_DISCOUNT_LIST',
        route: { name: 'student-discounts' },
        role: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN, TENANT_ROLES.SECTION_TEACHER],
      },
      {
        name: 'STUDENT_DISCOUNT_DETAIL',
        route: { name: 'student-discounts-detail' },
        isDynamicRoute: true,
      },
    ],
  },
  {
    id: '28',
    name: 'LB_EM',
    icon: 'expenseManager',
    role: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN],
    basedOn: [SCOPE_LEVELS.ROOT_LEVEL, SCOPE_LEVELS.CAMPUS_LEVEL],
    route: { name: 'expenses' },
    showChilds: false,
    routesName: ['expenses', 'categories'],
    childs: [
      {
        name: 'LB_EC',
        route: { name: 'categories' },
      },
      {
        name: 'Expenses',
        route: { name: 'expenses' },
        role: [TENANT_ROLES.SUPER_ADMIN],
      },
    ],
  },
  {
    id: '29',
    icon: 'examPlanner',
    name: 'GRAD_STRUC',
    role: [TENANT_ROLES.SUPER_ADMIN],
    route: { name: 'non-gpa-grading-structure' },
    basedOn: [
      SCOPE_LEVELS.ROOT_LEVEL,
      SCOPE_LEVELS.CAMPUS_LEVEL,
      SCOPE_LEVELS.SECTION_LEVEL,
      SCOPE_LEVELS.CLASS_LEVEL,
    ],

    showChilds: false,
    routesName: [
      'non-gpa-grading-structure',
      'non-gpa-grading-structure-detail-view',
      'gpa-grading-structure',
      'gpa-grading-structure-detail-view',
    ],
    childs: [
      {
        name: 'NON_GPA_GRADE',
        route: { name: 'non-gpa-grading-structure' },
      },
      {
        name: 'NON_GPA_GRADE_DETAIL',
        route: { name: 'non-gpa-grading-structure-detail-view' },
        isDynamicRoute: true,
      },
      {
        name: 'GPA_GRADE',
        route: { name: 'gpa-grading-structure' },
      },
      {
        name: 'GPA_GRADE_DETAIL',
        route: { name: 'gpa-grading-structure-detail-view' },
        isDynamicRoute: true,
      },
    ],
  },
  {
    id: '029',
    icon: 'examPlanner',
    name: 'Exam Planner',
    role: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.SECTION_TEACHER],
    route: { name: 'exams' },
    basedOn: [SCOPE_LEVELS.SECTION_LEVEL, SCOPE_LEVELS.CLASS_LEVEL],
    showChilds: false,
    routesName: ['exam-types', 'exams', 'exams-detail-view', 'exam-result-view'],
    childs: [
      {
        name: 'EXAM_TYPES',
        route: { name: 'exam-types' },
      },
      {
        name: 'Exam',
        route: { name: 'exams' },
      },
      {
        name: 'Exam Details',
        route: { name: 'exams-detail-view' },
        isDynamicRoute: true,
      },
      {
        name: 'Exams Result View',
        route: { name: 'exam-result-view' },
        isDynamicRoute: true,
      },
    ],
  },
  {
    id: '00029',
    icon: 'examPlanner',
    name: 'EXAM_RESULT',
    role: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.SECTION_TEACHER],
    route: { name: 'subject-result' },
    basedOn: [SCOPE_LEVELS.SECTION_LEVEL, SCOPE_LEVELS.CLASS_LEVEL],
    showChilds: false,
    routesName: [
      'subject-result',
      'subject-result-detail',
      'subject-result-view',
      'section-result',
      'section-result-detail-view',
      'section-result-view',
      'exam-student-result',
    ],
    childs: [
      {
        name: 'SUBJECT_RESULT',
        route: { name: 'subject-result' },
      },
      {
        name: 'Subject Result detail',
        route: { name: 'subject-result-detail' },
        isDynamicRoute: true,
      },
      {
        name: 'Subject Result View',
        route: { name: 'subject-result-view' },
        isDynamicRoute: true,
      },
      {
        name: 'Section Exams Result',
        route: { name: 'section-result' },
      },
      {
        name: 'Section Exams Result detail',
        route: { name: 'section-result-detail-view' },
        isDynamicRoute: true,
      },
      {
        name: 'Student Result detail',
        route: { name: 'exam-student-result' },
        isDynamicRoute: true,
      },

      {
        name: 'Section Result View',
        route: { name: 'section-result-view' },
        isDynamicRoute: true,
      },
    ],
  },
  {
    id: '31',
    icon: 'expenseManager',
    name: 'Salary Manager',
    role: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN, TENANT_ROLES.CAMPUS_ADMIN],
    basedOn: [SCOPE_LEVELS.CAMPUS_LEVEL],
    showChilds: false,
    routesName: [
      'salaries',
      'allowence-type',
      'deduction-type',
      'bonuses-type',
      'extra-working-unit',
      'salary-generator',
      'salary-template',
    ],
    childs: [
      {
        name: 'Salaries',
        route: { name: 'salaries' },
      },
      {
        name: 'Allowence Type',
        route: { name: 'allowence-type' },
      },
      {
        name: 'Deduction Type',
        route: { name: 'deduction-type' },
      },
      {
        name: 'Bonuses Type',
        route: { name: 'bonuses-type' },
      },
      {
        name: 'Extra Working Unit',
        route: { name: 'extra-working-unit' },
      },
      {
        name: 'Salary Generator',
        route: { name: 'salary-generator' },
      },
      {
        name: 'Salary Template',
        route: { name: 'salary-template' },
        isDynamicRoute: true,
      },
    ],
  },

  {
    id: '33',
    icon: 'pickup',
    role: [TENANT_ROLES.PICKUP_PERSON],
    name: 'LB_AR',
    route: { name: 'active-request' },
    basedOn: [SCOPE_LEVELS.ROOT_LEVEL, SCOPE_LEVELS.CAMPUS_LEVEL, SCOPE_LEVELS.SECTION_LEVEL],
  },
  {
    id: '35',
    icon: 'pickup',
    role: [TENANT_ROLES.PICKUP_PERSON],
    name: 'LB_ALL_R',
    route: { name: 'all-requests' },
  },
  {
    id: '36',
    name: 'LB_PF',
    icon: 'pickup',
    role: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN],
    basedOn: [SCOPE_LEVELS.CAMPUS_LEVEL],
    showChilds: false,
    routesName: ['active-request', 'all-request'],
    childs: [
      {
        name: 'LB_AR',
        route: { name: 'active-request' },
      },
      {
        name: 'LB_ALL_R',
        route: { name: 'all-requests' },
      },
    ],
  },
  {
    id: '37',
    name: 'LB_PS',
    icon: 'pickup',
    role: [
      TENANT_ROLES.SUPER_ADMIN,
      TENANT_ROLES.SECTION_TEACHER,
      TENANT_ROLES.STAFF,
      TENANT_ROLES.CAMPUS_ADMIN,
      TENANT_ROLES.CAMPUS_ADMIN,
    ],
    basedOn: [SCOPE_LEVELS.CAMPUS_LEVEL],
    showChilds: false,
    routesName: ['support-active-request', 'support-all-requests'],
    childs: [
      {
        name: 'LB_AR',
        route: { name: 'support-active-request' },
      },

      {
        name: 'LB_ALL_R',
        route: { name: 'support-all-requests' },
      },
    ],
  },
  {
    id: '38',
    name: 'NOTIFIER',
    icon: 'announcement',
    basedOn: [SCOPE_LEVELS.CAMPUS_LEVEL],
    role: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN],
    showChilds: false,
    routesName: ['announcement', 'announcement-types', 'announcement-detail'],
    childs: [
      {
        name: 'ANNOUNCEMENT_TYPES',
        route: { name: 'announcement-types' },
      },
      {
        name: 'S_NOTIFIER',
        route: { name: 'announcement' },
      },
      {
        name: 'Announcement Detail',
        route: { name: 'announcement-detail' },
        isDynamicRoute: true,
      },
    ],
  },
  {
    id: '39',
    icon: 'announcement',
    name: 'REGIS_PROC',
    basedOn: [SCOPE_LEVELS.CAMPUS_LEVEL],
    role: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN],
    showChilds: false,
    redirect: { name: 'registration' },
    routesName: ['registration-list', 'form-generator'],
    childs: [
      {
        name: 'REGIS',
        route: { name: 'registration-list' },
      },
      {
        name: 'FORM_GEN',
        route: { name: 'form-generator' },
      },
    ],
  },
]
