<template>
  <div>
    <div class="px-2 py-2 mb-3">
      <div class="text-center py-3 gap-2 flex justify-center items-center">
        <h3 class="font-roboto font-medium text-text-color text-xl mb-1">{{ graphTitle }}</h3>
      </div>
    </div>
    <div class="relative ml-1 px-6 mb-5 rlt:left">
      <div
        class="bg-graph-bar border-l border-b pb-1 border-primary-grey relative flex flex-col justify-evenly"
      >
        <div>
          <div v-for="xAxis in graphXAxes" :key="xAxis">
            <div class="graph-line -ml-5 text-xxs flex flex-row items-center relative">
              <span class="y-axis ltr:pr-2 rtl:pl-2 text-right rtl:text-left">{{ xAxis }}</span>
              <span class="line container ml-0"></span>
            </div>
          </div>
        </div>
        <span class="zero-index text-xxs absolute">0</span>
      </div>
      <div class="front-bars-wrapper bottom-0 flex absolute align-end items-baseline">
        <div v-for="graph in graphList" :key="graph.tooltip" class="h-full flex items-end pl-1">
          <UiGraphBars
            :info="graph.info"
            :height="(graph.value / getMaxLimit) * 100"
            :value="graph.value"
            :color="graph.color"
            :tooltip="graph.tooltip"
            :title="graph.tooltipTitle"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UiGraphBars from '@components/UiElements/BarGraph/UiGraphBars.vue'
import { generateXAxes } from '@/src/utils/generate-graph-coordinates.util'
export default {
  components: { UiGraphBars },
  props: {
    graphTitle: {
      type: String,
      default: '',
    },
    graphList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    getMaxLimit() {
      return this.graphList.reduce((item1, item2) => (item1.value > item2.value ? item1 : item2))
        .value
    },
    graphXAxes() {
      return generateXAxes(this.getMaxLimit, 10)
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-graph-bar {
  min-height: 170px;
  .zero-index {
    bottom: -7px;
    left: -9px;
    color: #1d273e;
  }
  .graph-line {
    .y-axis {
      min-width: 24px;
      color: #1d273e;
    }
    .line {
      border: dashed 1px #d7dbde;
      border-top: none;
    }
  }
}
.front-bars-wrapper {
  height: 95%;
  padding-right: 10px;
}
body[dir='rtl'] {
  .bg-graph-bar {
    margin-right: 10px;
    transform: scaleX(-1);
  }
  .zero-index {
    left: -10px;
    transform: scaleX(-1);
  }
  .graph-line {
    transform: scaleX(-1);
  }
  .front-bars-wrapper {
    margin-right: 10px;
  }
}
</style>
