import { TENNAT_API_URL } from '@services/.env'
import { by, del } from '@services/custom.service'
import { BaseService } from '@services/base.service'

export default class ClassesService extends BaseService {
  static addClass({ classData, id = null }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}classes`, classData, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not add Class'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static deleteClass(id) {
    return new Promise((resolve, reject) => {
      return del(`${TENNAT_API_URL}classes/soft_delete`, `${id}`)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not delete class'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getAllClasses(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}classes/filters`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not Get classes'
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static getUserClasses(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}user/classes`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not Get classes'
          reject(this.errorWrapper(error, message))
        })
    })
  }
}
