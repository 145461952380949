<template>
  <div :class="isModalOpen ? 'border-transparent' : ''">
    <section ref="customContainer" class="custom-container mx-auto">
      <div class="grid h-[inherit] md:grid-cols-16 grid-cols-none">
        <div class="col-span-16 h-[inherit] grid md:grid-cols-16 grid-cols-none">
          <div :class="[leftSpan]" class="md:h-[inherit]">
            <UiOnClickOutside :do="closeMobileLeftBar">
              <LeftBar
                :class="[$style.leftBar, { mobileLeftBar: tabLeftNav }]"
                :left-bar-visibility="tabLeftNav"
                @handleClick="handleClick"
              ></LeftBar>
            </UiOnClickOutside>
          </div>
          <div class="h-[inherit] overflow-y-hidden" :class="[mainContentGrids]">
            <AppHeader
              ref="headerContainer"
              :show-right-bar-icon="true"
              @mobileLeftBar="mobileLeftBar"
            />
            <div :class="`${centerGrid} `" class="grid" :style="gutterContentStyle">
              <div
                class="pb-8 xl:pl-6 pl-4 xl:pt-6 md:pt-5 pt-2.5"
                :class="[centerSpan, $style.mainContentGutters, centerSpanOverflow]"
                :style="mainContentStyle"
              >
                <slot />
                <router-view></router-view>
              </div>
              <div v-if="tabRightNav || rightbarHidden" class="xl:contents hidden">
                <UiOnClickOutside :do="closeMobileRightBar">
                  <RightBar
                    :class="[
                      {
                        mobileRightBar: isMobileRightBar,
                        'ltr:ml-0 rtl:mr-0': tabRightNav,
                      },
                    ]"
                    :right-bar-visibility="isMobileRightBar"
                  />
                </UiOnClickOutside>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div
      class="sidebar-overlay w-full h-full top-0 left-0 fixed z-[5000] overflow-hidden"
      :class="[showOverlay]"
    ></div>
    <RoleSwitchingSuiteModal />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import UiOnClickOutside from '@components/UiElements/UiOnClickOutside.vue'
import LeftBar from '@/src/router/layouts/left-bar/LeftBar.vue'
import RightBar from '@layouts/rightBar.vue'
import AppHeader from './feed/Header.vue'
import { getDefaultRoute } from '@utils/generalUtil'
import debounce from 'lodash/debounce'
import RoleSwitchingSuiteModal from '@src/router/views/admin/modals/RoleSwitchingSuiteModal.vue'
import DEFAULT_LAYOUT_DATA from '@/src/router/layouts/defaultLayout.json'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'

export default {
  components: {
    LeftBar,
    RightBar,
    AppHeader,
    UiOnClickOutside,
    RoleSwitchingSuiteModal,
  },
  data() {
    return {
      isTabLeftBar: window.matchMedia('(max-width: 767px)'),
      isLaptopRightBar: window.matchMedia('(max-width: 1279px)').matches,
      campusesNotAllowerdFor: ['pickup_person'],
      authenticRoute: null,
      headerOffSetHeight: 99,
    }
  },
  page: {
    title: 'Layout',
    meta: [{ name: 'description', content: 'Default Layout of MyQampus' }],
  },
  computed: {
    ...mapState({
      tabRightNav: (state) => state.layout.tabRightNav,
      tabLeftNav: (state) => state.layout.tabLeftNav,
      isModalOpen: (state) => state.layout.isModalOpen,
      currentCampusScope: (state) => state.layout.currentCampusScope,
      currentSectionScope: (state) => state.layout.currentSectionScope,
      currentClassScope: (state) => state.layout.currentClassScope,
      LeftBarClassList: (state) => state.layout.doRerenderLayout,
      activeRole: (state) => state.layout.activeRole,
      campuses: (state) => state.layout.campuses,
      leftBarContent: (state) => state.layout.leftBarContent,
      isAppLoading: (state) => state.layout.isAppLoading,
    }),
    showOverlay() {
      return this.tabLeftNav ? 'w-full block md:hidden' : 'hidden'
    },
    currentRoute() {
      return this.$route.name
    },
    centerSpanOverflow() {
      return this.isLaptopRightBar && this.tabRightNav
        ? 'overflow-y-hidden xl:pr-6 pr-4'
        : 'overflow-y-scroll pr-2 xl:pr-4'
    },
    rightbarHidden() {
      let isRightBarActive
      isRightBarActive = DEFAULT_LAYOUT_DATA.RightBarUnactive.includes(this.$route.name)
      if (!isRightBarActive)
        return (isRightBarActive = DEFAULT_LAYOUT_DATA.RightBarActive.includes(this.$route.path))
      return isRightBarActive
    },
    isMobileRightBar() {
      return this.rightbarHidden ? false : this.tabRightNav
    },
    centerGrid() {
      return !this.tabLeftNav
        ? ' grid-cols-16 md:grid-cols-[repeat(15,_minmax(0,_1fr))]'
        : 'grid-cols-13'
    },
    centerSpan() {
      let styleValue
      if ((!this.tabRightNav || this.rightbarHidden) && !this.tabLeftNav) {
        styleValue = 'col-span-16 md:col-span-15 overflow-x-auto'
      } else if (!this.tabLeftNav) {
        styleValue = 'col-span-16 md:col-span-15 xl:col-[_span_12_/_span_12]'
      } else if (!this.tabRightNav || this.rightbarHidden) {
        styleValue = 'col-span-16 md:col-span-13'
      } else {
        styleValue = 'col-span-16 md:col-span-13 xl:col-span-10 '
      }
      return styleValue
    },
    mainContentGrids() {
      let styleValue
      if ((!this.tabRightNav || this.rightbarHidden) && !this.tabLeftNav) {
        styleValue = 'md:col-span-15 col-span-16  overflow-x-auto'
      } else if (!this.tabLeftNav) {
        styleValue = 'col-span-16 md:col-span-15  overflow-x-auto'
      } else if (!this.tabRightNav || this.rightbarHidden) {
        styleValue = 'col-span-16 md:col-span-12 xl:col-span-13'
      } else {
        styleValue = 'col-span-16 md:col-span-12 xl:col-span-13'
      }
      return styleValue
    },
    leftSpan() {
      return this.tabLeftNav ? 'xl:col-span-3 md:col-span-4' : 'md:col-span-1'
    },

    isIntituteLevelChange() {
      return this.currentCampusScope?.id + this.currentSectionScope?.id + this.currentClassScope?.id
    },
    isAdmin() {
      return [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN].includes(this.activeRole)
    },
    gutterContentStyle() {
      return {
        'max-height': `calc(100% - ${this.headerOffSetHeight + 24 + 6}px)`,
      }
    },
    mainContentStyle() {
      let height = `calc(100vh - ${this.headerOffSetHeight + 34 + 6}px)`
      return {
        'max-height': height,
        'min-height': '80vh',
      }
    },
  },
  watch: {
    currentCampusScope: {
      handler(value) {
        if (!value) this.checkLevelAuthentication('root-level')
        else this.checkLevelAuthentication('campus-level')
      },
    },
    currentSectionScope: {
      handler(value) {
        if (!value) this.checkLevelAuthentication('class-level')
        else this.checkLevelAuthentication('section-level')
      },
    },
    currentClassScope: {
      handler(value) {
        if (!value) this.checkLevelAuthentication('campus-level')
        else this.checkLevelAuthentication('class-level')
      },
    },
    isIntituteLevelChange: {
      handler() {
        this.getInstituteStats()
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    const rightBarContent = {
      stats: [
        {
          title: 'University',
          categories: [
            { text: 'Campuses', value: '--' },
            { text: 'Students', value: '--' },
          ],
        },
      ],
    }
    this.setRightbarContent(rightBarContent)
    if (this.currentSectionScope && this.currentCampusScope) {
      this.setCampusData()
    }
    if (!this.campusesNotAllowerdFor.includes(this.activeRole)) this.fetchInitialCampuse()
    window.addEventListener('resize', this.handleResize)
  },
  beforeUnmount() {
    document.body.style.overflowY = 'auto'
  },
  mounted() {
    document.body.style.overflowY = 'hidden'
    this.handleResize()
    this.setCustomContainerHeight()
    this.getHeaderContainerHeight()
  },
  methods: {
    handleResize() {
      this.setCustomContainerHeight()
      if (!this.isLaptopRightBar) {
        this.$store.commit('layout/SET_TAB_RIGHT_BAR', true)
      }
    },
    setCustomContainerHeight() {
      let customContainer = this.$refs['customContainer']
      if (customContainer) {
        customContainer.style.height = window.innerHeight + 'px'
      }
    },
    getInstituteStats: debounce(function () {
      if (!this.isAdmin) return
      this.getInstituteCount()
    }, 100),

    handleClick(action) {
      switch (action.type) {
        case 'rightBar':
          this.$store.commit('layout/SET_TAB_RIGHT_BAR')
          break
        case 'leftBar':
          this.$store.commit('layout/SET_TAB_LEFT_BAR')
      }
    },

    mobileLeftBar() {
      this.$store.commit('layout/SET_TAB_LEFT_BAR')
      document.body.style.overflowY = 'hidden'
    },

    closeMobileLeftBar() {
      if (this.isTabLeftBar.matches && this.tabLeftNav) {
        this.$store.commit('layout/SET_TAB_LEFT_BAR', false)
      }
    },
    closeMobileRightBar() {
      if (window.matchMedia('(max-width: 1279px)').matches && this.tabRightNav) {
        setTimeout(() => {
          this.$store.commit('layout/SET_TAB_RIGHT_BAR', false)
        })
      }
    },

    async fetchInitialCampuse() {
      await this.getCampusList().then((response) => {
        this.setCampuses(response.data)
        if (this.currentCampusScope) this.getUserSpecificSectionsList()
      })
    },
    setCampusData() {
      const campus = JSON.parse(localStorage.getItem('campus'))
      const campusClass = JSON.parse(localStorage.getItem('class'))
      const availableRoles = JSON.parse(localStorage.getItem('classRoles'))

      if (!this.currentSectionScope.id && !this.currentCampusScope.id) {
        this.setSelectedCampuse(campus)
        this.setcurrentSelectedSection(campusClass)
        if (availableRoles && availableRoles.length)
          this.setcurrentSelectedSectionRole(availableRoles[0])
      }
    },

    checkLevelAuthentication(level = 'root-level') {
      if (this.isAppLoading) return
      this.authenticRoute = null
      let routes = this.leftBarContent.routes
      let defaultRoute = this.leftBarContent?.defaultRoute
      routes.forEach((parentRoute) => {
        if (parentRoute.childs && parentRoute.childs.length) {
          this.childRouteAuthenticationHandler(parentRoute, level)
        } else if (
          parentRoute.route.name &&
          parentRoute.route.name === this.$route.name &&
          parentRoute.basedOn &&
          parentRoute.basedOn.length &&
          parentRoute.basedOn.includes(level)
        )
          this.authenticRoute = true
        else if (
          parentRoute.route.name &&
          parentRoute.route.name === this.$route.name &&
          !parentRoute.basedOn
        )
          this.authenticRoute = true
      })

      if (this.authenticRoute) return
      else {
        setTimeout(() => {
          let route = getDefaultRoute(
            this.activeRole,
            this.currentCampusScope,
            this.currentSectionScope,
            defaultRoute,
          )
          this.$router?.push({ name: route })
        })
      }
    },
    childRouteAuthenticationHandler(parentRoute, level) {
      parentRoute.childs.forEach((childRoute) => {
        if (
          childRoute.route.name === this.$route.name &&
          parentRoute.basedOn &&
          parentRoute.basedOn.length &&
          parentRoute.basedOn.includes(level)
        ) {
          if (
            !childRoute.basedOn ||
            (childRoute.basedOn && childRoute.basedOn.length && childRoute.basedOn.includes(level))
          )
            this.authenticRoute = true
        }
      })
    },
    getHeaderContainerHeight() {
      if (this.$refs.headerContainer)
        this.headerOffSetHeight = this.$refs.headerContainer.$el.clientHeight
    },
    ...mapActions('layout', [
      'setRightbarContent',
      'setcurrentSelectedSection',
      'setcurrentSelectedSectionRole',
      'setcurrentSelectedSectionRoles',
      'setSelectedCampuse',
      'getCampusList',
      'getUserSpecificSectionsList',
      'setCampuses',
      'setClassList',
      'modifyLeftBarDropdown',
    ]),
    ...mapActions('instituteCount', ['getInstituteCount']),
  },
}
</script>

<style lang="scss" module>
@import '@src/design/_mediaquery-mixin.scss';

.mainContentGutters {
  @include media(null, 767px) {
    grid-column: span 16 / span 16;
  }
}

.leftBar {
  @include media(null, 767px) {
    transition: 0.5s;
  }
}
</style>

<style lang="scss">
.mobileLeftBar {
  left: 0 !important;
}
.sidebar-overlay {
  backdrop-filter: brightness(80%);
}
</style>
