export default {
  SET_CURRENT_SELECTED_TEACHER(state, currentTEACHER) {
    state.currentSelectedTEACHER = currentTEACHER
  },
  SET_CURRENT_TEACHER_LIST(state, list) {
    state.currentTEACHERList = list
  },
  SET_ALL_TEACHER_LIST(state, list) {
    state.campusTeacherList = list
  },
  SET_CLASS_TEACHER_LIST(state, list) {
    state.classTeacherList = list
  },

  // Teacher Mode mutations
  SET_LISTEN_STORAGE(state, payload) {
    state.listenStorage = payload
  },
}
