<template>
  <UiOnClickOutside :do="closeDropdown">
    <div
      class="gap-2 relative md:mt-0 mt-[3px] icon--color self-center"
      :class="$style.scrollBarHidden"
      @click="toggleDropdown"
    >
      <div class="h-11 w-11 rounded-full flex items-center justify-center bg-primary-white">
        <icon
          class="icon"
          :icon="count ? 'notificationBell' : 'bell'"
          color="primary-white"
          height="24"
          width="24"
        />
      </div>
      <UiMenuDropDown
        :is-open="isOpen"
        class="rtl:right-auto rtl:-left-16 rounded-lg w-[336px] ltr:top-[50px] ltr:right-[-1px] max-h-[87vh] overflow-y-auto ltr:p-0"
      >
        <ul
          class="font-inter font-normal text-text-color text-sm gap-2 relative overflow-y-auto h-full"
        >
          <li class="flex pt-5 px-5 text-xl text-primary-purple-700 justify-between items-center">
            <div v-i18n="dashboard" class="font-medium">Notifications</div>
            <div>
              <div class="p-3 sm:pr-1 pr-0 verticalElipsesRight" @click.stop="notificationOptions">
                <icon width="4" height="17" class="icon cursor-pointer" icon="verticalElipses" />
              </div>
              <UiMenuDropDown :class="$style.optionsDropdown" :is-open="options">
                <ul class="px-2 py-3 font-rubik text-sm text-text-color">
                  <li v-i18n="dashboard" class="pb-2" @click="markAllAsRead">Mark all as Read</li>
                </ul>
              </UiMenuDropDown>
            </div>
          </li>
          <div v-if="notifications.length > 0" class="px-5 pb-5 mt-2 max-h-[65vh] overflow-auto">
            <NotificationItem
              :notifications="filteredNotificationsItems"
              class="rounded-lg cursor-pointer flex flex-col gap-2"
              @notificationAction="notificationAction"
            />
          </div>
          <div v-if="!notifications.length" class="flex h-[300px] justify-center items-center">
            <p v-i18n="dashboard" class="text-center py-5 text-2xl text-action-bg">
              No Notification Found
            </p>
          </div>
          <router-link
            to="/notifications"
            class="bg-primary-purple-50 flex justify-center font-inter font-medium px-2 text-text-color text-base py-7"
          >
            <div class="flex items-center gap-2">
              <div
                v-i18n="dashboard"
                class="font-inter font-semibold text-base text-primary-purple-700"
              >
                ALL_NOTIFICATION
              </div>
              <icon color="primary-purple-700" icon="arrowRight" height="11.67" width="11.67" />
            </div>
          </router-link>
        </ul>
      </UiMenuDropDown>
    </div>
  </UiOnClickOutside>
</template>

<script>
import icon from '@components/icons/icon.vue'
import UiOnClickOutside from '@components/UiElements/UiOnClickOutside.vue'
import { objectDeepCopy } from '@utils/generalUtil'
import UiMenuDropDown from '@components/UiElements/UiMenuDropDown.vue'
import { mapState, mapActions } from 'vuex'
import NotificationItem from './NotificationItem.vue'
import { NOTIFICATION_STATUS } from '@src/constants/general-constants'

export default {
  components: {
    icon,
    UiOnClickOutside,
    UiMenuDropDown,
    NotificationItem,
  },
  data() {
    return {
      notifications: '',
      announcements: '',
      isActive: false,
      isOpen: false,
      options: false,
      announcementsOptionsIsOpen: false,
      count: '',
      notificationLimit: { skip: 0, limit: 10 },
      showMore: false,
      dashboard: 'dashboard',
      selectedTheme: JSON.parse(localStorage.getItem('theme'))
        ? JSON.parse(localStorage.getItem('theme'))?.name
        : 'green',
    }
  },
  computed: {
    filteredNotificationsItems() {
      return this.notifications.slice(0, 5)
    },

    ...mapState('auth', ['currentUser']),
    ...mapState({
      notificationsFromStore: (state) => state.notifications.notifications,
      announcementsFromStore: (state) => state.notifications.announcements,
    }),
  },
  watch: {
    notificationsFromStore: {
      handler() {
        this.notifications = objectDeepCopy(this.notificationsFromStore)
      },
      immediate: true,
    },
    announcementsFromStore: {
      handler() {
        this.announcements = objectDeepCopy(this.announcementsFromStore)
      },
      immediate: true,
    },
  },
  /**
   * Created Hook
   * @description Calls the method initializeNotification() to initialize the notification by providing notificationLimit as parameter of method
   */
  created() {
    this.initializeNotification(this.notificationLimit)
  },
  methods: {
    ...mapActions('notifications', ['getNotificationAndAnnouncements', 'setNotificationStatus']),
    /**
     * Mark All As Read
     * @description It returns notification.id into allNotificationIDs and set the status to read
     */
    // TODO: function name should be related to notification, it's too generic here
    markAllAsRead() {
      var allNotificationIDs = this.notifications.map((notification) => {
        return notification.id
      })
      if (this.count > 0) {
        this.setNotificationStatus({
          notification_ids: allNotificationIDs,
          status: NOTIFICATION_STATUS.READ,
        }).then(() => {
          this.notifications.forEach((notification) => {
            if (notification.status !== NOTIFICATION_STATUS.READ) {
              notification.status = NOTIFICATION_STATUS.READ
            }
          })
          this.initializeNotification(this.notificationLimit)
        })
      }
    },

    markAllAnnouncementAsRead() {
      var allAnnouncementsIDs = this.announcements.map((announcement) => {
        return announcement.id
      })
      if (this.count > 0) {
        this.setNotificationStatus({
          notification_ids: allAnnouncementsIDs,
          status: NOTIFICATION_STATUS.READ,
        }).then(() => {
          this.announcements.forEach((announcement) => {
            if (announcement.status !== NOTIFICATION_STATUS.READ) {
              announcement.status = NOTIFICATION_STATUS.READ
            }
          })
          this.initializeNotification(this.notificationLimit)
        })
      }
    },
    /**
     * Notification Action
     * @param {object} payload - Object contains {notification_id:'',notification_action:''}
     * @returns {void} - Performs Action on notification eg: Read & Unread
     * @description Changes the count for notification against specific value of action and id
     */
    // TODO: Refactor the function
    notificationAction(payload) {
      if (payload.action === NOTIFICATION_STATUS.READ)
        this.setNotificationStatus({
          notification_ids: [payload?.id],
          status: payload?.action,
        }).then(() => {
          this.notifications.forEach((notify) => this.readNotification(notify, payload))
          this.announcements.forEach((notify) => this.readNotification(notify, payload))
        })
    },

    readNotification(notification, payload) {
      if (
        notification.id === payload.id &&
        payload.action === NOTIFICATION_STATUS.UNREAD &&
        notification.status !== NOTIFICATION_STATUS.UNREAD
      ) {
        notification.status = NOTIFICATION_STATUS.UNREAD
        this.count += 1
      } else if (
        notification.id === payload.id &&
        payload.action === NOTIFICATION_STATUS.READ &&
        notification.status !== NOTIFICATION_STATUS.READ
      ) {
        notification.status = NOTIFICATION_STATUS.READ
        this.count -= 1
      }
    },
    /**
     * Notification Options
     * @param {boolean} value
     * @returns {void}
     * @description This method is used to toggle notification option value
     */
    // TODO: function name is not descriptive one
    notificationOptions(value) {
      this.options = typeof value === 'boolean' ? value : !this.options
    },

    // TODO: function name is not descriptive one
    announcementsOptions(value) {
      this.announcementsOptionsIsOpen =
        typeof value === 'boolean' ? value : !this.announcementsOptionsIsOpen
    },
    /**
     * Toggle Dropdown
     * @description Toggles the dropdown between open and closed
     */
    // TODO: there is a duplication of functions, these functions are alredy defined in src/modules/header/campuses.vue, so shouldnot be created again.
    toggleDropdown() {
      this.isActive = !this.isActive
      this.toggleIsOpen()
    },
    /**
     * Toggle Is Open
     * @param { boolean | * } value - boolean or any other value
     * @returns {void}
     * @description Assigns the boolean value to isOpen variable
     */
    toggleIsOpen(value) {
      this.isOpen = typeof value === 'boolean' ? value : !this.isOpen
      this.options = false
      this.announcementsOptionsIsOpen = false
    },
    /**
     * Close Dropdown
     * @description Closes the dropdown
     */
    closeDropdown() {
      if (this.isActive) {
        this.isActive = false
        this.options = false
        this.toggleIsOpen()
      }
    },
    /**
     * Initialize Notification
     * @param {number} limit - Default Notification Limit is 10
     * @returns {void}
     * @description Method is used to get the notification list with API call and also connects the notification socket channel
     */
    // TODO : function is too complex
    initializeNotification(limit) {
      this.getNotificationAndAnnouncements(limit).then((response) => {
        this.count = response.data.unread_count
        this.showMore = !!(this.notifications.length > 5)
        this.$nextTick(() => {
          if (this.$socket) {
            const userChannel = this.$socket.channel(`tenant_notification:${this.currentUser.id}`)

            userChannel.on('notificationcreated', (payload) => {
              if (!this.$socket.isConnected()) return

              const notification = {
                id: payload.id,
                title: payload.title,
                inserted_at: payload.inserted_at,
                status: 'unread',
                redirect_path: payload?.redirect_path,
                description: payload?.body,
                related_user: {
                  related_user_first_name: payload?.related_user_first_name,
                  related_user_last_name: payload?.related_user_last_name,
                  related_user_image: payload?.related_user_image,
                },
              }

              this.notifications.unshift(notification)
              this.count += 1
              this.$store.commit(
                'toast/NEW',
                {
                  id: notification.id,
                  message: notification.title,
                  description: notification?.description || '',
                  image: notification.related_user.related_user_image,
                  related_user: notification.related_user,
                  inserted_at: notification.inserted_at,
                  type: 'notification',
                },
                { root: true },
              )
            })
            /* ANNOUNCEMENTS */
            userChannel.on('announcementcreated', (payload) => {
              const notification = {
                id: payload.id,
                title: payload.title,
                inserted_at: payload.inserted_at,
                status: 'unread',
                redirect_path: payload?.redirect_path,
                description: payload?.body,
              }
              this.announcements.unshift(notification)
              this.count += 1
              this.$store.commit(
                'toast/NEW',
                {
                  id: notification.id,
                  message: notification.title,
                  description: notification?.description || '',
                  inserted_at: notification.inserted_at,
                  type: 'success',
                },
                { root: true },
              )
            })
            userChannel.onError(() => {
              // eslint-disable-next-line no-console
              console.log('Error')
            })
            userChannel
              .join()
              .receive('ok', ({ message }) => console.log(message)) // eslint-disable-line no-console
              .receive('error', ({ reason }) => console.log('failed join', reason)) // eslint-disable-line no-console
              .receive('timeout', () => console.log('Networking issue. Still waiting...')) // eslint-disable-line no-console
          } else {
            console.error('Cannot join channel !')
          }
        })
      })
    },
  },
}
</script>

<style lang="scss" module>
.scrollBarHidden {
  ::-webkit-scrollbar {
    width: 5px;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #d4d5d9;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.optionsDropdown {
  top: 65px;
  right: 10px;
  width: 220px;
  &::before {
    position: absolute;
    top: -6px;
    width: 10px;
    height: 10px;
    content: '';
    background: var(--bg-white);
    border-top: 1px solid var(--primary-grey);
    border-left: 1px solid var(--primary-grey);
    transform: rotate(45deg);
  }
}
body[dir='ltr'] {
  .optionsDropdown {
    right: 5px;
    &::before {
      right: 15px;
    }
  }
}
body[dir='rtl'] {
  .optionsDropdown {
    right: 103px;
    &::before {
      left: 23px;
    }
  }
}
@media (max-width: 640px) {
  body[dir='ltr'] {
    .optionsDropdown {
      right: 9px;
      &::before {
        right: 23px;
      }
    }
  }
  body[dir='rtl'] {
    .optionsDropdown {
      right: 68px;
      &::before {
        left: 23px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.verticalElipsesRight {
  text-align: -webkit-right;
}
@import '@src/design/_mediaquery-mixin.scss';
@media screen and (max-width: 639px) {
  .icon {
    width: 20px;
    height: 20px;
  }
}
</style>
