import { InstituteService } from '@src/services/institute.service'

export default {
  // Institute Setup

  instituteSetup({ commit }, payload) {
    return new Promise((resolve, reject) => {
      InstituteService.instituteSetup(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },
  // Campus Setup

  campusSetup({ commit }, payload) {
    return new Promise((resolve, reject) => {
      InstituteService.campusSetup(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },
  // Section Setup

  sectionSetup({ commit }, payload) {
    return new Promise((resolve, reject) => {
      InstituteService.sectionSetup(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },
  // Institute Logo

  setInstituteLogo({ commit }, payload) {
    return new Promise((resolve, reject) => {
      InstituteService.setInstituteLogo(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },
  // Favicon Icon

  setInstituteFaviconIcon({ commit }, payload) {
    return new Promise((resolve, reject) => {
      InstituteService.setInstituteFaviconIcon(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  // Deactivate Institute User

  deactivateInstituteUser({ commit }, { id, password }) {
    return new Promise((resolve, reject) => {
      InstituteService.deactivateInstituteUser(id, password)
        .then((response) => {
          commit(
            'toast/NEW',
            { type: 'success', message: 'User is deactivated successfully' },
            { root: true },
          )
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  resetUserPassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return InstituteService.resetUserPassword(payload)
        .then((response) => {
          commit('toast/NEW', { type: 'success', message: 'Successfully Updated' }, { root: true })
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
}
