/* eslint-disable vue/no-boolean-default */
<template>
  <div
    ref="leftBar"
    class="bg-light-pastel-purple flex h-full justify-center box-border md:w-auto w-[200px] md:bg-pastel-purple min-h-[450px] md:relative fixed overflow-y-scroll left-0 top-0 md:z-[0] z-[5001] overflow-x-hidden pt-0 md:pl-0 rtl:ml-3.5"
    :class="[leftBarVisibility ? 'md:col-span-3' : 'md:col-span-1 md:flex hidden']"
    @click="showLeftBar"
  >
    <div class="w-full h-full md:flex hidden absolute">
      <div
        class="absolute rounded-full xl:left-[-325px] lg:w-[590px] lg:left-[11px] md:w-[341px] md:bottom-[135px] md:left-[18px] h-[500px] w-[248px] bottom-[-9px] left-[8px] orange-circle"
      ></div>
    </div>

    <div
      :class="[
        leftBarVisibility
          ? 'col-span-3 md:bg-transparent tooltip'
          : 'col-span-1 md:bg-transparent ',
      ]"
      class="xl:px-5 px-2.5 h-[inherit] flex flex-col transform leftbar-margin-top w-full"
    >
      <div class="border-b-[1.5px] h-auto border-white">
        <div
          class="flex w-full justify-start xl:mt-[14px] mt-5 items-center"
          :class="[(!leftBarVisibility || isTabLeftBar) && 'justify-center md:mb-6 mb-[15px]']"
        >
          <div class="flex items-center">
            <span class="flex items-center" :class="[leftBarVisibility && 'xl:pr-2 pr-1']">
              <img v-if="logoSrc" :src="logoSrc" class="xl:w-12 xl:h-12 md:w-9 md:h-9" />
              <div v-else>
                <icon class="xl:w-12 xl:h-12 md:w-9 md:h-9" icon="logoheader" />
              </div>
            </span>
            <span
              v-if="leftBarVisibility"
              :title="instituteTitle"
              class="font-medium font-inter md:w-auto w-[120px] text-xs xl:text-sm flex flex-wrap text-gray-700"
            >
              {{ instituteTitle }}
            </span>
          </div>
          <div class="flex items-center">
            <span class="bg-white md:hidden block rounded-[5px] p-[5px]" @click="hideLeftBar">
              <icon icon="doubleChevronLeft" width="8" height="8" />
            </span>
          </div>
        </div>
        <!-- Class dropdown -->
        <div
          v-if="showClassDropdown && leftBarVisibility"
          class="xl:mt-[54px] md:block hidden md:mt-[30px] mt-[15px]"
        >
          <ClassDropdown />
        </div>
        <div v-if="showSectionDropdown && leftBarVisibility" class="-mt-2.5 md:block hidden">
          <SectionDropdown />
        </div>
      </div>

      <div
        class="items-center xl:mt-5 mt-[15px] h-auto min-h-[50px] overflow-y-auto overflow-x-hidden"
        :class="[!leftBarVisibility && 'flex justify-center']"
      >
        <!-- Left bar button -->
        <h1
          v-if="showLeftBarTitle"
          class="border-b border-primary-grey pb-1 font-roboto font-medium text-text-color px-2 mb-2"
        >
          <span>
            {{ $t(`leftBar.${leftBarContent.title}`) }}
          </span>
        </h1>
        <UiDropDown
          :left-bar-visibility="leftBarVisibility"
          :content="leftBarContent.routes"
          @setcurrentSelectedSection="setcurrentSelectedSection"
          @setSelectedCampuse="setSelectedCampuse"
        />
      </div>
      <div
        class="xl:min-h-[115px] min-h-[95px] mt-auto xl:h-[115px] h-[95px]"
        :class="[leftBarVisibility && 'mb-8']"
      >
        <div
          class="xl:mt-6 mt-[15px] border-t-[1.5px] w-full border-white"
          :class="[!leftBarVisibility && 'flex justify-center']"
        >
          <UserProfile />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import SectionDropdown from '@/src/router/layouts/left-bar/SectionDropdown.vue'
import ClassDropdown from '@src/router/layouts/left-bar/ClassDropdown.vue'
import UserProfile from '@src/modules/header/user-profile.vue'
import icon from '@components/icons/icon.vue'
import { UiDropDown } from '@components/UiElements'

export default {
  components: {
    icon,
    UserProfile,
    UiDropDown,
    SectionDropdown,
    ClassDropdown,
  },
  props: {
    leftBarVisibility: { type: Boolean },
  },
  emits: ['handleClick'],
  data() {
    return {
      isTabLeftBar: window.matchMedia('(max-width: 767px)').matches,
      instituteTitle: JSON.parse(localStorage.getItem('setting')).title,
      rightBar: 'rightBar',
    }
  },
  computed: {
    ...mapState({ logoSrc: (state) => state.settings.generalSettingLogoURL }),
    ...mapState({
      activeRole: (state) => state.layout.activeRole,
      leftBarContent: (state) => state.layout.leftBarContent,
      showSectionDropdown: (state) => state.layout.showSectionDropdown,
      showClassDropdown: (state) => state.layout.showClassDropdown,
    }),
    showLeftBarTitle() {
      return (
        this.leftBarContent.title &&
        this.leftBarContent?.SHOW_SECTION_DROPDOWN_TO?.includes(this.activeRole)
      )
    },
  },
  page: {
    title: 'Campuses',
    meta: [{ name: 'description', content: 'Campuses' }],
  },
  mounted() {
    this.setLeftbarHeight()
    window.addEventListener('resize', this.setLeftbarHeight)
    window.addEventListener('orientationchange', this.setLeftbarHeight)
  },

  methods: {
    setLeftbarHeight() {
      let leftBar = this.$refs['leftBar']
      if (leftBar) {
        if (window.innerHeight < 450) {
          document.body.style.overflowY = 'scroll'
          leftBar.style.overflowY = 'scroll'
          leftBar.style.height = window.innerHeight + 'px'
        } else {
          leftBar.style.height = '100%'
        }
      }
    },
    showLeftBar() {
      if (!this.leftBarVisibility && !this.isTabLeftBar)
        this.$store.commit('layout/SET_TAB_LEFT_BAR')
    },
    hideLeftBar() {
      this.$store.commit('layout/SET_TAB_LEFT_BAR')
    },
    ...mapActions('layout', ['setcurrentSelectedSection']),
    ...mapActions('teacher', ['setSelectedCampuse']),
  },
}
</script>

<style lang="scss" scoped>
.orange-circle {
  background-image: radial-gradient(
    ellipse,
    rgba(255, 222, 188, 0.95),
    rgba(255, 148, 33, 0),
    transparent 140%
  );
}
::-webkit-scrollbar {
  display: none;
}
@media (min-width: 1025px) and (max-width: 1279px) {
  .leftbar-margin-top {
    margin-top: 0;
  }
}
</style>
