<template>
  <ul class="h-full">
    <li v-for="item in leftbarContentFiltered" :key="item.id" class="mb-0.5">
      <template v-if="checkRouteLevel(item)">
        <div v-if="item.childs && item.role.includes(activeRole)">
          <div
            :class="[
              makeDropdownActive(item),
              item.showChilds && leftBarVisibility ? 'rounded-t-md' : 'rounded-md',
            ]"
            class="p-2 flex items-center xl:py-4 py-3 justify-between cursor-pointer"
            @click="toggleChilds(item)"
            @mouseover="dropDownActionMouseOver(item)"
            @mouseleave="dropDownActionMouseLeave()"
          >
            <span class="flex gap-2 items-center justify-center cursor-pointer">
              <icon
                class="icon"
                :icon="item.icon"
                :color="setIconColor(item)"
                height="20"
                width="20"
              />
              <h2
                v-if="leftBarVisibility"
                class="font-inter font-medium md:text-sm text-xs select-none"
              >
                {{ $t(`leftBar.${item.name}`) }}
              </h2>
            </span>
            <icon
              v-if="leftBarVisibility"
              class="icon"
              :icon="item.showChilds ? 'chevronUp' : 'chevronBottom'"
              :color="setIconColor(item)"
              height="5.801"
              width="10.146"
            />
          </div>
          <transition name="slide">
            <ul
              v-if="item.showChilds && leftBarVisibility"
              class="dropdown bg-primary-purple-25 pb-[15px] rounded-b-md"
            >
              <li
                v-for="child in item.childs"
                ref="children"
                :key="child.name"
                class="cursor-pointer font-inter hover:text-primary-purple-700 text-primary-purple-400 font-medium md:text-sm text-xs"
              >
                <router-link
                  v-if="childRoleAccess(child) && checkRouteLevel(child)"
                  v-slot="{ isActive, isExactActive }"
                  :to="child.route"
                >
                  <li
                    class="py-[6px] block ml-4 border-l border-purple-800"
                    @click="toggleLeftBar()"
                  >
                    <div class="justify-start gap-2 flex items-center">
                      <div class="w-[14px] border-t border-purple-800"></div>
                      <a v-if="child.metaData" :class="[isActive && '  text-primary-purple-700']">
                        {{ $t(`leftBar.${child.name}`) }}
                      </a>
                      <a v-else :class="[isExactActive && ' rounded-md text-primary-purple-700']">
                        {{ $t(`leftBar.${child.name}`) }}
                      </a>
                    </div>
                  </li>
                </router-link>
                <h1
                  v-if="child.click"
                  class="p-2 block"
                  @click="$store.dispatch(child.click.actionName)"
                >
                  {{ $t(`leftBar.${child.name}`) }}
                </h1>
              </li>
            </ul>
          </transition>
        </div>
        <template v-else>
          <router-link
            v-if="item.role.includes(activeRole) || item.isRenderForcefully"
            v-slot="{ route, isExactActive }"
            :to="item.route"
          >
            <div
              @mouseover="dropDownActionMouseOver(item)"
              @mouseleave="dropDownActionMouseLeave()"
              @click.prevent="CloseLeftBar(route, item)"
            >
              <span
                class="flex gap-2 xl:py-4 py-3 rounded-md px-2 items-center justify-start cursor-pointer"
                :class="[setTextColor(isExactActive, item)]"
              >
                <icon
                  class="icon"
                  :icon="item.icon"
                  :color="setIconColor(item, isExactActive)"
                  height="20"
                  width="20"
                />
                <a
                  v-if="showLeftBarItem(item)"
                  class="font-inter font-medium rounded-md md:text-sm text-xs cursor-pointer block"
                >
                  {{ $t(`leftBar.${item.name}`) }}
                </a>
              </span>
            </div>
          </router-link>
        </template>
      </template>
    </li>
  </ul>
</template>

<script>
import icon from '@components/icons/icon.vue'
import { mapActions, mapState } from 'vuex'
import { FEATURES_ENVIRONMENT, ALLOWED_FEATURES } from '@src/services/.env.js'
import { accessLevel } from '@utils/generalUtil'
export default {
  components: {
    icon,
  },

  props: {
    content: {
      type: Array,
      default: () => [],
    },
    leftBarVisibility: { type: Boolean, default: false },
  },
  emits: ['setcurrentSelectedSection', 'setSelectedCampuse'],

  data() {
    return {
      isAppInitialised: false,
      role: 'admin dashboard',
      currentHoverItem: '',
      isTabLeftBar: window.matchMedia('(max-width: 767px)').matches,
    }
  },
  computed: {
    ...mapState('layout', [
      'activeRole',
      'currentSectionScope',
      'tabLeftNav',
      'currentCampusScope',
      'currentClassScope',
      'currentRoute',
    ]),
    leftbarContentFiltered() {
      if (!FEATURES_ENVIRONMENT) {
        return this.content
      }

      const filteredContent = []
      this.content.forEach((sidebarParentItem) => {
        if (sidebarParentItem.childs?.length > 0) {
          const filteredChildItem = sidebarParentItem.childs.filter((childItem) => {
            return ALLOWED_FEATURES.includes(childItem.name)
          })

          if (filteredChildItem.length > 0) {
            const item = {
              ...sidebarParentItem,
              childs: filteredChildItem,
            }

            filteredContent.push(item)
          }
        } else {
          if (ALLOWED_FEATURES.includes(sidebarParentItem.name)) {
            filteredContent.push(sidebarParentItem)
          }
        }
      })

      return filteredContent
    },
  },
  watch: {
    leftbarContentFiltered: {
      deep: true,
      handler() {
        if (!this.isAppInitialised) this.toggleInitialLeftbarCollapseState()
      },
    },
  },
  methods: {
    showLeftBarItem(item) {
      return (
        (item.role.includes(this.activeRole) || item.isRenderForcefully) && this.leftBarVisibility
      )
    },
    setIconColor(item, activeCell = false) {
      let color
      if (
        item?.id === this.currentHoverItem?.id ||
        activeCell ||
        item.showChilds ||
        item?.routesName?.includes(this.currentRoute)
      ) {
        color = 'primary-purple-700'
      } else {
        color = 'primary-gray-700'
      }
      return color
    },
    toggleLeftBar() {
      if (this.isTabLeftBar) this.$store.commit('layout/SET_TAB_LEFT_BAR')
    },
    dropDownActionMouseOver(item) {
      if (item) this.currentHoverItem = item
    },
    dropDownActionMouseLeave() {
      this.currentHoverItem = ''
    },
    childRoleAccess(child) {
      return (
        (child.route &&
          !child.isDynamicRoute &&
          child.role &&
          child.role.includes(this.activeRole)) ||
        (child.route && !child.isDynamicRoute && !child.role)
      )
    },
    /**
     * checking level of access either institue level , campus level or class level
     * @params {Object} item
     * @returns {Boolean} level
     */
    checkRouteLevel(item) {
      return accessLevel(
        item,
        this.currentCampusScope,
        this.currentSectionScope,
        this.currentClassScope,
      )
    },
    /**
     * Toggle initial state of left bar
     * @returns {Boolean}
     */
    toggleInitialLeftbarCollapseState() {
      this.isAppInitialised = true
      const matchedRouteParent = this.leftbarContentFiltered.find((route) => {
        if (route?.routesName?.includes(this.$route.name)) return true
        return false
      })

      if (matchedRouteParent?.childs?.length > 0) this.toggleChilds(matchedRouteParent)
    },
    /**
     * Toggle child features
     */
    toggleChilds(item) {
      if (FEATURES_ENVIRONMENT) {
        const parentItemIdx = this.content.findIndex((parentItem) => parentItem.name === item.name)
        this.setItemState(this.content[parentItemIdx])
      } else {
        this.setItemState(item)
      }
    },
    /**
     * Closing left bar
     * @params {String} route
     */
    CloseLeftBar(route, item) {
      this.$router?.push(route)
      this.toggleLeftBar()
    },
    setTextColor(isActive, item) {
      return isActive || this.makeLeftbarParentActive(item)
        ? 'bg-primary-purple-25  text-primary-purple-700'
        : 'text-primary-gray-700 hover:bg-primary-purple-25 hover:text-primary-purple-700'
    },

    // If we get metaData from route  it mean we need to perform some action on route switch action

    /*
      TODO
        Perhaps make a sinlge function, that takes a thrid arguement as a custom event name
      TODO
        and it uses this agruement(third) to navigate the route with a switch statement
    */
    makeLeftbarParentActive(item) {
      let activeChild = false
      if (item.routesName && item.routesName.length) {
        activeChild = item.routesName.some((routeName) => routeName === this.$route.name)
      }
      return activeChild
    },
    /**
     * Navigating child route
     * @params {Object,String} item, routeName
     * emit the item to parent component
     */
    childRouteNavigate(routeName, item) {
      this.$router?.push(routeName)
      this.$emit('setcurrentSelectedSection', item)
    },
    /**
     * Navigating the route
     * @params {Object, String} item, routeName
     * emit the item to parent component
     */
    routeNavigate(routeName, item) {
      this.$router?.push(routeName)
      this.$emit('setSelectedCampuse', item)
    },
    /**
     * Activate drop down
     * @params {Object} parent
     * @returns styles of left bar text and it's childs
     */
    makeDropdownActive(parent) {
      let activeChild = ''
      if (parent.childs) {
        activeChild = parent.childs.some((child) => child.route.name === this.currentRoute)
      }
      if (activeChild || parent.showChilds) return 'bg-primary-purple-25 text-primary-purple-700'
      else return 'text-primary-gray-700  hover:bg-primary-purple-25 hover:text-primary-purple-700'
    },

    ...mapActions('layout', ['setItemState']),
  },
}
</script>

<style lang="scss" scope>
.dropdown {
  transition: transform 0.1s ease-in-out;
  transform-origin: top;
}
.slide-enter,
.slide-leave-to {
  transform: scaleY(0);
}
</style>

<style lang="scss" scoped>
@media (max-width: 640px) {
  svg[icon='chevronUp'] {
    width: 11px;
    height: 11px;
  }

  svg[icon='chevronBottom'] {
    width: 11px;
    height: 11px;
  }
}
</style>
