export default {
  currentCourse: {},
  currentStudentList: [],
  currentSelectedStudent: {},
  classList: [],
  currentStudentAttendance: {
    time: '',
    date: '',
  },
  currentAttendanceFilter: {},
  studentListScheduleBase: [],
  currentSchulde: {},
  studentReceivedFees: [],
  studentReceivableFees: [],
  listenStorage: false,
  currentStepForRegistration: '',
  toggleFormStepModal: false,
}
