import { ExpenseService } from '@services/expense.service.js'
export default {
  getExpenseCategories({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      ExpenseService.getExpenseCategories(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },
  addAndupdateExpenseCategories({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      ExpenseService.addAndupdateExpenseCategories(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },
  deleteExpenseCategories({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      ExpenseService.deleteExpenseCategories(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  getExpenses({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      ExpenseService.getExpenses(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },
  addNewExpense({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      ExpenseService.addNewExpense(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  updateExistingExpense({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      ExpenseService.updateExistingExpense(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },
  deleteExpenses({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      ExpenseService.deleteExpenses(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  addExtraAttachment({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      ExpenseService.addExtraAttachment(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  removeAttachmentFromExpense({ state, commit, rootState }, id) {
    return new Promise((resolve, reject) => {
      ExpenseService.removeAttachmentFromExpense(id)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },
}
