import { by, del } from '@services/custom.service'
import { BaseService } from '@services/base.service'
import { TENNAT_API_URL } from '@services/.env'

export default class TeacherService extends BaseService {
  static addNewTeacher(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}teachers`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not add Teacher'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static editTeacher({ teacher, id = null }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}teachers`, teacher, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  static getTeachers(data) {
    return new Promise((resolve, reject) => {
      return by(
        `${TENNAT_API_URL}institute_users/role_users`,
        data,
        data?.getTeacherCampusBase ? { filterClass: true } : '',
      )
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get all Teacher list'
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static getTeacherStats(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}teachers/teacher_stats`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }
  static getTeacherWithFilters(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}teachers/filters`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get all Teacher list'
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static loadTeacherCheckInAndOut(payload) {
    return new Promise((resolve, reject) => {
      const options = {}
      if (payload.filterClass) {
        options.filterClass = payload.filterClass
      }
      return by(`${TENNAT_API_URL}teacher_check_in_outs/filters`, payload, options)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Student attendance check in and out'
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static markTeacherCheckInOut(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}teacher_check_in_outs`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Students'
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static setTeachersAttendance(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}teacher_attendance`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Teacher list'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static teacherAttendanceFilter(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}teacher_attendance/filters`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Teacher Attendance'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  /**
   * Assign any teacher into campus and section
   */

  static teacherAssignment(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}section_subject_teachers/section_assignment`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data.message))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  // Teacher mode endpoints

  static teacherOwnAttendance(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}teacher/teacher_attendance/filters`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Teacher Attendance'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static loadStudentAttendance(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}teacher/student_attendance/filters`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Student attendance list'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static filterScheduleForTeachers(payload) {
    return new Promise((resolve, reject) => {
      let data = payload
      const options = {}

      if (payload.filterClass) {
        data = payload.filter_by
        options.filterClass = payload.filterClass
      }

      return by(`${TENNAT_API_URL}teacher/schedule/filters`, data, options).then((response) => {
        resolve(this.responseWrapper(response, response.data))
      })
    })
  }

  static filteredStudentsForTeachers(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}teacher/institute_users/role_users`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get Students'
          reject(this.errorWrapper(error, message))
        })
    })
  }
}
