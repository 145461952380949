import FILTER_KEYS from '@src/constants/filter-constants.js'

export function buildWhereQuery(option, key, filterdValue = '') {
  if (!option || !key) throw new Error(!option ? 'Option param is missing' : 'Key param is missing')

  let query = ''
  switch (option) {
    case FILTER_KEYS.ILIKE:
      query = { [key]: { [option]: `%${filterdValue}%` } }
      break
    case FILTER_KEYS.LIKE:
      query = { [key]: { [option]: `%${filterdValue}%` } }
      break
    case FILTER_KEYS.NOT_LIKE:
      query = { [key]: { [option]: `%${filterdValue}%` } }
      break
    case FILTER_KEYS.NOT_ILIKE:
      query = { [key]: { [option]: `%${filterdValue}%` } }
      break
    case FILTER_KEYS.LESS_THAN: // lessThan && lessThan  the other field
      query = { [key]: { [option]: filterdValue } }
      break
    case FILTER_KEYS.LESS_THAN_EQUAL: // lessThanEqual && lessThanEqual the other field
      query = { [key]: { [option]: filterdValue } }
      break
    case FILTER_KEYS.GREATER_THAN: // greaterThan && greaterThanEqaul the other field
      query = { [key]: { [option]: filterdValue } }
      break
    case FILTER_KEYS.GREATER_THAN_EQUAL: // greaterThanEqual && greaterThanEqual the other field
      query = { [key]: { [option]: filterdValue } }
      break
    case FILTER_KEYS.BETWEEN: // between
      query = { [key]: { [option]: `${JSON.stringify(filterdValue)}` } }
      break
    case FILTER_KEYS.NOT_BETWEEN: // Not between
      query = { [key]: { [option]: `${JSON.stringify(filterdValue)}` } }
      break
    case FILTER_KEYS.IN: // Include
      query = { [key]: { [option]: filterdValue } }
      break
    case FILTER_KEYS.NOT_IN: // Not Include
      query = { [key]: { [option]: `${JSON.stringify(filterdValue)}` } }
      break
    case FILTER_KEYS.IS_NIL: // Is Nil
      query = { [key]: option }
      break
    case FILTER_KEYS.IS_NOT_NULL: // Not Nil
      query = { [key]: option }
      break
    case FILTER_KEYS.EQUAL: // $equal
      query = { [key]: { [option]: filterdValue } }
      break
    default:
      throw new Error('Invalid Option')
  }
  return query
}
