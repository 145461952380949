import i18n from '@src/i18n'
import TeacherService from './services'
const t = i18n?.global?.t

export default {
  addTeacher({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      TeacherService.addNewTeacher(payload)
        .then((response) => {
          commit(
            'toast/NEW',
            { message: t(`toast.Teacher Added Successfully`), type: 'success' },
            { root: true },
          )
          resolve(response)
        })
        .catch((error) => {
          commit('layout/IS_LOADING', false, { root: true })
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  editTeacher({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      TeacherService.editTeacher(payload)
        .then((response) => {
          commit(
            'toast/NEW',
            { message: t(`toast.Teacher Updated Successfully`), type: 'success' },
            { root: true },
          )
          resolve(response)
        })
        .catch((error) => {
          commit('layout/IS_LOADING', false, { root: true })
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  setTeachersAttendance({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      TeacherService.setTeachersAttendance(payload)
        .then((response) => {
          commit(
            'toast/NEW',
            { message: t(`toast.Attendance Marked Successfully`), type: 'success' },
            { root: true },
          )
          resolve(response)
        })
        .catch((error) => {
          commit('layout/IS_LOADING', false, { root: true })
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  loadTeacherCheckInAndOut({ commit }, payload) {
    return new Promise((resolve, reject) => {
      TeacherService.loadTeacherCheckInAndOut(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  markTeacherCheckInOut({ commit }, payload = {}) {
    return new Promise((resolve, reject) => {
      TeacherService.markTeacherCheckInOut(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  setCurrentTeacher({ state, commit, rootState }, payload) {
    commit('SET_CURRENT_SELECTED_TEACHER', payload)
  },

  setCurrentClassAttendance({ state, commit, rootState }, payload) {
    commit('SET_CURRENT_CLASS_ATTENDANCE', payload)
  },

  getTeacherList({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      TeacherService.getTeachers(payload)
        .then((response) => {
          response.data.records.forEach((record) => {
            record.full_name = `${record.first_name} ${record.last_name}`
          })
          if (payload?.section_id) {
            commit('SET_CLASS_TEACHER_LIST', response.data.records)
          } else commit('SET_ALL_TEACHER_LIST', response.data.records)
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  getTeacherStats({ commit }, payload = '') {
    return new Promise((resolve, reject) => {
      return TeacherService.getTeacherStats(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  getTeacherWithFilters({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      TeacherService.getTeacherWithFilters(payload)
        .then((response) => {
          response.data.records.forEach((record) => {
            record.full_name = `${record.first_name} ${record.last_name}`
          })
          if (payload?.section_id) {
            commit('SET_CLASS_TEACHER_LIST', response.data.records)
          } else commit('SET_ALL_TEACHER_LIST', response.data.records)
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  getAllClasses({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      TeacherService.getAllClasses()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  getSubjects({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      TeacherService.getSubjects()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  setSelectedCampuse({ state, commit, rootState }, payload) {
    commit('SET_SELECTED_CAMPUSE', payload)
  },

  teacherAttendanceFilter({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      TeacherService.teacherAttendanceFilter(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  teacherAssignment({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      TeacherService.teacherAssignment(payload)
        .then((response) => {
          commit(
            'toast/NEW',
            {
              message: t(`toast.Teacher is successfully assigned into the campus`),
              type: 'success',
            },
            { root: true },
          )
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
        .finally(() => {
          commit('layout/IS_LOADING', false, { root: true })
        })
    })
  },

  // Teacher Mode endpoints

  teacherOwnAttendance({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      TeacherService.teacherOwnAttendance(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  loadStudentAttendance({ commit }, payload) {
    return new Promise((resolve, reject) => {
      TeacherService.loadStudentAttendance(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  filterScheduleForTeachers({ commit }, payload) {
    return new Promise((resolve, reject) => {
      TeacherService.filterScheduleForTeachers(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  filteredStudentsForTeachers({ commit }, payload = '') {
    return new Promise((resolve, reject) => {
      TeacherService.filteredStudentsForTeachers(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
}
