import { PaymentMethods } from '@services/payment-methods.services'

export default {
  setLoading({ commit }, payload) {
    commit('SET_LOADING', payload)
  },
  setModalLoading({ commit }, payload) {
    commit('SET_MODAL_LOADING', payload)
  },

  setModal({ commit }, payload) {
    commit('SET_MODAL', payload)
  },

  async getAllAccounts({ commit }, payload) {
    commit('SET_LOADING', true)
    const [response, error] = await PaymentMethods.getAllAccounts(commit, payload)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('SET_LOADING', false)
    return [response, error]
  },

  async createStripeStandardAccount({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await PaymentMethods.createStripeStandardAccount(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async onBoardUser({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await PaymentMethods.onBoardUser(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async deAuthorize({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await PaymentMethods.deAuthorize(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async payStudentFees({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await PaymentMethods.payStudentFees(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },
}
