import { by, del } from '@services/custom.service'
import { TENNAT_API_URL } from '@services/.env'
import { BaseService } from '@services/base.service'
import { extractErrorMassage } from '@utils/generalUtil'
export default class ScheduleService extends BaseService {
  static getPeriod(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}/schedule/teacher_schedules`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get schedule !'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getPaginatedPeriod(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}lesson_planner/periods_filter`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error))
        })
    })
  }

  static deletePeriod(id) {
    return new Promise((resolve, reject) => {
      return del(`${TENNAT_API_URL}period/deactivate`, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get schedule !'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getSubjects(query) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}period/section_subjects`, query)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get subjects !'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getSectionSubjectTeacher(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}section_subject_teachers/filter`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get teachers !'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getRooms(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}schedule/department_rooms`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get rooms !'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static storeSchedule(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}schedule`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not create schedule !'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static filterPeriod(payload) {
    return new Promise((resolve, reject) => {
      let data = payload
      const options = {}

      if (payload.filterClass) {
        data = payload.filter_by
        options.filterClass = payload.filterClass
      }

      return by(`${TENNAT_API_URL}period/filters`, data, options)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = extractErrorMassage(error)
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static getPeriodsStats(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}period/stats`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not add Schedule'
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static addPeriod({ routine, id = null }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}period`, routine, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not add Schedule'
          reject(this.errorWrapper(error, message))
        })
    })
  }
}
