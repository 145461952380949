<template>
  <div>
    <div
      v-if="item.type !== 'notification'"
      class="ui-toast relative sm:w-97 my-5 bg-primary-white rounded-xl border component"
      :class="toastState.class"
    >
      <div
        :class="[!item.description ? 'items-center sm:py-3 sm:px-4 py-2 px-3' : 'sm:p-4 p-3']"
        class="flex flex-row justify-between sm:gap-5 gap-3"
      >
        <div
          :class="[{ 'items-center': !item.description }]"
          class="ui-toast__container grid gap-2.5"
        >
          <div
            class="h-10 w-10 flex items-center justify-center rounded-full"
            :style="{
              backgroundColor: toastState.icon.backgroundColor,
              border: `6px solid ${toastState.icon.borderColor}`,
            }"
          >
            <icon
              class="icon d-f ai-c lrt:mr-10 rtl:ml-10"
              :icon="toastState.icon.name"
              :color="toastState.icon.color"
              height="16.67"
              width="16.67"
            />
          </div>
          <div class="toast d-f ac-c font-roboto">
            <div class="font-medium text-text-color sm:text-sm text-xs">
              <span v-if="item.type === 'error'">
                {{ $filters.capitalizeCharacterFromSentense(item.message) }}.
              </span>
              <span v-else>{{ item.message }}.</span>
              <a v-if="item.type === 'info' && item.learnMore" class="text-twitter-color underline">
                {{ GENERAL_CONSTANT.LEARN_MORE }}
              </a>
            </div>
            <div v-if="item.description" class="toast__body text-xs text-menu font-roboto">
              {{ item.description }}
            </div>
          </div>
        </div>
        <div class="cursor-pointer ui-toast__actions">
          <span class="lh-0" @click="remove">
            <icon icon="cancel" height="20" width="20" color="text-color" />
          </span>
        </div>
      </div>
    </div>
    <div
      v-else
      class="ui-toast relative w-88 sm:w-97 my-5 rounded-xl bg-primary-white component border"
    >
      <div
        :class="toastState.class"
        class="bg-bg-green-left py-3 px-2.5 border-b border-primary-green rounded-tl-lg rounded-tr-lg"
      >
        <div class="ui-toast__container items-center">
          <div class="icon mt-1 ltr:mr-1 sm:ltr:mr-auto">
            <icon
              class="icon d-f ai-c lrt:mr-10 rtl:ml-10"
              :icon="toastState.icon.name"
              :color="toastState.icon.color"
              height="24.09"
              width="24.09"
            />
          </div>
          <div class="toast d-f ac-c ltr:pl-2 rtl:pr-2 font-roboto">
            <div class="text-text-color text-sm font-medium">New Notification</div>
          </div>
        </div>
        <div class="ui-toast__action rtl:left-4 rtl:right-auto d-f">
          <span class="lh-0" @click="remove">
            <icon icon="cross" height="11.3" width="11.3" color="text-color" />
          </span>
        </div>
      </div>
      <div class="p-4">
        <div class="flex gap-2.5 items-center">
          <div v-if="item.image" class="w-7 h-7">
            <img class="rounded-full h-full w-full" :src="item.image" alt="" />
          </div>
          <div
            v-else
            class="h-7 w-7 rounded-full bg-gray-300 flex justify-center items-center text-xs text-gray-700"
          >
            {{ $filters.getInitial(item.related_user.first_name, item.related_user.last_name) }}
          </div>
          <div class="font-roboto font-medium text-primary-green text-sm">{{ item.message }}</div>
        </div>
        <p class="mt-2.5 font-roboto font-medium text-sm text-menu">
          {{ item.description }}
        </p>
        <div
          class="mt-4 font-roboto flex"
          :class="item.learnMore ? 'justify-between' : 'justify-end'"
        >
          <div v-if="item.learnMore" class="text-sm font-medium text-primary-green">
            {{ GENERAL_CONSTANT.LEARN_MORE }}
          </div>
          <div class="text-xs text-menu justify-end-end">
            {{ $filters.getTimeOnly(item.inserted_at, DATE_TIME_CONSTANT.time12H) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import icon from '@components/icons/icon.vue'
import GENERAL_CONSTANT from '@src/constants/general-constants'
import DATE_TIME_CONSTANT from '@src/constants/date-time-constants.js'
export default {
  name: 'UiToast',
  components: {
    icon,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  emits: ['remove'],
  data() {
    return {
      GENERAL_CONSTANT,
      DATE_TIME_CONSTANT,
    }
  },

  computed: {
    /*
     * Checks the position of the Ui-toast.
     * @param {void} Nothing.
     * @retuns {null/true} return value is based on the Boolean value of the "this.item.description" data property
     */
    isCentered() {
      return this.item.description ? null : true
    },

    /*
     * Sets the current state of the toast based on the value of the "this.item.type" data property.
     * @param {void} Nothing.
     * @retuns {object} state - the current state of the toast.
     */
    toastState() {
      let state = {
        class: 'default',
        icon: {
          name: 'checkCircle',
          color: 'menu',
          backgroundColor: 'white',
          borderColor: 'black',
        },
      }

      switch (this.item.type) {
        case 'success':
          state = {
            class: 'success',
            icon: {
              name: 'check-circle',
              color: 'primary-green',
              backgroundColor: 'var(--primary-light-green-100)',
              borderColor: 'var(--primary-light-green-50)',
            },
          }
          break
        case 'warning':
          state = {
            class: 'warning',
            icon: {
              name: 'exclamationCircle',
              color: 'text-color-info',
            },
          }
          break
        case 'error':
          state = {
            class: 'error',
            icon: {
              name: 'exclamationCircle',
              color: 'text-color-danger',
              backgroundColor: 'var(--error-100)',
              borderColor: 'var(--error-50)',
            },
          }
          break
        case 'notification':
          state = {
            class: 'notification',
            icon: {
              name: 'checkCircle',
              color: 'primary-green',
            },
          }
          break
        case 'info':
          state = {
            class: 'info',
            icon: {
              name: 'exclamationCircle',
              color: 'primary-purple-600',
              backgroundColor: 'var(--primary-purple-100)',
              borderColor: 'var(--primary-purple-50)',
            },
          }
          break
        default:
          state = {
            class: 'default',
            icon: {
              name: 'exclamationCircle',
              color: 'menu',
            },
          }
          break
      }

      return state
    },
  },

  mounted() {
    if (this.item.duration) {
      const timer = setTimeout(() => {
        clearTimeout(timer)
        if (!this.item.sticky) this.remove()
      }, this.item.duration)
    }
  },

  methods: {
    remove() {
      this.$emit('remove', this.item.id)
    },
  },
}
</script>

<style lang="scss">
.ui-toast {
  box-shadow: 2px 6px 19px #00000029;
  .toast {
    flex-direction: column;
    &__body {
      margin-top: 4px;

      /* @include font('Roboto', 0.8125em, normal); */

      /* color: $label-text; */
      color: var(--label-text);
    }
  }
  &.default {
    border: 1px solid;
  }
  &__container {
    grid-template-columns: auto 1fr;
    width: 89%;
  }
  &__actions {
    height: fit-content;
  }
  &__action {
    position: absolute;
    top: 18px;
    right: 10px;
    span {
      cursor: pointer;
    }
    &--centered {
      top: 24px;
      transform: translateY(10px);
    }
  }
}

@media (max-width: 475px) {
  .ui-toast {
    width: 320px;
  }
}

@media (max-width: 400px) {
  .ui-toast {
    width: 280px;
  }
}
</style>
