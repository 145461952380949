<template>
  <div
    class="font-inter font-medium justify-start gap-1 flex w-full text-sm items-center"
    :class="categoryLength > 1 && 'mb-2.5'"
  >
    <div class="flex text-start text-primary-gray-500">
      {{ $t(`rightBar.${category.text}`) }}
    </div>
    <div class="w-auto flex-1 justify-end flex text-primary-gray-700">
      <span v-if="category.currency">{{ category.currency }}&nbsp;</span>
      {{ category.value }}
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    category: {
      type: Object,
      default: undefined,
    },
    categoryLength: {
      type: Number,
      default: 1,
    },
  },
}
</script>
