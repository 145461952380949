//import Vue from 'vue'
const state = {
  curLanguage: JSON.parse(window.localStorage.getItem('curLanguage')) || {
    short: 'en',
    long: 'English',
    dir: 'ltr',
  },
  theme: window.localStorage.getItem('theme') || null,
  themes: [
    {
      name: 'green',
      dark: true,
    },
    {
      name: 'maroon',
      dark: true,
    },
    // {
    //   name: 'blue',
    //   dark: true,
    // },
    // {
    //   name: 'orange',
    //   dark: true,
    // },
    // {
    //   name: 'red',
    //   dark: true,
    // },
  ],
  languages: [
    {
      id: 0,
      short: 'en',
      long: 'English',
      dir: 'ltr',
    },
    {
      id: 1,
      short: 'ur',
      long: 'Urdu',
      dir: 'rtl',
    },
    {
      id: 2,
      short: 'ar',
      long: 'Arabic',
      dir: 'rtl',
    },
  ],
}
const mutations = {
  // Language Mutations
  // REMOVE_PREVIOUS_LANG(state, lang) {
  //   const previousLang = Vue.i18n.locale()
  //   Vue.i18n.remove(previousLang)
  // },
  SET_NEW_LANG(state, lang) {
    // Vue.i18n.set(lang)
    state.languages.map(function (language) {
      if (lang === language.short) {
        state.curLanguage = language
      }
    })
    document.documentElement.setAttribute('lang-is', lang)
  },
  // Body Direction
  BODY_DIR(state, dir = 'ltr') {
    document.getElementsByTagName('body')[0].setAttribute('dir', dir)
  },
  SET_LANG_LS(state, payload) {
    window.localStorage.setItem('curLanguage', JSON.stringify(payload))
  },
  SET_LS(state, payload) {
    window.localStorage.setItem(payload.name, JSON.stringify(payload.data))
  },
  GET_LS(state, payload) {
    state[payload.assignee] = JSON.parse(window.localStorage.getItem(payload.name)) || {
      error: 'Not Found',
      message: 'Check localStoragte if error Exist',
    }
  },
  SET_STATE_BASE_THEME(state, payload) {
    state.theme = JSON.parse(payload)
  },
  SET_THEME_TO_LOCAL_STORAGE(state, payload) {
    window.localStorage.setItem('theme', payload)
  },
  SET_BASE_THEME(state, payload) {
    payload.setAttribute('data-theme', state.theme.name)
    /* SETTING IF THEME IS DARK SHADED OR LIGHT SHADDED */
    if (state.theme.name.includes('dark')) {
      payload.setAttribute('theme-is', 'dark')
    } else {
      payload.setAttribute('theme-is', 'light')
    }
  },
  SET_LS_THEME(state, payload) {
    window.localStorage.setItem('theme', JSON.stringify(payload))
  },
}

// Language Actions
const actions = {
  loadInitialLang(context, payload) {
    context.dispatch('handleLanguagePref', payload?.short)
    // update direction
    context.commit('BODY_DIR', payload?.dir)
  },

  handleLanguagePref(context, lang) {
    // set language in state
    context.commit('SET_NEW_LANG', lang)
    // Load new language file
  },
  loadInitalTheme(context, payload) {
    // if localStorage Null add Theme Object to it
    if (context.state.theme === null) {
      const newTheme = JSON.stringify({ name: 'green' })
      context.commit('SET_STATE_BASE_THEME', newTheme)
    } else {
      // if theme from LS is not null then apply it
      context.commit('SET_STATE_BASE_THEME', context.state.theme)
      context.commit('SET_BASE_THEME', payload)
    }
  },
  changeTheme(context, payload) {
    const { theme } = payload
    context.commit('SET_BASE_THEME', document.documentElement)
    context.commit('SET_LS_THEME', theme)
  },

  init(context) {
    const localLang = localStorage.getItem('curLanguage')
    const curLang = localLang ? JSON.parse(localLang) : { short: 'en', long: 'English', dir: 'ltr' }
    context.dispatch('loadInitialLang', curLang)
    context.dispatch('loadInitalTheme', document.documentElement)
  },

  // todo: add user sign in actions and set protective routes
}

const getters = {}

export default {
  state,
  mutations,
  actions,
  getters,
}
