import { TENNAT_API_URL, TEAM_TENNAT_API_URL } from '@services/.env'
import { BaseService } from './base.service'
import { by, get, del, put } from './custom.service'
import { extractErrorMassage } from '@utils/generalUtil'
export default class SettingsService extends BaseService {
  static setDefaultSettings(data) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}institute_settings`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  // Get Default Settings Endpoint

  static getDefaultSettings() {
    return new Promise((resolve, reject) => {
      return get(`${TENNAT_API_URL}institute_settings`)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
  /* GENERAL SETTING */
  static setLogoAndFavicon(data) {
    return put(
      `${TENNAT_API_URL}institutes/${data.type == 'logo' ? 'upload_image' : 'upload_favicon'}`,
      data.formData,
    )
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
  }
  static setInstituteNameAndTitle(data) {
    return by(`${TEAM_TENNAT_API_URL}institutes/general_setting`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
  }
  static getContactSettings(data, commit) {
    return get(`${TEAM_TENNAT_API_URL}tenant_contacts`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }
  static addAndUpdateContactSetting(data, commit) {
    const id = data?.id
    if (id) {
      return by(`${TEAM_TENNAT_API_URL}tenant_contacts`, data, id)
        .then((response) => [this.responseWrapper(response, response?.data), null])
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          const responseError = this.errorWrapper(error, message)
          return [null, responseError]
        })
        .finally(() => {
          commit('layout/IS_LOADING', false, { root: true })
        })
    } else {
      return by(`${TEAM_TENNAT_API_URL}tenant_contacts`, data)
        .then((response) => [this.responseWrapper(response, response?.data), null])
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          const responseError = this.errorWrapper(error, message)
          return [null, responseError]
        })
        .finally(() => {
          commit('layout/IS_LOADING', false, { root: true })
        })
    }
  }
  static deleteContactSetting(data, commit) {
    return del(`${TEAM_TENNAT_API_URL}tenant_contacts`, data?.id)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }
  static getBillingSetting(data, commit) {
    return get(`${TEAM_TENNAT_API_URL}billing_addresses`)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }
  static setOrUpdateBillingSetting(data, commit) {
    const id = data?.id
    if (id) {
      return by(`${TEAM_TENNAT_API_URL}billing_addresses`, data, id)
        .then((response) => [this.responseWrapper(response, response?.data), null])
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          const responseError = this.errorWrapper(error, message)
          return [null, responseError]
        })
        .finally(() => {
          commit('layout/IS_LOADING', false, { root: true })
        })
    } else {
      return by(`${TEAM_TENNAT_API_URL}billing_addresses`, data)
        .then((response) => [this.responseWrapper(response, response?.data), null])
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          const responseError = this.errorWrapper(error, message)
          return [null, responseError]
        })
        .finally(() => {
          commit('layout/IS_LOADING', false, { root: true })
        })
    }
  }

  static postSiteSettings(data, commit) {
    return by(`${TENNAT_API_URL}institute_settings`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static getPublicSettings(data, commit) {
    return by(`${TENNAT_API_URL}public_setting`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  // Get all general settings

  static getGeneralSettings() {
    return get(`${TEAM_TENNAT_API_URL}institutes/general_setting`)
      .then((response) => this.responseWrapper(response, response?.data))
      .catch((error) => {})
  }
  /* EXAM PLANNER SETTINGS */
  static getExamPlannerSettings(data, commit) {
    return get(`${TENNAT_API_URL}exam_planner_permissions/filter`)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static updateExamPlannerSettings(data, commit) {
    return put(`${TENNAT_API_URL}exam_planner_permissions/update`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }
  /* TEACHER SETTINGS */
  static getTeacherSettings(data, commit) {
    return get(`${TENNAT_API_URL}teacher_permissions/filter`)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static updateTeacherSettings(data, commit) {
    return put(`${TENNAT_API_URL}teacher_permissions/update`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }
  /* TIMETABLE SETTINGS */
  static getTimeTableSettings(data, commit) {
    return get(`${TENNAT_API_URL}time_table_permissions/filter`)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static updateTimeTableSettings(data, commit) {
    return put(`${TENNAT_API_URL}time_table_permissions/update`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  /* SECTION SETTINGS */
  static getSectionSettings(data, commit) {
    return get(`${TENNAT_API_URL}section_permissions/filter`)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static updateSectionSettings(data, commit) {
    return put(`${TENNAT_API_URL}section_permissions/update`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  /* STAFF SETTINGS */
  static getStaffSettings(data, commit) {
    return get(`${TENNAT_API_URL}staff_permissions/filter`)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static updateStaffSettings(data, commit) {
    return put(`${TENNAT_API_URL}staff_permissions/update`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  /* CLASS SETTINGS */
  static getClassSettings(data, commit) {
    return get(`${TENNAT_API_URL}class_permissions/filter`)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static updateClassSettings(data, commit) {
    return put(`${TENNAT_API_URL}class_permissions/update`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  /* MODULE SETTINGS //--> Attandance */
  static getCampusAttandaceSettings(data, commit) {
    return get(`${TENNAT_API_URL}campus_attendance_permissions/filter`)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static updateCampusAttandaceSettings(data, commit) {
    return put(`${TENNAT_API_URL}campus_attendance_permissions/update`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static getSectionAttandaceSettings(data, commit) {
    return get(`${TENNAT_API_URL}section_attendance_permissions/filter`)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = extractErrorMassage(error)
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static updateSectionAttandaceSettings(data, commit) {
    return put(`${TENNAT_API_URL}section_attendance_permissions/update`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = extractErrorMassage(error)
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static getPeriodAttandaceSettings(data, commit) {
    return get(`${TENNAT_API_URL}period_attendance_permissions/filter`)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = extractErrorMassage(error)
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }
  static getSyllabusTemplateSettings(data, commit) {
    return get(`${TENNAT_API_URL}syllabus_automator_permissions/filter`)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = extractErrorMassage(error)
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }
  static updatePeriodAttandaceSettings(data, commit) {
    return put(`${TENNAT_API_URL}period_attendance_permissions/update`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = extractErrorMassage(error)
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }
  static updateSyllabusTemplateSettings(data, commit) {
    return put(`${TENNAT_API_URL}syllabus_automator_permissions/update`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = extractErrorMassage(error)
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  /* MODULE SETTINGS FEE_TYPES */
  static getFeePermissionSettings(data, commit) {
    return get(`${TENNAT_API_URL}fee_setting_permissions/filter`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static updateFeePermissionSettings(data, commit) {
    return put(`${TENNAT_API_URL}fee_setting_permissions/update`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  /* MODULE SETTINGS SYLLABUS */
  static getSyllabusPermissionSettings(data, commit) {
    return get(`${TENNAT_API_URL}syllabus_permissions/filter`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static updateSyllabusPermissionSettings(data, commit) {
    return put(`${TENNAT_API_URL}syllabus_permissions/update`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  /* MODULE SETTINGS SALARY */
  static getSalaryPermissionSettings(data, commit) {
    return get(`${TENNAT_API_URL}salary_permissions`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static updateSalaryPermissionSettings(data, commit) {
    return by(`${TENNAT_API_URL}salary_permissions`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  /* MODULE SETTINGS EXAM */
  static getExamPermissionSettings(data, commit) {
    return get(`${TENNAT_API_URL}exam_permissions`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static updateExamPermissionSettings(data, commit) {
    return by(`${TENNAT_API_URL}exam_permissions`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  /* MODULE SETTINGS SCHEDULE */
  static getSchedulePermissionSettings(data, commit) {
    return get(`${TENNAT_API_URL}schedule_permissions`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static updateSchedulePermissionSettings(data, commit) {
    return by(`${TENNAT_API_URL}schedule_permissions`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  /* MODULE SETTINGS LESSON PLANNER */
  static getLessonPlannerPermissionSettings(data, commit) {
    return get(`${TENNAT_API_URL}lesson_planner_permissions/filter`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static updateLessonPlannerPermissionSettings(data, commit) {
    return put(`${TENNAT_API_URL}lesson_planner_permissions/update`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  /* MODULE SETTINGS PICKUP */
  static getPickupPermissionSettings(data, commit) {
    return get(`${TENNAT_API_URL}pickup_permissions/filter`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static updatePickupPermissionSettings(data, commit) {
    return put(`${TENNAT_API_URL}pickup_permissions/update`, data)
      .then((response) => [this.responseWrapper(response, response?.data), null])
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  /* Pickup Settings */
  static getPickupSettings(data) {
    return new Promise((resolve) => {
      return get(`${TENNAT_API_URL}pickup_facilitator_settings`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response?.data), null)
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          const responseError = this.errorWrapper(error, message)
          return [null, responseError]
        })
    })
  }
  static setPickcupSettings({ payload, id }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}pickup_facilitator_settings`, payload, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response?.data), null)
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  static getCurrenciesList(payload) {
    return new Promise((resolve, reject) => {
      return get(`${TENNAT_API_URL}currencies`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Error in creating staff'
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static updateFeaturesGeneralSettings(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}general_feature_settings`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Error in creating staff'
          reject(this.errorWrapper(error, message))
        })
    })
  }
  static getFeaturesGeneralSettings() {
    return new Promise((resolve, reject) => {
      return get(`${TENNAT_API_URL}general_feature_settings`)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Error in creating staff'
          reject(this.errorWrapper(error, message))
        })
    })
  }
}
