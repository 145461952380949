export default {
  NEW(state, payload) {
    const toast = {
      id: new Date().getTime(),
      message: typeof payload === 'string' ? payload : payload.message,
      type: payload.type || 'default',
      duration: payload.duration || 5000,
      description: payload.description || null,
      sticky: payload?.stickey || false,
    }
    if (toast.type === 'notification') {
      toast.image = payload.image
      toast.related_user = payload.related_user
      toast.inserted_at = payload.inserted_at
    }
    state.toastsList.push({ ...toast })
  },
  REMOVE(state, payload) {
    state.toastsList = state.toastsList.filter(({ id }) => id !== payload.id)
  },
  CLEAR_LIST(state) {
    state.toastsList = []
  },
}
