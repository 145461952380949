<template>
  <div ref="singleSelect" class="w-full" :class="childClass">
    <!-- Title -->
    <label
      v-if="!hideTitle"
      class="text-primary-gray-700 flex mb-1.5 font-inter font-medium"
      :class="[titleClasses]"
    >
      {{ translatedTitle }}
      <span v-if="rules.includes('required')" class="text-error-500 pl-1">*</span>
      <!-- Tooltip -->
      <div v-if="tooltip" class="-mt-0.5">
        <div
          class="flex-item ml-2 rtl:mr-2 self-center cursor-pointer"
          @click="clickToolTip"
          @mouseover="displayToolTip"
          @mouseleave="hideToolTip"
        >
          <Icon
            class="icon relative"
            icon="infoCircle"
            color="primary-grey"
            height="19.617"
            width="15.5"
          />
          <div
            v-if="showToolTip"
            class="-ml-24 absolute md:-ml-7 mt-3 md:mt-2 rtl:-mr-24 md:rtl:-mr-7 z-10"
          >
            <Tooltip class="w-min sm:w-auto" direction="left" />
          </div>
        </div>
      </div>
    </label>

    <!-- Main Container -->
    <div class="min-w-1 md:min-w-1.5" :class="[containerClasses]">
      <Field
        v-show="false"
        v-model="singleItem"
        :rules="rules"
        :name="name === '' ? title : name"
        :label="getErrorMessageKey"
      />
      <section class="w-full relative bg-primary-white rounded-lg">
        <UiOnClickOutside :do="onClickOutside">
          <div class="flex">
            <!-- SELECTED ITEMS ETC -->

            <div
              class="flex h-11 items-center justify-between rounded-lg cursor-pointer border relative rtl:flex-row-reverse pl-2 py-2 w-full"
              :class="[
                selectBoxClasses,
                isVisible && 'shadow-purple-100 border-primary-purple-300',
                disabled && 'cursor-not-allowed',
                inputColor,
                isErrorAvailable && 'bg-error-50 border border-error-500 rounded-md',
                backgroundColor,
                borderRounded && 'border rounded-full',
                ,
              ]"
              :title="allowHtmlToolTipForLongText && singleItem ? getLabel(singleItem) : ''"
            >
              <div v-if="showLock" class="pt-0.5 px-1">
                <UiLock
                  class="ltr:border-r rtl:border-l border-text-color-grey -ml-1 py-1 pt-2 pr-3"
                  :model-value="lockValue"
                  :deactivate="isLockedFromUpperLevel"
                  @update:modelValue="updateLockEvent"
                />
              </div>
              <div
                class="w-full border-primary-grey flex items-center h-full relative"
                @click.stop="toggleDropdown()"
              >
                <span
                  v-if="!singleItem"
                  class="text-sm md:text-base align-baseline flex self-center gap-2 opacity-50 text-gray-500 overflow-hidden font-normal px-1.5"
                  :class="[inputValueClasses]"
                >
                  <span v-if="image" class="mb-[2px] self-center">
                    <Icon
                      class="icon relative"
                      icon="dummyUser"
                      color="primary-white"
                      height="15"
                      width="13"
                    />
                  </span>

                  <span>
                    {{ $t(`placeholder.${placeholder}`) }}
                  </span>
                </span>
                <!-- header, if there is any selected item -->
                <span
                  v-else
                  class="rounded-full overflow-hidden px-1 w-auto py-0.5"
                  :class="[selectedItemClasses]"
                  @mouseover="uiDisplayToolTip"
                  @mouseleave="uiHideToolTip"
                >
                  <div class="flex w-full items-center rtl:pt-1 ltr:pt-0">
                    <div v-if="image">
                      <div v-if="image && singleItem.image" class="w-7 h-7 mr-1">
                        <img :src="singleItem.image" class="h-full w-full rounded-full" />
                      </div>
                      <span
                        v-else
                        class="h-7 w-7 rounded-full cursor-pointer flex justify-center items-center text-sm bg-primary-purple-50 text-primary-purple-600 ltr:mr-1 rtl:ml-1"
                      >
                        {{ initials(getLabel(singleItem)) }}
                      </span>
                    </div>
                    <div v-if="uiShowToolTip && showUiSelectTooptip">
                      <UiTooltip :title="uiSelectTooltipTitle" :description="uiSelectTooltipDes" />
                    </div>
                    <div>
                      <div
                        class="text-sm md:text-base font-medium capitalize text-gray-700 truncate whitespace-nowrap"
                        :class="[inputValueClasses]"
                        :style="{ width: selectedItemWidth }"
                      >
                        {{ getLabel(singleItem)?.replaceAll('_', ' ') }}
                      </div>
                    </div>
                  </div>
                </span>
                <span class="absolute pr-4 right-0" @click.stop="toggleDropdown()">
                  <Icon
                    :icon="iconType"
                    :height="chevronHeight"
                    :width="chevronWidth"
                    color="primary-gray-500"
                  />
                </span>
              </div>
            </div>
            <!-- Dropdown Section -->
            <div
              class="absolute top-11 text-sm md:text-base left-0 z-60"
              :class="dropDownContainer"
            >
              <div
                v-if="isVisible && !disabled"
                class="relative left-0 right-0 shadow-lg rounded-md border bg-white mt-2 pb-1"
              >
                <!-- search section -->

                <div
                  class="items-center flex gap-2 h-auto border-primary-grey border-b py-2"
                  :class="[hideSearchBar]"
                >
                  <span class="absolute ltr:left-3 rtl:right-3">
                    <Icon class="icon" icon="search" color="primary-grey-500" />
                  </span>
                  <input
                    v-model.trim="searchQuery"
                    class="lg:h-11 h-9 pl-9 bg-primary-gray-50 text-primary-gray-500 focus:outline-none font-normal font-roboto w-full"
                    type="text"
                    :placeholder="$t(`placeholder.Search`)"
                    @keyup="searchThroughApi ? emitQuery : filterData"
                  />
                </div>

                <!-- Selected List -->
                <div v-if="singleItem" class="border-b border-primary-grey">
                  <div
                    class="bg-primary-gray-50 flex flex-col h-auto justify-center items-between p-3 my-2"
                    :class="[singleItem.detail ? 'py-3.5 gap-0' : 'lg:py-3 py-2 ']"
                  >
                    <div class="flex w-full justify-between">
                      <div
                        class="flex items-center"
                        :title="allowHtmlToolTipForLongText ? getLabel(singleItem) : ''"
                      >
                        <div v-if="image">
                          <div v-if="image && singleItem.image" class="w-7 h-7 mr-1">
                            <img :src="singleItem.image" class="h-full w-full rounded-full" />
                          </div>
                          <span
                            v-else
                            class="h-7 w-7 rounded-full cursor-pointer flex justify-center items-center text-sm bg-primary-purple-50 text-primary-purple-600 ltr:mr-1 rtl:ml-1"
                          >
                            {{ initials(getLabel(singleItem)) }}
                          </span>
                        </div>
                        <div
                          class="line-clamp-3 font-roboto w-full font-medium capitalize text-gray-700 px-1"
                        >
                          {{ getLabel(singleItem)?.replaceAll('_', ' ') }}
                        </div>
                      </div>

                      <div
                        v-if="!hideUnSelectIcon"
                        class="flex items-center"
                        @click="removeSelectedItem(singleItem)"
                      >
                        <Icon
                          class="cursor-pointer"
                          color="primary-purple-600"
                          icon="cross"
                          height="13"
                          width="15"
                        />
                      </div>
                    </div>
                    <div v-if="singleItem.detail" class="text-menu px-2">
                      {{ singleItem.detail }}
                    </div>
                  </div>
                </div>

                <!-- Unselected List -->

                <div class="w-full">
                  <div
                    v-if="optionsArray.length === 0"
                    class="empty-list text-color-primary-grey text-center w-full p-3 bg-primary-white overflow-hidden"
                  >
                    {{ $t('dashboard.NO_ITEM') }}
                  </div>

                  <div v-else class="options w-full mt-1">
                    <ul
                      class="overflow-x-hidden list-none text-start overflow-y-auto max-h-[250px]"
                    >
                      <li
                        v-for="(item, index) in filterData"
                        :key="index"
                        class="w-full cursor-pointer hover:bg-bg-color-light h-auto flex flex-col justify-center"
                        :class="item.detail ? 'py-3.5 gap-0' : 'lg:py-3 py-2'"
                        @click="selectSingletItem(item)"
                      >
                        <div class="flex items-center px-2">
                          <div v-if="image">
                            <div v-if="image && item.image" class="w-7 h-7">
                              <img :src="item.image" class="h-full w-full rounded-full" />
                            </div>
                            <span v-else>
                              <div
                                class="h-7 w-7 p-0.5 rounded-full cursor-pointer flex justify-center items-center text-sm bg-primary-purple-50 text-primary-purple-600 mr-1 rtl:ml-2"
                              >
                                {{ initials(getLabel(item)) }}
                              </div>
                            </span>
                          </div>
                          <div
                            class="line-clamp-3 capitalize font-roboto font-medium w-full text-gray-600 pl-1"
                            :title="allowHtmlToolTipForLongText ? getLabel(item) : ''"
                          >
                            {{ getLabel(item)?.replaceAll('_', ' ') }}
                          </div>
                        </div>
                        <div v-if="item.detail" class="pl-5 pr-2 text-menu">
                          {{ item.detail }}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </UiOnClickOutside>
      </section>
    </div>
    <!-- ERRORS -->
    <div v-if="!noError" class="w-full info-wrapper">
      <div ref="errorContainer" class="flex justify-end h-5">
        <ErrorMessage v-slot="{ message }" :name="name === '' ? title : name">
          <small class="error-msg text-text-color-danger text-sm italic">
            <p>{{ displayError(message) }}</p>
          </small>
        </ErrorMessage>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@components/icons/icon.vue'
import { filterdData } from '@src/utils/generalUtil.js'
import { translatedError } from '@src/utils/generalUtil.js'
import UiOnClickOutside from '@src/components/UiElements/UiOnClickOutside.vue'
import Tooltip from '@src/components/tooltip.vue'
import generalUtil from '@src/mixins/general-mixins.js'
import UiLock from '@components/UiElements/UiLockAnimation.vue'
import tooltipMixin from '@/src/mixins/components/tooltip-mixin.js'
import UiTooltip from '@src/components/UiSelectTooltip.vue'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import validationMixin from '@src/mixins/components/validation-mixin.js'
import { Field, ErrorMessage, configure } from 'vee-validate'
import { validationConfiguration } from '@src/vee-validate/index.js'
configure(validationConfiguration)
export default {
  name: 'UiSingleSelect',
  components: {
    Icon,
    UiOnClickOutside,
    Field,
    ErrorMessage,
    Tooltip,
    UiLock,
    UiTooltip,
  },
  mixins: [generalUtil, tooltipMixin, validationMixin],
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    selectBoxClasses: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    inputValueClasses: {
      type: String,
      default: '',
    },
    noError: {
      type: Boolean,
      default: false,
    },
    dynamicTitle: {
      type: Boolean,
      default: false,
    },
    uiSelectTooltipTitle: {
      type: String,
      default: '',
    },
    uiSelectTooltipDes: {
      type: String,
      default: '',
    },
    titleClasses: {
      type: String,
      default: 'text-sm',
    },
    showUiSelectTooptip: {
      type: Boolean,
      default: false,
    },
    childClass: { type: String, default: '' },
    placeholder: {
      type: String,
      default: 'Select',
    },
    borderRounded: {
      type: Boolean,
      default: false,
    },
    containerClasses: {
      type: String,
      default: '',
    },
    inputColor: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    tooltip: {
      type: Boolean,
      default: false,
    },
    cardUnSelected: {
      type: Boolean,
      default: false,
    },
    cardSelected: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number, Boolean, Object],
      default: '',
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },

    hideUnSelectIcon: {
      type: Boolean,
      default: false,
    },
    dropDownContainer: {
      type: String,
      default: 'w-full',
    },
    rules: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    image: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: 'autocomplete',
    },
    options: {
      type: [Array, Object],
      default: () => [],
    },
    languageStyling: {
      type: String,
      default: 'text-gray-700',
    },
    title: { type: String, default: 'title' },
    label: { type: String, default: 'title' },
    reduce: { type: String, default: '' },
    selectedItemClasses: { type: String, default: 'title' },
    showLock: { type: Boolean, default: false },
    lockValue: { type: Boolean, default: false },
    searchThroughApi: { type: Boolean, default: false },
    selectObjectWithoutReduceKey: { type: Boolean, default: false },
    allowHtmlToolTipForLongText: { type: Boolean, default: false },
    isLockedFromUpperLevel: { type: Boolean, default: false },
    filter: {
      type: Object,
      default: () => {},
    },
  },
  emits: [
    'search',
    'emitQuery',
    'changeMode',
    'deSelect',
    'updateLockValue',
    'selectedObj',
    'update:modelValue',
    'change',
  ],
  data() {
    return {
      searchQuery: '',
      singleItem: '',
      optionsArray: [],
      isVisible: false,
      isErrorAvailable: false,
      itemIndex: '',
      dashboard: 'dashboard',
      singleSelectWidth: 0,
    }
  },
  computed: {
    selectedItemWidth() {
      return `${this.singleSelectWidth - 50}px`
    },
    translatedTitle() {
      return !this.dynamicTitle ? this.$t(`title.${this.title}`) : this.title
    },
    iconType() {
      return this.disabled || !this.isVisible ? 'chevronBottom' : 'chevronUp'
    },
    getErrorMessageKey() {
      return this.dynamicTitle ? this.title : this.$t(`placeholder.${this.title}`)
    },
    inputBoxStyle() {
      let response = 'lg:h-11 h-9 min-h-[33px] p-3 w-full'
      return response
    },
    /**
     * Filter Data
     * @returns {String} - filtered String
     * @description filter data from options list by input search query returning the filtered list.
     */
    filterData() {
      const query = this.searchQuery.toLowerCase()
      if (this.searchThroughApi) return this.optionsArray
      else return filterdData(query, this.optionsArray)
    },
    emitQuery() {
      return this.$emit('search', this.searchQuery)
    },
    hideSearchBar() {
      let response
      if (this.optionsArray.length <= 9 && !this.searchThroughApi) {
        response = 'hidden'
      } else {
        response = 'flex'
      }

      return response
    },
    chevronHeight() {
      let height = this.languageStyling ? '6' : '15'
      return height
    },
    chevronWidth() {
      let width = this.languageStyling ? '10' : '15'
      return width
    },
  },
  watch: {
    options: {
      immediate: true,
      handler() {
        this.optionsArray = JSON.parse(JSON.stringify(this.options))
        if (this.modelValue !== '') {
          this.editForm()
        }
      },
    },
    modelValue: {
      handler(value) {
        if (value !== '') {
          this.editForm()
          this.errorHandler()
        } else if (value != null && typeof value === 'object' && !Object.values(value).length) {
          this.singleItem = null
        } else if (!value) {
          this.singleItem = null
          this.errorHandler()
          if (this.filter && this.filter.option) this.emitWhereQuery()
        }
      },
    },
  },
  mounted() {
    this.getSingleSelectWidth()
    window.addEventListener('resize', this.getSingleSelectWidth)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.getSingleSelectWidth)
  },
  methods: {
    getSingleSelectWidth() {
      this.singleSelectWidth = this.$refs.singleSelect.offsetWidth
    },
    emitWhereQuery(value = '') {
      this.$emit('emitQuery', buildWhereQuery(this.filter.option, this.filter.key, value))
    },
    translatedError,
    toggleMode() {
      this.$emit('changeMode')
    },
    /**
     * Remove Selected Item
     * @param {object} item
     * @returns {void}
     * @description Function description:
     * - Gets item as param and remove it from selected items list
     * - Adds item in unselected items
     * - Emitting the removed item
     */
    removeSelectedItem(item) {
      this.optionsArray.splice(this.itemIndex, 0, item)
      this.singleItem = ''
      this.$emit('update:modelValue', this.singleItem)
      this.$emit('deSelect', item)
      if (this.filter && this.filter.option) this.emitWhereQuery()
    },
    /**
     * Edit Form
     * @description getting the data through v-model if any edit form is opened,
     */
    editForm() {
      // if options passed are objects
      if (this.reduce)
        this.optionsArray.filter((option, idx) => {
          if (option[this.reduce] === this.modelValue) {
            this.optionsArray.splice(idx, 1)
            this.itemIndex = idx
            this.singleItem = option
          }
        })
      // Edit Object With out reduce key
      else if (this.selectObjectWithoutReduceKey) {
        this.singleItem = this.modelValue
        this.optionsArray.filter((option, idx) => {
          if (JSON.stringify(option) === JSON.stringify(this.singleItem)) {
            this.optionsArray.splice(idx, 1)
            this.itemIndex = idx
          }
        })
      }
      // if options are static array (not objects)
      else {
        this.singleItem = this.modelValue
        this.optionsArray.filter((option, idx) => {
          if (option === this.singleItem) {
            this.optionsArray.splice(idx, 1)
            this.itemIndex = idx
          }
        })
      }
    },
    /**
     * Get Label
     * @param {object | *}  - option
     * @returns {object} - option
     * @description Function Description :
     * - checking the type of param, if it is object then returning the filtered data from the list
     */
    getLabel(option) {
      if (typeof option === 'object') {
        return option[this.label]
      }
      return option
    },
    /**
     * Toggle Dropdown
     * @description Open or Close the dropdown
     */
    toggleDropdown() {
      this.toggleMode()
      this.isVisible = !this.isVisible
      if (!this.isVisible) {
        this.searchQuery = ''
      }
      if (!this.disabled && this.searchThroughApi) {
        this.searchQuery = ''
        this.$emit('search', this.searchQuery)
      }
    },
    /**
     * On Click Outside
     * @description Close the dropdown and reset the search box
     */
    onClickOutside() {
      this.searchQuery = ''
      if (this.isVisible && this.searchThroughApi) this.$emit('search', this.searchQuery)
      this.isVisible = false
    },
    updateLockEvent(value) {
      this.$emit('updateLockValue', value)
    },
    /**
     * Select Single Item
     * @param {object }  - item
     * @returns {Void}
     * @description Function Description :
     * - Get single item as param and assign it to a data variable
     * - If reducer is passed, we are emitting the reduced value of the item otherwise the complete item
     * - Closing the dropdown if any value is selected
     */
    selectSingletItem(item) {
      let value
      if (this.singleItem) {
        this.optionsArray.splice(this.itemIndex, 0, this.singleItem)
      }
      this.singleItem = item
      if (this.reduce)
        this.optionsArray.filter((option, idx) => {
          if (option[this.reduce] === this.singleItem[this.reduce]) {
            this.optionsArray.splice(idx, 1)
            this.itemIndex = idx
          }
        })
      else
        this.optionsArray.filter((option, idx) => {
          if (option === this.singleItem) {
            this.optionsArray.splice(idx, 1)
            this.itemIndex = idx
          }
        })

      if (this.reduce) value = this.singleItem[this.reduce]
      else value = this.singleItem
      if (this.reduce) {
        this.$emit('selectedObj', this.singleItem)
      }
      this.$emit('update:modelValue', value)
      this.$emit('change', value)
      if (
        (value && this.filter && this.filter.option) ||
        (value === false && this.filter && this.filter.option)
      )
        this.emitWhereQuery(value)
      if (!this.singleItem && this.searchThroughApi) {
        this.searchQuery = ''
        this.$emit('search', this.searchQuery)
      }
      this.isVisible = false
    },
  },
}
</script>
