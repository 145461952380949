import { RegistrationService } from '@services/registration.service'

export default {
  generateForm({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return RegistrationService.generateForm(payload)
        .then((response) => {
          commit('toast/NEW', { message: response.message, type: 'success' }, { root: true })
          resolve(response.data)
        })
        .catch((error) => {
          commit('layout/IS_LOADING', false, { root: true })
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
}
