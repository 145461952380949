export default {
  data() {
    return {
      showToolTip: false,
      clickTooltipIcon: false,
      uiShowToolTip: false,
      uiClickTooltip: false,
      isActive: false,
      searchQuery: '',
      isOpen: false,
      optionsArray: [],
      isVisible: false,
    }
  },

  methods: {
    /**
     * Get Label
     * @param {object | *} option - Object or any other datatype
     * @return {string | *} - Returns string in case of object else returns parameter as it is.
     * @description Gets option as param and returns mentioned value of key that provided in item
     */
    getLabel(option) {
      if (typeof option === 'object') {
        return option[this.label]
      }
      return option
    },
    /**
     * Display ToolTip
     * @description  Displays tooltip on hovering
     */
    displayToolTip() {
      this.showToolTip = true
    },
    uiDisplayToolTip() {
      this.uiShowToolTip = true
    },
    /**
     * Hide ToolTip
     * @description Hides tooltip on hovering out of the icon
     */
    hideToolTip() {
      if (this.showToolTip && this.clickTooltipIcon) {
        this.showToolTip = true
      } else {
        this.showToolTip = false
        this.clickTooltipIcon = false
      }
    },
    uiHideToolTip() {
      if (this.uiShowToolTip && this.uiClickTooltip) {
        this.uiShowToolTip = true
      } else {
        this.uiShowToolTip = false
      }
    },
    /**
     * Click ToolTip
     * @description Makes tooltip sticky on click
     */
    clickToolTip() {
      this.clickTooltipIcon = !this.clickTooltipIcon
    },
    uiClickToolTip() {
      this.uiClickTooltip = true
      this.uiShowToolTip = !this.uiShowToolTip
    },

    /**
     * Close ToolTip
     * @description Closes the tooltip if it is open
     */
    closeToolTip() {
      if (this.showToolTip && this.clickTooltipIcon) {
        this.showToolTip = false
        this.clickTooltipIcon = false
      }
    },
  },
}
