<template>
  <div class="relative" :class="graphData?.type === 'bar' ? 'mr-2' : 'p-4'">
    <div v-if="!isdata" class="absolute w-full h-full flex items-center justify-center">
      <NoRecordFound />
    </div>
    <canvas ref="chartCanvas"></canvas>
  </div>
</template>

<script setup>
import GRAPH_CONSTANTS from '@src/constants/graph-constants.js'
import { ref, watch, onMounted } from 'vue'
import Chart from 'chart.js/auto'
import { objectDeepCopy } from '@/src/utils/generalUtil'
import isEmpty from 'lodash/isEmpty'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import {
  afterDraw,
  configureLegend,
  configureDatalabels,
  setDatasetFill,
  configureTooltip,
} from '@/src/router/views/graph/graphView.js'

Chart.register(ChartDataLabels)

const props = defineProps({
  chartData: { type: Object, default: () => ({}) },
  enableDatalabels: { type: Boolean, default: false },
  uniqueLegend: { type: Boolean, default: false },
  tooltip: { type: String, default: '' },
})

let myChart = null
const graphData = ref(null)
const chartCanvas = ref(null)
const isdata = ref(false)

watch(
  () => props.chartData,
  (newChartData) => {
    graphData.value = objectDeepCopy(newChartData)
    reRenderChart()
  },
  { deep: true },
)

watch(
  () => props.enableDatalabels,
  () => {
    reRenderChart()
  },
)

onMounted(() => {
  reRenderChart()
})

const renderChart = () => {
  if (!chartCanvas.value) return

  const ctx = chartCanvas.value.getContext('2d')
  const options = objectDeepCopy(graphData.value?.options || {})
  const datasets = objectDeepCopy(graphData.value?.data?.datasets || {})
  const startTime = objectDeepCopy(graphData.value?.startTime || { Hours: 8, Minutes: 0 })
  const startTimeInMinutes = startTime?.Hours * 60 + startTime?.Minutes
  afterDraw(graphData, isdata)
  if (!options.plugins) {
    options.plugins = {}
  }
  if (!options.plugins.datalabels) {
    options.plugins.datalabels = {}
  }

  if (props.uniqueLegend) {
    configureLegend(options)
  }

  if (props.enableDatalabels) {
    configureDatalabels(options, startTimeInMinutes)
  }

  options.plugins.datalabels.display = props.enableDatalabels

  if (graphData.value?.type === 'line') {
    setDatasetFill(datasets)
  }
  if (props.tooltip) {
    configureTooltip(options, graphData, props)
  }

  myChart = new Chart(ctx, {
    type: graphData.value?.type || GRAPH_CONSTANTS.PIE,
    data: {
      ...graphData.value?.data,
      datasets,
    },
    options,
  })
}

const reRenderChart = () => {
  graphData.value = objectDeepCopy(props.chartData)

  if (myChart) myChart.destroy()

  if (!isEmpty(graphData.value)) renderChart()
}
</script>
