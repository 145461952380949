import { createApp } from 'vue'
import App from './app.vue'
import { register } from 'register-service-worker'

// Globally register all `_base`-prefixed components
import '@components/_globals'
import './assets/styles/index.css'
import './assets/styles/_global.scss'
import '@design'
import filters from '@src/filters/filter.js'
import './registerServiceWorker'

// Sentary
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

// Global Store
import store from '@src/state/store.js'

// Socket

import phoenixSocket from '@src/plugins/phoenixSocket.js'

// Router
import router from '@router'

// Vue Carousel

//  i18n
import i18n from '@src/i18n/index.js'

// Vee-Validate
import './vee-validate/index'

// Directives
import LazyImageLoad from '@src/directives/lavyImageLoad'
import PermissionDirective from '@src/directives/permission'
import VueI18n from '@src/directives/vue-i18n'

const app = createApp(App)

if (process.env.NODE_ENV === 'production') {
  app.config.productionTip = false
} else {
  app.config.devtools = true
  app.config.performance = true
}

// Sentary Init
if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  })
}

// i18n directive
app.directive('i18n', VueI18n)

// LazyLoad Image Directive
app.directive('lazyimg', LazyImageLoad)

// Element Permission Directive
app.directive('permission', PermissionDirective)
// If running inside Cypress...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Ensure tests fail when Vue emits an error.
  app.config.errorHandler = window.Cypress.cy.onUncaughtException
}
register('firebase-messaging-switch.js')
// Global Filters
app.config.globalProperties.$filters = filters
// Mock $t

// If running e2e tests...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Attach the app to the window, which can be useful
  // for manually setting state in Cypress commands
  // such as `cy.logIn()`.
  window.__app__ = app
}
app.use(store).use(router).use(i18n).use(phoenixSocket).mount('#app')

export default app
