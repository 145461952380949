<!-- https://codesandbox.io/s/qvow6lvp64 -->

<script>
import emitter from 'tiny-emitter/instance'
export default {
  name: 'UiOnClickOutside',
  props: {
    do: {
      type: Function,
      default: () => {},
    },
  },

  mounted() {
    this.outside()
  },
  methods: {
    /**
     * Listener
     * @param {window event} e
     * @returns {void}
     * @description Checks if click event is performed outside the dropdown then do that event and removing the click event when destroyed hook is called
     */
    outside() {
      const listener = (e) => {
        if (e.target !== this.$el && !this.$el.contains(e.target)) {
          this.do(e)
        }
      }
      document.addEventListener('click', listener, true)
      emitter.once('hook:destroyed', () => {
        document.removeEventListener('click', listener, true)
      })
    },
  },
  render() {
    return this.$slots.default ? this.$slots.default()[0] : null
  },
}
</script>
