import { by, del, get } from '@services/custom.service'
import { BaseService } from '@services/base.service'
import { TENNAT_API_URL } from '@services/.env'

export default class StaffService extends BaseService {
  /**
   * Add new staff member
   * @param {Object} data staff data
   */

  static createCampusAdmin(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}campus_admins`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  static getCampusAdminStats(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}campus_admins/campus_admin_stats`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  static getCampusAdmin(data) {
    return new Promise((resolve, reject) => {
      return by(
        `${TENNAT_API_URL}campus_admins/filter
      `,
        data,
      )
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  static editCampusAdmin({ admin, id }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}campus_admins`, admin, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }
}
