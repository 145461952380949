export default {
  set(state, payload) {
    state[payload[0]] = payload[1]
  },
  SET_NOTIFICATION(state, payload) {
    state.notifications = payload
  },
  SET_NOTIFICATION_COUNT(state, payload) {
    state.notificationCount = payload
  },
}
