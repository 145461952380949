<template>
  <UiModalContainer
    :disable-position-center="true"
    :modal-show="showRoleSwitchingSuiteModal"
    :modal-width="30"
    @handleClick="handleClick"
  >
    <template v-slot:header>
      <span>{{ title }}</span>
    </template>
    <template v-slot>
      <div class="pb-5">
        <div class="font-inter sm:text-sm text-xs text-primary-gray-500 mb-1">
          {{ description }}
        </div>
        <ul class="font-inter flex flex-col">
          <li
            v-for="(userRole, index) in userRoles"
            :key="index"
            class="cursor-pointer p-2.5 rounded-xl text-left even:bg-primary-purple-50"
            @click="redirectToSelectedUserRole(userRole.role_id)"
          >
            <div class="capitalize sm:text-base text-sm font-semibold text-primary-gray-700">
              {{ $filters.filteredUserRole(userRole.role_id) }}
            </div>
            <div class="text-primary-gray-600 sm:text-sm text-xs">
              {{ roleTextmaping[userRole.role_id] }}
            </div>
          </li>
        </ul>
      </div>
      <UserRoleSwitch v-show="isRoleSwitchVisible" ref="userRoleSwitch" />
    </template>
  </UiModalContainer>
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import { mapGetters, mapState } from 'vuex'
import RoleSwitchingSuiteData from '@src/router/views/admin/modals/role-switching-suite.json'
import UserRoleSwitch from '@src/modules/header/UserRoleSwitch.vue'
export default {
  components: {
    UiModalContainer,
    UserRoleSwitch,
  },
  props: {
    modal: { type: Boolean, default: false },
  },
  emits: ['toggle'],
  data() {
    return {
      title: RoleSwitchingSuiteData.title,
      description: RoleSwitchingSuiteData.description,
      roleTextmaping: RoleSwitchingSuiteData.roleTitleDescription,
      isRoleSwitchVisible: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['userRoles']),
    ...mapState({
      showRoleSwitchingSuiteModal: (state) => state.layout.showRoleSwitchingSuiteModal,
      currentCampusScope: (state) => state.layout.currentCampusScope,
      currentSectionScope: (state) => state.layout.currentSectionScope,
    }),
  },
  mounted() {
    this.toggleStikyTooltip(false)
    document.addEventListener('keydown', (e) => {
      this.closeRoleSwitchingSuiteModal(e)
    })
  },
  beforeUnmount() {
    document.removeEventListener('keydown', (e) => {
      this.closeRoleSwitchingSuiteModal(e)
    })
  },
  methods: {
    toggleStikyTooltip(booleanValue) {
      this.$store.commit('layout/SHOW_TOOLTIP', booleanValue)
    },
    redirectToSelectedUserRole(role) {
      this.$refs['userRoleSwitch']?.switchRoles(role)
      this.$store.commit('layout/SET_SHOW_ROLE_SWITCHING_SUITE_MODAL', false)
    },
    closeRoleSwitchingSuiteModal(e) {
      if (e.key === 'Escape') {
        if (this.showRoleSwitchingSuiteModal) this.toggleStikyTooltip(true)
        this.$store.commit('layout/SET_SHOW_ROLE_SWITCHING_SUITE_MODAL', false)
      }
    },
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$store.commit('layout/SET_SHOW_ROLE_SWITCHING_SUITE_MODAL', false)
          break
      }
    },
  },
}
</script>
