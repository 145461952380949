<template>
  <div>
    <div class="profile-info-block">
      <div class="h-52 mx-auto w-48 lg:mb-1 mt-5 md:mb-0">
        <img
          class="h-full rounded-md w-full"
          :src="
            profileInfo.imageUrl
              ? profileInfo.imageUrl
              : require('@src/assets/images/dummy-profile.png')
          "
          alt="User Profile"
        />
      </div>
      <div v-if="profileInfo.isUploadButton" class="mx-2 sm:mx-10">
        <UploadPhoto
          class="px-2 2xl:px-10 pt-2"
          label="Change Image"
          :rightbar-image-upload="true"
          :camera-icon="true"
        />
      </div>
      <div v-if="profileInfo.isUploadButton" class="mt-4 mx-5 pt-6 border-t border-primary-grey">
        <span
          class="font-roboto font-medium text-primary-green text-xs sm:text-sm 2xl:text-base mr-1"
        >
          Account Created :
        </span>
        &emsp;
        <span class="font-roboto font-normal text-text-color text-xs sm:text-sm 2xl:text-base">
          23/08/2020
        </span>
      </div>
      <div v-if="!profileInfo.hideLogininfo" class="mt-2 sm:mt-3 pb-5 mx-5 text-center">
        <span
          class="font-roboto font-medium text-primary-green text-xs sm:text-sm 2xl:text-base mr-1"
        >
          {{ $t(`dashboard.SP_LL`) }}:
        </span>
        &emsp;
        <span class="font-roboto font-normal text-text-color text-xxs sm:text-sm 2xl:text-base">
          April 10 2022
          <span class="">10:45 AM</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import UploadPhoto from '@src/components/UiElements/UploadImage.vue'

export default {
  components: {
    UploadPhoto,
  },
  props: {
    profileInfo: {
      type: Object,
      default: undefined,
    },
  },
  methods: {
    getImgUrl(pic) {
      return require('@assets/images/' + pic)
    },
  },
}
</script>
