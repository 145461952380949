import { TENNAT_API_URL } from '@services/.env'
import { by, get, getById } from '@services/custom.service'
import { BaseService } from '@services/base.service'

export class AttendanceService extends BaseService {
  static updateAttendanceOfSetionStudent(data, commit) {
    return by(`${TENNAT_API_URL}section_attendance/user_section_attendance`, data, data.id)
      .then((response) => {
        commit(
          'toast/NEW',
          { message: 'Attendance updated successfully', type: 'success' },
          { root: true },
        )
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static getAttendanceOfSetionStudentBYId(data, commit) {
    return get(`${TENNAT_API_URL}section_attendance/${data.id}/section_attendance_detail`, {
      params: data,
    })
      .then((response) => {
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static markPeriodAttendance(data, commit) {
    return by(`${TENNAT_API_URL}period_attendance`, data)
      .then((response) => {
        return [this.responseWrapper(response, response?.data), null]
      })
      .catch((error) => {
        const message = error.response.data ? error.response.data.error : error.response.statusText
        const responseError = this.errorWrapper(error, message)
        return [null, responseError]
      })
      .finally(() => {
        commit('layout/IS_LOADING', false, { root: true })
      })
  }

  static getPeriodAttendanceDetails({ query, id }) {
    return new Promise((resolve, reject) => {
      return getById(`${TENNAT_API_URL}period_attendance/periods`, id, {
        params: new URLSearchParams(query),
      })
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          const message = 'Could not get rooms list'
          reject(this.errorWrapper(error, message))
        })
    })
  }
}
