<template>
  <header
    class="border-b-[1.5px] border-border-color-light bg-primary-white sm:h-[88px] h-[72px] lg:h-[100px] scroll top-0 md:z-[5002] z-[5000]"
  >
    <div class="w-full h-full relative">
      <div class="absolute w-full h-full overflow-hidden">
        <div
          class="absolute rounded-full xl:left-[171px] lg:w-[473px] lg:left-[11px] md:w-[341px] md:bottom-[-70px] md:left-[18px] h-[376px] w-[248px] bottom-[-9px] left-[8px] orange-circle"
        ></div>
        <div
          class="absolute rounded-full xl:bottom-[-168px] xl:right-[-130px] lg:h-[460px] lg:w-[784px] lg:bottom-[-95px] lg:right-[-205px] md:h-[312px] md:w-[634px] md:bottom-[-79px] md:right-[-159px] h-[312px] w-[458px] bottom-[-34px] right-[-210px] purple-circle"
        ></div>
      </div>
      <nav class="flex flex-col mx-auto h-full xl:pl-6 md:pl-5 md:pr-5 px-4">
        <div class="grid grid-cols-16 pt-[13px] pb-[19px] sm:py-4 h-full items-center tooltip">
          <div
            class="flex-1 flex justify-start items-end md:items-baseline gap-x-2 md:gap-x-2.5 lg:gap-x-5 col-span-14 md:mr-4.5 mr-2"
          >
            <div
              class="transform rtl:rotate-180 self-end mt-1 w-9 lg:h-11 lg:mb-[-2px] lg:w-11 h-9 bg-white border hidden md:flex justify-center items-center cursor-pointer rounded-lg"
              @click="mobileLeftBar"
            >
              <icon :icon="leftBarToggleIcon" />
            </div>
            <span
              class="flex transform rounded-[9px] bg-primary-purple-100 mr-0.5 justify-center items-center cursor-pointer h-10 w-10 md:hidden"
              @click="$emit('mobileLeftBar')"
            >
              <icon icon="bars" />
            </span>
            <span v-if="isCampusDropdownHidden" class="flex self-baseline mb-[1px] lg:w-64">
              <Campuses />
            </span>
            <UserRoleSwitch />
          </div>
          <div class="flex-1 flex justify-end items-center col-span-2">
            <Notification />
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
import icon from '@components/icons/icon.vue'
import Campuses from '@src/modules/header/campuses.vue'
import Notification from '@src/modules/header/notification.vue'
import UserRoleSwitch from '@src/modules/header/UserRoleSwitch.vue'
import debounce from 'lodash/debounce'
import { mapState } from 'vuex'

export default {
  components: {
    Campuses,
    Notification,
    UserRoleSwitch,
    icon,
  },
  props: {
    showRightBarIcon: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['mobileLeftBar'],
  data() {
    return {
      isShadow: false,
    }
  },
  computed: {
    ...mapState({
      tabLeftNav: (state) => state?.layout?.tabLeftNav,
      campusDropdownHiddenOnRoutes: (state) => state?.layout?.campusDropdownHiddenOnRoutes,
    }),
    isCampusDropdownHidden() {
      return !this.$route.matched.some((route) =>
        this.campusDropdownHiddenOnRoutes.includes(route.name),
      )
    },
    leftBarToggleIcon() {
      return this.tabLeftNav ? 'doubleChevronLeft' : 'doubleChevronRight'
    },
  },
  /**
   * Created Hook
   * @description This hook calls the handleScroll function with the delay
   */
  // TODO: This function is not used anywhere
  created() {
    this.handleDebouncedScroll = debounce(this.handleScroll, 10)
    window.addEventListener('scroll', this.handleDebouncedScroll)
  },
  methods: {
    debounce,
    handleScroll(event) {
      this.isUserScrolling = window.scrollY > 0
      this.isShadow = !!this.isUserScrolling
    },
    mobileLeftBar() {
      this.$store.commit('layout/SET_TAB_LEFT_BAR')
    },
  },
}
</script>

<style lang="scss" scoped>
.orange-circle {
  background-image: radial-gradient(
    ellipse,
    rgba(255, 222, 188, 0.95),
    rgba(255, 148, 33, 0),
    transparent 140%
  );
}
.purple-circle {
  background-image: radial-gradient(
    ellipse,
    rgba(198, 184, 234, 0.37),
    rgba(105, 65, 198, 0),
    transparent 147%
  );
}
@media screen and (max-width: 639px) {
  .icon {
    width: 25px;
    height: 29px;
  }
  .top-position {
    top: 72px;
  }
}
@media (max-width: 369px) {
  .top-position {
    top: 92px;
  }
}
@media screen and (min-width: 640px) {
  .icon {
    width: 30px;
    height: 34px;
  }
}
</style>
