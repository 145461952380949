<template>
  <div
    :class="[
      rightBarVisibility
        ? 'col-span-3  xl:pt-6 xl:w-full md:w-[18.2rem] w-[260px]'
        : ' col-span-1 bg-transparent hidden',
    ]"
    class="xl:static absolute top-[52px] right-0 z-[11] rightbar-carot"
  >
    <div v-if="rightBarVisibility">
      <div v-if="rightBarContent.header" class="text-center px-2.5 xl:block hidden">
        <SplitButton
          v-for="(button, index) in rightBarContent.header.buttons"
          :key="index + button.title"
          v-permission="button.permissions"
          :buttons="button"
        />
      </div>
      <!-- User Profile -->
      <div
        v-if="isStatsAvailable"
        ref="rightBarContainer"
        class="bg-white p-5 border border-transparent overflow-y-auto rounded-lg relative xl:h-[82vh]"
      >
        <ProfileInfo
          v-for="(info, index) in rightBarContent.profileInfo"
          :key="index"
          :profile-info="info"
        />
        <!-- Stats -->
        <div v-if="rightBarContent.stats">
          <RightBarStats
            v-for="(stat, idx) in rightBarContent.stats"
            :key="stat.title + '-' + idx"
            :stats="stat"
          />
        </div>
        <!-- Graphs -->
        <div v-if="rightBarContent.bargraph">
          <hr class="mx border-primary-grey" />
          <div v-for="(info, index) in []" :key="index">
            <RightsideBarGraph :graph-title="info.barGraphTitle" :graph-list="info.barGraphList" />
          </div>
        </div>
        <div v-permission="graphsPermissions">
          <div v-for="(graph, index) in rightBarContent.chartData" :key="index">
            <div v-if="!isEmpty(graph)">
              <div v-if="!isChartDataEmpty(graph.chart.data.datasets[0].data)">
                <h3
                  v-if="graph.GRAPH_TITLE && !isEmpty(graph.GRAPH_TITLE)"
                  class="font-rubik font-medium text-text-color text-base md:text-xl text-center"
                >
                  {{ graph.GRAPH_TITLE }}
                </h3>
                <GraphView :chart-data="graph.chart" />
                <div v-if="graph.LEGEND_DATA && graph.LEGEND_DATA.length">
                  <Status
                    v-for="status in graph.LEGEND_DATA"
                    :key="status.title"
                    :status="status"
                    :graph-name="graph.chart.type"
                  />
                </div>
                <Divider :class="lastDivider(rightBarContent.chartData, index)" />
              </div>
              <div v-else>
                <NoRecordFound custom-class="text-xl" text="NO_STATS_FOUND" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RightBarStats from '@components/RightBarStats.vue'
import SplitButton from '@components/SplitButton.vue'
import ProfileInfo from '@components/ProfileInfo.vue'
import RightsideBarGraph from '@router/views/BarGraphs/rightside-bar-graph.vue'
import { mapState } from 'vuex'
import GraphView from '@src/router/views/graph/graphView.vue'
import isEmpty from 'lodash/isEmpty'
import { ENV } from '@src/constants/general-constants.js'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import Divider from '@/src/components/Divider.vue'
import Status from '@/src/components/status.vue'

export default {
  components: {
    RightBarStats,
    ProfileInfo,
    SplitButton,
    RightsideBarGraph,
    GraphView,
    NoRecordFound,
    Divider,
    Status,
  },
  props: {
    rightBarVisibility: { type: Boolean },
    mobileRightBarHeight: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      rightBar: 'rightBar',
      stats: null,
      graphsPermissions: {
        env: [ENV.DEV],
      },
    }
  },
  computed: {
    ...mapState({
      rightBarContent: (state) => state.layout.rightBarContent,
      activeEvent: (state) => state.layout.activeEvent,
      tabRightNav: (state) => state.layout.tabRightNav,
    }),
    isStatsAvailable() {
      return this.rightBarContent?.stats
    },
  },
  watch: {
    mobileRightBarHeight: {
      handler(val) {
        if (val) {
          this.setRightBarHeight()
        }
      },
    },
  },
  mounted() {
    this.setRightBarHeight()
  },
  methods: {
    isEmpty,
    setRightBarHeight() {
      let rightBarContainer = this.$refs['rightBarContainer']
      if (rightBarContainer && this.mobileRightBarHeight) {
        rightBarContainer.style.height = this.mobileRightBarHeight + 'px'
      }
    },
    lastDivider(chartData, index) {
      return chartData.length === index + 1 ? 'hidden' : ''
    },
    isChartDataEmpty(dataSets) {
      return dataSets.every((elementValue) => elementValue === 0)
    },
  },
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none;
}
@media (max-width: 1279px) {
  .rightbar-carot {
    border-radius: 8px;
    &::before {
      position: absolute;
      top: -5px;
      right: 11px;
      width: 14px;
      height: 15px;
      content: '';
      background-color: var(--bg-white);
      transform: rotate(314deg);
      clip-path: polygon(0% -10%, 100% 0%, 115% 100%);
    }
  }
}
</style>
