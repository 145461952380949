<template>
  <div class="status">
    <div class="text-center hidden bg-bg-color-light h-12 justify-center items-center">
      <h3 class="font-rubik font-medium text-text-color text-xl">
        {{ $t(`rightBar.${status.title}`) }}
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    percentage: {
      type: String,
      default: '',
    },
    status: {
      type: Object,
      default: undefined,
    },
    graphName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      rightBar: 'rightBar',
    }
  },
}
</script>
