export const DOMAIN_TITLE = 'MyQampus'
export const API_URL = process.env.VUE_APP_API_URL || 'be.dev.myqampus.com'
export const URL_SCHEME = process.env.VUE_APP_URL_SCHEME || 'https'
export const SOCKET_SCHEME = URL_SCHEME === 'https' ? 'wss' : 'ws'
export const BASE_API_URL = process.env.VUE_APP_BASE_API_URL || `${URL_SCHEME}://${API_URL}`
export const FEATURES_ENVIRONMENT = process.env.VUE_APP_FEATURES_ENVIRONMENT || 'prod'
//ADMIN_API_URL must be same as BASE_API_URL before the /api
export const ADMIN_API_URL = '/api/v1/'
export const TENNAT_API_URL = '/t_api/v1/'
export const TEAM_TENNAT_API_URL = '/tt_api/v1/'
export const VUE_APP_I18N_LOCALE = 'en'
export const VUE_APP_I18N_FALLBACK_LOCALE = 'en'

export const PROD_FEATURES = [
  'Subjects',
  'Chapters',
  'Topics',
  'LESSON_PLANNER',
  'EXAM_TYPES',
  'Exam',
  'TIME_TABLE_AUTOMATOR',
  'SECTION_ATTENDANCE_PERMISSION',
  'SYLLABUS_PERMISSION_SETTINGS',
  'TIME_TABLE_PERMISSION',
  'PERIOD_ATTENDANCE_PERMISSION',
  'CAMPUS_ATTENDANCE_PERMISSION',
  'CAMPUS_STUDENT_ATTENDANCE',
  'STUDENT_SECTION_ATTENDANCE',
  'STUDENTS_SECTION_ATTENDANCE',
  'STUDENTS_PERIOD_ATTANDANCE',
  'FEE',
  'CLASS',
  'CLASS_PERMISSION',
  'SECTION_PERMISSION',
  'STAFF',
  'STUDENT_PAYABLE',
  'STUDENT_PAID',
  'ACCEPT_PAYMENT',
  'TEACHER',
  'RV_LB_G',
  'ROLES_AND_ACCESS',
  'SECTION',
  'LB_QA',
  'Teachers',
  'Students',
  'Classes',
  'Fee Manager',
  'Settings',
  'Fees',
  'Payable',
  'Paid',
  'Fee Types',
  'Receivable',
  'Received',
  'Discounts',
  'Monthly Fee Generator',
  'Section Fee',
  'Sections',
  'Profile',
  'Contact Settings',
  'Theme Settings',
  'Campuses',
  'Staff',
  'Discount Types',
  'Fee Discounts',
  'Site Settings',
  'FEE_SETTINGS',
  'Profile Settings',
  'perosnal-info',
  'contact-info',
  'professional-info',
  'My Profile',
  'Fee Notification',
  'Notifications',
  'general-notifications',
  'features-notifications',
  'security-notifications',
  'RV_LB_T',
  'STUDENT_DISCOUNT_LIST',
  'CAMPUS_ADMIN',
  'Dashboard',
  'OWNERSHIP',
  'SMS_AD_ONS',
  'EMAIL_AD_ONS',
  'STUDENT_NOTIFICATION',
  'All Users',
  'SUPER_ADMIN',
  'PERIOD_STUDENT_ATTENDANCE',
  'Attendance Settings',
  'FEATURE_SETTINGS',
  'CURRENT_TIME_TABLE',
  'Lesson Planner',
  'NON_GPA_GRADE',
  'NON_GPA_GRADE_DETAIL',
  'SUBJECT_RESULT',
  'Section Exams Result',
  'subject-result',
  'Lessons',
  'LB_TEMP',
  'SYLLABUS_MANAGER_PERMISSION',
  'General Settings',
  'SCHEDULE_SETTINGS',
  'SYLLABUS_AUTOMATOR_PERMISSION',
  'COURSE_PROVIDER',
  'Attandance Notification',
  'GUARDIAN_NOTIFICATION',
]
export const DEV_FEATURES = [...PROD_FEATURES, 'Occasions']
export let ALLOWED_FEATURES = []

if (FEATURES_ENVIRONMENT === 'prod') ALLOWED_FEATURES = PROD_FEATURES
else if (FEATURES_ENVIRONMENT === 'dev') ALLOWED_FEATURES = DEV_FEATURES
console.log(process.env, 'process.env')
/**
 * Firebase Configuration
 */

export const firebaseConfig = {
  apiKey: process.env.VUE_APP_FCM_API_KEY || 'AIzaSyC4y7e0_fU3PKkKL81ppH909U9cZDfcMQs',
  authDomain: process.env.VUE_APP_FCM_AUTH_DOMAIN || 'myqampus-dev-ad775.firebaseapp.com',
  projectId: process.env.VUE_APP_FCM_PROJECT_ID || 'myqampus-dev-ad775',
  storageBucket: process.env.VUE_APP_FCM_STORAGE_BUCKET || 'myqampus-dev-ad775.appspot.com',
  messagingSenderId: process.env.VUE_APP_FCM_MESSAGING_SENDER_ID || '810931957090',
  appId: process.env.VUE_APP_FCM_APP_ID || '1:810931957090:web:13ae34df9c6be5a75d903b',
  measurementId: process.env.VUE_APP_FCM_MEASUREMENT_ID || 'G-PD1C01Y845',
}
export const vapidKey = {
  vapidKey:
    process.env.VUE_APP_FCM_VAPID_KEY ||
    'BJhYd6aYMuAIypzvroaAoHLIg-cNWbHguAVEKhunazhBRG1XVssRo26KSvo2pZ42g6jxzeBihqwWYAZz8Tqs-ug',
}
export const ALLOWED_FILTERS = [
  'tenants',
  'students',
  'staff',
  'teachers',
  'guardians',
  'topics',
  'subjects',
  'all-users',
  'receivable',
  'announcement',
  'syllabus-template-course',
  'syllabus-template',
  'chapters',
  'section',
  'period',
  'active-request',
  'all-requests',
  'lessons',
  'pickup-person',
  'received',
  'student-campus-attendance',
  'announcement-types',
  'guardians',
  'support-user-active-request',
  'facility-user-all-requests',
  'support-user-all-requests',
  'student-fee-receivable',
  'student-fee-received',
  'student-paid',
  'student-discounts',
  'payable',
  'section-result',
  'exams',
  'class-notification',
  'rooms',
  'classes',
  'campus-admin',
  'monthly-fee-generator',
  'fee-types',
  'exam-types',
  'section-notification',
  'super-admin',
  'discount-types',
  'subject-result',
  'non-gpa-grading-structure',
  'gpa-grading-structure',
  'student-section-attendance',
  'facility-user-active-request',
  'fee-notification-settings',
  'students-period-attendance',
  'campus-attendance-notification',
  'support-active-request',
  'section-attendance-notification',
  'support-all-requests',
  'sections',
  'teacher-notification-settings',
  'staff-notification',
  'pickup-person-notification',
  'student-notification-settings',
  'period-attendance-notification',
  'syllabus-manager-notification',
  'syllabus-automator-notification',
  'campuses',
  'pickup-facility-notification',
  'subject-syllabus-template',
]
