<template>
  <div
    class="input-field relative flex flex-col justify-center w-full"
    :class="[isFile && 'truncate']"
  >
    <!-- TITLE -->
    <label
      v-if="title"
      for="title"
      class="text-gray-700 font-inter text-sm font-medium flex mb-1.5"
      :class="[hideTitle && 'hidden']"
    >
      {{ inputTitle }}
      <span v-if="rules.includes('required')" class="text-error-500 pl-1">*</span>
      <!-- TOOLTIP -->
      <div v-if="tooltipProp">
        <UiOnClickOutside>
          <div
            class="flex-item ml-2 rtl:mr-2 self-center cursor-pointer"
            @click="clickToolTip"
            @mouseover="displayToolTip"
            @mouseleave="hideToolTip"
          >
            <icon
              class="icon"
              icon="infoCircle"
              color="primary-purple-600"
              :height="height"
              :width="width"
            />
            <div v-if="showToolTip" class="absolute -ml-4 mt-1 rtl:-mr-24 md:rtl:-mr-7 z-10">
              <Tooltip
                class="w-min sm:w-auto"
                :title="$t(title)"
                :text="$t(tooltipDesc)"
                direction="left"
              />
            </div>
          </div>
        </UiOnClickOutside>
      </div>
    </label>
    <!-- INPUT FIELDS -->
    <div :class="isFile && 'flex gap-2 truncate'">
      <div
        class="relative text-base flex border rounded-lg w-full items-center input-container"
        :class="[
          errorbackground,
          customClasses,
          disabledBackgroundColor,
          isTextarea ? 'h-full' : 'h-11',
          isFile && ' truncate',
        ]"
      >
        <div
          class="pointer-events-none inset-y-0 flex items-center"
          :class="[type === 'url' && 'border-r-2 border-gray-300 pr-3', inputProperties.class]"
        >
          <span v-if="isCurrency" class="text-gray-500">{{ currencySymbol?.symbol }}</span>
          <icon v-else-if="inputProperties?.name" :icon="inputProperties.name" />
        </div>
        <div :class="[!isFile && 'w-full h-full flex']">
          <!-- FILE -->
          <Field
            v-if="isFile"
            v-model="fieldValue"
            :rules="rules"
            :name="name === '' ? title : name"
          >
            <input
              :id="id"
              :key="inputKey"
              ref="imgUpload"
              type="file"
              hidden
              :multiple="multiple"
              name="file"
              accept="image/png, image/jpeg, image/jpg"
              @input="updateInput"
            />
          </Field>
          <!-- TEXT-AREA -->
          <Field
            v-else-if="isTextarea"
            v-model="fieldValue"
            :rules="rules"
            :name="name === '' ? title : name"
          >
            <textarea
              v-show="!hidden"
              :id="id"
              v-model="fieldValue"
              :rows="rows"
              :resize="resize"
              :disabled="disabled"
              :class="[disabled && 'cursor-not-allowed']"
              class="focus:outline-none w-full text-text-color p-3 border-none bg-inherit"
              @input="updateInput($event)"
            />
          </Field>
          <!-- Range -->
          <div v-else-if="isRange" class="flex">
            <Field
              v-show="!hidden"
              :id="id"
              v-model="rangeInitialValue"
              :name="`${getErrorMessageKey} gt`"
              :label="inputTitle"
              type="text"
              :placeholder="isNotTranslated ? placeholder : GENERAL_CONSTANTS.LESS_THAN"
              :rules="rules"
              class="focus:outline-none w-full rounded-lg ml-2 text-sm md:text-base opacity-70 bg-inherit"
              :class="inputClasses"
              :autocomplete="autocomplete"
              :disabled="disabled"
              @change="updateRangeInput($event, 'initial')"
              @keyup="updateRangeInput($event, 'initial')"
              @keydown.enter.prevent
              @blur="emitBlurEvent($event)"
            />
            <span class="flex">
              <icon
                class="flex items-center"
                icon="chevronLeft"
                height="16"
                width="16"
                color="primary-gray-300"
              />
              <icon
                class="flex items-center"
                icon="chevronRight"
                height="16"
                width="16"
                color="primary-gray-300"
              />
            </span>
            <Field
              v-show="!hidden"
              :id="id"
              v-model="rangeSecondaryValue"
              :name="`${getErrorMessageKey} lt`"
              :label="inputTitle"
              type="text"
              :placeholder="isNotTranslated ? placeholder : GENERAL_CONSTANTS.GREATER_THAN"
              :rules="rules"
              class="focus:outline-none w-full rounded-lg ml-2 text-sm md:text-base opacity-70 bg-inherit"
              :class="inputClasses"
              :autocomplete="autocomplete"
              :disabled="disabled"
              @change="updateRangeInput($event, 'secondary')"
              @keyup="updateRangeInput($event, 'secondary')"
              @keydown.enter.prevent
              @blur="emitBlurEvent($event)"
            />
          </div>
          <!-- TEXT -->
          <Field
            v-else
            v-show="!hidden"
            :id="id"
            v-model="fieldValue"
            :name="getErrorMessageKey"
            :label="inputTitle"
            :type="type"
            :placeholder="isNotTranslated ? placeholder : $t(`placeholder.${placeholder}`)"
            :rules="rules"
            class="focus:outline-none w-full h-full rounded-lg bg-inherit"
            :class="inputClasses"
            :autocomplete="autocomplete"
            :disabled="disabled"
            :oninput="fieldValueFormat"
            @change="updateInput($event)"
            @keyup="updateInput($event)"
            @keydown.enter.prevent
            @blur="emitBlurEvent($event)"
          />
        </div>
        <!-- FILE PLACEHOLDER -->
        <div
          v-if="isFile"
          class="flex items-center flex-row rounded-lg w-full truncate"
          :class="[previewImage?.src && 'justify-between']"
        >
          <label
            v-if="isFile"
            :for="id"
            class="flex cursor-pointer items-center rounded-lg pl-1"
            :class="fileSrc && 'px-3.5'"
          >
            <span class="flex px-2.5 py-1.5 gap-1.5 rounded-md shadow-sm" :class="errorClasses">
              <icon icon="UploadFile" height="20" width="20" :color="strokeColor" />
              <span class="font-normal whitespace-nowrap">
                {{ isFile && 'Choose a file' }}
              </span>
            </span>
          </label>
          <div v-if="!fileSrc" class="truncate">
            <span :title="previewImage?.fileName" class="mx-1 text-xs text-gray-700 truncate">
              {{ previewImage ? previewImage.fileName : 'No file chosen' }}
            </span>
          </div>
          <span v-if="previewImage?.src" class="pr-0.5">
            <icon icon="cross" height="16" width="16" color="error-500" />
          </span>
        </div>
        <!-- PASSWORD -->
        <div
          v-if="isPassword"
          class="absolute top-0 right-0 bottom-3 h-full mr-[15px] text-primary-gray-400 cursor-pointer flex justify-between items-center"
        >
          <span class="cursor-pointer" @click="showPass">
            <icon
              class="icon"
              :icon="showText ? 'eye' : 'eyeSlash'"
              color="primary-gray-400"
              height="17.5"
              width="21.31"
            />
          </span>
        </div>
        <!-- IS-VERIFIED TICK -->
        <div v-if="isVerified" class="ml-auto justify-end float-right relative">
          <div
            class="absolute"
            :class="!isFile ? 'top-[13px] right-[11px]' : 'right-[11px] bottom-[17px]'"
          >
            <icon icon="circleTick" color="white" />
          </div>
        </div>
        <!-- LOADING -->
        <div v-if="isComponentInLoading" class="ml-auto justify-end float-right relative">
          <div
            class="absolute"
            :class="!isFile ? 'top-[13px] right-[11px]' : 'right-[11px] bottom-[17px]'"
          >
            <Loader :is-background-light="true" />
          </div>
        </div>
        <div v-if="isCurrency" class="inset-y-0 flex items-center">
          <span class="pr-5 uppercase">{{ currencySymbol?.id }}</span>
        </div>
      </div>
      <div v-if="isFile && !fileSrc" class="shrink-0">
        <img
          class="h-11 w-11 object-cover rounded-lg"
          :src="previewImage ? previewImage.src : require('@src/assets/images/upload.svg')"
          alt="photo"
        />
      </div>
    </div>

    <!-- ERRORS -->
    <div v-if="!noError" class="w-full info-wrapper" :class="errorCustomClasses">
      <div ref="errorContainer" class="flex justify-end min-h-[20px]">
        <ErrorMessage v-slot="{ message }" :name="getErrorMessageKey">
          <small class="error-msg text-sm text-error-500 italic rtl:mt-1 ltr:-mt-0">
            <p>{{ displayError(message) }}</p>
          </small>
        </ErrorMessage>
      </div>
    </div>
  </div>
</template>

<script>
import tooltipMixin from '@/src/mixins/components/tooltip-mixin.js'
import Tooltip from '@components/tooltip.vue'
import UiOnClickOutside from '@components/UiElements/UiOnClickOutside.vue'
import { translatedError } from '@src/utils/generalUtil.js'
import Loader from '@components/BaseComponent/Loader.vue'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import { Field, configure, ErrorMessage } from 'vee-validate'
import icon from '@components/icons/icon.vue'
import validationMixin from '@src/mixins/components/validation-mixin.js'
import { validationConfiguration } from '@src/vee-validate/index.js'
configure(validationConfiguration)
import { findCurrencyById } from '@utils/generalUtil'
import GENERAL_CONSTANTS from '@/src/constants/general-constants'

export default {
  components: {
    ErrorMessage,
    UiOnClickOutside,
    Tooltip,
    Loader,
    Field,
    icon,
  },
  mixins: [tooltipMixin, validationMixin],
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    confirmPassword: { type: String, default: '' },
    width: { type: [String, Number], default: '15.5' },
    height: { type: [String, Number], default: '19.617' },
    errorCustomClasses: { type: String, default: '' },
    tooltipProp: {
      type: Boolean,
      default: false,
    },
    tooltipDesc: {
      type: String,
      default: 'toolTipInfo.SAMPLE_TEXT',
    },
    customClasses: {
      type: String,
      default: '',
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: 'enterHere',
    },
    type: {
      type: String,
      default: 'text',
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    initial: {
      type: [String, Number],
      default: '',
    },
    secondary: {
      type: [String, Number],
      default: '',
    },
    rules: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: String,
      default: 'autocomplete',
    },
    noError: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: 'input',
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    isVerified: {
      type: Boolean,
      default: false,
    },
    fileSrc: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isComponentInLoading: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 4,
    },
    resize: {
      type: Boolean,
      default: false,
    },
    isNotTranslated: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Object,
      default: () => {},
    },
    previewImage: {
      type: Object,
      default: () => {},
    },
  },
  emits: [
    'clearValue',
    'change',
    'update:modelValue',
    'emitQuery',
    'blurEvent',
    'preview',
    'update:initial',
    'update:secondary',
  ],
  data() {
    return {
      GENERAL_CONSTANTS,
      translatedError,
      fieldValue: '',
      rangeInitialValue: '',
      rangeSecondaryValue: '',
      confirmPasswordModelValue: '',
      showText: false,
      required: false,
      isErrorAvailable: false,
      inputKey: 0,
    }
  },
  computed: {
    isText() {
      return this.type === 'text'
    },
    isTextarea() {
      return this.type === 'textarea'
    },
    isCurrency() {
      return this.type === 'currency'
    },
    isEmail() {
      return this.type === 'email'
    },
    isFile() {
      return this.type === 'file'
    },
    isPassword() {
      return this.type === 'password'
    },
    isRange() {
      return this.type === 'range'
    },
    strokeColor() {
      return this.isErrorAvailable ? 'stroke-red-400' : 'stroke-gray-500'
    },
    errorbackground() {
      return this.isErrorAvailable
        ? 'border-error-500 bg-error-50 focus-within:shadow-error-100   '
        : this.isFile && !this.isErrorAvailable
        ? 'bg-primary-gray-50'
        : 'focus-within:shadow-purple-100'
    },
    errorClasses() {
      return {
        'bg-red-100 text-red-400': this.isErrorAvailable,
        'bg-white text-primary-gray-400': !this.isErrorAvailable,
      }
    },
    disabledBackgroundColor() {
      return [this.disabled && 'bg-primary-gray-50 cursor-not-allowed']
    },
    inputClasses() {
      return [
        // Check the type and apply classes accordingly
        this.isText || this.isPassword ? 'px-3.5' : 'px-2',

        // Check if disabled is true and apply the corresponding classes
        this.disabled && 'cursor-not-allowed',

        // Check if isErrorAvailable is true and apply the corresponding class
      ]
    },
    rulesArray() {
      return this.rules.split('|')
    },

    currencySymbol() {
      return findCurrencyById()
    },
    inputProperties() {
      let iconData = {}

      switch (this.type) {
        case 'currency':
          iconData = { class: 'pl-3.5' }
          break
        case 'email':
          iconData = {
            name: 'email',
            class: 'pl-3.5',
          }
          break
        case 'paymentMethod':
          iconData = {
            name: 'paymentMethod',
            class: 'pl-2.5',
          }
          break
        case 'url':
          iconData = {
            name: 'url',
            class: 'pl-3.5',
          }
          break
        default:
          iconData = {}
          break
      }

      return iconData
    },

    inputTitle() {
      return this.isNotTranslated ? this.title : this.$t(`placeholder.${this.title}`)
    },
    getErrorMessageKey() {
      return !this.name ? this.title : this.name
    },
  },

  watch: {
    modelValue: {
      immediate: true,
      handler(val) {
        this.fieldValue = val
        this.errorHandler()
      },
    },
    initial: {
      immediate: true,
      handler(val) {
        this.rangeInitialValue = val
        this.errorHandler()
      },
    },
    secondary: {
      immediate: true,
      handler(val) {
        this.rangeSecondaryValue = val
        this.errorHandler()
      },
    },
    rules: {
      handler() {
        this.errorHandler()
      },
    },
    confirmPassword: {
      handler(val) {
        this.confirmPasswordModelValue = val
      },
    },
  },
  methods: {
    /**
     * Formats the value of an input field based on defined rules.
     *
     * @param {Event} event The event object triggered by a user interaction with the input field.
     * @returns {void} (Does not return a value)
     */
    fieldValueFormat(event) {
      // Get the current value from the input field
      let inputValue = event.target.value
      // Check if the field should only accept numeric or double values (floating-point numbers)
      const isNumber = this.rulesArray.includes('numeric') || this.rulesArray.includes('double')
      if (isNumber) {
        // Remove all characters except numbers and a single decimal point
        inputValue = inputValue.replace(/[^0-9.]/g, '')

        // Ensure there's only one decimal point and no trailing decimals
        inputValue = inputValue.replace(/(\..*?)\..*/g, '$1')
      }
      // Update the internal value and the input field value (optional for two-way binding)
      this.value = inputValue
      event.target.value = this.value
    },
    clearData() {
      this.$emit('clearValue', 'remove')
    },
    /**
     * @description function to be called on input modelValue change
     */
    updateInput(ev) {
      this.inputKey += 1
      if (this.type === 'file' && ev.target.files) {
        if (this.multiple) {
          this.$emit('update:modelValue', this.$refs.imgUpload.files)
        } else {
          this.$emit('update:modelValue', this.$refs.imgUpload.files[0])
        }
        for (let i = 0; i < ev.target.files.length; i++) {
          const reader = new FileReader()
          reader.onload = (e) => {
            this.$emit('change', ev.target.files[i], e.target.result, ev.target.files[0].name)
            this.$emit('preview', { src: e.target.result, fileName: ev.target.files[0].name })
          }
          reader.readAsDataURL(ev.target.files[i])
        }
        return
      }

      this.$emit('update:modelValue', this.fieldValue)
      this.$emit('change', this.name, this.fieldValue)
      if (this.filter && this.filter.option) {
        let value = this.formatInputValue(this.fieldValue)
        this.$emit('emitQuery', buildWhereQuery(this.filter.option, this.filter.key, value))
      }
    },
    updateRangeInput(_, rangeType) {
      if (rangeType === 'initial') {
        this.$emit('update:initial', this.rangeInitialValue)

        if (this.filter && this.filter.initalOption) {
          let value = this.formatInputValue(this.rangeInitialValue)
          this.$emit('emitQuery', buildWhereQuery(this.filter.initalOption, this.filter.key, value))
        }
      } else if (rangeType === 'secondary') {
        this.$emit('update:secondary', this.rangeSecondaryValue)

        if (this.filter && this.filter.secondaryOption) {
          let value = this.formatInputValue(this.rangeSecondaryValue)
          this.$emit(
            'emitQuery',
            buildWhereQuery(this.filter.secondaryOption, this.filter.key, value),
          )
        }
      }
    },
    emitBlurEvent(e) {
      this.$emit('blurEvent', e)
    },

    formatInputValue(value) {
      let isNumber = this.rulesArray.includes('numeric')
      return isNumber && (value === '0' || value > 0) ? Number(value) : value.trim()
    },

    showPass(event) {
      const passElm = event.target.closest('.input-field').querySelector('input')
      if (passElm.type === 'text') {
        passElm.type = 'password'
        this.showText = false
      } else {
        passElm.type = 'text'
        this.showText = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@src/router/views/auth/auth.scss';
.DatePickerInput::-webkit-inner-spin-button,
.DatePickerInput::-webkit-calendar-picker-indicator {
  opacity: 0;
  -webkit-appearance: none;
}
</style>
