import { by, del, get, put, getById } from '@services/custom.service'
import { BaseService } from '@services/base.service'
import { TENNAT_API_URL } from '@services/.env'

export default class PickupMembers extends BaseService {
  static createPickupMember(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}pickup_persons`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  static getPickupPersonStats(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}pickup_persons/pickup_person_stats`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  static getPickupMembers(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}pickup_persons/filters`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  static editPickupMember({ pickup, id }) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}pickup_persons`, pickup, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  static deactivatePickupPerson(id) {
    return new Promise((resolve, reject) => {
      return del(`${TENNAT_API_URL}pickup_persons/deactivate`, id)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  // Assosiate student with pickup person

  static assosiateStudentsWithPickupMembers(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}pickup_persons/associate_student`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  // Unassosiate student with pickup person

  static unAssosiateStudentsWithPickupMembers(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}pickup_persons/unassociate_student`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  // Pickup Facility

  static generateNewPickupRequest(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}pickup_requests`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  static getAllPickupRequests(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}pickup_requests/filter`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  static markStudentIsPickedUp({ payload, id }) {
    return new Promise((resolve, reject) => {
      return put(`${TENNAT_API_URL}/pickup_requests/${id}/picked_student`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  static closeActivePickupRequest(payload) {
    return new Promise((resolve, reject) => {
      return by(
        `${TENNAT_API_URL}pickup_requests/close_request`,
        payload,
        payload.pickup_request_id,
      )
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }
  static rejectActivePickupRequest(payload) {
    return new Promise((resolve, reject) => {
      return by(
        `${TENNAT_API_URL}pickup_requests/reject_request`,
        payload,
        payload.pickup_request_id,
      )
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  static getAssociatedStudents(query) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}pickup_persons/pickup/students`, query)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  static reReuquestPickupRequest(id = undefined) {
    return new Promise((resolve, reject) => {
      return put(`${TENNAT_API_URL}pickup_requests/${id}/rerequest`)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  static respondToPickupMemvber(payload) {
    return new Promise((resolve, reject) => {
      return by(`${TENNAT_API_URL}pickup_request_logs`, payload)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }

  static getRequestDetails({ id, payload }) {
    return new Promise((resolve, reject) => {
      return getById(`${TENNAT_API_URL}pickup_requests`, id, { params: payload })
        .then((response) => {
          resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => {
          reject(this.errorWrapper(error, error.message))
        })
    })
  }
}
