import i18n from '@src/i18n'
import { UsersService } from '@services/users.service'
import { fullName } from '@src/utils/settings/tenant-user.util.js'

const t = i18n?.global?.t

export default {
  setupUser({ commit }, payload) {
    commit('SET_ACTIVE_USER', payload)
    commit('SET_CURRENT_ROLE', 'student')
  },
  storeShortBio({ state, commit, rootState }, payload) {
    const data = {
      short_bio: payload,
    }

    return new Promise((resolve, reject) => {
      UsersService.storeShortBio(data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  uploadUserImage({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UsersService.uploadUserImage(payload)
        .then((response) => {
          resolve(response.data)
          commit(
            'toast/NEW',
            { message: t(`toast.Image Uploaded Successfully`), type: 'success' },
            { root: true },
          )
        })
        .catch((error) => {
          reject(error)
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  getShow({ state, commit, rootState }, payload) {},

  getUserDetails({ commit }, { id, payload }) {
    return new Promise((resolve, reject) => {
      UsersService.getUserById(id, payload)
        .then((response) => {
          let user = response.data
          fullName(user?.user_details)
          resolve(response, response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getPickupPersonDetails({ commit }, { id, payload }) {
    return new Promise((resolve, reject) => {
      UsersService.getPickupPersonById(id, payload)
        .then((response) => {
          response.data.records.forEach((user) => {
            fullName(user)
          })
          resolve(response, response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getMyPickupPersonDetails({ commit }, { id, payload }) {
    return new Promise((resolve, reject) => {
      UsersService.getMyPickupPersonById(id, payload)
        .then((response) => {
          response.data.records.forEach((user) => {
            fullName(user)
          })
          resolve(response, response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getGuardiansOfDetails({ commit }, { id, payload }) {
    return new Promise((resolve, reject) => {
      UsersService.getGuardianOfById(id, payload)
        .then((response) => {
          response.data.records.forEach((user) => {
            fullName(user)
          })
          resolve(response, response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getSectionStudentDetails({ commit }, { id, payload }) {
    return new Promise((resolve, reject) => {
      UsersService.getSectionStudentById(id, payload)
        .then((response) => {
          response.data.records.forEach((user) => {
            fullName(user)
          })
          resolve(response, response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getSectionTeacherDetails({ commit }, { id, payload }) {
    return new Promise((resolve, reject) => {
      UsersService.getSectionTeacherById(id, payload)
        .then((response) => {
          response.data.records.forEach((user) => {
            fullName(user)
          })
          resolve(response, response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getCampusAdminDetails({ commit }, { id, payload }) {
    return new Promise((resolve, reject) => {
      UsersService.getCampusAdminById(id, payload)
        .then((response) => {
          response.data.records.forEach((user) => {
            fullName(user)
          })
          resolve(response, response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getStaffDetails({ commit }, { id, payload }) {
    return new Promise((resolve, reject) => {
      UsersService.getStaffById(id, payload)
        .then((response) => {
          response.data.records.forEach((user) => {
            fullName(user)
          })
          resolve(response, response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  revokeUserEntityPermission({ commit }, id) {
    return new Promise((resolve, reject) => {
      UsersService.revokeUserEntityPermission(id)
        .then((response) => {
          resolve(response, response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  getPickupPersonStudents({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UsersService.getPickupPersonStudents(payload)
        .then((response) => {
          resolve(response, response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  getGuardianStudents({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UsersService.getGuardianStudents(payload)
        .then((response) => {
          resolve(response, response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  getTeacherClassesSections({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UsersService.getTeacherClassesSections(payload)
        .then((response) => {
          resolve(response, response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  getStudentClassesSections({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UsersService.getStudentClassesSections(payload)
        .then((response) => {
          resolve(response, response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
}
