<template>
  <div class="w-full relative border-b border-b-primary-purple-100" @click.stop="toggleIsOpen">
    <div
      v-if="currentStudent"
      class="w-full h-14 pb-2.5 px-2.5 rounded-lg flex items-center relative"
    >
      <div
        class="text-primary-purple-600 uppercase font-inter rounded-full w-8 h-8 min-w-[32px] min-h-[32px]"
        :class="
          currentStudent &&
          !currentStudent.image &&
          'bg-primary-purple-200 flex items-center justify-center'
        "
      >
        <img
          v-if="currentStudent?.image"
          class="object-cover rounded-full h-full w-full"
          :src="currentStudent.image"
          alt="user img not found"
        />
        <span v-else class="font-inter text-xs leading-[18px] font-medium sm:text-sm">
          {{ initials(`${currentStudent.first_name} ${currentStudent.last_name}`) }}
        </span>
      </div>
      <div class="text-primary-gray-700 font-inter ltr:ml-2 rtl:mr-2.5 truncate">
        <div class="text-sm font-medium truncate">
          {{ currentStudent.first_name }} {{ currentStudent.last_name }}
        </div>
        <div class="text-primary-gray-400 font-normal text-xs truncate">
          <span>{{ currentStudent.email }}</span>
        </div>
      </div>
      <div class="flex-1 ml-1">
        <icon
          :icon="chevronIconDirection"
          class="chevronIconPosition rtl:mr-2"
          width="12"
          height="6"
          :color="chevronIconColor"
        />
      </div>
    </div>
    <UiMenuDropDown :class="$style.dropDownPosition" :is-open="isOpen" class="bg-primary-purple-50">
      <ul
        class="font-inter py-2 w-auto font-normal text-sm md:text-base text-text-color border-b border-primary-purple-100"
      >
        <li class="flex ltr:flex-row rtl:flex-row-reverse items-center justify-between h-9 my-2">
          <div class="bg-primary-purple-100 my-4 p-2.5 w-full h-auto flex items-center relative">
            <div
              class="text-text-color rounded-full min-w-[28px] min-h-[28px] h-7 w-7"
              :class="
                currentStudent &&
                !currentStudent.image &&
                'bg-primary-purple-200 flex items-center justify-center'
              "
            >
              <img
                v-if="currentStudent?.image"
                class="object-cover rounded-full h-full w-full"
                :src="currentStudent.image"
                alt="user img not found"
              />
              <span
                v-else
                class="font-inter text-xs leading-[18px] font-medium sm:text-sm text-primary-purple-600"
              >
                {{ initials(`${currentStudent.first_name} ${currentStudent.last_name}`) }}
              </span>
            </div>
            <div class="text-primary-gray-700 font-inter ltr:ml-2 rtl:mr-2.5 truncate">
              <div class="text-sm font-medium truncate">
                {{ currentStudent.first_name }} {{ currentStudent.last_name }}
              </div>
              <div class="text-xs truncate text-primary-gray-400">
                <span>{{ currentStudent.email }}</span>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <ul class="overflow-y-auto max-h-[120px] pb-1">
        <div v-if="filterData.length > 0" class="pt-1">
          <li
            v-for="(student, index) in filterData"
            :key="index"
            class="cursor-pointer flex text-base font-inter hover:bg-primary-purple-100 items-center"
            @click="switchStudent(student)"
          >
            <div class="p-2.5 w-full h-auto flex items-center relative">
              <div
                class="text-text-color rounded-full min-w-[28px] min-h-[28px] h-7 w-7"
                :class="
                  student &&
                  !student.image &&
                  'bg-primary-purple-200 flex items-center justify-center'
                "
              >
                <img
                  v-if="student?.image"
                  class="object-cover rounded-full h-full w-full"
                  :src="student.image"
                  alt="user img not found"
                />
                <span
                  v-else
                  class="font-inter text-xs leading-[18px] font-medium sm:text-sm text-primary-purple-600"
                >
                  {{ initials(`${student.first_name} ${student.last_name}`) }}
                </span>
              </div>
              <div class="text-primary-gray-700 font-inter ltr:ml-2 rtl:mr-2.5 truncate">
                <div class="text-sm font-medium truncate">
                  {{ student.first_name }} {{ student.last_name }}
                </div>
                <div class="text-xs truncate text-primary-gray-400">
                  <span>{{ student.email }}</span>
                </div>
              </div>
            </div>
          </li>
        </div>
        <div v-else class="flex justify-center items-center h-14">
          <NoRecordFound custom-class="text-base py-2" />
        </div>
      </ul>
    </UiMenuDropDown>
  </div>
</template>

<script>
import UiMenuDropDown from '@components/UiElements/UiMenuDropDown.vue'
import icon from '@components/icons/icon.vue'
import { mapState, mapActions } from 'vuex'
import generalMixin from '@src/mixins/general-mixins.js'
import { switchCampusAndSection } from '@utils/settings/tenant-user.util'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'

export default {
  components: {
    UiMenuDropDown,
    NoRecordFound,
    icon,
  },

  mixins: [generalMixin],
  emits: ['closeStudentSwitchDropdown', 'openStudentSwitchDropdown'],

  data() {
    return {
      isOpen: false,
      isRoleList: false,
      searchQuery: '',
      studentList: [],
      forceRerender: 1,
      get currentStudent() {
        return JSON.parse(localStorage.getItem('currentActiveStudent'))
      },
      set currentStudent(value) {
        JSON.parse(localStorage.getItem('currentActiveStudent'))
      },
    }
  },
  computed: {
    ...mapState({
      classId: (state) => state.layout.currentSectionScope.id,
      campusId: (state) => state.layout.currentCampusScope.id,
      chevronIconColor() {
        return this.isOpen ? 'primary-purple-700' : 'primary-gray-700'
      },
      chevronIconDirection() {
        return !this.isOpen ? 'chevronBottom' : 'chevronUp'
      },
    }),
    /**
     * Filter Data
     * @returns {String} - filtered String
     * @description filter data from options list by input search query returning the filtered list.
     */
    filterData() {
      this.forceRerender
      const query = this.searchQuery.toLowerCase()
      return this.studentList.filter((item) => {
        if (this.currentStudent.user_id !== item.user_id) {
          return Object.values(item).some((word) => String(word).toLowerCase().includes(query))
        }
      })
    },
  },

  created() {
    this.studentList = JSON.parse(localStorage.getItem('guardianAssociatedStudents')) || []
  },
  methods: {
    /**
     * Toggle student dropdown
     */

    toggleIsOpen() {
      this.isOpen = !this.isOpen
      this.$emit('openStudentSwitchDropdown', this.isOpen)
    },

    toggleIsRoleList() {
      this.isRoleList = !this.isRoleList
    },
    closeIsOpen() {
      if (this.isOpen && this.searchQuery) {
        this.searchQuery = ''
      }
      this.isOpen = false
    },

    switchStudent(student) {
      this.$emit('closeStudentSwitchDropdown')
      let currentRoute = this.$route
      localStorage.setItem('currentActiveStudent', JSON.stringify(student))
      switchCampusAndSection(currentRoute)
      this.forceRerender++
    },
  },
}
</script>

<style lang="scss" module>
.dropDownPosition {
  top: auto;
  right: -1px;
  bottom: 66px !important;
  width: calc(100% + 2px);
  padding: 0;
  border: 1.5px solid white;
  border-top-width: 1.5px;
  border-right-width: 1.5px;
  border-bottom-color: var(--primary-purple-100);
  border-bottom-width: 1.5px;
  border-left-width: 1.5px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
</style>

<style>
.chevronIconPosition {
  text-align: -webkit-right;
}
</style>
