// import ScheduleService from './services'
import { SubjectService } from '@services/subject.service'
import i18n from '@src/i18n'
const t = i18n?.global?.t

export default {
  newSubject({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      SubjectService.createSubject(payload)
        .then((response) => {
          if (payload.id)
            commit(
              'toast/NEW',
              { type: 'success', message: 'Successfully Updated' },
              { root: true },
            )
          else {
            commit(
              'toast/NEW',
              { type: 'success', message: t(`toast.Successfully Created`) },
              { root: true },
            )
          }
          resolve(response)
        })
        .catch((error) => {
          commit('layout/IS_LOADING', false, { root: true })
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  getSubjectStats({ commit }, payload = '') {
    return new Promise((resolve, reject) => {
      return SubjectService.getSubjectStats(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  assignTeacherToSubject({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return SubjectService.assignTeacherToSubject(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  deleteSubject({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      SubjectService.deleteSubject(payload)
        .then((response) => {
          commit(
            'toast/NEW',
            { type: 'success', message: t(`toast.SUCCESSFULLY_DELETED`) },
            { root: true },
          )
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  classSubjects({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      SubjectService.getSubjects(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
}
